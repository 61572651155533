import { dateRangeOverlaps } from "./date-utils";
import { firestore as db } from "../services/firebase";

export let generateNewId = async (collectionName, incrementProperty) => {
    let newID = 100;

    try {
        let docs = (await db.collection(collectionName).orderBy(incrementProperty, "desc").limit(1).get()).docs;

        for (const doc of docs) {
            newID = Number(doc.data()[incrementProperty]) + 1;
        }

        return `${newID}`;
    } catch (e) {
        console.log(e);
    }

    return false;
};

export let generateNewIdFromDocs = (docs, incrementProperty) => {
    let greatestID = 99;
    for (const doc of docs) {
        let id = Number(doc.data()[incrementProperty]);
        if (id > greatestID) greatestID = id;
    }

    return greatestID + 1;
};

export let getSeasons = async (start, end) => {
    let seasons = [];
    try {
        seasons = (await db.collection("Season").get()).docs;
        seasons = seasons.filter((season) =>
            dateRangeOverlaps(start, end, season.data().start_date.toDate(), season.data().end_date.toDate())
        );
    } catch (err) {
        console.log(err);
    }

    return seasons;
};

export let getLinkedOutletBranchesIDs = async (branchID) => {
    return db.collection("Branch_Outlet_Branch").doc(branchID).collection("Outlet_Branch").get();
};

export let getLinkedOutletBranches = async (supplierID) => {
    let outletBranches = [];
    try {
        //* get supplier branches
        const supplierBranches = (await db.collection("Branch").where("supplier_id", "==", supplierID).get()).docs;
        //* get linked outlet branch IDs
        const OBIDsResult = await Promise.all(supplierBranches.map((sb) => getLinkedOutletBranchesIDs(sb.id)));
        //* process the result and get unique IDs
        let outletBranchesIDs = [];
        for (const snapshot of OBIDsResult) {
            const obIDs = snapshot.docs.map((doc) => doc.id);
            //merge 2 array without duplicates using ES6 destructuring
            outletBranchesIDs = [...outletBranchesIDs, ...obIDs];
        }

        //* get outlet branches info
        const OBResult = await Promise.all(
            outletBranchesIDs.map(async (id) => db.collection("Outlet_Branch").doc(id).get())
        );
        outletBranches = OBResult.map((obDoc) => obDoc.data());
    } catch (error) {
        console.log(error);
        return false;
    }

    return outletBranches;
};

//*      Project Collection       *//
export let getProject = async (id) => {
    let project = null;
    try {
        project = await db.collection("Project").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return project;
};

export let getProjectOutletBranch = async (projectID, supplierBranchID, outletBranchID) => {
    let outletBranch = null;
    try {
        outletBranch = await (await getProject(projectID)).ref
            .collection("Branch_Outlet_Product")
            .doc(supplierBranchID)
            .collection("Outlet_Product")
            .doc(outletBranchID)
            .get();
    } catch (error) {
        console.log(error);
    }

    return outletBranch;
};

//*      Supplier Collection       *//
export let getSupplier = async (id) => {
    let supplier = null;
    try {
        supplier = await db.collection("Supplier").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return supplier;
};

//*      Outlet Branch Collection       *//
export let getOutletBranch = async (id) => {
    let outletBranch = null;
    try {
        outletBranch = await db.collection("Outlet_Branch").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return outletBranch;
};

export let getOutletBranches = async (IDs) => {
    let outletBranches = [];

    try {
        outletBranches = await Promise.all(IDs.map(getOutletBranch));
    } catch (error) {
        console.log(error);
    }
    return outletBranches;
};

//*      Products Collection       *//
export let getProduct = async (id) => {
    let product = null;
    try {
        product = await db.collection("Product").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return product;
};

export let getProducts = async (IDs) => {
    let products = [];

    try {
        products = await Promise.all(IDs.map(getProduct));
    } catch (error) {
        console.log(error);
    }
    return products;
};

export let getNewMerchNumber = async () => {
    try {
        //get merchandising with with latest number
        const merchandiser = await db.collection("Merchandiser").orderBy("merchandiser_number", "desc").limit(1).get();

        //init merchandiser number
        let year = `${new Date().getFullYear()}`;
        let month = new Date().getMonth() + 1 < 10 ? `0${new Date().getMonth() + 1}` : `${new Date().getMonth() + 1}`;
        let shortNumber = "0000";
        let number = `MS-${year}-${month}-${shortNumber}`;

        //if merchandiser collection is empty
        if (merchandiser.empty) return number;

        let lastNumber = merchandiser.docs[0].data().merchandiser_number;
        let parts = lastNumber.split("-"); //ex ["MS", "2020", "06", "0005"]

        //if not in the same month or year, take the initial number above
        if (parts[1] !== year || parts[2] !== month) return number;

        //take the latest short number and increment it
        shortNumber = Number(parts[3]) + 1;
        shortNumber = String(shortNumber).padStart(4, "0");
        number = `MS-${year}-${month}-${shortNumber}`;

        return number;
    } catch (error) {
        console.log(error);
    }

    return false;
};

export let copyAllSupplierProducts = async (from, to) => {
    try {
        //get supplier's products
        const products = (await db.collection("Product").where("supplier_id", "==", from).get()).docs;
        let latestID = await generateNewId("Product", "product_id");
        //copy them with a target supplier id
        await Promise.all(products.map(async (product) => copyProductTo(to, product, latestID++)));
    } catch (error) {
        console.log(error);
    }
};

export let copyProductTo = async (targetID, productDoc, newID) => {
    try {
        const data = productDoc.data();

        //copy the data to the new document with the new supplier id
        const newData = {
            ...data,
            murtab_code: `${data.barcode}-${targetID}`,
            supplier_id: `${targetID}`,
            product_id: `${newID}`,
        };

        //check for duplicate
        const duplicate = await db.collection("Product").doc(`${newID}`).get();

        if (duplicate.exists) throw new Error(`a Product document ID: ${newID} already exists.`);

        await db.collection("Product").doc(`${newID}`).set(newData);

        return newData;
    } catch (error) {
        console.log(error);
    }

    return false;
};

export let copyAllSupplierBranches = async (from, to) => {
    try {
        //get supplier's products
        const products = (await db.collection("Branch").where("supplier_id", "==", from).get()).docs;
        //get latest id and increment from there based on products quantity
        let latestID = await generateNewId("Branch", "branch_id");

        //copy them with a target supplier id
        await Promise.all(products.map(async (product) => copySupplierBranchTo(to, product, latestID++)));
    } catch (error) {
        console.log(error);
    }
};

export let copySupplierBranchTo = async (targetID, branchDoc, newID) => {
    try {
        const data = branchDoc.data();
        //copy the data to the new document with the new supplier id
        const newData = {
            ...data,
            supplier_id: `${targetID}`,
            branch_id: `${newID}`,
        };

        //check for duplicate
        const duplicate = await db.collection("Branch").doc(`${newID}`).get();

        if (duplicate.exists) throw new Error(`a supplier branch document ID: ${newID} already exists.`);

        await db.collection("Branch").doc(`${newID}`).set(newData);

        return newData;
    } catch (error) {
        console.log(error);
    }

    return false;
};

export let copyAllExtraDisplayContracts = async (from, to) => {
    try {
        //get supplier's products
        const contracts = (await db.collection("Contract_Extra_Display").where("supplier_id", "==", from).get()).docs;
        //get latest id and increment from there based on products quantity
        const targetContracts = (await db.collection("Contract_Extra_Display").where("supplier_id", "==", to).get())
            .docs;
        let latestID = generateNewIdFromDocs(targetContracts, "short_id");

        //copy them with a target supplier id
        await Promise.all(contracts.map(async (product) => copyExtraDisplayContractTo(to, product, latestID++)));
    } catch (error) {
        console.log(error);
    }
};

export let copyExtraDisplayContractTo = async (targetID, doc, newID) => {
    try {
        const data = doc.data();
        //copy the data to the new document with the new supplier id
        const newData = {
            ...data,
            contract_id: `${targetID}-${newID}`,
            supplier_id: `${targetID}`,
            short_id: `${newID}`,
        };

        //check for duplicate
        const duplicate = await db.collection("Contract_Extra_Display").doc(`${targetID}-${newID}`).get();

        if (duplicate.exists)
            throw new Error(`an extra display contract document ID: ${targetID}-${newID} already exists.`);

        await db.collection("Contract_Extra_Display").doc(`${targetID}-${newID}`).set(newData);

        return newData;
    } catch (error) {
        console.log(error);
    }

    return false;
};

let getProjectOutletBranches = async (supplierBranchDoc) =>
    (await supplierBranchDoc.ref.collection("Outlet_Product").get()).docs;

let getSupplierBranchInfo = async (id) => {
    let branch = null;

    try {
        branch = await db.collection("Branch").doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return branch;
};

let getUniqueIDsInsideProjectBranches = (projectSupplierBranches) => {
    let outletBranchesIDs = [];
    let productsIDs = [];
    for (const suppBranch of projectSupplierBranches) {
        let outletBranches = suppBranch.outletBranches;
        for (const ob of outletBranches) {
            let obDoc = ob.doc;
            let alreadyIncluded = outletBranchesIDs.includes(obDoc.id);
            if (!alreadyIncluded) outletBranchesIDs.push(obDoc.id);

            for (const product of ob.products) {
                alreadyIncluded = productsIDs.includes(product.doc.id);
                if (!alreadyIncluded) productsIDs.push(product.doc.id);
            }
        }
    }

    return { outletBranchesIDs, productsIDs };
};

let getUniqueOutletIDFromBranches = (outletBranches) => {
    let outletIDs = [];

    for (const outletBranch of outletBranches) {
        let alreadyIncluded = outletIDs.includes(outletBranch.data().outlet_id);
        if (!alreadyIncluded) outletIDs.push(outletBranch.data().outlet_id);
    }

    return outletIDs;
};

let getLinkedProducts = async (outletID, supplierID) => {
    let linkedProducts = [];
    try {
        //get number of products registered in this outlet
        linkedProducts = (
            await db
                .collection("Supplier_Outlet_Product")
                .doc(supplierID)
                .collection("Outlet_Product")
                .doc(outletID)
                .collection("Product")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }

    return linkedProducts;
};
export let getWholeProject = async (projectID, relatedCollectionsIsIncluded = false) => {
    try {
        const project = await db.collection("Project").doc(projectID).get();
        const supplierBranchesDocs = (await project.ref.collection("Branch_Outlet_Product").get()).docs;

        //gather supplier branch data (firestore doc, supplier branch info and its outlet branch coverage)
        const supplierBranches = await Promise.all(
            supplierBranchesDocs.map(async (sbDoc) => {
                const sbRequests = [getProjectOutletBranches(sbDoc)];
                if (relatedCollectionsIsIncluded) sbRequests.push(getSupplierBranchInfo(sbDoc.id));

                const [outletBranchesDocs, supplierBranchInfo] = await Promise.all(sbRequests);

                // const outletBranchesDocs = (await sbDoc.ref.collection("Outlet_Product").get()).docs;
                const outletBranches = await Promise.all(
                    outletBranchesDocs.map(async (obDoc) => {
                        const products = (await obDoc.ref.collection("Product").get()).docs;
                        let data = obDoc.data();
                        data.start_time = data.start_time.toDate();
                        data.end_time = data.end_time.toDate();

                        return { doc: obDoc, data: data, products: products.map((p) => ({ doc: p, data: p.data() })) };
                    })
                );

                return { doc: sbDoc, data: sbDoc.data(), outletBranches: outletBranches, info: supplierBranchInfo };
            })
        );

        if (relatedCollectionsIsIncluded) {
            //inside project's scope, get unique outlet branch IDs, product IDs to prevent duplicate requests
            const { outletBranchesIDs, productsIDs } = getUniqueIDsInsideProjectBranches(supplierBranches);
            const [outletBranchesDocs, productDocs] = await Promise.all([
                getOutletBranches(outletBranchesIDs),
                getProducts(productsIDs),
            ]);

            //get the number of linked products per outlet
            const outletIDs = getUniqueOutletIDFromBranches(outletBranchesDocs);

            const projectLinkedProducts = await Promise.all(
                outletIDs.map(async (outletID) => {
                    const linkedProducts = await getLinkedProducts(outletID, project.data().supplier_id);
                    return { outletID, linkedProducts };
                })
            );

            //put the result each project outlet branch
            supplierBranches.forEach((suppBranch) => {
                let outletBranches = suppBranch.outletBranches;
                for (const outletBranch of outletBranches) {
                    let obDoc = outletBranch.doc;
                    let info = outletBranchesDocs.find((doc) => doc.id === obDoc.id);
                    if (info) outletBranch.info = info;
                    else throw Error(`could not find outlet branch's info in the database (${obDoc.id})`);

                    let linkedProducts = projectLinkedProducts.find((lp) => lp.outletID === info.data().outlet_id);
                    outletBranch.linkedProducts = linkedProducts || [];

                    for (const product of outletBranch.products) {
                        info = productDocs.find((doc) => doc.id === product.doc.id);
                        if (info) product.info = info;
                        else throw Error(`could not find product's info in the database (${product.doc.id})`);
                    }
                }
            });
        }

        let data = project.data();
        data.date_from = data.date_from.toDate();
        data.date_to = data.date_to.toDate();
        data.project_date = data.project_date.toDate();

        return { doc: project, data: data, supplierBranches: supplierBranches };
    } catch (error) {
        console.log(error);
    }
};

export let copyProject = async (projectID) => {
    try {
        const wholeProject = await getWholeProject(projectID);
        const latestID = await generateNewId("Project", "short_id");

        let data = wholeProject.doc.data();
        let yearShort = data.year.substr(2, 2);
        let month = Number(data.month) < 10 ? `0${data.month}` : data.month;
        const newProjectID = `M-${data.supplier_id}-${yearShort}${month}-${latestID}`;
        let newData = {
            ...data,
            short_id: `${latestID}`,
            project_id: newProjectID,
        };

        let newProjectRef = db.collection("Project").doc(newProjectID);

        //check for duplicate
        const duplicate = await newProjectRef.get();

        if (duplicate.exists) throw new Error(`a Project document ID: ${newProjectID} already exists.`);

        await newProjectRef.set(newData);

        await Promise.all(
            wholeProject.supplierBranches.map(async (sb) => {
                let data = sb.doc.data();
                let newSBRef = newProjectRef.collection("Branch_Outlet_Product").doc(sb.doc.id);
                await newSBRef.set(data);

                await Promise.all(
                    sb.outletBranches.map(async (ob) => {
                        let data = ob.doc.data();
                        let newOBRef = newSBRef.collection("Outlet_Product").doc(ob.doc.id);
                        await newOBRef.set(data);

                        await Promise.all(
                            ob.products.map(async (product) => {
                                let data = product.doc.data();
                                await newOBRef.collection("Product").doc(product.id).set(data);
                            })
                        );
                    })
                );
            })
        );
    } catch (error) {
        console.log(error);
    }
};

export let getSupplierMerchandisers = async (supplierID) => {
    let merchandisers = [];

    try {
        //* get merchandisers
        merchandisers = (
            await db.collection("Merchandiser").where("account_information.owner_id", "==", supplierID).get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return merchandisers;
};

export let getVAT = async () => {
    let vat = null;
    try {
        const today = new Date();

        vat = (await db.collection("VAT").where("start_date", "<=", today).orderBy("start_date", "desc").limit(1).get())
            .docs;

        if (vat.length > 0) {
            vat = vat[0];
        }
    } catch (error) {
        console.log(error);
    }

    return vat;
};

//generates random id;
export let guid = () => {
    let s4 = () => {
        return Math.floor((1 + Math.random()) * 0x10000)
            .toString(16)
            .substring(1);
    };
    //return id of format 'aaaaaaaa'-'aaaa'-'aaaa'-'aaaa'-'aaaaaaaaaaaa'
    return s4() + s4() + s4() + s4() + s4() + s4() + s4() + s4();
};

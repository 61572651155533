import React from "react";
import { Redirect } from "react-router";
import { DashboardHeader, EmailVerificationBar } from "..";
import PATHS, { PATHS_ACCESS } from "../../constants/paths";
import { useAuth } from "../../contexts/auth-context";
import { getDaysSinceSpecificDate } from "../../helpers/date-utils";
import { CompatRoute } from "react-router-dom-v5-compat";

const PrivateRoute = (props) => {
    const { currentUser, companyData, userData } = useAuth();
    const { children, path } = props;
    const oPath = props.oPath ?? null;
    if (!currentUser || !companyData || !userData) {
        // console.log(1);
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }

    // check if user is not verified and it's been 10 days since registration
    const daysElapsed = getDaysSinceSpecificDate(userData.created_at);
    if (!currentUser.emailVerified && daysElapsed > 10) {
        // console.log(2);
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }
    // console.log(3);
    if (!PATHS_ACCESS[userData.user_type][oPath ?? path])
        return <Redirect to={{ pathname: PATHS.DASHBOARD, state: { from: path } }} />;

    return (
        <CompatRoute {...props}>
            <DashboardHeader />
            <EmailVerificationBar />
            {children}
        </CompatRoute>
        // <Route {...props}>
        //     <DashboardHeader />
        //     <EmailVerificationBar />
        //     {children}
        // </Route>
    );
};

export default PrivateRoute;

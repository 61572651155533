import { COLLECTIONS } from "./constants";
import { firestore as db } from "../firebase";
import { flatten } from "lodash";
import { arrayChunks } from "../../helpers/array-helpers";

export const queryTaskDetails = async (query, { enableOnSnapshot = false, ...otherOptions } = {}) => {
    try {
        let ref = db.collection(COLLECTIONS.TASK_DETAILS);
        if (query.docID) {
            return await ref.doc(query.docID).get();
        }

        for (const op of query) {
            const { key, value, operator } = op;
            if (operator === "in") continue;
            ref = ref.where(key, operator, value);
        }

        if (enableOnSnapshot) {
            return ref.onSnapshot(otherOptions.onSnapshot, otherOptions.onError);
        }

        //check for in operator
        let inQuery = query.filter((op) => op?.operator === "in");
        if (inQuery.length > 1) throw new Error("There are more than 1 in operator");

        if (inQuery.length === 1) {
            const { key, value = null, operator = "==" } = inQuery[0];

            //inQuery value should an array
            const chunks = arrayChunks(value, 10);
            const res = await Promise.all(
                chunks.map(async (chunk) => {
                    return (await ref.where(key, operator, chunk).get()).docs;
                })
            );

            //flatten
            return flatten(res);
        } else {
            return (await ref.get()).docs;
        }
    } catch (error) {
        throw error;
    }
};

export const getDetailsFromTasks = async (
    tasks,
    { isEnded = null, isRemoved = null, enableOnSnapshot = false, onSnapshot = () => {}, onError = () => {} } = {}
) => {
    try {
        // converts tasks into chunks of 10
        const chunks = arrayChunks(tasks, 10);

        // result = [array of task details] or array of unsubs
        const result = await Promise.all(
            chunks.map(async (chunk, index) => {
                let query = [{ key: "task_id", operator: "in", value: chunk.map((task) => task.task_id) }];
                if (isRemoved !== null) query.push({ key: "removed", operator: "==", value: isRemoved });
                if (isEnded !== null) query.push({ key: "isEnded", operator: "==", value: isEnded });

                if (enableOnSnapshot) {
                    let unsub = await queryTaskDetails(query, {
                        enableOnSnapshot,
                        onSnapshot: (snapshot) => onSnapshot(snapshot, index, chunks.length),
                        onError: (error) => onError(error, index),
                    });
                    return unsub;
                } else {
                    let details = await queryTaskDetails(query);
                    //doc to data
                    return details.map((doc) => doc.data());
                }
            })
        );

        return flatten(result);
    } catch (error) {
        throw error;
    }
};

import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import { calculateWeeklyVisits } from "../../../helpers/misc";
import styles from "./styles";
// import { Font } from "./fonts.js";

let rowsPerPage = 15;
let totalPages = 0;

let formatAMPM = (date) => {
    var hours = date.getHours();
    var minutes = date.getMinutes();
    var ampm = hours >= 12 ? "pm" : "am";
    hours = hours % 12;
    hours = hours ? hours : 12; // the hour '0' should be '12'
    minutes = minutes < 10 ? "0" + minutes : minutes;
    var strTime = hours + ":" + minutes + " " + ampm;
    return strTime;
};

let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

let createRow = (row) => {
    return (
        <View style={styles.row} key={`PDF Row ${Math.random()}`}>
            {/* <Text style={styles.cellWide}>{row.supplierBranchName}</Text> */}
            <Text style={styles.cellWide}>{row.outletBranchName}</Text>
            <View style={row.merchDays.sun.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.mon.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.tue.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.wed.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.thu.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.fri.selected ? styles.dayCellColored : styles.dayCell}></View>
            <View style={row.merchDays.sat.selected ? styles.dayCellColored : styles.dayCell}></View>
            <Text style={styles.cell}>{row.totalVisits}</Text>
            <Text style={styles.cell}>{formatAMPM(row.startTime)}</Text>
            <Text style={styles.cell}>{formatAMPM(row.endTime)}</Text>
            <Text style={styles.cell}>{row.duration} Min.</Text>
        </View>
    );
};

let createPage = (info, rows, pageNum) => {
    let rowsToDisplay = rows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="landscape" style={styles.page} key={`PDF ${Math.random()}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Appendix A | Project Timing & Coverage</Text>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Project No.: <Text style={styles.bold}>{info.projectId}</Text>
                    </Text>
                    <Text>
                        Project Period: {formatDate(info.startDate)} - {formatDate(info.endDate)}
                    </Text>
                    <Text>
                        Supplier: <Text style={styles.bold}>{`${info.supplierName} | ${info.supplierCompany}`}</Text>
                    </Text>
                </View>
                {/* <View style={styles.right}>
                    <Text>Phone Number: {phone}</Text>
                    <Text>Issued Date: {formatDate(new Date())}</Text>
                    <Text>Issued By: {exportedBy}</Text>
                </View> */}
            </View>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={styles.headerRow}>
                            {/* <View style={styles.headerCellWide}>
                                <Text style={styles.headerCellText}>Supplier Branch</Text>
                            </View> */}

                            <View style={styles.headerCellWide}>
                                <Text style={styles.headerCellText}>Outlet Branch</Text>
                            </View>

                            {/* Cell Group spans mulitple rows */}
                            <View style={styles.rowGroup}>
                                <View style={styles.rowGroupRow}>
                                    <View style={styles.lastHeaderCell}>
                                        <Text style={styles.headerCellText}>Merchandising Days</Text>
                                    </View>
                                </View>
                                <View style={styles.lastRowGroupRow}>
                                    {/* Cell Group spans mulitple column */}
                                    <View style={styles.columnGroup}>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Sun</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Mon</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Tue</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Wed</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Thu</Text>
                                        </View>
                                        <View style={styles.headerCell}>
                                            <Text style={styles.headerCellText}>Fri</Text>
                                        </View>
                                        <View style={styles.lastHeaderCell}>
                                            <Text style={styles.headerCellText}>Sat</Text>
                                        </View>
                                    </View>
                                </View>
                            </View>

                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Total Visits</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Start Time</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>End Time</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Duration</Text>
                            </View>
                        </View>
                    </View>

                    {/* Table Body */}
                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((row, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;

                            return createRow(row, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>C.R: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let flattenCoverageArray = (coverageDetails) => {
    let rows = [];
    for (const cd of coverageDetails) {
        let totalVisits = calculateWeeklyVisits(cd.scheduleData.merchandising_days);
        let temp = {
            supplierBranchName: cd.supplierBranchInfo.En_name,
            outletBranchName: cd.outletBranchInfo.En_short_name,
            merchDays: cd.scheduleData.merchandising_days,
            startTime: cd.scheduleData.merchandising_days.sun.start_time,
            endTime: cd.scheduleData.merchandising_days.sun.end_time,
            duration: cd.scheduleData.duration,
            totalVisits: totalVisits,
        };

        rows = [...rows, temp];
    }
    return rows;
};
let createDoc = (info) => {
    const { coverageDetails } = info;
    //convert 2d array into 1d array1
    let rows = flattenCoverageArray(coverageDetails);
    totalPages = Math.ceil(rows.length / rowsPerPage);
    let pages = [];
    for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        pages[pageNum] = createPage(info, rows, pageNum);
    }

    return pages;
};

// Create Document Component
const CoverageAndTimingPdf = (props) => {
    let document = createDoc(props.info);

    return <Document>{document}</Document>;
};

export default CoverageAndTimingPdf;

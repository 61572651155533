import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    arabic: {
        direction: "rtl",
        textAlign: "right",
    },
    root: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        maxWidth: "1200px",

        "& h2": {
            fontSize: "1.4rem",
            fontWeight: "bold",
        },
        "& h3": {
            fontSize: "1.3rem",
        },
        "& h4": {
            fontSize: "1.15rem",
        },
        "& p": {
            fontSize: "1rem",
        },
    },
});

export default styles;

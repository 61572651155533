import * as Yup from "yup";

const objectRules = (object, rule) => Object.keys(object).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

export const FORM_SCHEMA = Yup.object().shape({
    products: Yup.lazy((obj) =>
        Yup.object().shape({
            ...objectRules(
                obj,
                Yup.object().shape({
                    minimum_stock: Yup.number().required(),
                    maximum_stock: Yup.number().required(),
                })
            ),
        })
    ),
});

export const INITIAL_VALUES = {
    products: {
        //product_id: {
        //    minimum_stock: 0,
        //    maximum_stock: 0,
        //},
    },
};

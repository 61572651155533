export let calculateWeeklyVisits = (merchDays) => {
    let totalVisits = 0;
    for (const day in merchDays) {
        if (Object.hasOwnProperty.call(merchDays, day)) {
            totalVisits += merchDays[day].selected ? 1 : 0;
        }
    }

    return totalVisits;
};

export const stringifyProductPacking = (product) => {
    return `${product.outer_unit <= 1 ? "" : product.outer_unit + "x"}${
        product.packing.no_units <= 1 ? "" : product.packing.no_units + "x"
    }${product.packing.size}${product.packing.size_unit}`;
};

import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import CustomTextField from "./CustomTextField";
import { Button, InputAdornment } from "@material-ui/core";
import ClearIcon from "@material-ui/icons/Clear";
import FileButton from "../CustomButtons/FileButton.jsx";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles({
    uploadBtn: {
        margin: "4px",
        color: COLORS.background,
        backgroundColor: COLORS.submit,
    },
    downloadBtn: {
        margin: "4px 0 4px 4px",
        color: COLORS.background,
        backgroundColor: COLORS.accent,
    },

    disabledBtn: {
        margin: "4px 0 4px 4px",
        color: "white",
        backgroundColor: "grey",
    },
});

let CustomFileInput = (props) => {
    const classes = useStyles(props);
    return (
        <>
            <CustomTextField
                rootProps={props.rootProps}
                inputProps={{
                    endAdornment:
                        !props.fileURL && !props.fileObject ? null : (
                            <InputAdornment onClick={props.onClear} style={{ cursor: "pointer" }} position="end">
                                <ClearIcon />
                            </InputAdornment>
                        ),
                    style: {
                        borderBottom: "1px solid rgba(0, 0, 0, 0.42)",
                    },
                }}
            />
            <FileButton
                label={"Upload"}
                id={`${props.rootProps.id}-upload-input-button`}
                buttonProps={{
                    className: classes.uploadBtn,
                }}
                onChange={props.onFileSelect}
                accept={"application/pdf, .pdf"}
            />

            <a href={props.fileURL} download rel="noopener noreferrer" target="_blank">
                <Button
                    variant="contained"
                    component="span"
                    disabled={props.fileURL ? false : true}
                    className={props.fileURL ? classes.downloadBtn : classes.disabledBtn}
                >
                    Download
                </Button>
            </a>
        </>
    );
};

CustomFileInput.propTypes = {
    classes: PropTypes.object,
};

export default CustomFileInput;

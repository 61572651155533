import React, { useState } from "react";
import { useHistory } from "react-router";
import styles from "./styles";
import { LogoHeader } from "../../core-ui/custom";
import Title from "../../core-ui/custom/title";
import { FormHeader, SubmitButton, TextField } from "../../core-ui/forms-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { TransitionAlert } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { Link } from "react-router-dom";
import { isEmailExist } from "../../helpers/firebase-helpers";

const FORM_SCHEMA = Yup.object().shape({
    email: Yup.string().email("Invalid email.").required("required"),
});

const ForgotPassword = () => {
    const classes = styles();
    const history = useHistory();
    const [showInfoAlert, setShowInfoAlert] = useState(false);
    const [loading, setLoading] = useState(false);
    const [error, setError] = useState("");
    const { authLoading, sendResetPassword } = useAuth();

    const handleSubmit = async (values) => {
        try {
            setError("");

            setLoading(true);
            setShowInfoAlert(false);

            const { email } = values;
            const isExist = await isEmailExist(email);
            if (!isExist) {
                setError("This Email Does not exist.");
            } else {
                const res = await sendResetPassword(email);
                if (res?.data?.success) setShowInfoAlert(true);
            }
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
        }
        setLoading(false);
    };

    return (
        <section className={classes.signinPage}>
            <TransitionAlert
                key="signin"
                severity="info"
                variant="filled"
                open={showInfoAlert}
                setOpen={setShowInfoAlert}
            >
                A reset link has been sent to your email.
            </TransitionAlert>

            <LogoHeader variant="dark" />

            <section className={classes.container}>
                <div className={classes.formSection}>
                    <Title>Forgot Password</Title>
                    <Formik
                        initialValues={{ email: history.location.state?.email || "" }}
                        validationSchema={FORM_SCHEMA}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <FormHeader>Enter your email address and we will send you a reset link</FormHeader>
                            <TextField className={classes.input} name="email" label="Email" type="email" />
                            {error && <p className={classes.errorMsg}>{error}</p>}
                            <SubmitButton className={classes.submitBtn} submitting={loading || authLoading}>
                                Submit
                            </SubmitButton>
                            <Link to="/sign-in">Sign In</Link>
                        </Form>
                    </Formik>
                </div>
            </section>
        </section>
    );
};

export default ForgotPassword;

import React, { Fragment } from "react";

//related to meterial ui package
import styles from "./styles";
import { Header, Seperator, TransitionAlert } from "../../core-ui/custom";
import useProductStockSettings from "./use-product-stock-settings";
import { Filters } from "../../components";
import { Form, Formik } from "formik";
import { FORM_SCHEMA } from "./schema";
import { Card, Grid, InputAdornment, Typography } from "@material-ui/core";
import { SubmitButton, TextField } from "../../core-ui/forms-ui";

const ProductStockSettings = (props) => {
    const classes = styles();

    const {
        initializing,
        loading,
        saving,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,
        initialValues,
        handleSubmit,
        products,
    } = useProductStockSettings();

    return (
        <section className={classes.pageRoot}>
            <TransitionAlert
                variant="filled"
                severity={alertState.severity}
                open={alertState.open}
                setOpen={setAlertOpen}
                className={classes.stickyAlert}
                timer={3000}
            >
                {alertState.message}
            </TransitionAlert>
            <Header className={classes.pageHeader}>Product Stock Settings Per Outlet Branch</Header>
            <div className={classes.pageRoot}>
                <Filters
                    init={initFilters}
                    onSubmit={applyFilters}
                    classes={{ root: classes.filtersRoot, gridContainer: classes.filtersContainer }}
                    submitText="Apply"
                />
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={FORM_SCHEMA}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <div className={classes.cardContainer}>
                                    <Typography component="h2" className={classes.cardTitle}>
                                        Products
                                    </Typography>
                                    <Card className={classes.formCard}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.discriptionText}>
                                                    <b>Minimum Stock:</b> Minimum stock of the product
                                                </Typography>
                                                <Typography className={classes.discriptionText}>
                                                    <b>Maximum Stock:</b> Maximum stock of the product
                                                </Typography>
                                            </Grid>
                                            <Seperator />
                                            {Object.keys(formik.values.products).map((productID, index, arr) => {
                                                const lastIndex = index === arr.length - 1;
                                                const product = products.find((p) => p.product_id === productID);
                                                const productName = product?.En_name ?? "Unknown";
                                                const productImage = product?.product_image?.product_image1 ?? "";
                                                const productCode = product?.product_number ?? "";
                                                return (
                                                    <Fragment key={`product-${productID}`}>
                                                        <Grid
                                                            item
                                                            xs={12}
                                                            sm={4}
                                                            md={6}
                                                            className={classes.productInfo}
                                                        >
                                                            {productImage && (
                                                                <img
                                                                    src={productImage}
                                                                    alt={"Product Image"}
                                                                    className={classes.productImage}
                                                                />
                                                            )}
                                                            <Typography className={classes.discriptionText}>
                                                                <span className={classes.productName}>
                                                                    <b>{productName}</b>
                                                                </span>
                                                                {productCode}
                                                            </Typography>
                                                        </Grid>
                                                        <Grid item xs={6} sm={4} md={3}>
                                                            <TextField
                                                                name={`products.${productID}.minimum_stock`}
                                                                label={"Min. Stock"}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Typography>Qty</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        <Grid item xs={6} sm={4} md={3}>
                                                            <TextField
                                                                name={`products.${productID}.maximum_stock`}
                                                                label={"Max. Stock"}
                                                                type="number"
                                                                InputProps={{
                                                                    endAdornment: (
                                                                        <InputAdornment position="end">
                                                                            <Typography>Qty</Typography>
                                                                        </InputAdornment>
                                                                    ),
                                                                }}
                                                            />
                                                        </Grid>
                                                        {!lastIndex && <Seperator />}
                                                    </Fragment>
                                                );
                                            })}
                                        </Grid>
                                    </Card>
                                    <SubmitButton
                                        type="submit"
                                        className={classes.submitBtn}
                                        loadingClassName={classes.circularProgress}
                                        submitting={saving}
                                        // disabled={saving}
                                    >
                                        Save
                                    </SubmitButton>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </section>
    );
};

export default ProductStockSettings;

import { get } from "../../helpers/object-utils";
import { firestore as db } from "../firebase";

export const getMerchandiser = async (merchandiserId) => {
    try {
        return await db.collection("Merchandiser").doc(merchandiserId).get();
    } catch (error) {
        throw error;
    }
};

//===============================================================

//########################## Utilities ##########################

//===============================================================

export let getMerchandiserName = (merchandiser) => {
    let merchName = get(merchandiser, "personal_information.name.english");
    merchName = `${merchName.first}${merchName.middle ? ` ${merchName.middle}` : ""}${
        merchName.last ? ` ${merchName.last}` : ""
    }`;
    return merchName;
};

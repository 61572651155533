import { Link } from "react-router-dom";
import PATHS from "../../../constants/paths";
import Logo from "../logo";
import styles from "./styles";

const LogoWithText = ({ variant, classes: customClasses = {}, ...otherProps }) => {
    const classes = styles();

    let textClasses = `${classes.text} ${variant === "dark" ? classes.textDark : ""}`;

    return (
        <div className={`${classes.logo} ${customClasses.logo}`}>
            <Link to={PATHS.DASHBOARD}>
                <Logo />
            </Link>
            <h1 className={textClasses}>Murattab Supplier</h1>
        </div>
    );
};

export default LogoWithText;

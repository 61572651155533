import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";

import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

//import FilterListIcon from '@material-ui/icons/FilterList';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { defaultFont } from "../core-ui/core-styles/jss/material-kit-ui";

// core components

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import Swal from "sweetalert2";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";
import Box from "@material-ui/core/Box";
import Button from "../core-ui/material-kit-ui/buttons/Button";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";

import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import { COLORS } from "../constants/constants-lagacy";

import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";

import { generatePDF } from "../helpers/pdf-utils";
import ContractExtraDisplayPdf from "../components-lagacy/PDF/extra-display-contracts";

import AuthContext from "../contexts/auth-context";

const styles = (theme) => ({
    button: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "contract_no", numeric: false, disablePadding: true, label: "Contract No." },
    { id: "outlet_name", numeric: false, disablePadding: true, label: "Outlet Name" },
    { id: "date_from", numeric: false, disablePadding: true, label: "Date From" },
    { id: "date_to", numeric: false, disablePadding: true, label: "Date To" },
    { id: "date_of_contract", numeric: false, disablePadding: true, label: "Date of Contract" },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: COLORS.background, padding: "0px", margin: "0px" }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        style={{
                            color: COLORS.primary,
                        }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {headRows.map((row) => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? "right" : "left"}
                        //padding={row.disablePadding ? 'none' : 'default'}
                        style={{ paddingRight: "15px", paddingLeft: "0px" }}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                            style={{
                                color: COLORS.primary,
                                paddingBottom: "5px",
                                paddingTop: "5px",
                                fontWeight: "500",
                                fontSize: "14px",
                            }}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: "#d50000",
                  backgroundColor: lighten("#d50000", 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: "#d50000",
              },
    spacer: {
        flex: "1 1 50%",
    },
    actions: {
        color: "#d50000",
    },
    title: {
        flex: "0 0 auto",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        // contractExtraDisplay,
        selectedRowName,
        numSelected,
        handleShowDeleteAlertMethod,
        handleAddNewContractExtraDisplayMethod,
        handleContractExtraDisplayPdfMethod,
        handleEditNewContractExtraDisplayInfoMethod,
        loading,
    } = props;
    const id = selectedRowName[0];

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        Contract Extra Display ({numSelected} selected)
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Contract Extra Display
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div>
                {numSelected > 0 ? (
                    numSelected === 1 ? (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    marginRight: "22px",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.button}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Edit"
                                    placement={"top"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        onClick={() => {
                                            handleEditNewContractExtraDisplayInfoMethod(id);
                                        }}
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        style={{ padding: 0 }}
                                    >
                                        <EditIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    ) : (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    )
                ) : (
                    <List
                        style={{
                            ...defaultFont,
                            fontSize: "14px",
                            margin: 0,
                            paddingLeft: "0",
                            listStyle: "none",
                            paddingTop: "0",
                            paddingBottom: "0",
                            color: COLORS.primary,
                        }}
                    >
                        <ListItem
                            style={{
                                float: "left",
                                color: COLORS.primary,
                                position: "relative",
                                display: "block",
                                width: "auto",
                                margin: "0",
                                padding: "0",
                            }}
                        >
                            <Tooltip
                                id="instagram-twitter"
                                title="Add New Contract Extra Display"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                                style={{ float: "right" }}
                            >
                                <Button
                                    onClick={() => {
                                        handleAddNewContractExtraDisplayMethod();
                                    }}
                                    simple
                                    //color="google"
                                    block
                                    className={classes.textCenter}
                                    style={{ padding: 0 }}
                                >
                                    <AddIcon
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "40px",
                                            padding: "7px",
                                            backgroundColor: COLORS.accent,
                                            color: COLORS.font,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </ListItem>

                        <ListItem
                            style={{
                                float: "left",
                                color: COLORS.primary,
                                position: "relative",
                                display: "block",
                                width: "auto",
                                margin: "0",
                                padding: "0",
                                display: loading ? "none" : "block",
                            }}
                        >
                            <Tooltip
                                id="instagram-twitter"
                                title="PDF"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                                style={{ float: "right" }}
                            >
                                <Button
                                    onClick={() => {
                                        handleContractExtraDisplayPdfMethod();
                                    }}
                                    simple
                                    //color="google"
                                    block
                                    className={classes.textCenter}
                                    style={{ padding: 0 }}
                                >
                                    <PictureAsPdfIcon
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "40px",
                                            padding: "7px",
                                            backgroundColor: COLORS.accent,
                                            color: COLORS.font,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </ListItem>
                    </List>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleShowDeleteAlertMethod: PropTypes.func,
    selectedRowName: PropTypes.array,
    handleEditNewContractExtraDisplayInfoMethod: PropTypes.func,
    handleAddNewContractExtraDisplayMethod: PropTypes.func,
    handleContractExtraDisplayPdfMethod: PropTypes.func,
    contractExtraDisplay: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(1),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function EnhancedTable(props) {
    const {
        contractExtraDisplay,
        handleShowDeleteAlertMethod,
        handleEditNewContractExtraDisplayInfoMethod,
        handleAddNewContractExtraDisplayMethod,
        handleContractExtraDisplayPdfMethod,
        loading,
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState(contractExtraDisplay);
    // const [selectedRow, setSelectedRow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(true);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = contractExtraDisplay.map((n) => n.contract_id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        // setSelectedRow(name);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, contractExtraDisplay.length - page * rowsPerPage);

    return (
        <div className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    contractExtraDisplay={contractExtraDisplay}
                    selectedRowName={selected}
                    numSelected={selected.length}
                    handleShowDeleteAlertMethod={handleShowDeleteAlertMethod}
                    handleEditNewContractExtraDisplayInfoMethod={handleEditNewContractExtraDisplayInfoMethod}
                    handleAddNewContractExtraDisplayMethod={handleAddNewContractExtraDisplayMethod}
                    handleContractExtraDisplayPdfMethod={handleContractExtraDisplayPdfMethod}
                    loading={loading}
                />

                <div className={classes.tableWrapper}>
                    <Table
                        className={classes.table}
                        aria-labelledby="tableTitle"
                        size={dense ? "small" : "medium"}
                        style={{ tableLayout: "outo", whiteSpace: "nowrap" }}
                    >
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={contractExtraDisplay.length}
                        />
                        <TableBody>
                            {stableSort(contractExtraDisplay, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.contract_id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.contract_id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.contract_id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{
                                                        color: COLORS.primary,
                                                        //boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
                                                    }}
                                                    //labelStyle={{color: "#d50000"}}
                                                    //iconStyle={{fill: "#d50000"}}
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </TableCell>
                                            <TableCell
                                                style={{ paddingRight: "15px" }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.contract_no}
                                            </TableCell>
                                            <TableCell
                                                style={{ paddingRight: "15px" }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.outlet_name}
                                            </TableCell>
                                            <TableCell
                                                style={{ paddingRight: "15px" }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.date_from.split("/").reverse().join("/")}
                                            </TableCell>
                                            <TableCell
                                                style={{ paddingRight: "15px" }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.date_to.split("/").reverse().join("/")}
                                            </TableCell>
                                            <TableCell
                                                style={{ paddingRight: "15px" }}
                                                component="th"
                                                id={labelId}
                                                scope="row"
                                                padding="none"
                                            >
                                                {row.date_of_contract.split("/").reverse().join("/")}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={contractExtraDisplay.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

//==================================================================================
//==========================ContractExtraDisplayList======================
//==================================================================================

class ContractExtraDisplayList extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            contractExtraDisplay: [],
            showDeleteAlert: false,
            checkedB: false,
            tableDisplay: 2,
            contractExtraDisplayDisplayed: [],
            activeContractExtraDisplay: [],
            notActiveContractExtraDisplay: [],
            supermarkets: [],

            contract_no: "",
            outlet_id: "",

            searchCount: 0,
            pathEditContractExtraDisplayInfo: "",
            editContractExtraDisplayId: "",
            pathAddNewContractExtraDisplay: "",
            pathBack: "",
            loading: true,
        };
    }

    componentDidMount() {
        let datat = "";
        let datat2 = [];
        let datat3 = [];
        let datat4 = [];
        let extraDisplayTypes = [];

        //getting extra displays types (cuz we need thier ids and names):
        var extraDisplayTypesRef = db.collection("Extra_Display_Price");
        extraDisplayTypesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    datat = doc.data();
                    extraDisplayTypes.push(datat);
                });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
        datat = "";

        var contractsRef = db.collection("Contract_Extra_Display").where("supplier_id", "==", this.props.id);
        contractsRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    datat = doc.data();
                    datat.outlet_name = "-";
                    datat.date_from = (
                        datat.date_from.toDate().getFullYear() +
                        "/" +
                        (datat.date_from.toDate().getMonth() + 1) +
                        "/" +
                        datat.date_from.toDate().getDate()
                    ).toString();

                    datat.date_to = (
                        datat.date_to.toDate().getFullYear() +
                        "/" +
                        (datat.date_to.toDate().getMonth() + 1) +
                        "/" +
                        datat.date_to.toDate().getDate()
                    ).toString();

                    datat.date_of_contract = (
                        datat.date_of_contract.toDate().getFullYear() +
                        "/" +
                        (datat.date_of_contract.toDate().getMonth() + 1) +
                        "/" +
                        datat.date_of_contract.toDate().getDate()
                    ).toString();

                    datat2.push(datat);
                    if (datat.status === "0") {
                        datat3.push(datat);
                    } else if (datat.status === "1") {
                        datat4.push(datat);
                    }
                });

                //this.setState({ contractExtraDisplay: datat2, activeContractExtraDisplay: datat4, contractExtraDisplayDisplayed: datat4, notActiveContractExtraDisplay: datat3 });
            })
            .then(() => {
                let supermarkets = [];
                for (let i = 0; i < datat2.length; i++) {
                    var supermarketsRef = db.collection("Outlet_Branch").where("branch_id", "==", datat2[i].outlet_id);
                    supermarketsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            supermarkets.push({
                                value: doc.data().branch_id,
                                label: doc.data().En_short_name,
                            });
                            if (datat2[i].status === "1") {
                                let index = datat4.findIndex((post) => {
                                    return post.contract_id === datat2[i].contract_id;
                                });
                                if (index !== -1) {
                                    datat4[index].outlet_name = doc.data().En_short_name;
                                }
                            } else {
                                let index = datat3.findIndex((post) => {
                                    return post.contract_id === datat2[i].contract_id;
                                });

                                if (index !== -1) {
                                    datat3[index].outlet_name = doc.data().En_short_name;
                                }
                            }
                        });

                        this.setState({
                            supermarkets: supermarkets,
                            contractExtraDisplay: datat2,
                            extraDisplayTypes: extraDisplayTypes,

                            activeContractExtraDisplay: datat4,
                            contractExtraDisplayDisplayed: datat4,
                            notActiveContractExtraDisplay: datat3,
                            loading: false,
                        });
                    });
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    }

    handleShowDeleteAlert = (contractExtraDisplay, numSelected) => {
        if (this.state.tableDisplay === 3 || this.state.tableDisplay === 4) {
            if (contractExtraDisplay.length > 1) {
                alert("The contract extra display have already been deleted");
            } else {
                alert("The contract extra display has already been deleted");
            }
        } else {
            this.deleteAlertWindow(contractExtraDisplay);
        }
    };

    deleteAlertWindow = (contractExtraDisplay) => {
        if (contractExtraDisplay.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Activities !",
                text: "Do you want to delete these contract extra display?",
            }).then((result) => {
                if (result.value) {
                    this.setState({ showDeleteAlert: false });
                } else {
                    this.deleteAlertWindow2(contractExtraDisplay);
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Contract Extra Display !",
                text: "Do you want to delete this contract extra display?",
            }).then((result) => {
                if (result.value) {
                    this.setState({ showDeleteAlert: false });
                } else {
                    this.deleteAlertWindow2(contractExtraDisplay);
                }
            });
        }
    };

    deleteAlertWindow2 = (contractExtraDisplay) => {
        if (contractExtraDisplay.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Activities !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (result.value) {
                    this.setState({ showDeleteAlert: false });
                } else {
                    this.deleteRow(contractExtraDisplay);
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Contract Extra Display !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (result.value) {
                    this.setState({ showDeleteAlert: false });
                } else {
                    this.deleteRow(contractExtraDisplay);
                }
            });
        }
    };

    deleteRow = (contractExtraDisplay) => {
        for (let i = 0; i < contractExtraDisplay.length; i++) {
            let status = "0";
            let used = false;
            //delete the Contract Extra Display from firestore collection(Contract_Extra_Display)
            let contractExtraDisplayRef = db
                .collection("Contract_Extra_Display")
                .where("contract_id", "==", contractExtraDisplay[i]);
            contractExtraDisplayRef
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        status = doc.data().status;
                        used = doc.data().used;
                        if (status === "0") {
                            alert("The contract (" + doc.data().contract_no + ") has already been deleted");
                        }
                        if (used) {
                            alert(
                                "Sorry, you can't delete the contract (" +
                                    doc.data().contract_no +
                                    "), because it is used with some projects"
                            );
                        } else {
                            doc.ref.update({
                                status: "0",
                            });
                        }
                    });
                })
                .then(() => {
                    if (/*status === "0" ||*/ status === "1" && !used) {
                        //delete contract extra display from All
                        const index = this.state.contractExtraDisplay.findIndex((post) => {
                            return post.contract_id === contractExtraDisplay[i];
                        });

                        //var deletedContractExtraDisplayItem =[];
                        var arrayAllContractExtraDisplay = [...this.state.contractExtraDisplay];
                        if (index !== -1) {
                            arrayAllContractExtraDisplay[index].status = "0";
                        }

                        if (this.state.tableDisplay === 2) {
                            // delete contract extra display from active array
                            const index2 = this.state.activeContractExtraDisplay.findIndex((post) => {
                                return post.contract_id === contractExtraDisplay[i];
                            });

                            if (index2 !== -1) {
                                var deletedContractExtraDisplayItem = [];
                                //delete contract extra display from active array
                                var array = [...this.state.activeContractExtraDisplay];
                                deletedContractExtraDisplayItem = array.splice(index2, 1);

                                //add contract extra display into notActive array
                                deletedContractExtraDisplayItem[0].status = "0";
                                var array2 = [...this.state.notActiveContractExtraDisplay];
                                array2.push(deletedContractExtraDisplayItem[0]);
                                this.setState({
                                    contractExtraDisplay: arrayAllContractExtraDisplay,
                                    activeContractExtraDisplay: array,
                                    contractExtraDisplayDisplayed: array,
                                    notActiveContractExtraDisplay: array2,
                                });
                            }
                        }
                    }
                });
        } //for
    };

    isItemSelected = (name) => {
        alert(name);
        this.setState({ checkedB: !this.state.checkedB });
    };

    doSearch = () => {
        if (this.state.tableDisplay === 1) {
            let array = [...this.state.contractExtraDisplay];
            this.search2(array);
        } else if (this.state.tableDisplay === 2) {
            let array = [...this.state.activeContractExtraDisplay];
            this.search2(array);
        } else if (this.state.tableDisplay === 3) {
            let array = [...this.state.notActiveContractExtraDisplay];
            this.search2(array);
        } else {
            let array = [...this.state.deletedContractExtraDisplay];
            this.search2(array);
        }
    };
    //==================================================================================
    search2 = (array) => {
        let queryResult = [];
        array.forEach((extra_display_price) => {
            if (
                (extra_display_price.outlet_id.toLowerCase().search(this.state.outlet_id.toLowerCase()) > -1 ||
                    this.state.outlet_id.length === 0) &&
                (extra_display_price.contract_no.toLowerCase().search(this.state.contract_no.toLowerCase()) > -1 ||
                    this.state.contract_no.length === 0)
            )
                queryResult.push(extra_display_price);
        });
        // console.log(this.state.contract_no)
        // console.log(this.state.outlet_id)
        // console.log(queryResult)
        this.setState({
            contractExtraDisplayDisplayed: queryResult,
        });
    };

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };

    handleEditNewContractExtraDisplayInfo = (id) => {
        let index = this.state.contractExtraDisplayDisplayed.findIndex((post) => {
            return post.contract_id === id;
        });

        if (index !== -1 && !this.state.contractExtraDisplayDisplayed[index].used) {
            //console.log("editContractExtraDisplayId", id)
            this.setState({
                pathEditContractExtraDisplayInfo: PATHS.UPDATE_EXTRA_DISPLAY_CONTRACT,
                editContractExtraDisplayId: id,
            });
        } else {
            alert("Sorry, you can't edit that contract, because it is used with some projects");
        }
    };

    handleAddNewContractExtraDisplay = () => {
        this.setState({ pathAddNewContractExtraDisplay: PATHS.ADD_EXTRA_DISPLAY_CONTRACT });
    };

    handleContractExtraDisplayPdf = () => {
        generatePDF(
            <ContractExtraDisplayPdf
                info={{
                    contractExtraDisplays: this.state.contractExtraDisplayDisplayed,
                    extraDisplayTypes: this.state.extraDisplayTypes,
                    companyData: this.context.companyData,
                }}
            />,
            `Extra Display Contracts PDF`
        );
    };

    goToAdminDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================
    render() {
        if (this.state.pathEditContractExtraDisplayInfo.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathEditContractExtraDisplayInfo,
                        state: {
                            contractID: this.state.editContractExtraDisplayId,
                            id: this.props.id,
                        },
                    }}
                />
            );
        } else if (this.state.pathAddNewContractExtraDisplay.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathAddNewContractExtraDisplay,
                    }}
                />
            );
        } else if (this.state.pathAddNewContractExtraDisplay.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathAddNewContractExtraDisplay,
                    }}
                />
            );
        } else if (this.state.pathBack.length > 0) {
            return <Redirect push={true} to={{ pathname: this.state.pathBack }} />;
        } else {
            // const classes = makeStyles((theme) => ({
            //     root: {
            //         width: "100%",
            //         marginTop: theme.spacing(3),
            //         overflowX: "auto",
            //     },
            //     table: {
            //         minWidth: 650,
            //     },
            // }));

            const { classes } = this.props;

            return (
                <div>
                    <div
                        className={classNames(classes.main, classes.textCenter)}
                        style={{ width: "100%", display: "contents" }}
                    >
                        <Grid
                            container
                            spacing={0}
                            justifyContent="center"
                            className={classes.container}
                            style={{ maxWidth: "100%" }}
                        >
                            <Box
                                style={{
                                    paddingTop: 15,
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    marginBottom: "75px",
                                    backgroundColor: "#FFFFFF",
                                    color: "#EEEEEE",
                                    width: "90%",
                                    boxShadow: "#96a1a9 0 4px 5px 0px",
                                }}
                                pt={4}
                            >
                                <GridContainer justifyContent="center" alignItems="center">
                                    <div className={classes.title} style={{ width: "3%" }}>
                                        <div
                                            style={{
                                                fontWeight: "700",
                                                // backgroundColor: "#91041C",
                                                color: "#EEEEEE",
                                                height: "30px",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "2px solid #575757",
                                                // borderRightColor: "#91041C",
                                                // borderBottomColor: "#91041C",
                                                backgroundColor: COLORS.primary,
                                                borderRightColor: COLORS.primary,
                                                borderBottomColor: COLORS.primary,
                                            }}
                                        ></div>
                                    </div>
                                    <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                                        <h4
                                            style={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                backgroundColor: COLORS.background,
                                                color: COLORS.primary,
                                                // color: "#91041C",
                                                height: "30px",
                                                paddingLeft: "20px",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "1px solid #000000",
                                            }}
                                        >
                                            Extra Display Contracts
                                        </h4>
                                    </div>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <p
                                            style={{
                                                margin: 0,
                                                textTransform: "none",
                                                color: "#000000",
                                                fontWeight: "900",
                                            }}
                                        >
                                            Search By
                                        </p>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={10}>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                    </GridItem>

                                    <div style={{ width: "92%", display: "flex", flexDirection: "row" }}>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.contract_no}
                                                        variant="filled"
                                                        fullWidth
                                                        id="contract_no"
                                                        label="Contract No."
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({ contract_no: event.target.value })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.outlet_id}
                                                        variant="filled"
                                                        fullWidth
                                                        id="outlet_id"
                                                        label="Outlet"
                                                        style={{ textAlign: "left" }}
                                                        multiline
                                                        onChange={(event) => this.handleChange(event, "outlet_id")}
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.supermarkets.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={5}
                                                    style={{ width: "100%", display: "flex", flexDirection: "row" }}
                                                >
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <Button
                                                            simple
                                                            size="lg"
                                                            // disabled={!this.validateThirdForm()}
                                                            onClick={this.doSearch}
                                                            style={{
                                                                marginTop: 18,
                                                                marginRight: 10,
                                                                padding: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                float: "left",
                                                                textTransform: "none",
                                                                backgroundColor: COLORS.submit,
                                                                color: COLORS.font,
                                                                // borderColor: "#4BB543",
                                                                border: "2",
                                                                fontWeight: "400",
                                                            }}
                                                            type="submit"
                                                        >
                                                            {" "}
                                                            Search{" "}
                                                        </Button>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <Button
                                                            simple
                                                            size="lg"
                                                            // disabled={!this.validateThirdForm()}
                                                            onClick={() => {
                                                                this.setState({
                                                                    tableDisplay: 2,
                                                                    contractExtraDisplayDisplayed:
                                                                        this.state.contractExtraDisplay,
                                                                    outlet_id: "",
                                                                    contract_no: "",
                                                                });
                                                            }}
                                                            style={{
                                                                marginTop: 17,
                                                                padding: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                float: "left",
                                                                textTransform: "none",
                                                                backgroundColor: COLORS.accent,
                                                                color: COLORS.font,
                                                                // borderColor: "#91041C",
                                                                border: "2",
                                                                fontWeight: "400",
                                                            }}
                                                            type="submit"
                                                        >
                                                            {" "}
                                                            Cancel{" "}
                                                        </Button>
                                                    </GridItem>
                                                </GridItem>
                                            </div>
                                        </Grid>
                                    </div>

                                    <div style={{ width: "89%" }}>
                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 2,
                                                    contractExtraDisplayDisplayed:
                                                        this.state.activeContractExtraDisplay,
                                                    outlet_id: "",
                                                    contract_no: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 2
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Active{" "}
                                        </Button>

                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 3,
                                                    contractExtraDisplayDisplayed:
                                                        this.state.notActiveContractExtraDisplay,
                                                    outlet_id: "",
                                                    contract_no: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 3
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Not Active{" "}
                                        </Button>
                                    </div>

                                    <GridItem xs={12} sm={12} md={11}>
                                        <EnhancedTable
                                            contractExtraDisplay={this.state.contractExtraDisplayDisplayed}
                                            handleShowDeleteAlertMethod={this.handleShowDeleteAlert}
                                            handleEditNewContractExtraDisplayInfoMethod={
                                                this.handleEditNewContractExtraDisplayInfo
                                            }
                                            handleAddNewContractExtraDisplayMethod={
                                                this.handleAddNewContractExtraDisplay
                                            }
                                            handleContractExtraDisplayPdfMethod={this.handleContractExtraDisplayPdf}
                                            loading={this.state.loading}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>
                </div>
            );
        }
    }
}

ContractExtraDisplayList.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
};

export default withStyles(componentsStyle, styles)(ContractExtraDisplayList);

import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";

// import { Font } from "./fonts.js";

let rowsPerPage = 26;
let totalPages = 0;

let formatDate = (stringDate) => {
    const date = new Date(stringDate);

    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

let createRow = (display, info, style) => {
    if (!display)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${display.contract_id}`}>
                <Text style={styles.lastCell}>Error getting the data</Text>
            </View>
        );

    let listOfExtraDisplayWithCommas = "";

    info.extraDisplayTypes.map((DisplayType, index) => {
        let arrayOfDisplays = display.extra_display_type;
        let includedInArray = arrayOfDisplays.includes(DisplayType.extra_display_price_id);

        if (includedInArray) {
            listOfExtraDisplayWithCommas = listOfExtraDisplayWithCommas + DisplayType.extra_display_type + ", ";
        }
    });

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${display.contract_id}`}>
            <Text style={styles.cell}>{display.contract_id}</Text>
            <Text style={styles.cellNo}>{display.outlet_name}</Text>
            <Text style={styles.cell}>{formatDate(display.date_from)}</Text>

            <Text style={styles.cell}>{formatDate(display.date_to)}</Text>
            <Text style={styles.cell}>{formatDate(display.date_of_contract)}</Text>
            <Text style={styles.lastCell}>{listOfExtraDisplayWithCommas}</Text>
        </View>
    );
};

let createPage = (contractExtraDisplays, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = contractExtraDisplays.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page
            size="A4"
            orientation="landscape"
            style={styles.page}
            key={`Contract Extra Display ${Math.random()}-${pageNum}`}
        >
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Contract Extra Display PDF</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Contract No</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Outlet Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>From</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>To</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Contract Date</Text>
                            </View>

                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>Rent Display</Text>
                            </View>
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((contractExtraDisplays, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            console.log("asdf " + contractExtraDisplays.day);
                            return createRow(contractExtraDisplays, info, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>R.C: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { contractExtraDisplays } = info;

    console.log(info.companyData.company_name);
    console.log(info.companyData.logo);

    let pages = [];
    let displaysPerPage = Math.ceil(contractExtraDisplays.length / rowsPerPage);
    for (let pageNum = 0; pageNum < displaysPerPage; pageNum++) {
        pages[pageNum] = createPage(contractExtraDisplays, info, pageNum);
    }

    return pages;
};

// Create Document Component
const ContractExtraDisplayPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default ContractExtraDisplayPdf;

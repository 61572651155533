import React, { useCallback } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Grid from "@material-ui/core/Grid";
import List from "@material-ui/core/List";
import Card from "@material-ui/core/Card";
import ListItemText from "@material-ui/core/ListItemText";
import ListItemIcon from "@material-ui/core/ListItemIcon";
import Checkbox from "@material-ui/core/Checkbox";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CardHeader from "@material-ui/core/CardHeader";
import ListItem from "@material-ui/core/ListItem";
import PropTypes from "prop-types";
import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import withStyles from "@material-ui/core/styles/withStyles";

import Paper from "@material-ui/core/Paper";
// nodejs library that concatenates classes
import classNames from "classnames";
import Box from "@material-ui/core/Box";
import CircularProgress from "@material-ui/core/CircularProgress";
import MenuItem from "@material-ui/core/MenuItem";
import TextField from "@material-ui/core/TextField";
import { Redirect } from "react-router-dom";
import { COLORS } from "../constants/constants-lagacy";
import { firestore as db } from "../services/firebase";
import GridItem from "../components-lagacy/Grid/GridItem";
import { LoadingOverlay } from "../core-ui/custom";
import { PLATFORM } from "../constants/global";

let global_right = [];

const useStyles = makeStyles((theme) => ({
    root: {
        margin: "auto",
    },
    cardHeader: {
        padding: theme.spacing(1, 2),
    },
    list: {
        width: 250,
        height: 280,
        backgroundColor: theme.palette.background.paper,
        overflow: "auto",
    },
    button: {
        margin: theme.spacing(0.5, 0),
    },
}));

function not(a, b) {
    return a.filter((value) => b.indexOf(value) === -1);
}

function intersection(a, b) {
    return a.filter((value) => b.indexOf(value) !== -1);
}

function union(a, b) {
    return [...a, ...not(b, a)];
}

function TransferList(props) {
    const classes = useStyles();
    const { handleAddOutletsMethod, handleCancelButtonMethod } = props;
    // console.log(outlets);
    const [checked, setChecked] = React.useState([]);
    const [left, setLeft] = React.useState(props.left);
    const [right, setRight] = React.useState(props.right);

    const update = useCallback(() => {
        setLeft(props.left);
        setRight(props.right);
    }, [props.left, props.right]);

    React.useEffect(() => {
        update();
    }, [props.left, update]); // Only re-run the effect if props.left changes

    const leftChecked = intersection(checked, left);
    const rightChecked = intersection(checked, right);

    const handleToggle = (value) => () => {
        const currentIndex = checked.indexOf(value);
        const newChecked = [...checked];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setChecked(newChecked);
    };

    const numberOfChecked = (items) => intersection(checked, items).length;

    const handleToggleAll = (items) => () => {
        if (numberOfChecked(items) === items.length) {
            setChecked(not(checked, items));
        } else {
            setChecked(union(checked, items));
        }
    };

    const handleCheckedRight = () => {
        // console.log("CheckedRight")
        setRight(right.concat(leftChecked));
        //console.log(right.concat(leftChecked))
        global_right = right.concat(leftChecked);
        //console.log(global_right)
        setLeft(not(left, leftChecked));
        setChecked(not(checked, leftChecked));
    };

    const handleCheckedLeft = () => {
        //console.log("Checkedleft")
        setLeft(left.concat(rightChecked));
        //console.log(not(right, rightChecked))
        global_right = not(right, rightChecked);
        setRight(not(right, rightChecked));
        setChecked(not(checked, rightChecked));
    };

    const handleAddOutlets = () => {
        //console.log(right)
        handleAddOutletsMethod(right);
    };
    const handleCancelButton = () => {
        //console.log(right)
        handleCancelButtonMethod();
    };

    const CustomList = (title, items) => {
        // console.log("items", items);
        return (
            <Card>
                <CardHeader
                    style={{
                        background: COLORS.primary,
                        color: COLORS.font,
                        //"rgba(145, 4, 28,0.83)", "#91041C"
                    }}
                    className={classes.cardHeader}
                    avatar={
                        <Checkbox
                            style={{
                                color: COLORS.font,
                            }}
                            onClick={handleToggleAll(items)}
                            checked={numberOfChecked(items) === items.length && items.length !== 0}
                            indeterminate={numberOfChecked(items) !== items.length && numberOfChecked(items) !== 0}
                            disabled={items.length === 0}
                            inputProps={{ "aria-label": "all items selected" }}
                        />
                    }
                    title={title}
                    subheader={
                        <p style={{ color: COLORS.font, fontSize: "13px" }}>{`${numberOfChecked(items)}/${
                            items.length
                        } selected`}</p>
                    }
                />
                <Divider />
                <List className={classes.list} dense component="div" role="list">
                    {items.map((value) => {
                        const labelId = `transfer-list-all-item-${value.En_short_name}-label`;
                        // console.log("value", items);
                        return (
                            <ListItem
                                style={{ padding: "0", margin: "0" }}
                                key={value.branch_id}
                                role="listitem"
                                button
                                onClick={handleToggle(value)}
                            >
                                <ListItemIcon>
                                    <Checkbox
                                        style={{
                                            color: COLORS.primary,
                                        }}
                                        checked={checked.indexOf(value) !== -1}
                                        tabIndex={-1}
                                        disableRipple
                                        inputProps={{ "aria-labelledby": labelId }}
                                    />
                                </ListItemIcon>
                                <ListItemText id={labelId} primary={`${value.En_short_name}`} />
                            </ListItem>
                        );
                    })}
                    <ListItem />
                </List>
            </Card>
        );
    };
    /*
    const checkArrays=(arr1, arr2)=>{
      for (var i = 0; i < arr1.length; i++) {
      if (arr1[i] !== arr2[i]) return false;
      }
      return true;
    }
    const handleLeft = () => {
      if(!(checkArrays(left, props.left)&&checkArrays(props.left, left))){
          setLeft(props.left)
      }
    };
  */
    // console.log("befor return", left);
    return (
        <Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>{left === undefined ? CustomList("Outlets", []) : CustomList("Outlets", left)}</Grid>
                <Grid item>
                    <Grid container direction="column" alignItems="center">
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedRight}
                            disabled={leftChecked.length === 0}
                            aria-label="move selected right"
                        >
                            &gt;
                        </Button>
                        <Button
                            variant="outlined"
                            size="small"
                            className={classes.button}
                            onClick={handleCheckedLeft}
                            disabled={rightChecked.length === 0}
                            aria-label="move selected left"
                        >
                            &lt;
                        </Button>
                    </Grid>
                </Grid>
                <Grid item>
                    {right === undefined ? CustomList("Selected Outlets", []) : CustomList("Selected Outlets", right)}
                </Grid>
            </Grid>
            <Grid container spacing={2} justifyContent="center" alignItems="center" className={classes.root}>
                <Grid item>
                    <Grid container direction="row" alignItems="center">
                        <Button
                            style={{
                                float: "right",
                                textTransform: "none",
                                backgroundColor: COLORS.accent,
                                color: COLORS.font,
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                marginRight: 20,
                                // borderColor: "#91041C",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleCancelButton}
                        >
                            Cancel
                        </Button>

                        <Button
                            style={{
                                float: "right",
                                textTransform: "none",
                                backgroundColor: COLORS.submit,
                                color: COLORS.font,
                                marginTop: 14,
                                padding: 10,
                                paddingLeft: 20,
                                paddingRight: 20,
                                // borderColor: "#4BB543",
                                border: "2",
                                fontWeight: "400",
                            }}
                            type="submit"
                            onClick={handleAddOutlets}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Grid>
        </Grid>
    );
}

//==================================================================================
//==========================OutletBranchSupplierBranch======================
//==================================================================================
class OutletBranchSupplierBranch extends React.Component {
    constructor(props) {
        super(props);
        this.state = {
            branch_id: "",
            city_id: "all",
            outlet_id: "all",
            supplier_name: "",
            branches: [],
            cities: [],
            outlets: [],

            left: [],
            right: [],
            oldRight: [],
            checkLeft: false,
            checkBranches: false,
            checkOutlets: false,
            checkNotFirstBranch: false,
            outletBranches: [],
            supplierOutlets: [],
            supplierBranchOutlets: [],

            //right: [{En_short_name:'L', outlet_id:'1'}],
            // left: [{En_short_name:'R', outlet_id:'2'},{En_short_name:'L', outlet_id:'1'}],
            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,

            branchesOutletBranches: [],
        };
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    updateBranchesOutletBranches = async () => {
        const branches = [...this.state.branches];

        const branchesOutletBranches = await Promise.all(
            branches.map(async (branch) => {
                const outletBranches = (
                    await db.collection("Branch_Outlet_Branch").doc(branch.branch_id).collection("Outlet_Branch").get()
                ).docs;
                return { branchID: branch.branch_id, outletBranches: outletBranches };
            })
        );

        this.setState({ branchesOutletBranches });
    };

    componentDidMount() {
        let datat2 = [];
        //firebase.firestore();
        var branchesRef = db.collection("Branch").where("supplier_id", "==", this.props.id);
        branchesRef
            .get()
            .then(async (snapshot) => {
                snapshot.forEach((doc) => {
                    if (doc.data().status === "1") {
                        let datat = doc.data();
                        datat2.push(datat);
                    }
                });
                if (snapshot.size === 0) {
                    this.setState({ checkBranches: true });
                }

                this.setState({ branches: datat2 }, this.updateBranchesOutletBranches);
            })
            /*.then(()=>{
        var supplierRef = db.collection('Supplier').where('company_id', '==', this.props.id );
        supplierRef.get()
          .then(snapshot => {
            snapshot.forEach(doc => {
                this.setState({ supplier_name: doc.data().En_short_name, });
                
            });
            
          })
      })*/
            .then(() => {
                let datat7 = [];
                var outletsRef = db.collection("Outlet").orderBy("En_short_name");
                outletsRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            if (doc.data().status === "1") {
                                let datat = doc.data();
                                datat7.push(datat);
                            }
                        });

                        this.setState({ outlets: datat7 });
                    })
                    .then(() => {
                        //console.log(this.state.outlets)
                        //console.log(datat7)
                        let datat4 = [];
                        var outletsRef = db
                            .collection("Supplier_Outlet_Product")
                            .doc(this.props.id)
                            .collection("Outlet_Product");
                        outletsRef
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    let datat = doc.data();
                                    let index = this.state.outlets.findIndex((post) => {
                                        return post.outlet_id === datat.outlet_id;
                                    });
                                    if (index !== -1) {
                                        datat.En_short_name = this.state.outlets[index].En_short_name;
                                        datat4.push(datat);
                                    }
                                });
                                if (snapshot.size === 0) {
                                    this.setState({ checkOutlets: true });
                                }
                                this.setState({ supplierOutlets: datat4 });
                            })
                            .then(() => {
                                db.collection("Price")
                                    .get()
                                    .then((snapshot) => {
                                        let prices = snapshot.docs;
                                        if (prices.length > 0) {
                                            //console.log(this.state.supplierOutlets)
                                            //console.log(datat4)
                                            let datat5 = [];
                                            for (let i = 0; i < this.state.supplierOutlets.length; i++) {
                                                //console.log(this.state.supplierOutlets[i].outlet_id)
                                                var outletBranchesRef = db
                                                    .collection("Outlet_Branch")
                                                    .where("outlet_id", "==", this.state.supplierOutlets[i].outlet_id);
                                                outletBranchesRef.get().then((snapshot) => {
                                                    snapshot.forEach((doc) => {
                                                        let classId = doc.data().class_id;
                                                        let price = prices.find(
                                                            (price) =>
                                                                price.data().class_id === classId &&
                                                                price.data().project_type === "101"
                                                        );
                                                        if (price) {
                                                            datat5.push({
                                                                outlet_id: doc.data().outlet_id,
                                                                branch_id: doc.data().branch_id,
                                                                En_short_name: doc.data().En_short_name,
                                                                city_id: doc.data().city_id,
                                                            });
                                                        }
                                                    });
                                                    if (i === this.state.supplierOutlets.length - 1) {
                                                        //console.log(datat5)
                                                        this.setState({
                                                            supplierOutletBranches: datat5,
                                                            checkLeft: true,
                                                            checkNotFirstBranch: false,
                                                        });
                                                    }
                                                });
                                            }
                                        }
                                    });
                            });
                    })
                    .catch((err) => {
                        console.log("Error getting documents", err);
                    });
            })
            .then(() => {
                let datat6 = [];
                var citiesRef = db.collection("City").orderBy("En_name");
                citiesRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            let datat = doc.data();
                            datat6.push(datat);
                        });

                        this.setState({ cities: datat6 });
                    })
                    .catch((err) => {
                        console.log("Error getting documents", err);
                    });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    }

    //===================================================================
    handleChangeBranch = async (event, id) => {
        this.setState({ [id]: event.target.value });
        await this.updateBranchesOutletBranches();
        this.handleChangeOutletsBranches(event.target.value);
    };
    //===================================================================
    handleChangeCity = (event) => {
        if (this.state.branch_id.length > 0 && this.state.outletBranches.length > 0) {
            //console.log(global_right)
            //console.log(this.state.outletBranches)
            if (event.target.value === "all") {
                if (this.state.outlet_id === "all") {
                    //just change left side
                    let left = [];
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            left.push(this.state.outletBranches[i]);
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            this.setState({ left: [...left], city_id: event.target.value, right: [...global_right] });
                        }
                    }
                } else {
                    //just change left side
                    let left = [];
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (this.state.outletBranches[i].outlet_id === this.state.outlet_id) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            this.setState({ left: [...left], city_id: event.target.value, right: [...global_right] });
                        }
                    }
                }
            } else {
                if (this.state.outlet_id === "all") {
                    //just change left side
                    let left = [];
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (this.state.outletBranches[i].city_id === event.target.value) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            this.setState({ left: [...left], city_id: event.target.value, right: [...global_right] });
                        }
                    }
                } else {
                    //just change left side
                    let left = [];
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (
                                this.state.outletBranches[i].city_id === event.target.value &&
                                this.state.outletBranches[i].outlet_id === this.state.outlet_id
                            ) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            this.setState({ left: [...left], city_id: event.target.value, right: [...global_right] });
                        }
                    }
                }
            }
        } else {
            this.setState({ city_id: event.target.value });
        }
    };
    //===================================================================
    handleChangeOutletCompany = (event) => {
        if (this.state.branch_id.length > 0 && this.state.outletBranches.length > 0) {
            //just change left side
            let left = [];
            if (event.target.value === "all") {
                if (this.state.city_id === "all") {
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            left.push(this.state.outletBranches[i]);
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            //console.log(left)
                            //console.log("left")
                            this.setState({ left: [...left], outlet_id: event.target.value, right: [...global_right] });
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (this.state.outletBranches[i].city_id === this.state.city_id) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            //console.log(left)
                            //console.log("left")
                            this.setState({ left: [...left], outlet_id: event.target.value, right: [...global_right] });
                        }
                    }
                }
            } else {
                if (this.state.city_id === "all") {
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (this.state.outletBranches[i].outlet_id === event.target.value) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            //console.log(left)
                            //console.log("left")
                            this.setState({ left: [...left], outlet_id: event.target.value, right: [...global_right] });
                        }
                    }
                } else {
                    for (let i = 0; i < this.state.outletBranches.length; i++) {
                        const index = global_right.findIndex((post) => {
                            return post.branch_id === this.state.outletBranches[i].branch_id;
                        });
                        if (index === -1) {
                            if (
                                this.state.outletBranches[i].outlet_id === event.target.value &&
                                this.state.outletBranches[i].city_id === this.state.city_id
                            ) {
                                left.push(this.state.outletBranches[i]);
                            }
                        }

                        if (i === this.state.outletBranches.length - 1) {
                            //console.log(left)
                            //console.log("left")
                            this.setState({ left: [...left], outlet_id: event.target.value, right: [...global_right] });
                        }
                    }
                }
            }
        } else {
            this.setState({ outlet_id: event.target.value });
        }
    };
    //===================================================================

    handleChangeOutletsBranches = (id) => {
        // console.log(this.state.supplierOutletBranches);
        let supplierOutletBranches = [...this.state.supplierOutletBranches];
        let selectedOutletBranches = this.state.branchesOutletBranches.filter((branch) => branch.branchID !== id);
        selectedOutletBranches = selectedOutletBranches.reduce((arr, branch) => [...arr, ...branch.outletBranches], []);

        supplierOutletBranches = supplierOutletBranches.filter((outlet) => {
            return !selectedOutletBranches.find((outletBranch) => outlet.branch_id === outletBranch.id);
        });

        //firebase.firestore();
        var branchesRef = db.collection("Branch_Outlet_Branch").where("branch_id", "==", id);
        branchesRef
            .get()
            .then((snapshot) => {
                if (snapshot.size === 0) {
                    this.setState({
                        outlet_id: "all",
                        city_id: "all",
                        left: [...supplierOutletBranches],
                        outletBranches: [...supplierOutletBranches],
                        right: [],
                        oldRight: [],
                    });
                } else {
                    snapshot.forEach((doc) => {
                        var datat5 = [];
                        var outletBranchesRef = db
                            .collection("Branch_Outlet_Branch")
                            .doc(id)
                            .collection("Outlet_Branch");
                        outletBranchesRef
                            .get()
                            .then((snapshot2) => {
                                snapshot2.forEach((doc2) => {
                                    const index = supplierOutletBranches.findIndex((post) => {
                                        return post.branch_id === doc2.data().branch_id;
                                    });

                                    if (index !== -1) {
                                        datat5.push(supplierOutletBranches[index]);
                                        supplierOutletBranches.splice(index, 1);
                                    }
                                });

                                //console.log(supplierOutletBranches)
                                //console.log("left")
                                global_right = [...datat5];
                                this.setState({
                                    outlet_id: "all",
                                    city_id: "all",
                                    left: [...supplierOutletBranches],
                                    outletBranches: [...supplierOutletBranches],
                                    right: [...datat5],
                                    oldRight: [...datat5],
                                });
                            })
                            .catch((err) => {
                                console.log("Error getting documents", err);
                            });
                    });
                }
            })
            .catch((err) => {
                console.log("Error getting documents", err);
            });
    };

    handleAddOutlets = (outletsSelected) => {
        if (!(this.state.supplierOutletBranches === undefined)) {
            let checkBranch = true;
            //firebase.firestore();
            var branchesRef = db.collection("Branch_Outlet_Branch").where("branch_id", "==", this.state.branch_id);
            branchesRef
                .get()
                .then((snapshot) => {
                    if (snapshot.size === 1) {
                        checkBranch = false;
                    }
                })
                .then(() => {
                    if (checkBranch) {
                        var branchesRef = db.collection("Branch_Outlet_Branch");
                        branchesRef
                            .doc(this.state.branch_id)
                            .set({
                                platform: PLATFORM,
                                supplier_id: this.props.id,
                                branch_id: this.state.branch_id,
                            })
                            .then(() => {
                                var AddOutlets = this.not(outletsSelected, this.state.oldRight);
                                //add outlet info. to Outlet_Product collection
                                let OutletBranchRef = db
                                    .collection("Branch_Outlet_Branch")
                                    .doc(this.state.branch_id)
                                    .collection("Outlet_Branch");
                                for (let i = 0; i < AddOutlets.length; i++) {
                                    OutletBranchRef.doc(AddOutlets[i].branch_id)
                                        .set({
                                            platform: PLATFORM,
                                            supplier_id: this.props.id,
                                            branch_id: AddOutlets[i].branch_id,
                                            supplier_branch_id: this.state.branch_id,
                                        })
                                        .catch((error) => {
                                            console.error(error);
                                            alert("There is something wrong!");
                                        });
                                } // for
                                global_right = [];
                                this.setState({
                                    left: [],
                                    outletBranches: [],
                                    right: [],
                                    oldRight: [],
                                    branch_id: "",
                                    outlet_id: "all",
                                    city_id: "all",
                                });
                            });
                    } else {
                        var AddOutlets = this.not(outletsSelected, this.state.oldRight);
                        var DeleteOutlets = this.not(this.state.oldRight, outletsSelected);
                        //add outlet info. to Outlet_Product collection
                        let OutletBranchRef = db
                            .collection("Branch_Outlet_Branch")
                            .doc(this.state.branch_id)
                            .collection("Outlet_Branch");
                        for (let i = 0; i < AddOutlets.length; i++) {
                            OutletBranchRef.doc(AddOutlets[i].branch_id)
                                .set({
                                    platform: PLATFORM,
                                    supplier_id: this.props.id,
                                    branch_id: AddOutlets[i].branch_id,
                                    supplier_branch_id: this.state.branch_id,
                                })
                                .catch((error) => {
                                    console.error(error);
                                    alert("There is something wrong!");
                                });
                        } // for

                        for (let i = 0; i < DeleteOutlets.length; i++) {
                            //add outlet info. to Outlet_Product collection
                            OutletBranchRef.doc(DeleteOutlets[i].branch_id)
                                .delete()
                                .catch((error) => {
                                    console.error(error);
                                    alert("There is something wrong!");
                                });
                        } // for
                        global_right = [];
                        this.setState({
                            left: [],
                            outletBranches: [],
                            right: [],
                            oldRight: [],
                            branch_id: "",
                            outlet_id: "all",
                            city_id: "all",
                        });
                    } //else checkBranch
                }); //then set
        } //if supplierOutletBranches
    };
    //==================================================================================

    not = (a, b) => {
        return a.filter((value) => b.indexOf(value) === -1);
    };

    intersection = (a, b) => {
        //console.log(a)
        //console.log(b)
        return a.filter((value) => b.indexOf(value) !== -1);
    };

    union = (a, b) => {
        return [...a, ...not(b, a)];
    };

    handleCancelButton = () => {
        //global_right =[];
        //this.setState({left: this.state.outletBranches , right: this.state.oldRight, outlet_id: "all", city_id: "all",});
        this.goToSupplierDashboardPage("/SupplierDashboard");
    };

    goToSupplierDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================

    render() {
        const { classes } = this.props;
        if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                    }}
                />
            );
        } else {
            // const left = this.state.left;

            const branch_list = [];
            for (let i = 0; i < this.state.branches.length; i++) {
                let label = this.state.branches[i].En_name;
                branch_list.push({ value: this.state.branches[i].branch_id, label: label });
            }

            let city_list = [];
            city_list.push({ value: "all", label: "All" });
            for (let i = 0; i < this.state.cities.length; i++) {
                let label = this.state.cities[i].En_name;
                city_list.push({ value: this.state.cities[i].city_id, label: label });
            }

            let outlet_Company_list = [];
            outlet_Company_list.push({ value: "all", label: "All" });
            for (let i = 0; i < this.state.supplierOutlets.length; i++) {
                let label = this.state.supplierOutlets[i].En_short_name;
                outlet_Company_list.push({ value: this.state.supplierOutlets[i].outlet_id, label: label });
            }

            if (this.state.checkLeft) {
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%" }}>
                                    <Box pt={4} pb={8} xs={12} sm={12} md={12}>
                                        <Grid xs={12} sm={12} md={12}>
                                            <Card pt={20} color="primary">
                                                <div
                                                    style={{
                                                        width: "95%",
                                                        display: "flex",
                                                        flexDirection: "row",
                                                        marginLeft: "25px",
                                                    }}
                                                >
                                                    <div className={classes.title} style={{ width: "3%" }}>
                                                        <div
                                                            style={{
                                                                fontWeight: "700",
                                                                // backgroundColor: "#91041C",
                                                                color: "#EEEEEE",
                                                                height: "30px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "2px solid #575757",
                                                                // borderRightColor: "#91041C",
                                                                // borderBottomColor: "#91041C",
                                                                backgroundColor: COLORS.primary,
                                                                borderRightColor: COLORS.primary,
                                                                borderBottomColor: COLORS.primary,
                                                            }}
                                                        ></div>
                                                    </div>
                                                    <div
                                                        className={classes.title}
                                                        style={{ width: "96.7%", marginLeft: "2px" }}
                                                    >
                                                        <h4
                                                            style={{
                                                                justifyContent: "flex-start",
                                                                fontWeight: "700",
                                                                backgroundColor: COLORS.background,
                                                                color: COLORS.primary,
                                                                height: "30px",
                                                                paddingLeft: "10px",
                                                                paddingTop: "3px",
                                                                paddingBottom: "3px",
                                                                border: "1px solid #000000",
                                                            }}
                                                        >
                                                            Select outlet branches that your branch will deal with them.
                                                        </h4>
                                                    </div>
                                                </div>
                                                <Grid container alignItems="center" justifyContent="center">
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <TextField
                                                            style={{ textAlign: "left" }}
                                                            select
                                                            value={this.state.branch_id}
                                                            variant="outlined"
                                                            fullWidth
                                                            id="branch_id"
                                                            label="Choose the Branch"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) =>
                                                                this.handleChangeBranch(event, "branch_id")
                                                            }
                                                            className={classes.textField}
                                                            margin="dense"
                                                            rowsMax={1}
                                                        >
                                                            {branch_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <TextField
                                                            style={{ textAlign: "left" }}
                                                            select
                                                            value={this.state.city_id}
                                                            variant="outlined"
                                                            fullWidth
                                                            id="city_id"
                                                            label="Choose the City"
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChangeCity(event)}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            rowsMax={1}
                                                        >
                                                            {city_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <TextField
                                                            style={{ textAlign: "left" }}
                                                            select
                                                            value={this.state.outlet_id}
                                                            variant="outlined"
                                                            fullWidth
                                                            id="outlet_id"
                                                            label="Choose the Outlet Co."
                                                            placeholder=""
                                                            multiline
                                                            //value={"" + this.props.input}
                                                            onChange={(event) => this.handleChangeOutletCompany(event)}
                                                            className={classes.textField}
                                                            margin="dense"
                                                            rowsMax={1}
                                                        >
                                                            {outlet_Company_list.map((option) => (
                                                                <MenuItem key={option.value} value={option.value}>
                                                                    {option.label}
                                                                </MenuItem>
                                                            ))}
                                                        </TextField>
                                                    </GridItem>

                                                    <GridItem xs={12} sm={12} md={10}>
                                                        <TransferList
                                                            left={this.state.left}
                                                            right={this.state.right}
                                                            handleAddOutletsMethod={this.handleAddOutlets}
                                                            handleCancelButtonMethod={this.handleCancelButton}
                                                        />
                                                    </GridItem>

                                                    <Grid item xs={12}>
                                                        <p></p>
                                                        <Paper className={classes.paper}> </Paper>
                                                    </Grid>
                                                </Grid>
                                            </Card>
                                        </Grid>
                                    </Box>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>
                    </div>
                );
            } else {
                let progressArray = [];
                if (this.state.checkBranches) {
                    progressArray.push(
                        <p style={{ marginTop: "100px" }}>
                            {" "}
                            You don't have any branch, yet. Add your branches first, please
                        </p>
                    );
                } else if (this.state.checkOutlets) {
                    progressArray.push(
                        <p style={{ marginTop: "100px" }}>
                            {" "}
                            You don't have any deal with any outlet company, yet. Please, add the outlets that you will
                            deal with them first
                        </p>
                    );
                } else {
                    progressArray.push(
                        <CircularProgress
                            key={"circular-progress"}
                            style={{ marginTop: "65px" }}
                            className={classes.progress}
                            // color={COLORS.primary}
                        />
                    );
                }
                return (
                    <div
                        /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/ className={
                            classes.pageHeader
                        }
                    >
                        <LoadingOverlay />
                        <div>
                            <div className={classNames(classes.container)}>
                                <div style={{ width: "100%" }}>
                                    <Grid item xs={12}>
                                        <p></p>
                                        <Paper className={classes.paper}> </Paper>
                                    </Grid>
                                    <Grid item xs={12}>
                                        <p></p>
                                        <Paper className={classes.paper}> </Paper>
                                    </Grid>
                                    <div style={{ display: "flex", justifyContent: "center" }}>{progressArray}</div>
                                </div>
                            </div>
                        </div>
                        <div>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                            <Grid item xs={12}>
                                <p></p>
                                <Paper className={classes.paper}> </Paper>
                            </Grid>
                        </div>
                    </div>
                );
            }
        }
    }
}
OutletBranchSupplierBranch.propTypes = {
    classes: PropTypes.object,
};
export default withStyles(componentsStyle)(OutletBranchSupplierBranch);

/*
if(this.state.branch_id.length >0){
    console.log(global_right)

    if(event.target.value === "all"){
      if(this.state.outlet_id === 'all'){
        //just change left side
        let left = [];
        for( let i=0; i< this.state.outletBranches.length; i++){
          const index = global_right.findIndex(post => {
            return post.branch_id === this.state.outletBranches[i].branch_id
          })
          if(index === -1){
              left.push(this.state.outletBranches[i])
          }

          if(i === this.state.outletBranches.length-1){
          console.log(left)
          console.log("left")
          this.setState({left: [...left], city_id: event.target.value, right: [...global_right]});
          }
        }
      }else{
        //just change left side
        let left = [];
        for( let i=0; i< this.state.outletBranches.length; i++){
          const index = global_right.findIndex(post => {
            return post.branch_id === this.state.outletBranches[i].branch_id
          })
          if(index === -1){
            if(this.state.outletBranches[i].outlet_id === this.state.outlet_id){
              left.push(this.state.outletBranches[i])
            }
          }

          if(i === this.state.outletBranches.length-1){
          console.log(left)
          console.log("left")
          this.setState({left: [...left], city_id: event.target.value, right: [...global_right]});
          }
        }
      }

    }else{

      if(this.state.outlet_id === 'all'){
        //just change left side
        let left = [];
        for( let i=0; i< this.state.outletBranches.length; i++){
          const index = global_right.findIndex(post => {
            return post.branch_id === this.state.outletBranches[i].branch_id
          })
          if(index === -1){
            if(this.state.outletBranches[i].city_id === event.target.value){
              left.push(this.state.outletBranches[i])
            }
          }


          if(i === this.state.outletBranches.length-1){
           console.log(left)
           console.log("left")
           this.setState({left: [...left], city_id: event.target.value, right: [...global_right]});
          }
        }
      }else{
        //just change left side
        let left = [];
        for( let i=0; i< this.state.outletBranches.length; i++){
          const index = global_right.findIndex(post => {
            return post.branch_id === this.state.outletBranches[i].branch_id
          })
          if(index === -1){
            if(this.state.outletBranches[i].city_id === event.target.value && this.state.outletBranches[i].outlet_id === this.state.outlet_id){
              left.push(this.state.outletBranches[i])
            }
          }

          if(i === this.state.outletBranches.length-1){
           console.log(left)
           console.log("left")
           this.setState({left: [...left], city_id: event.target.value, right: [...global_right]});
          }
        }
      }


    }

  }else{
    this.setState({city_id: event.target.value});
  }
*/

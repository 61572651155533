import React, { useCallback, useEffect, useState } from "react";
import { useHistory } from "react-router";
import styles from "./styles";
import { LogoHeader } from "../../core-ui/custom";
import Title from "../../core-ui/custom/title";
import { FormHeader, PasswordField, SubmitButton } from "../../core-ui/forms-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoadingOverlay } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";

const lowercaseRegex = /(?=.*[a-z])/;
const uppercaseRegex = /(?=.*[A-Z])/;
const numericRegex = /(?=.*[0-9])/;

const FORM_SCHEMA = Yup.object().shape({
    password: Yup.string()
        .matches(lowercaseRegex, "One lowercase required")
        .matches(uppercaseRegex, "One uppercase required")
        .matches(numericRegex, "One number required")
        .min(6, "Must be at least 6 characters")
        .required("Required"),
    confirm_password: Yup.string()
        .oneOf([Yup.ref("password")], "Password must match")
        .required("Required"),
});

const ResetPassword = () => {
    const classes = styles();
    const history = useHistory();
    const [loading, setLoading] = useState(false);
    const [codeAuthenticating, setCodeAuthenticating] = useState(true);
    const [email, setEmail] = useState("example@example.com");
    const [error, setError] = useState("");
    const [searchParams, setSearchParams] = useState(null);
    const { authLoading, verifyActionCode, confirmResetPassword } = useAuth();

    const verifyCode = useCallback(
        async (code) => {
            try {
                const email = await verifyActionCode(code);
                setEmail(email);
                setCodeAuthenticating(false);
            } catch (error) {
                history.push({
                    pathname: "/sign-in",
                    state: { message: "Invalid reset password link, please try again.", messageType: "error" },
                });
            }
        },
        [verifyActionCode, history]
    );

    useEffect(() => {
        const searchParams = new URLSearchParams(history.location.search);
        setSearchParams(searchParams);
        verifyCode(searchParams.get("oobCode"));
    }, [history.location.search, verifyCode]);

    const handleSubmit = async (values) => {
        try {
            setLoading(true);

            const { password } = values;
            // Get the action to complete.
            // var mode = searchParams.get("mode");
            // Get the one-time code from the query parameter.
            var actionCode = searchParams.get("oobCode");
            // (Optional) Get the continue URL from the query parameter if available.
            // var continueUrl = searchParams.get("continueUrl");
            // (Optional) Get the language code if available.
            // var lang = searchParams.get("lang") || "en";

            await confirmResetPassword(actionCode, password);

            history.push({
                pathname: "/sign-in",
                state: { email: email, message: "Password has been successfully changed.", messageType: "success" },
            });
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            console.log(errorCode, errorMessage);
            if (errorCode === "auth/invalid-action-code") {
                setError("The session link has expired, please resend the reset link again.");
            }
            setLoading(false);
        }
    };

    if (codeAuthenticating) {
        return <LoadingOverlay />;
    }

    return (
        <section className={classes.signinPage}>
            <LogoHeader variant="dark" />

            <section className={classes.container}>
                <div className={classes.formSection}>
                    <Title>Reset Password</Title>
                    <Formik
                        initialValues={{ password: "", confirm_password: "" }}
                        validationSchema={FORM_SCHEMA}
                        onSubmit={handleSubmit}
                    >
                        <Form>
                            <FormHeader>Reset password for {email}</FormHeader>
                            <PasswordField className={classes.input} name="password" label="New Password" />
                            <PasswordField className={classes.input} name="confirm_password" label="Confirm Password" />
                            {error && <p className={classes.errorMsg}>{error}</p>}
                            <SubmitButton className={classes.submitBtn} submitting={loading || authLoading}>
                                Submit
                            </SubmitButton>
                        </Form>
                    </Formik>
                </div>
            </section>
        </section>
    );
};

export default ResetPassword;

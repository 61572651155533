import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";
// import { Font } from "./fonts.js";

let rowsPerPage = 17;
// let totalPages = 0;

let createRow = (product, style) => {
    // let pieces = product.packing.packing_unit + "*" + product.outer_unit + "*" + product.packing.no_units;
    const packing = `${product.packing.packing_unit}x${product.outer_unit}x${product.packing.no_units}x${product.packing.size} ${product.packing.size_unit}`;
    if (!product)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${product.id}`}>
                <Text style={styles.lastCell}>Error getting the product's data</Text>
            </View>
        );

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${product.id}`}>
            <Text style={styles.cellNoNo}>{product.product_number}</Text>
            <Text style={styles.cellNo}>{product.barcode}</Text>
            <Text style={styles.cell}>{product.En_name}</Text>
            <Text style={styles.cell}>{product.Ar_name}</Text>

            <Text style={styles.lastCellNo}>{packing}</Text>
            {/* <Text style={[styles.lastCellNo, styles.greenBg]}>{product.status ? "Active" : "Not Active"}</Text> */}
        </View>
    );
};

let createPage = (projects, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = projects.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page
            size="A4"
            language="arabic"
            orientation="portrait"
            style={styles.page}
            key={`Products List ${Math.random()}-${pageNum}`}
        >
            <View style={styles.logoContainer}>
                {/* <Image style={styles.logo} src={logo}/> */}
                {/* <Text>Supplier: {info.companyData.company_name}</Text> */}
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Prodcuts List</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.headerCellNoNo}>
                                <Text style={styles.headerCellText}>No.</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Barcode</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Name</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Arabic Name</Text>
                            </View>
                            <View style={styles.lastHeaderCellNo}>
                                <Text style={styles.headerCellText}>Packing</Text>
                            </View>
                            {/* <View style={styles.lastHeaderCellNo}>
                                <Text style={styles.headerCellText}>Status</Text>
                            </View> */}
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((project, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            console.log("asdf " + project.day);
                            return createRow(project, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>R.C: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { products } = info;

    let pages = [];
    let productsPerPage = Math.ceil(products.length / rowsPerPage);

    for (let pageNum = 0; pageNum < productsPerPage; pageNum++) {
        pages[pageNum] = createPage(products, info, pageNum);
    }

    return pages;
};

// Create Document Component
const ProductPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default ProductPdf;

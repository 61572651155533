import React from "react";
import { Redirect, Route } from "react-router";
import { DashboardHeader, EmailVerificationBar } from "..";
import PATHS from "../../constants/paths";
import { useAuth } from "../../contexts/auth-context";
import { getDaysSinceSpecificDate } from "../../helpers/date-utils";
import { CompatRoute, useLocation } from "react-router-dom-v5-compat";

const LagacyRoute = (props) => {
    const { currentUser, companyData, userData } = useAuth();
    const location = useLocation();
    const { children, path } = props;

    const id = companyData?.company_id;
    const uid = currentUser?.uid;

    if (!currentUser || !companyData || !userData) {
        // console.log("lagacy" + 1);
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }

    // check if user is not verified and it's been 10 days since registration
    const daysElapsed = getDaysSinceSpecificDate(userData.created_at);
    if (!currentUser.emailVerified && daysElapsed > 10) {
        // console.log("lagacy" + 2);
        return <Redirect to={{ pathname: PATHS.SIGN_IN, state: { from: path } }} />;
    }

    // console.log("lagacy" + 3);
    return (
        <CompatRoute {...props} path={path}>
            <DashboardHeader />
            <EmailVerificationBar />
            {React.cloneElement(children, { id, uid, ...location?.state })}
        </CompatRoute>
        // <Route {...props} path={path}>
        //     <DashboardHeader />
        //     <EmailVerificationBar />
        //     {React.cloneElement(children, { id, uid, ...props.location?.state })}
        // </Route>
    );
};

export default LagacyRoute;

export let get = function (obj, key) {
    return key.split(".").reduce(function (o, x) {
        return typeof o == "undefined" || o === null ? o : o[x];
    }, obj);
};

export let sumObjectsByKey = (...objs) => {
    return objs.reduce((a, b) => {
        for (let k in b) {
            if (b.hasOwnProperty(k)) a[k] = (a[k] || 0) + b[k];
        }
        return a;
    }, {});
};

import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import LogoWithText from "../logo-with-text";

const useStyles = makeStyles({
    container: {
        position: "absolute",
        top: "0",
        left: "0",
        // width: "100%",
        height: "115px",
        padding: 10,
        zIndex: 1000,
        
    },

    logo: {
        height: "100%",
    },
});

let LogoHeader = (props) => {
    const classes = useStyles(props);
    const { variant = null } = props;
    return (
        <header className={classes.container}>
            <LogoWithText variant={variant} />
        </header>
    );
};

LogoHeader.propTypes = {
    classes: PropTypes.object,
};

export default LogoHeader;

import React, { Component } from "react";
//related to meterial ui package
import PropTypes from "prop-types";
import { withStyles } from "@material-ui/core/styles";
import EnhancedTable from "../../../components-lagacy/Table/EnhancedTable";
import { Box, Grid } from "@material-ui/core";
import GridContainer from "../../../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../../../components-lagacy/Grid/GridItem.jsx";

//utils
import { getProjectTotalPrice } from "../../../helpers/project-price-calculator";
import { generateNewId } from "../../../helpers/firestore-utils";
import Swal from "sweetalert2";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { firestore as db } from "../../../services/firebase";
import { getVAT } from "../../../helpers/firestore-utils";
import { getWholeProject, queryProjects } from "../../../services/firestore/Project";
import { PLATFORM } from "../../../constants/global";

import AuthContext from "../../../contexts/auth-context";
import { intersection } from "lodash";
import { getToday } from "../../../helpers/date-utils";

let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

const useStyles = (theme) => ({
    Container: {
        width: "100%",
        padding: "10px 20px",
    },

    main: {
        background: "#FFFFFF",
        position: "relative",
        zIndex: "3",
    },

    textCenter: {
        textAlign: "center",
    },

    Content: {
        display: "flex",
        flexDirection: "column",
        alignItems: "center",
        backgroundColor: "white",
        width: "100%",
        minWidth: "560px",
        padding: "10px",
        borderRadius: "5px",
    },

    header: {
        color: "black",
        fontSize: "2.5em",
        textAlign: "center",
        margin: "0 0 10px 0",
    },
});

let generateTableToolbarButton = (title, action, icon, enabled, iconStyle = {}, style = {}) => {
    return { title, action, icon, enabled, iconStyle, style };
};

class Content extends Component {
    static contextType = AuthContext;

    state = {
        // user: null,
        rows: [],
        tableToolbarButtons: [],
        loading: false,
    };

    loadProjectCovering = async (id) => {
        let covering = [];
        try {
            let supplierBranches = (await db.collection("Project").doc(id).collection("Branch_Outlet_Product").get())
                .docs;
            //if no branches is involved in this project, return empty
            if (supplierBranches.length <= 0) return covering;

            for (const sb of supplierBranches) {
                let outletBranches = (
                    await db
                        .collection("Project")
                        .doc(id)
                        .collection("Branch_Outlet_Product")
                        .doc(sb.id)
                        .collection("Outlet_Product")
                        .get()
                ).docs;

                if (outletBranches.length <= 0) continue;

                covering.push({
                    ...sb.data(),
                    outletBranches: [...outletBranches.map((ob) => ob.data())],
                });
            }

            return covering;
        } catch (error) {
            console.log(error);
        }
    };

    loadRowsData = async () => {
        this.setState({ loading: true });
        let rows = [];
        try {
            // //delete products
            // (await db.collection("Product").where("supplier_id", "==", "101").get()).docs.forEach((doc) => {
            //     doc.ref.delete();
            // });

            //load user data
            const user = this.context.userData;

            //get projects' data
            const supplierId = this.context.companyData.company_id;

            // let projects = await queryProjects([])

            let projects = (
                await db
                    .collection("Project")
                    .where("supplier_id", "==", supplierId)
                    .where("project_type", "in", [PROJECT_TYPES.SUPPLIER_OUTSOURCE, PROJECT_TYPES.OPEN_MP_INSOURCE])
                    .get()
            ).docs;

            if (!user.owner) {
                projects = projects.filter((p) => {
                    const branches = p.data().branches;
                    const intersects = intersection(branches, user.branch_ids ?? []);

                    return intersects.length > 0;
                });
            }

            //process data for each project
            const vat = (await getVAT()).data().percentage;
            rows = await Promise.all(
                projects.map(async (project) => {
                    const projectType = project.data().project_type;
                    let mpName = "Murtab";
                    // if (project.data().mp_id) {
                    //     mpName = (await db.collection("MP").doc(project.data().mp_id).get()).data().En_short_name;
                    // }

                    //get project prices from summary receipt for confirmed projects, otherwise calculate the prices
                    let priceData = {
                        value: 0,
                        vat: vat,
                        vatValue: 0,
                        valueAfterVat: 0,
                    };
                    if (projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE) {
                        if (project.data().confirm === "1" || project.data().isConfirmed) {
                            let summaryReceipt = (
                                await db.collection("Summary_Receipt").where("project_id", "==", project.id).get()
                            ).docs[0].data();
                            priceData = {
                                value: summaryReceipt.project_amount_no_vat,
                                vat: summaryReceipt.vat,
                                vatValue: summaryReceipt.vat * summaryReceipt.project_amount_no_vat,
                                valueAfterVat: summaryReceipt.project_amount,
                            };
                        } else {
                            //get the supplier branches and its outlets that are involved in the project
                            // let covering = await this.loadProjectCovering(project.id);
                            let projectFullData = await getWholeProject(project.id);
                            const startDate = projectFullData.data.date_from;
                            const endDate = projectFullData.data.date_to;
                            let totalPrice = await getProjectTotalPrice(projectFullData, { startDate, endDate });
                            totalPrice = totalPrice.selling.total;
                            const discountAmount = project.data().discount_amount || 0;
                            totalPrice -= discountAmount;
                            console.log(totalPrice);

                            priceData = {
                                value: totalPrice,
                                vat: vat,
                                vatValue: vat * totalPrice,
                                valueAfterVat: vat * totalPrice + totalPrice,
                            };
                        }
                    }

                    let supplier_confirmation =
                        project.data().confirm === "1" || project.data().isConfirmed ? "Confirmed" : "Not Confirmed";

                    return {
                        project: project,
                        id: project.id,
                        project_name: project.data().projName,
                        mp_name: mpName,
                        project_date: formatDate(project.data().project_date.toDate()),
                        project_period: `${formatDate(project.data().date_from.toDate())} - ${formatDate(
                            project.data().date_to.toDate()
                        )}`,

                        project_total:
                            projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE ? priceData.value.toFixed(2) : "-",
                        vat:
                            projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE
                                ? `${priceData.vatValue.toFixed(2)} (${priceData.vat * 100}%)`
                                : "-",
                        project_total_vat:
                            projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE ? priceData.valueAfterVat.toFixed(2) : "-",

                        supplier_confirmation: supplier_confirmation,
                    };
                })
            );

            this.setState((prevState) => ({
                rows: [...rows],
                tableToolbarButtons: user.owner
                    ? [
                          generateTableToolbarButton(
                              "Confirm Project",
                              this.confirmProjects,
                              "check",
                              this.confirmationBtnCheck,
                              {
                                  backgroundColor: COLORS.submit,
                              },
                              {
                                  padding: "0 15px 0 0",
                                  margin: "0 15px 0 0",
                                  borderRight: "1px solid " + COLORS.accent,
                              }
                          ),
                          ...prevState.tableToolbarButtons,
                      ]
                    : [...prevState.tableToolbarButtons],
            }));
        } catch (error) {
            console.log(error);
        }

        this.setState({ loading: false });
    };

    confirmProjects = async (ids) => {
        if (!this.context.userData.owner) {
            return false;
        }
        if (ids.length <= 0) {
            return false;
        }

        let result = await Swal.fire({
            type: "warning",
            showCancelButton: true,
            confirmButtonColor: "",
            cancelButtonColor: "",
            confirmButtonText: "Yes",
            cancelButtonText: "No",
            title: "Project Confirmation",
            text: "Do you want to confirm this project?",
        });

        if (!result.value) {
            return false;
        }

        this.setState({ loading: true });

        try {
            let id = ids[0];
            const project = this.state.rows.find((row) => row.id === id).project;

            if (project.data().date_from.toDate() <= getToday()) {
                await Swal.fire({
                    type: "error",
                    confirmButtonColor: "",
                    confirmButtonText: "Ok",
                    title: "Cannot confirm project",
                    text: "Minimum start date should be from tomorrow. ",
                });

                return false;
            }

            let projectSupplierBranches = (await project.ref.collection("Branch_Outlet_Product").get()).docs;

            if (projectSupplierBranches.length <= 0) {
                this.props.showDialogMsg(
                    "Alert",
                    "In order to confirm this project, at least 1 supplier branch must be assigned.",
                    this.props.hideDialogMsg
                );
                return false;
            }

            //verify all supplier branches has assigned outlets + products

            for (const supplierBranch of projectSupplierBranches) {
                let outletBranches = (await supplierBranch.ref.collection("Outlet_Product").get()).docs;

                if (outletBranches.length <= 0) {
                    this.props.showDialogMsg(
                        "Alert",
                        "In order to confirm this project, at least 1 outlet branch must be assigned.",
                        this.props.hideDialogMsg
                    );
                    return false;
                }

                //check if every outlet at least has 1 product and 1 merch day
                for (const outletBranch of outletBranches) {
                    let schedules = (await outletBranch.ref.collection("Schedule").get()).docs;

                    if (schedules.length <= 0) {
                        this.props.showDialogMsg(
                            "Alert",
                            "In order to confirm this project, at least 1 schedule must exist.",
                            this.props.hideDialogMsg
                        );
                        return false;
                    }

                    //check if every outlet at least has 1 product and 1 merch day
                    let result = schedules.reduce((valid, schedule) => {
                        let merchDays = schedule.data().merchandising_days;
                        let hasMerchDays =
                            merchDays.sun.selected ||
                            merchDays.mon.selected ||
                            merchDays.tue.selected ||
                            merchDays.wed.selected ||
                            merchDays.thu.selected ||
                            merchDays.fri.selected ||
                            merchDays.sat.selected;
                        return valid && hasMerchDays && schedule.data().has_products;
                    }, true);
                    if (!result) {
                        this.props.showDialogMsg(
                            "Alert",
                            "In order to confirm this project, for every outlet branch, at least 1 product + 1 day must be assigned",
                            this.props.hideDialogMsg
                        );
                        return false;
                    }
                }
            }

            //confirm Project
            if (project.data().project_type === PROJECT_TYPES.OPEN_MP_INSOURCE) {
                await project.ref.update({ supplier_confirmation: true });
                this.props.showDialogMsg(
                    "Project Confirmation",
                    "The Project is successfully confirmed.",
                    this.props.hideDialogMsg
                );
                let newRows = this.state.rows;
                let rowIndex = newRows.findIndex((row) => row.id === project.id);
                newRows[rowIndex].supplier_confirmation = "Confirmed";
                this.setState({
                    rows: newRows,
                });
            } else {
                await project.ref.update({ confirm: "1", isConfirmed: true, supplier_confirmation: true });
                //create a new summary receipt
                await this.createNewSummaryReceipt(project.data());
            }

            // const project = this.state.rows.find((row) => row.id === id).project.data();
            //create a new summary receipt
            // await this.createNewSummaryReceipt(project);
        } catch (err) {
            console.error(err);
        }

        this.setState({ loading: false });
    };

    createNewSummaryReceipt = async (project) => {
        try {
            let project_id = project.project_id;
            let date_from = project.date_from === undefined ? null : project.date_from.toDate();
            let date_to = project.date_to === undefined ? null : project.date_to.toDate();
            let supplier_id = project.supplier_id === undefined ? null : project.supplier_id;
            const mp_id = project.mp_id === undefined ? null : project.mp_id;
            let project_type = project.project_type === undefined ? null : project.project_type;
            let date_from_copy = new Date(
                date_from.getFullYear(),
                date_from.getMonth(),
                date_from.getDate(),
                23,
                59,
                59,
                999
            );
            date_from_copy = new Date(date_from_copy.setDate(date_from_copy.getDate() - 1));
            let last_receipt_date = date_from_copy === undefined ? null : date_from_copy;
            let date_to_paid =
                project.date_from === undefined
                    ? null
                    : new Date(date_from.getFullYear(), date_from.getMonth(), date_from.getDate(), 0, 0, 0, 0);
            let receipt_amount = 0;

            let projectFullData = await getWholeProject(project_id);
            let totalPrice = await getProjectTotalPrice(projectFullData, null);
            totalPrice = totalPrice.selling.total;

            // let covering = await this.loadProjectCovering(project_id);
            // let totalPrice = await getProjectTotalPrice(covering, null, project_id);

            let vat = (await getVAT()).data().percentage;
            let totalProjectValue = totalPrice;
            let totalProjectValueVat = vat * totalPrice + totalPrice;

            let summaryReceiptId = null;
            let summaryReceipt = (await db.collection("Summary_Receipt").where("project_id", "==", project_id).get())
                .docs;
            if (summaryReceipt.length > 0) {
                summaryReceiptId = summaryReceipt[0].id;
            } else {
                summaryReceiptId = await generateNewId("Summary_Receipt", "id");
            }

            if (!summaryReceiptId) {
                await this.undoConfirmation(project_id);
                this.props.showDialogMsg(
                    "Alert",
                    "Cannot confirm the project due to internal error, Please try again later.",
                    this.props.hideDialogMsg
                );
                return false;
            }

            let receiptVouchers = (await db.collection("Receipt_Voucher").where("project_id", "==", project_id).get())
                .docs;
            for (const rv of receiptVouchers) {
                const data = rv.data();
                receipt_amount += data.amount;
                last_receipt_date =
                    data.date_to.toDate().getTime() > last_receipt_date.getTime()
                        ? data.date_to.toDate()
                        : last_receipt_date;

                let last_receipt_date_copy = new Date(
                    last_receipt_date.getFullYear(),
                    last_receipt_date.getMonth(),
                    last_receipt_date.getDate(),
                    0,
                    0,
                    0,
                    0
                );
                date_to_paid = new Date(last_receipt_date_copy.setDate(last_receipt_date_copy.getDate() + 1));
            }

            let doc = {
                platform: PLATFORM,

                id: summaryReceiptId,
                project_id: project_id,
                supplier_id: supplier_id,
                mp_id: mp_id,
                project_type: project_type,
                date_from: date_from,
                date_to: date_to,
                vat: vat,
                project_amount_no_vat: totalProjectValue,
                project_amount: totalProjectValueVat,
                last_receipt_date: last_receipt_date,
                date_to_paid: date_to_paid,
                receipt_amount: receipt_amount,
                reminder_amount: totalProjectValueVat - receipt_amount,

                status: totalProjectValueVat === receipt_amount ? "0" : "1",
            };

            await db.collection("Summary_Receipt").doc(summaryReceiptId).set(doc);

            this.props.showDialogMsg(
                "Project Confirmation",
                "The Project is successfully confirmed.",
                this.props.hideDialogMsg
            );
            let newRows = this.state.rows;
            let rowIndex = newRows.findIndex((row) => row.id === project_id);
            newRows[rowIndex].supplier_confirmation = "Confirmed";
            this.setState({
                rows: newRows,
            });
        } catch (err) {
            console.log(err);
            await this.undoConfirmation(project.project_id);
        }
    };

    undoConfirmation = async (project_id) => {
        await db
            .collection("Project")
            .doc(project_id)
            .update({ confirm: "1", isConfirmed: false, supplier_confirmation: false });
    };

    confirmationBtnCheck = (selectedRowsIds) => {
        if (selectedRowsIds.length === 1) {
            //find selected row
            const id = selectedRowsIds[0];
            const selectedRow = this.state.rows.find((row) => row.id === id);

            return selectedRow.supplier_confirmation.toUpperCase() === "Not Confirmed".toUpperCase();
        }
    };

    handleProjectDetailsClick = (ids) => {
        if (ids.length !== 1) {
            return false;
        }

        const row = this.state.rows.find((row) => row.id === ids[0]);
        if (!row) return false;

        this.props.gotToProjectDetails(row.project.data());
    };

    pdfClicked = () => {
        this.props.goToProjectPdf(this.state.rows);
    };

    componentDidMount() {
        this.loadRowsData();
        this.setState({
            tableToolbarButtons: [
                generateTableToolbarButton("Add Project", this.props.goToAddProject, "add", () => true),
                generateTableToolbarButton("Project PDF", this.pdfClicked, "pdf", () => !this.state.loading),
                generateTableToolbarButton(
                    "Manage Project",
                    this.handleProjectDetailsClick,
                    "",
                    (ids) => ids.length === 1
                ),
                // generateTableToolbarButton("Edit Confirmed Project", this.props.gotToProjectDetails, "edit", (ids) => {
                //     if (ids.length > 1 || ids.length < 1) return false;
                //     const projectID = ids[0];
                //     const project = this.state.rows.find((row) => row.id === projectID);
                //     if (project.project_confirm === "Not Confirmed") return false;

                //     return true;
                // }),
            ],
        });
    }

    render() {
        const classes = this.props.classes;
        const headRow = [
            { id: "id", numeric: false, disablePadding: false, label: "No." },
            { id: "project_name", numeric: false, disablePadding: false, label: "Project Name" },
            { id: "project_date", numeric: false, disablePadding: false, label: "Date" },
            { id: "project_period", numeric: false, disablePadding: false, label: "Period" },

            { id: "project_total_vat", numeric: false, disablePadding: false, label: "Total" },
            { id: "supplier_confirmation", numeric: false, disablePadding: false, label: "Confirmation" },
        ];

        const actions = this.state.tableToolbarButtons;

        return (
            <div className={`${classes.main} ${classes.textCenter}`} style={{ width: "100%", display: "contents" }}>
                <div
                    style={{ position: "relative", display: "flex", justifyContent: "center", alignItems: "center" }}
                    className={classes.container}
                >
                    <Box
                        style={{
                            paddingTop: 15,
                            borderRadius: "5px",
                            marginTop: "20px",
                            marginBottom: "75px",
                            backgroundColor: "#FFFFFF",
                            color: "#EEEEEE",
                            width: "90%",
                            boxShadow: "#96a1a9 0 4px 5px 0px",
                        }}
                        pt={4}
                    >
                        <GridContainer justifyContent="center" alignItems="center">
                            <div className={classes.title} style={{ width: "3%" }}>
                                <div
                                    style={{
                                        fontWeight: "700",
                                        // backgroundColor: "#91041C",
                                        color: "#EEEEEE",
                                        height: "30px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "2px solid #575757",
                                        // borderRightColor: "#91041C",
                                        // borderBottomColor: "#91041C",
                                        backgroundColor: COLORS.primary,
                                        borderRightColor: COLORS.primary,
                                        borderBottomColor: COLORS.primary,
                                    }}
                                ></div>
                            </div>
                            <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                                <h4
                                    style={{
                                        textAlign: "left",
                                        fontWeight: "700",
                                        backgroundColor: COLORS.background,
                                        color: COLORS.primary,
                                        // color: "#91041C",
                                        height: "30px",
                                        paddingLeft: "20px",
                                        paddingTop: "3px",
                                        paddingBottom: "3px",
                                        border: "1px solid #000000",
                                    }}
                                >
                                    Outsource Projects
                                </h4>
                            </div>

                            <Grid container justifyContent="center" style={{ width: "93%", margin: "20px 0" }}>
                                <GridItem xs={12} sm={12} md={12}>
                                    <EnhancedTable
                                        title={"PROJECTS LIST"}
                                        columns={headRow}
                                        rows={this.state.rows}
                                        // rows={this.state.rows}
                                        actions={actions}
                                        updateSelected={() => {}}
                                        // selectionDisabled={true}
                                        loading={this.state.loading}
                                    />
                                </GridItem>
                            </Grid>
                        </GridContainer>
                    </Box>
                </div>
            </div>
        );
    }
}

Content.propTypes = {
    classes: PropTypes.object,
};

export default withStyles(useStyles)(Content);

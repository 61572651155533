import React, { useState } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Card from "@material-ui/core/Card";
import CardActionArea from "@material-ui/core/CardActionArea";
// import CardActions from "@material-ui/core/CardActions";
import CardContent from "@material-ui/core/CardContent";
import CardMedia from "@material-ui/core/CardMedia";
// import Button from "@material-ui/core/Button";
import Typography from "@material-ui/core/Typography";
import { Grid, Tooltip } from "@material-ui/core";
import styles from "./styles";
import noImage from "../../assets/images/no-image-available-1.png";
import Slider from "../slider";
import { formatDate, formatTime } from "../../helpers/date-utils";
import GalleryItemDialog from "../gellery-item-dialog";

const GalleryImageCard = ({ info }) => {
    const classes = styles();
    const [isDialogOpened, setIsDialogOpened] = useState(false);
    return (
        <Card className={classes.root}>
            <Typography className={classes.picBeforeTitle} gutterBottom variant="h5" component="h5">
                {info.extra_display ? info.extra_display_info?.name?.en || "Ex. Display" : "Shelf"}
            </Typography>

            <Slider
                modified={{ url: info.picture_before || noImage, title: "Before" }}
                original={{ url: info.picture_after || noImage, title: "After" }}
            />
            {/* <Grid container>
                    <Grid item xs={6}>
                        <CardMedia
                            onLoad={(e) => {
                                console.log(e);
                            }}
                            component="img"
                            alt="Contemplative Reptile"
                            height="140"
                            // image="https://i.picsum.photos/id/1/5616/3744.jpg?hmac=kKHwwU8s46oNettHKwJ24qOlIAsWN9d2TtsXDoCWWsQ"
                            image={info.picture_before || noImage}
                            title="Image Before"
                        />
                    </Grid>
                    <Grid item xs={6}>
                        <CardMedia
                            component="img"
                            onLoad={(e) => {
                                console.log(e);
                            }}
                            alt="Contemplative Reptile"
                            height="140"
                            // image="https://i.picsum.photos/id/0/5616/3744.jpg?hmac=3GAAioiQziMGEtLbfrdbcoenXoWAW-zlyEAMkfEdBzQ"
                            image={info.picture_after || noImage}
                            title="Picture After"
                        />
                    </Grid>
                </Grid> */}
            <CardActionArea onClick={() => setIsDialogOpened(true)}>
                <CardContent>
                    <Tooltip title={info.product.En_name}>
                        <Typography className={classes.cardHeader} gutterBottom variant="h5" component="h2">
                            {info.product.En_name}

                            {/* <span className={classes.tooltip}>{info.product.En_name}</span> */}
                        </Typography>
                    </Tooltip>
                    <Typography variant="body2" color="textSecondary" component="p">
                        {info.outlet_branch.En_short_name}
                    </Typography>
                    <Typography className={classes.dateTime} variant="body2" color="textSecondary" component="p">
                        {formatDate(info.task.date_time_from.toDate())} {formatTime(info.task.date_time_from.toDate())}{" "}
                        ~ {formatTime(info.task.date_time_to.toDate())}
                    </Typography>
                </CardContent>
            </CardActionArea>

            {<GalleryItemDialog open={isDialogOpened} onClose={() => setIsDialogOpened(false)} info={info} />}
            {/* <CardActions>
        <Button size="small" color="primary">
          Share
        </Button>
        <Button size="small" color="primary">
          Learn More
        </Button>
      </CardActions> */}
        </Card>
    );
};

export default GalleryImageCard;

import { TASKS_STATUS } from "../../constants/constants-lagacy";
import { getScheduleByTask } from "./Project";
import { firestore as db } from "../firebase";
import { isDate, isObject } from "lodash/fp";
import { query } from "./helpers";

const TASK_COLLECTION = "Task";

export let getTask = async (id) => {
    try {
        const task = await db.collection("Task").doc(id).get();
        return task;
    } catch (error) {
        throw error;
    }
};

export const getTasksByID = async (ids) => {
    try {
        const tasks = await Promise.all(ids.map((id) => getTask(id)));
        return tasks;
    } catch (error) {
        throw error;
    }
};

export let getMerchandiserTasksByDate = async (merchandiserID, date) => {
    try {
        let dateStart = new Date(date);
        dateStart.setHours(0, 0, 0, 0);
        let dateEnd = new Date(date);
        dateEnd.setHours(23, 59, 59, 59);

        const tasks = (
            await db
                .collection("Task")
                .where("uid", "==", merchandiserID)
                .where("only_header", "==", false)
                .where("date_time_from", ">=", dateStart)
                .where("date_time_from", "<=", dateEnd)
                .get()
        ).docs;

        return tasks;
    } catch (error) {
        throw error;
    }
};

export let getMerchandiserTaskHeadersByDate = async (merchandiserID, date) => {
    try {
        let dateStart = new Date(date);
        dateStart.setHours(0, 0, 0, 0);
        let dateEnd = new Date(date);
        dateEnd.setHours(23, 59, 59, 59);

        let taskHeaders = (
            await db
                .collection("Task")
                .where("only_header", "==", true)
                .where("date_time_from", ">=", dateStart)
                .where("date_time_from", "<=", dateEnd)
                .get()
        ).docs.map((task) => task.data());

        let nonUID = taskHeaders.filter((taskHeader) => taskHeader.uid === null);

        await Promise.all(
            nonUID.map(async (task) => {
                const schedule = await getScheduleByTask(task);
                if (!schedule) {
                    throw new Error(`Could not find schedule for the task ID: ${task.id}`);
                }

                const modifiedProps = task.modified_properties ?? [];
                task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.merchandiser_id;
                task.state =
                    modifiedProps.indexOf("state") !== -1
                        ? task.state
                        : task.uid
                        ? TASKS_STATUS.BOOKED
                        : TASKS_STATUS.OPEN;
                task.duration = schedule.duration;
            })
        );

        return taskHeaders.filter((task) => task.uid === merchandiserID);
    } catch (error) {
        throw error;
    }
};

export const queryTasks = async (options) => {
    try {
        let ref = db.collection(TASK_COLLECTION);
        if (options.docID) {
            return await ref.doc(options.docID).get();
        }
        // console.log(options);

        for (const key in options) {
            if (Object.hasOwnProperty.call(options, key)) {
                let query = { value: null, operator: "==" };

                if (!isObject(options[key]) || isDate(options[key])) {
                    query.value = options[key];
                } else {
                    query = options[key];
                }
                const { value, operator } = query;
                if (key === "supplierBranchID") ref = ref.where("supplier_branch_id", operator, value);
                if (key === "mpID") ref = ref.where("mp_id", operator, value);
                if (key === "supplierID") ref = ref.where("supplier_id", operator, value);
                if (key === "date") ref = ref.where("date_time_from", operator, value);
                if (key === "type") ref = ref.where("type", operator, value);
                if (key === "routeID") ref = ref.where("route_id", operator, value);
            }
        }

        return (await ref.get()).docs;
    } catch (error) {
        throw error;
    }
};

export const queryTasks_V2 = async (options) => {
    try {
        return await query(TASK_COLLECTION, options);
    } catch (error) {
        throw error;
    }
};

export const queryTasksOnSnapshot = (options, snapshotListener, onTasksError) => {
    try {
        let ref = db.collection(TASK_COLLECTION);
        if (options.docID) {
            return ref.doc(options.docID).onSnapshot(snapshotListener, onTasksError);
        }
        // console.log(options);

        for (const op of options) {
            const { key, value, operator } = op;
            ref = ref.where(key, operator, value);
        }

        return ref.onSnapshot(snapshotListener, onTasksError);
    } catch (error) {
        throw error;
    }
};

export const populateTaskHeader = async (task) => {
    try {
        if (!task.only_header) return task;

        let schedule = await getScheduleByTask(task);
        if (!schedule) {
            throw new Error(`Could not find schedule for the task ID: ${task.id}`);
        }

        schedule = schedule.data();
        const modifiedProps = task.modified_properties ?? [];
        task.uid = modifiedProps.indexOf("uid") !== -1 ? task.uid : schedule.merchandiser_id;
        task.state =
            modifiedProps.indexOf("state") !== -1 ? task.state : task.uid ? TASKS_STATUS.BOOKED : TASKS_STATUS.OPEN;
        task.duration = schedule.duration;
        task.route_id = modifiedProps.indexOf("route_id") !== -1 ? task.route_id : schedule.route_id;

        return task;
    } catch (error) {
        throw error;
    }
};

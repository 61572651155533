export const arrayChunks = (array, chunk) => {
    let temporary = [];
    let chunkes = [];
    for (let index = 0, end = array.length; index < end; index += chunk) {
        temporary = array.slice(index, index + chunk);
        chunkes.push(temporary);
    }

    return chunkes;
};

// Function to find the missing numbers in a sorted array of integers (ascending order)
export const findMissingNumbers = (arr, start) => {
    let cnt = 0;
    const length = arr.length;
    start = start || arr[0];
    let missing = [];
    for (let i = start; i <= arr[length - 1]; i++) {
        // Check if number is equal to the first element in
        // given array if array element match skip it increment for next element
        if (arr[cnt] == i) {
            // Increment the count to check next element
            cnt++;
        } else {
            // Print missing number
            missing.push(i);
        }
    }

    return missing;
};

// Function to filter out the duplicate specified properties values in an array of objects
export const filterDuplicates = (arr, props) => {
    return arr.filter((obj, pos, arr) => {
        //get the values to be compared with by props specified
        let values = arr.map((mapObj) => props.reduce((res, prop) => ({ ...res, [prop]: mapObj[prop] }), {}));
        //check if the value is already present in the values array
        return values.findIndex((value) => props.reduce((res, prop) => res && value[prop] === obj[prop], true)) === pos;
        // return arr.map((mapObj) => mapObj[prop]).indexOf(obj[prop]) === pos;
    });
};

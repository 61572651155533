import React, { useCallback, useEffect } from "react";

//related to meterial ui package
import styles from "./styles";
import { Dialog, DialogContent, DialogTitle, Grid, IconButton, Typography } from "@material-ui/core";
import { useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import { Form, Formik } from "formik";
import { Autocomplete, SubmitButton, TextField } from "../../core-ui/forms-ui";

import CloseIcon from "@material-ui/icons/Close";
import Swal from "sweetalert2";
import COLORS from "../../constants/colors";
import { FORM_SCHEMA, INITIAL_FORM_VALUES, USER_TYPES_LIST } from "./schema";
import { Asterisk } from "../../components";
import { COMPANY_TYPES, STATUS } from "../../constants/global";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { isEmailExist, serverTimestamp } from "../../helpers/firebase-helpers";
import { capitalize, cloneDeep } from "lodash";
import { addUserAccount } from "../../services/auth";

const AddUserDialog = ({ open, onClose }) => {
    const classes = styles();
    const { companyData, userData } = useAuth();
    const [creating, setCreating] = useState(false);
    const [loading, setLoading] = useState(false);
    const [supplierBranchesList, setSupplierBranchesList] = useState([]);

    const castValues = (values) => {
        const newValues = cloneDeep(values);
        return {
            uid: null,
            company_id: companyData.company_id,
            branch_id: null,
            branch_ids: newValues.branch_ids.map((item) => item.value),
            first_name: capitalize(newValues.first_name),
            surname: capitalize(newValues.surname),
            email: newValues.email.toLowerCase(),
            password: newValues.password,
            user_type: newValues.user_type.value,
            blocked: false,
            owner: false,
            phone: `${newValues.phone}`,

            //new
            company_type: COMPANY_TYPES.SUPPLIER,
            status: STATUS.ACTIVE,
            is_verified: false,

            //add timestamp to document
            created_at: serverTimestamp(),
            updated_at: serverTimestamp(),
            created_by: userData.uid,
            updated_by: userData.uid,
        };
    };

    const handleSubmit = async (values, helpers) => {
        try {
            setCreating(true);
            const exist = await isEmailExist(values.email);
            if (exist) {
                helpers.setFieldError("email", "Email already exist");
                setCreating(false);
                return;
            }
            const newValues = castValues(values);
            await addUserAccount(newValues);

            // await addNewTermsAgreement(userData.uid);
            // await addNewPrivacyAgreement(userData.uid);

            await Swal.fire({
                title: `User Account Has Been Created!`,
                text: `User ${values.first_name} ${values.surname} has been successfully created.`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
                customClass: { container: classes.swal },
            });
            onClose(null, "submit", true);
        } catch (error) {
            console.log(error);
        }
        setCreating(false);
    };

    const init = useCallback(async () => {
        try {
            setLoading(true);
            const { company_id } = companyData;
            let supplierBranchesList = (await getBranchesBySupplierID(company_id)).map((doc) => ({
                label: doc.data().En_name,
                value: doc.id,
                data: doc.data(),
            }));

            setSupplierBranchesList(supplierBranchesList);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [companyData]);

    useEffect(() => {
        if (open) init();
    }, [open]);

    return (
        <Dialog maxWidth="md" fullWidth open={open} onClose={onClose} className={classes.dialogRoot}>
            <DialogTitle disableTypography>
                <Typography className={classes.titleText} variant="h5">
                    Create a User Account
                </Typography>
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            </DialogTitle>

            <DialogContent dividers className={classes.cardContent}>
                <Formik
                    initialValues={INITIAL_FORM_VALUES}
                    enableReinitialize
                    validationSchema={FORM_SCHEMA}
                    onSubmit={handleSubmit}
                >
                    {(formik) => (
                        <Form>
                            <Grid container spacing={3}>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Autocomplete
                                        options={USER_TYPES_LIST}
                                        name="user_type"
                                        label={<Asterisk>Role</Asterisk>}
                                        className={classes.input}
                                        disableClearable
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} md={6}>
                                    <Autocomplete
                                        multiple
                                        options={supplierBranchesList}
                                        name="branch_ids"
                                        label={<Asterisk>Supplier Branch</Asterisk>}
                                        className={classes.input}
                                        loading={loading}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        name={"first_name"}
                                        label={<Asterisk>First Name</Asterisk>}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6} md={3}>
                                    <TextField
                                        name={"surname"}
                                        label={<Asterisk>Last Name</Asterisk>}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField name={"phone"} label={"Phone"} className={classes.input} type="tel" />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        name={"email"}
                                        label={<Asterisk>Email</Asterisk>}
                                        className={classes.input}
                                        type="email"
                                        showValidationIcon
                                        validateOnChange
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="password"
                                        name="password"
                                        label={<Asterisk>Password</Asterisk>}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={6}>
                                    <TextField
                                        type="password"
                                        name="confirm_password"
                                        label={<Asterisk>Confirm Password</Asterisk>}
                                        className={classes.input}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={2} md={2}>
                                    <SubmitButton type="submit" className={classes.submitBtn} submitting={creating}>
                                        Create
                                    </SubmitButton>
                                </Grid>
                            </Grid>
                        </Form>
                    )}
                </Formik>
            </DialogContent>
        </Dialog>
    );
};

export default AddUserDialog;

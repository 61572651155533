import { firestore as db } from "../firebase";
import { COLLECTIONS } from "./constants";

export const getSupplier = async (supplierID) => {
    try {
        return await db.collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT).doc(supplierID).get();
    } catch (error) {
        throw error;
    }
};

export const addSupplier = async (data) => {
    try {
        return await db.collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT).doc(data.company_id).set(data);
    } catch (error) {
        throw error;
    }
};

export let getLinkedOutlet = async (supplierID, outletID) => {
    try {
        return await db
            .collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT)
            .doc(supplierID)
            .collection(COLLECTIONS.OUTLET_PRODUCT)
            .doc(outletID)
            .get();
    } catch (error) {
        throw error;
    }
};

export let getLinkedOutlets = async (supplierID) => {
    try {
        let linkedOutlets = [];

        //get number of outlet companies registered in this supplier account
        linkedOutlets = (
            await db.collection("Supplier_Outlet_Product").doc(supplierID).collection("Outlet_Product").get()
        ).docs;
        return linkedOutlets;
    } catch (error) {
        throw error;
    }
};

export const addLinkedOutlets = async (supplierID, outlets) => {
    try {
        const ref = db.collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT).doc(supplierID);
        await Promise.all(
            outlets.map(async (outlet) => {
                return ref.collection(COLLECTIONS.OUTLET_PRODUCT).doc(outlet.outlet_id).set(outlet);
            })
        );
    } catch (error) {
        throw error;
    }
};

export const deleteLinkedOutlets = async (supplierID, outletIDs) => {
    try {
        const ref = db.collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT).doc(supplierID);
        await Promise.all(
            outletIDs.map(async (id) => {
                // await deleteLinkedProducts(supplierID, id);
                return ref.collection(COLLECTIONS.OUTLET_PRODUCT).doc(id).delete();
            })
        );
    } catch (error) {
        throw error;
    }
};

export const updateLinkedOutlets = async (supplierID, outlets) => {
    try {
        const ref = db.collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT).doc(supplierID);
        await Promise.all(
            outlets.map(async (outlet) => {
                return ref.collection(COLLECTIONS.OUTLET_PRODUCT).doc(outlet.outlet_id).update(outlet);
            })
        );
    } catch (error) {
        throw error;
    }
};

export let getLinkedProducts = async (outletID, supplierID) => {
    let linkedProducts = [];
    try {
        //get number of products registered in this outlet
        linkedProducts = (
            await db
                .collection("Supplier_Outlet_Product")
                .doc(supplierID)
                .collection("Outlet_Product")
                .doc(outletID)
                .collection("Product")
                .get()
        ).docs;
    } catch (error) {
        console.log(error);
    }

    return linkedProducts;
};

export const addLinkedOutletProducts = async (supplierID, outletID, products) => {
    try {
        const ref = db
            .collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT)
            .doc(supplierID)
            .collection(COLLECTIONS.OUTLET_PRODUCT)
            .doc(outletID);
        await Promise.all(
            products.map(async (product) => {
                return ref.collection(COLLECTIONS.PRODUCTS).doc(product.product_id).set(product);
            })
        );
    } catch (error) {
        throw error;
    }
};

export const deleteLinkedProducts = async (supplierID, outletID, productIDs = []) => {
    try {
        const ref = db
            .collection(COLLECTIONS.SUPPLIER_OUTLET_PRODUCT)
            .doc(supplierID)
            .collection(COLLECTIONS.OUTLET_PRODUCT)
            .doc(outletID);

        if (productIDs.length <= 0) {
            const docs = (await ref.collection(COLLECTIONS.PRODUCTS).get()).docs;
            await Promise.all(docs.map(async (doc) => doc.ref.delete()));
        } else {
            await Promise.all(
                productIDs.map(async (id) => {
                    return ref.collection(COLLECTIONS.PRODUCTS).doc(id).delete();
                })
            );
        }
    } catch (error) {
        throw error;
    }
};

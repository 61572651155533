import { getBranchesBySupplierID } from "./Supplier_Branch";
import { firestore as db } from "../firebase";
import { PLATFORM } from "../../constants/global";

export let getLinkedOutletBranches = async (supplierBranchID) => {
    let outletBranches = [];
    try {
        outletBranches = (
            await db.collection("Branch_Outlet_Branch").doc(supplierBranchID).collection("Outlet_Branch").get()
        ).docs;
    } catch (error) {
        console.log(error);
    }
    return outletBranches;
};

export let getAllLinkedOutletBranches = async (supplierBranchIDs) => {
    let allOutletBranches = {};
    try {
        let result = await Promise.all(
            supplierBranchIDs.map(async (id) => {
                let outletBranches = (
                    await db.collection("Branch_Outlet_Branch").doc(id).collection("Outlet_Branch").get()
                ).docs;

                return { [id]: outletBranches.map((branch) => branch.id) };
            })
        );

        allOutletBranches = result.reduce((obj, curr) => ({ ...obj, ...curr }), {});
    } catch (error) {
        console.log(error);
    }
    return allOutletBranches;
};

export let getLinkedOutletBranchesBySupplierID = async (supplierID, branchesIDs = []) => {
    let outletBranches = [];
    try {
        if (branchesIDs.length === 0) {
            branchesIDs = (await getBranchesBySupplierID(supplierID)).map((branch) => branch.id);
        }
        outletBranches = await Promise.all(
            branchesIDs.map(async (id) => {
                let linkedOutletBranches = await getLinkedOutletBranches(id);
                return { supplierBranchID: id, outletBranches: linkedOutletBranches };
            })
        );

        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const linkOutletBranches = async (supplierID, branchID, outletBranchesIDs) => {
    try {
        //create or update branch doc
        await db.collection("Branch_Outlet_Branch").doc(branchID).set({
            platform: PLATFORM,
            supplier_id: supplierID,
            branch_id: branchID,
        });

        await Promise.all(
            outletBranchesIDs.map((id) =>
                db.collection("Branch_Outlet_Branch").doc(branchID).collection("Outlet_Branch").doc(id).set({
                    platform: PLATFORM,
                    supplier_id: supplierID,
                    branch_id: id,
                    supplier_branch_id: branchID,
                })
            )
        );
    } catch (error) {
        throw error;
    }
};

export const unlinkOutletBranches = async (branchID, outletBranchesIDs) => {
    try {
        await Promise.all(
            outletBranchesIDs.map((id) =>
                db.collection("Branch_Outlet_Branch").doc(branchID).collection("Outlet_Branch").doc(id).delete()
            )
        );
    } catch (error) {
        throw error;
    }
};

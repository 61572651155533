import React from "react";
import { EnhancedTransferList, Filters } from "../../components";
import { Button, CircularLoader, Header, LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import styles from "./styles";
import useLinkOutletBranches from "./use-link-outlet-branches";

const LinkOutletBranches = () => {
    const classes = styles();

    const {
        initializing,
        loading,
        saving,
        // transferListState,
        leftList,
        rightList,
        checkedList,
        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,
    } = useLinkOutletBranches();

    // const { leftList, rightList, checkedList } = transferListState;

    return (
        <section className={classes.root}>
            <TransitionAlert
                variant="filled"
                severity={alertState.severity}
                open={alertState.open}
                setOpen={setAlertOpen}
                className={classes.stickyAlert}
                timer={3000}
            >
                {alertState.message}
            </TransitionAlert>
            {(initializing || loading) && <LoadingOverlay />}

            <div className={classes.card}>
                <Header className={classes.header}>Link Your Branch With Outlet Branches</Header>
                <Filters init={initFilters} onSubmit={applyFilters} submitText="Apply" />
                <EnhancedTransferList
                    leftList={leftList}
                    rightList={rightList}
                    checkedList={checkedList}
                    onTransfer={transferHandler}
                    onFilter={filterHandler}
                />
                <Button className={classes.saveBtn} onClick={saveHandler} disabled={initializing || saving}>
                    {saving ? <CircularLoader size={24} className={classes.circularProgress} /> : "Save"}
                </Button>
            </div>
        </section>
    );
};

export default LinkOutletBranches;

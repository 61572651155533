import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";

import { defaultFont } from "../core-ui/core-styles/jss/material-kit-ui";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import Swal from "sweetalert2";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components-lagacy/Grid/GridContainer";
import GridItem from "../components-lagacy/Grid/GridItem";
import Box from "@material-ui/core/Box";
import Button from "../core-ui/material-kit-ui/buttons/Button";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { COLORS } from "../constants/constants-lagacy";

import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";

import { generatePDF } from "../helpers/pdf-utils";
import ProductsPdf from "../components-lagacy/PDF/products";
import AuthContext from "../contexts/auth-context";

const styles = (theme) => ({
    button: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "product_number_int", numeric: false, disablePadding: false, label: "Product Number" },
    { id: "En_name", numeric: false, disablePadding: false, label: "Product En. Name" },
    { id: "Ar_name", numeric: true, disablePadding: false, label: "Product Ar. Name" },
    { id: "packing_unitXouter_unitXno_units", numeric: false, disablePadding: false, label: "Pieces" },
    { id: "status", numeric: false, disablePadding: false, label: "Status" },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: COLORS.background, padding: "0px", margin: "0px" }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        style={{
                            color: COLORS.primary,
                        }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {headRows.map((row) => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? "right" : "left"}
                        padding={row.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            style={{
                                color: COLORS.primary,
                                paddingBottom: "5px",
                                paddingTop: "5px",
                                fontWeight: "500",
                                fontSize: "14px",
                            }}
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: "#d50000",
                  backgroundColor: lighten("#d50000", 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: "#d50000",
              },
    spacer: {
        flex: "1 1 50%",
    },
    actions: {
        color: "#d50000",
    },
    title: {
        flex: "0 0 auto",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        selectedRowName,
        numSelected,
        handleShowDeleteAlertMethod,
        handleAddNewSupplierProductMethod,
        handleUpdateProductInfoMethod,
        handleProductsListPdfMethod,
        loading,
    } = props;
    const id = selectedRowName[0];

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        Products List ({numSelected} selected)
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Products List
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div>
                {numSelected > 0 ? (
                    numSelected === 1 ? (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    marginRight: "22px",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.button}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Edit"
                                    placement={"top"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        onClick={() => {
                                            handleUpdateProductInfoMethod(id);
                                        }}
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        style={{ padding: 0 }}
                                    >
                                        <EditIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    ) : (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    )
                ) : (
                    <List
                        style={{
                            ...defaultFont,
                            fontSize: "14px",
                            margin: 0,
                            paddingLeft: "0",
                            listStyle: "none",
                            paddingTop: "0",
                            paddingBottom: "0",
                            color: "#91041C",
                        }}
                    >
                        <ListItem
                            style={{
                                float: "left",
                                color: COLORS.primary,
                                position: "relative",
                                display: "block",
                                width: "auto",
                                margin: "0",
                                padding: "0",
                            }}
                        >
                            <Tooltip
                                id="instagram-twitter"
                                title="Add New Product"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                                style={{ float: "right" }}
                            >
                                <Button
                                    onClick={() => {
                                        handleAddNewSupplierProductMethod();
                                    }}
                                    simple
                                    //color="google"
                                    block
                                    className={classes.textCenter}
                                    style={{ padding: 0 }}
                                >
                                    <AddIcon
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "40px",
                                            padding: "7px",
                                            backgroundColor: COLORS.accent,
                                            color: COLORS.font,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </ListItem>
                        <ListItem
                            style={{
                                float: "left",
                                color: COLORS.primary,
                                position: "relative",
                                display: "block",
                                width: "auto",
                                margin: "0",
                                padding: "0",
                                display: loading ? "none" : "block",
                            }}
                        >
                            <Tooltip
                                id="instagram-twitter"
                                title="PDF"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                                style={{ float: "right" }}
                            >
                                <Button
                                    onClick={() => {
                                        handleProductsListPdfMethod();
                                    }}
                                    simple
                                    //color="google"
                                    block
                                    className={classes.textCenter}
                                    style={{ padding: 0 }}
                                >
                                    <PictureAsPdfIcon
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "40px",
                                            padding: "7px",
                                            backgroundColor: COLORS.accent,
                                            color: COLORS.font,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </ListItem>
                    </List>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleShowDeleteAlertMethod: PropTypes.func,
    selectedRowName: PropTypes.array,
    handleUpdateProductInfoMethod: PropTypes.func,
    handleAddNewSupplierProductMethod: PropTypes.func,
    products: PropTypes.array,
    handleProductsListPdfMethod: PropTypes.func,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function EnhancedTable(props) {
    const {
        products,
        handleShowDeleteAlertMethod,
        handleUpdateProductInfoMethod,
        handleAddNewSupplierProductMethod,
        handleProductsListPdfMethod,
        loading,
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState(products);
    // const [selectedRow, setSelectedRow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(true);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = products.map((n) => n.product_id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        // setSelectedRow(name);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, products.length - page * rowsPerPage);

    return (
        <div style={{ paddingTop: 5, marginTop: 0 }} className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    products={products}
                    selectedRowName={selected}
                    numSelected={selected.length}
                    handleShowDeleteAlertMethod={handleShowDeleteAlertMethod}
                    handleUpdateProductInfoMethod={handleUpdateProductInfoMethod}
                    handleAddNewSupplierProductMethod={handleAddNewSupplierProductMethod}
                    handleProductsListPdfMethod={handleProductsListPdfMethod}
                    loading={loading}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={products.length}
                        />
                        <TableBody>
                            {stableSort(products, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.product_id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.product_id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.product_id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{
                                                        color: COLORS.primary,
                                                        //boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
                                                    }}
                                                    //labelStyle={{color: "#d50000"}}
                                                    //iconStyle={{fill: "#d50000"}}
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </TableCell>

                                            <TableCell>{row.product_number_int}</TableCell>

                                            <TableCell>{row.En_name}</TableCell>

                                            <TableCell align="right">{row.Ar_name}</TableCell>

                                            <TableCell>{row.packing_unitXouter_unitXno_units}</TableCell>

                                            <TableCell
                                                style={
                                                    row.status === "1"
                                                        ? { backgroundColor: "#4BB543", color: "#FFFFFF" }
                                                        : row.status === "0"
                                                        ? { backgroundColor: "#575757", color: "#FFFFFF" }
                                                        : { backgroundColor: "#91041C", color: "#FFFFFF" }
                                                }
                                            >
                                                {row.status === "1"
                                                    ? "Active"
                                                    : row.status === "0"
                                                    ? "Not Active"
                                                    : "no"}
                                            </TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={products.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

//==================================================================================
//==========================SupplierProductsList======================
//==================================================================================

class SupplierProductsList extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            products: [],
            activeProducts: [],
            notActiveProducts: [],
            deletedProducts: [],
            showDeleteAlert: false,
            delete_Products: [],
            checkedB: false,
            tableDisplay: 2,
            productsDisplayed: [],
            categories: [],
            subCategories: [],
            sub_category_id: "",
            category_id: "",
            name: "",

            supplier_name: "",

            searchCount: 0,

            pathAddNewSupplierProduct: "",
            pathUpdateProductInfo: "",
            UpdateProductId: "",
            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }

    //==================================================================================
    handleProductsListPdf = () => {
        generatePDF(
            <ProductsPdf
                info={{
                    products: this.state.products,
                    companyData: this.context.companyData,
                }}
            />,
            `Products PDF`
        );
    };
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    componentDidMount() {
        this.setState({ loading: true });

        // // if there is any filter before go to Add or edit product
        // if (
        //     this.props.location.state.name !== undefined ||
        //     this.props.location.state.category_id !== undefined ||
        //     this.props.location.state.sub_category_id !== undefined
        // ) {
        //     this.setState({
        //         name: this.props.location.state.name !== undefined ? this.props.location.state.name : "",
        //         category_id:
        //             this.props.location.state.category_id !== undefined ? this.props.location.state.category_id : "",
        //         sub_category_id:
        //             this.props.location.state.sub_category_id !== undefined
        //                 ? this.props.location.state.sub_category_id
        //                 : "",
        //     });
        // }

        let datat2 = [];
        datat2.push({ category_id: "", En_name: "Nothing" });
        var categoriesRef = db.collection("Category").orderBy("En_name");
        categoriesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    let datat = doc.data();
                    datat2.push(datat);
                });
                this.setState({ categories: datat2 });
            })
            .then(() => {
                let datat3 = [];
                var subCategoriesRef = db.collection("SubCategory").orderBy("En_name");
                subCategoriesRef.get().then((snapshot) => {
                    snapshot.forEach((doc) => {
                        let datat = doc.data();
                        datat3.push(datat);
                    });
                    this.setState({ subCategories: datat3 });
                });
            })
            .then(() => {
                let datat4 = [];
                var citiesRef = db.collection("Supplier").where("company_id", "==", this.props.id);
                citiesRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat4 = doc.data();

                            this.setState(() => ({
                                supplier_name: datat4.company_name,
                            }));
                        });
                    })
                    .then(() => {
                        // if there is any filter before go to Add or edit product
                        // if (this.props.location.state.name !== undefined || this.props.location.state.category_id !== undefined || this.props.location.state.sub_category_id !== undefined) {
                        this.doSearch();
                        // }
                    });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                this.showDialogMsg("Alert", "Something went wrong, please try to refresh.", this.hideDialogMsg);
            });
    }

    handleShowDeleteAlert = (products, numSelected) => {
        /*if(this.state.tableDisplay === 4){
      if(products.length > 1){
        alert("The products have already been deleted")
      }else{
        alert("The product has already been deleted")
      }
      
    }else{*/
        this.deleteAlertWindow(products);
        //}
    };

    deleteAlertWindow = (products) => {
        if (products.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Products !",
                text: "Do you want to delete these products?",
            }).then((result) => {
                if (!result.value) {
                    this.deleteAlertWindow2(products);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Product !",
                text: "Do you want to delete this product?",
            }).then((result) => {
                if (!result.value) {
                    this.deleteAlertWindow2(products);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        }
    };

    deleteAlertWindow2 = (products) => {
        if (products.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Products !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (!result.value) {
                    this.deleteRow(products);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Product !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (!result.value) {
                    this.deleteRow(products);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        }
    };

    deleteRow = (products) => {
        //delete the Product from firestore collection(store)
        for (let i = 0; i < products.length; i++) {
            let status = "0";

            var storeList = db.collection("Product").where("product_id", "==", products[i]);
            storeList
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        status = doc.data().status;
                        if (status === "0") {
                            this.showDialogMsg(
                                "Alert",
                                "The product (" + doc.data().En_name + ") has already been deleted",
                                this.hideDialogMsg
                            );
                        } else {
                            doc.ref.update({
                                status: "0",
                            });
                        }
                    });
                })
                .then(() => {
                    if (/*status === "0" ||*/ status === "1") {
                        //delete product from All
                        const index = this.state.products.findIndex((post) => {
                            return post.product_id === products[i];
                        });

                        var arrayAllProducts = [...this.state.products];
                        if (index !== -1) {
                            arrayAllProducts[index].status = "0";
                        }

                        /*
      //change status of product from active or not active to deleted into state of products Array 
      const index = this.state.products.findIndex(post => {
        return post.product_id === products[i]
      })
      var arrayAllProducts = [...this.state.products];
      arrayAllProducts[index].status="2";
  
      //delete product from All/ active/ not active array
      if(this.state.tableDisplay===1){
        
          if(status === "1"){
            
            const index = this.state.activeProducts.findIndex(post => {
              return post.product_id === products[i]
            })
            
            if (index !== -1) {
              var deletedproductItem =[];
              //delete product from active array
              var array = [...this.state.activeProducts];
              deletedproductItem = array.splice(index, 1);
              
              //add product into deleted array
              deletedproductItem[0].status="2";
              var array2 = [...this.state.deletedProducts];
              array2.push(deletedproductItem[0]);
              console.log(array2);
              this.setState({products: arrayAllProducts, activeProducts: array, deletedProducts: array2 });
            }
            
  
          }else{
            //delete product from not active array
            const index = this.state.notActiveProducts.findIndex(post => {
              return post.product_id === products[i]
            })
            
            if (index !== -1) {
              var deletedproductItem =[];
              //delete product from not active array
              var array = [...this.state.notActiveProducts];
              deletedproductItem = array.splice(index, 1);
  
              //add product into deleted array
              deletedproductItem[0].status="2";
              var array2 = [...this.state.deletedProducts];
              array2.push(deletedproductItem[0]);
              console.log(array2);
              this.setState({products: arrayAllProducts, notActiveProducts: array, deletedProducts: array2 });
            }
  
  
          }
  
        }else*/ if (this.state.tableDisplay === 2) {
                            // delete product from active array
                            const index = this.state.activeProducts.findIndex((post) => {
                                return post.product_id === products[i];
                            });

                            if (index !== -1) {
                                var deletedproductItem = [];
                                //delete product from active array
                                var array = [...this.state.activeProducts];
                                deletedproductItem = array.splice(index, 1);

                                //add product into notActive array
                                deletedproductItem[0].status = "0";
                                var array2 = [...this.state.notActiveProducts];
                                array2.push(deletedproductItem[0]);
                                this.setState({
                                    products: arrayAllProducts,
                                    activeProducts: array,
                                    productsDisplayed: array,
                                    notActiveProducts: array2,
                                });
                            }
                        } /*else{
          // delete product from not active array
            const index = this.state.notActiveProducts.findIndex(post => {
                return post.product_id === products[i]
              })
              
              if (index !== -1) {
                var deletedproductItem =[];
                //delete product from not active array
                var array = [...this.state.notActiveProducts];
                deletedproductItem = array.splice(index, 1);
  
                //add product into deleted array
                deletedproductItem[0].status="2";
                var array2 = [...this.state.deletedProducts];
                array2.push(deletedproductItem[0]);
                console.log(array2);
                this.setState({products: arrayAllProducts, notActiveProducts: array, productsDisplayed: array, deletedProducts: array2 });
              }
  
  
        }//else not active*/
                    } //if status is active or not active
                });
        } //for
    };

    isItemSelected = (name) => {
        // alert(name)
        this.setState({ checkedB: !this.state.checkedB });
    };

    doSearch = () => {
        this.setState({ loading: true });

        if (this.state.name.length > 0) {
            if (this.state.category_id.length > 0) {
                if (this.state.sub_category_id.length > 0) {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    let productsRef = db
                        .collection("Product")
                        .orderBy("En_name")
                        .startAt(this.state.name)
                        .endAt(this.state.name + "\uf8ff")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.category_id", "==", this.state.category_id)
                        .where("product_category.sub_category_id", "==", this.state.sub_category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                } else {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    let productsRef = db
                        .collection("Product")
                        .orderBy("En_name")
                        .startAt(this.state.name)
                        .endAt(this.state.name + "\uf8ff")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.category_id", "==", this.state.category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                }
            } else {
                if (this.state.sub_category_id.length > 0) {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    let productsRef = db
                        .collection("Product")
                        .orderBy("En_name")
                        .startAt(this.state.name)
                        .endAt(this.state.name + "\uf8ff")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.sub_category_id", "==", this.state.sub_category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                } else {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    //firebase.firestore();
                    let productsRef = db
                        .collection("Product")
                        .orderBy("En_name")
                        .startAt(this.state.name)
                        .endAt(this.state.name + "\uf8ff")
                        .where("supplier_id", "==", this.props.id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                }
            }
        } else {
            if (this.state.category_id.length > 0) {
                if (this.state.sub_category_id.length > 0) {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    //firebase.firestore();
                    let productsRef = db
                        .collection("Product")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.category_id", "==", this.state.category_id)
                        .where("product_category.sub_category_id", "==", this.state.sub_category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                } else {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    //firebase.firestore();
                    let productsRef = db
                        .collection("Product")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.category_id", "==", this.state.category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                }
            } else {
                if (this.state.sub_category_id.length > 0) {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    //firebase.firestore();
                    let productsRef = db
                        .collection("Product")
                        .where("supplier_id", "==", this.props.id)
                        .where("product_category.sub_category_id", "==", this.state.sub_category_id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                } else {
                    let datat = "";
                    let datat2 = [];
                    let datat3 = [];
                    let datat4 = [];
                    //firebase.firestore();
                    let productsRef = db.collection("Product").where("supplier_id", "==", this.props.id);
                    productsRef.get().then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();
                            datat.product_number_int = datat.product_number;
                            datat.barcode_int = Number(datat.barcode);
                            datat.packing_unitXouter_unitXno_units =
                                Number(datat.packing.packing_unit) *
                                Number(datat.outer_unit) *
                                Number(datat.packing.no_units);
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
                datat5.push(datat);
            }*/
                        });
                        this.setState({
                            activeProducts: datat3,
                            notActiveProducts: datat4,
                            /*deletedProducts:datat5,*/ products: datat2,
                            productsDisplayed: datat3,
                            loading: false,
                        });
                    });
                }
            }
        }
    };
    //================================================================================== str.search("WSchools")
    search2 = (array) => {
        var queryResult = [];
        array.forEach((product) => {
            if (
                (product.product_category.category_id === this.state.category_id ||
                    this.state.category_id.length === 0) &&
                (product.En_name.toLowerCase().search(this.state.name.toLowerCase()) > -1 ||
                    product.Ar_name.search(this.state.name) > -1 ||
                    this.state.name.length === 0) &&
                (product.product_category.sub_category_id === this.state.sub_category_id ||
                    this.state.sub_category_id.length === 0)
            )
                queryResult.push(product);
        });

        this.setState({
            productsDisplayed: queryResult,
        });
    };

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };

    handleUpdateProductInfo = (id) => {
        this.setState({ pathUpdateProductInfo: PATHS.UPDATE_PRODUCT, UpdateProductId: id });
    };

    handleAddNewSupplierProduct = () => {
        this.setState({ pathAddNewSupplierProduct: PATHS.ADD_PRODUCT });
    };

    goToSupplierDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================
    render() {
        if (this.state.pathUpdateProductInfo.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathUpdateProductInfo,
                        state: {
                            productID: this.state.UpdateProductId,
                            id: this.props.id,
                            name: this.state.name,
                            category_id: this.state.category_id,
                            sub_category_id: this.state.sub_category_id,
                        },
                    }}
                />
            );
        } else if (this.state.pathAddNewSupplierProduct.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathAddNewSupplierProduct,
                        state: {
                            id: this.props.id,
                            name: this.state.name,
                            category_id: this.state.category_id,
                            sub_category_id: this.state.sub_category_id,
                        },
                    }}
                />
            );
        } else if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                    }}
                />
            );
        } else {
            // const classes = makeStyles((theme) => ({
            //     root: {
            //         width: "100%",
            //         marginTop: theme.spacing(3),
            //         overflowX: "auto",
            //     },
            //     table: {
            //         minWidth: 650,
            //     },
            // }));
            const { classes } = this.props;

            //sub_category_list
            const sub_category_list = [];
            sub_category_list.push({ value: "", label: "Nothing" });
            if (this.state.category_id.length > 0) {
                for (let i = 0; i < this.state.subCategories.length; i++) {
                    if (this.state.category_id === this.state.subCategories[i].category_id) {
                        let label = this.state.subCategories[i].En_name;
                        sub_category_list.push({
                            value: this.state.subCategories[i].sub_category_id,
                            label: label,
                        });
                    }
                }
            } else {
                for (let i = 0; i < this.state.subCategories.length; i++) {
                    let label = this.state.subCategories[i].En_name;
                    sub_category_list.push({ value: this.state.subCategories[i].sub_category_id, label: label });
                }
            }

            return (
                <div /*style={{backgroundColor:"#262626", margin:"0px", padding:"0px", height:'100vh'}}*/>
                    <div
                        className={classNames(classes.main, classes.textCenter)}
                        style={{ width: "100%", display: "contents" }}
                    >
                        <Grid
                            container
                            spacing={0}
                            alignItems="center"
                            justifyContent="center"
                            direction="column"
                            style={{ position: "relative", maxWidth: "100%" }}
                            className={classes.container}
                        >
                            <div
                                className={`${this.props.classes.backdrop} ${this.props.classes.absolutePosition} ${
                                    this.state.loading ? null : this.props.classes.loadingHidden
                                }`}
                            ></div>
                            <div
                                className={`${this.props.classes.loaderContainer} ${
                                    this.props.classes.absolutePosition
                                } ${this.state.loading ? null : this.props.classes.loadingHidden}`}
                            >
                                <CircularProgress />
                            </div>
                            <Box
                                style={{
                                    paddingTop: 15,
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    marginBottom: "75px",
                                    backgroundColor: "#FFFFFF",
                                    color: "#EEEEEE",
                                    width: "90%",
                                    boxShadow: "#96a1a9 0 4px 5px 0px",
                                }}
                                pt={4}
                            >
                                <GridContainer justifyContent="center" alignItems="center">
                                    <div className={classes.title} style={{ width: "3%" }}>
                                        <div
                                            style={{
                                                fontWeight: "700",
                                                // backgroundColor: "#91041C",
                                                color: "#EEEEEE",
                                                height: "30px",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "2px solid #575757",
                                                // borderRightColor: "#91041C",
                                                // borderBottomColor: "#91041C",
                                                backgroundColor: COLORS.primary,
                                                borderRightColor: COLORS.primary,
                                                borderBottomColor: COLORS.primary,
                                            }}
                                        ></div>
                                    </div>
                                    <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                                        <h4
                                            style={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                backgroundColor: COLORS.background,
                                                color: COLORS.primary,
                                                // color: "#91041C",
                                                height: "30px",
                                                paddingLeft: "20px",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "1px solid #000000",
                                            }}
                                        >
                                            Manage Products
                                        </h4>
                                    </div>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <p
                                            style={{
                                                margin: 0,
                                                paddingTop: 0,
                                                textTransform: "none",
                                                color: "#000000",
                                                fontWeight: "900",
                                            }}
                                        >
                                            Search By
                                        </p>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={10}>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                    </GridItem>

                                    <div style={{ width: "92%", display: "flex", flexDirection: "row" }}>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.name}
                                                        variant="filled"
                                                        fullWidth
                                                        id="name"
                                                        label="Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({
                                                                name:
                                                                    event.target.value.charAt(0).toUpperCase() +
                                                                    event.target.value.slice(1),
                                                            })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.category_id}
                                                        variant="filled"
                                                        fullWidth
                                                        id="category_id"
                                                        label="Cate."
                                                        style={{ textAlign: "left" }}
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({ category_id: event.target.value })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.categories.map((option) => (
                                                            <MenuItem
                                                                key={option.category_id}
                                                                value={option.category_id}
                                                            >
                                                                {option.En_name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={6}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.sub_category_id}
                                                        variant="filled"
                                                        fullWidth
                                                        id="sub_category_id"
                                                        label="SubCate."
                                                        style={{ textAlign: "left" }}
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({ sub_category_id: event.target.value })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {sub_category_list.map((option) => (
                                                            <MenuItem key={option.value} value={option.value}>
                                                                {option.label}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>

                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        // disabled={!this.validateThirdForm()}
                                                        onClick={this.doSearch}
                                                        style={{
                                                            marginTop: 18,
                                                            marginRight: 10,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            float: "left",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.submit,
                                                            color: COLORS.font,
                                                            // borderColor: "#4BB543",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                    >
                                                        {" "}
                                                        Search{" "}
                                                    </Button>
                                                </GridItem>
                                                <GridItem xs={12} sm={12} md={2}>
                                                    <Button
                                                        simple
                                                        size="lg"
                                                        // disabled={!this.validateThirdForm()}
                                                        onClick={() => {
                                                            this.setState({
                                                                tableDisplay: 2,
                                                                productsDisplayed: this.state.activeProducts,
                                                                category_id: "",
                                                                name: "",
                                                                sub_category_id: "",
                                                            });
                                                        }}
                                                        style={{
                                                            marginTop: 17,
                                                            padding: 10,
                                                            paddingLeft: 20,
                                                            paddingRight: 20,
                                                            float: "left",
                                                            textTransform: "none",
                                                            backgroundColor: COLORS.accent,
                                                            color: COLORS.font,
                                                            // borderColor: "#91041C",
                                                            border: "2",
                                                            fontWeight: "400",
                                                        }}
                                                        type="submit"
                                                    >
                                                        {" "}
                                                        Cancel{" "}
                                                    </Button>
                                                </GridItem>
                                            </div>
                                        </Grid>
                                    </div>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                    </GridItem>

                                    <div style={{ width: "89%" }}>
                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 2,
                                                    productsDisplayed: this.state.activeProducts,
                                                    category_id: "",
                                                    name: "",
                                                    sub_category_id: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 2
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Active{" "}
                                        </Button>

                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 3,
                                                    productsDisplayed: this.state.notActiveProducts,
                                                    category_id: "",
                                                    name: "",
                                                    sub_category_id: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 3
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Not Active{" "}
                                        </Button>
                                    </div>

                                    <GridItem xs={12} sm={12} md={11}>
                                        <EnhancedTable
                                            products={this.state.productsDisplayed}
                                            handleShowDeleteAlertMethod={this.handleShowDeleteAlert}
                                            handleUpdateProductInfoMethod={this.handleUpdateProductInfo}
                                            handleAddNewSupplierProductMethod={this.handleAddNewSupplierProduct}
                                            handleProductsListPdfMethod={this.handleProductsListPdf}
                                            loading={this.state.loading}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Box>
                        </Grid>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: COLORS.accent }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}

SupplierProductsList.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
};

export default withStyles(componentsStyle, styles)(SupplierProductsList);

import React from "react";
import { Redirect, Route, useLocation } from "react-router";
import { CompatRoute } from "react-router-dom-v5-compat";
import { DashboardHeader, EmailVerificationBar } from "..";
import PATHS, { PUBLIC_PATHS } from "../../constants/paths";
import { useAuth } from "../../contexts/auth-context";
import { TransitionAlert } from "../../core-ui/custom";
import { getDaysSinceSpecificDate } from "../../helpers/date-utils";

const PublicRoute = (props) => {
    const { currentUser, companyData, userData } = useAuth();
    const location = useLocation();
    const { children } = props;

    //if the user is verified OR not verified and it's been 10 days or less since registration, let him in
    if (currentUser && companyData && userData) {
        const daysElapsed = getDaysSinceSpecificDate(userData.created_at);
        if (currentUser.emailVerified || (!currentUser.emailVerified && daysElapsed <= 10)) {
            return <Redirect to={{ pathname: location.state?.from ?? PATHS.DASHBOARD }} />;
        }
    }

    return (
        <CompatRoute {...props}>
            {currentUser && companyData && userData && <EmailVerificationBar />}
            {children}
        </CompatRoute>
        // <Route {...props}>
        //     {currentUser && companyData && userData && <EmailVerificationBar />}
        //     {children}
        // </Route>
    );
};

export default PublicRoute;

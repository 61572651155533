export const PLATFORM = "murattab";
export const PLATFORM_FULL = "murattab-supplier";
export const TASK_TYPES = {
    REGULAR: "regular",
    HANDOVER: "handover",
    MP_INSOURCE: "mp-insource",
    MP_OUTSOURCE: "mp-outsource",
    SUPPLIER_INSOURCE: "self-service-simple",
};

export const WEB_USER_ACCOUNT_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
};

export const COMPANY_TYPES = {
    SUPPLIER: "supplier",
    SERVICE_PROVIDER: "mp",
    OUTLET: "outlet",
};

export const USER_TYPES = {
    ADMIN: "admin",
    BRANCH_MANAGER: "branch_manager",
    BRAND_MANAGER: "brand_manager",
};

export const OUTLET_BRANCH_STATUS = {
    ACTIVE: "1",
    INACTIVE: "0",
    DELETED: "-1",
};

export const EMPLOYEE_TYPES = {
    SUPPLIER_MERCHANDISER: "supplier_employee",
    SUPPLIER_SUPERVISOR: "supplier_supervisor",
    MP_MERCHANDISER: "mp_employee",
    MP_SUPERVISOR: "mp_supervisor",
    STORE_MERCHANDISER: "store_merchandiser",
    STORE_SUPERVISOR: "store_supervisor",
    STORE_WAREHOUSE_KEEPER: "warehouse_keeper",
    FREELANCE_MERCHANDISER: "regular",
};

export const EMPLOYEE_ACCOUNT_STATUS = {
    INVALID: 0,
    VALID: 1,
    INCOMPLETE: 4,
    COMPLETE: 5,
    NOT_PASS: 8,
    PASS: 9,
    REGISTERED: 10,
    INACTIVE: 11,
};

export const EMPLOYEE_APP_STATUS = {
    RESUMED: "resumed",
    INACTIVE: "inactive",
    PAUSED: "paused",
};

export const PROJECT_TYPES = {
    SUPPLIER_OUTSOURCE: "101",
    SUPPLIER_INSOURCE: "102",
    MP_OUTSOURCE: "103",
    OPEN_MP_INSOURCE: "104",
    STORE_INSOURCE: "105",
    SUPPLIER_PROMOTING_INSOURCE: "106",
};

export const OUTLET_SUPPLIER_LINK_STATUS = {
    INACTIVE: "0",
    ACTIVE: "1",
    PENDING: "2",
    REJECTED: "3",
};

export const WEEKDAY_TO_DATE_INDEX = {
    sun: 0,
    sunday: 0,
    mon: 1,
    monday: 1,
    tue: 2,
    tuesday: 2,
    wed: 3,
    wednday: 3,
    thu: 4,
    thursday: 4,
    fri: 5,
    friday: 5,
    sat: 6,
    saturday: 6,
};

export const DEFAULT_PROJECT_OPTIONS = {
    availability: { active: false },
    quantity: { active: true },
    pictures: { active: false },

    warehouse: { active: true },
    inventory: { active: false, interval: "none", dates: [] },
    near_expiration: { active: true, interval: "daily", dates: [] },
    shelf_price: { active: true, interval: "daily", dates: [] },
    display_audit: { active: false, interval: "none", dates: [] },
    competitor_activity: { active: false, interval: "none", dates: [] },
};

export const DISTRIBUTION_METHODS = {
    DSD: "dsd",
    DC: "dc",
};

export const STATUS = {
    ACTIVE: "1",
    INACTIVE: "0",
    DELETED: "-1",
};

export const INITIAL_SCHEDULE_OPTIONS = {
    availability: { active: false },
    quantity: { active: true },
    pictures: { active: false },

    warehouse: { active: true },
    inventory: { active: false, interval: "none", dates: [], order: false },
    near_expiration: { active: true, interval: "daily", dates: [] },

    shelf_price: { active: true, interval: "daily", dates: [] },
    display_audit: { active: false, interval: "none", dates: [] },
    competitor_activity: { active: false, interval: "none", dates: [] },

    damaged: { active: false, interval: "none", dates: [] },
    delivery: { active: false, interval: "none", dates: [] },
};

import React from "react";
import { lighten, makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogContent from "@material-ui/core/DialogContent";

import AppBar from "@material-ui/core/AppBar";
import Toolbar from "@material-ui/core/Toolbar";
import IconButton from "@material-ui/core/IconButton";
import Typography from "@material-ui/core/Typography";
import CloseIcon from "@material-ui/icons/Close";
import Slide from "@material-ui/core/Slide";
import { COLORS } from "../../constants/constants-lagacy";
import { Paper } from "@material-ui/core";
import GridItem from "../../components-lagacy/Grid/GridItem";
import GridContainer from "../../components-lagacy/Grid/GridContainer";
import ArrowBackIcon from "@material-ui/icons/ArrowBack";
import ArrowForwardIcon from "@material-ui/icons/ArrowForward";
import noImage from "../../assets/images/no-image-available.png";
import CircularProgress from "@material-ui/core/CircularProgress";

const useStyles = makeStyles((theme) => ({
    appBar: {
        position: "relative",
        background: COLORS.primary,
    },
    title: {
        marginLeft: theme.spacing(2),
        flex: 1,
    },

    flexCenter: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
    },

    taskTitle: { width: "auto", padding: "10px", background: "#0000005e", borderRadius: "5px" },
    arrowBtn: {
        width: "auto",
        padding: "10px",
        margin: "0 10px",
        background: "#0000005e",
        borderRadius: "5px",
        cursor: "pointer",
        transition: "background 0.3s ease-in-out",
        "&:hover": {
            background: "#000000c4",
        },
    },

    thumbnailContainer: {
        padding: "0",
    },

    leftTumbnailcontainer: {
        paddingRight: "15px",
    },
    rightTumbnailcontainer: {
        paddingLeft: "15px",
    },

    thumbnailImageWrap: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "550px",
        backgroundColor: "#131313",
        "& img": {
            objectFit: "contain",
            width: "100%",
            height: "100%",
            transition: "opacity 0.5s",
        },
        "& $hidden": {
            opacity: 0,
        },
        "& $loader": {
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
        },
    },
    loader: {},
    hidden: {},

    btnsContainer: {
        padding: 0,
        marginTop: "5px",
    },

    leftBtns: {
        textAlign: "left",
        paddingLeft: "0",
        "& button": {
            margin: "0 5px",
            color: COLORS.font,
            background: "#0000005e",
            borderRadius: "5px",
            cursor: "pointer",
            transition: "background 0.3s ease-in-out",
            "&:hover": {
                background: "#000000c4",
            },
        },
        "& $activebtn": {
            color: "#151515",
            background: COLORS.font,
            "&:hover": {
                background: "#eeeeee",
            },
        },
    },
    rightBtns: {
        textAlign: "right",
        // paddingRight: "0",
    },

    activebtn: {},

    confirmBtn: {
        backgroundColor: COLORS.submit,
        color: COLORS.font,
        marginLeft: "5px",
        "&:hover": {
            backgroundColor: lighten(COLORS.submit, 0.1),
        },
    },

    cancelBtn: {
        backgroundColor: COLORS.accent,
        color: COLORS.font,
        "&:hover": {
            backgroundColor: lighten(COLORS.accent, 0.1),
        },
    },

    btnDisabled: {
        color: "#03030382",
        backgroundColor: "#acacac",
    },

    taskProductSwitcher: {
        marginTop: "5px",
    },

    loaderContainer: {
        position: "absolute",
        top: "0",
        left: "0",
        width: "100%",
        height: "100%",
        backgroundColor: "rgba(10, 10, 10, 0.5)",
        display: "flex",
        justifyContent: "center",
        alignItems: "center",

        "&$hidden": {
            opacity: 0,
            pointerEvents: "none",
        },
    },
}));

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

export default function TaskGallery(props) {
    const classes = useStyles();

    const task = props.tasks[props.selectedTask];
    if (!task) return null;

    const selectedProduct = task.products[props.selectedProduct];
    let actualWorkImg = noImage;
    let referenceTaskID = null;
    let referenceWorkImg = noImage;
    let oldReferenceWorkImg = noImage;
    let productName = "Product Name not found";

    if (selectedProduct) {
        productName = selectedProduct.ProductName;
        // actualWorkImg = props.toggleShelf ? selectedProduct.images.shelf || noImage : selectedProduct.images.extra_display || noImage;
        actualWorkImg = selectedProduct.images.actualWork || noImage;

        // referenceTaskID = props.toggleShelf ? selectedProduct.images.shelfReferenceTaskID : selectedProduct.images.extraDisplayReferenceTaskID;

        // referenceWorkImg = props.toggleShelf
        //     ? selectedProduct.images.shelfReference || noImage
        //     : selectedProduct.images.extraDisplayReference || noImage;

        referenceWorkImg = selectedProduct.images.referenceWork || noImage;

        // oldReferenceWorkImg = props.toggleShelf
        //     ? selectedProduct.images.oldShelfReference || noImage
        //     : selectedProduct.images.oldExtraDisplayReference || noImage;
        oldReferenceWorkImg = selectedProduct.images.oldReferenceWork || noImage;
    }

    // let displayBtnClicked = (shelfSelected, product, leftImageURL, rightImageURL) => {
    //     props.onToggleShelf(shelfSelected);
    //     //decide wheather to show loader or not
    //     if (!product) return;

    //     //left side
    //     const newLeftImgURL = shelfSelected ? product.images.shelf || noImage : product.images.extra_display || noImage;
    //     if (leftImageURL !== newLeftImgURL) props.toggleLeftLoader(false);
    //     //right side
    //     const newRightImgURL = shelfSelected
    //         ? product.images.shelfReference || noImage
    //         : product.images.extraDisplayReference || noImage;
    //     if (rightImageURL !== newRightImgURL) props.toggleRightLoader(false);
    // };

    let changeTask = (newIndex, tasks, leftImageURL, rightImageURL) => {
        newIndex = tasks.length === 0 ? 0 : (tasks.length + newIndex) % tasks.length;
        props.onTaskIndexChange(newIndex);

        //decide wheather to show loader or not
        //this covers if products length == 1 or 0
        if (props.selectedTask === newIndex) return;

        changeProduct(-1, 0, tasks[newIndex].products, leftImageURL, rightImageURL);
    };

    let changeProduct = (oldIndex, newIndex, products, leftImageURL, rightImageURL) => {
        newIndex = products.length === 0 ? 0 : (products.length + newIndex) % products.length;
        props.onProductIndexChange(newIndex);

        //decide wheather to show loader or not
        //this covers if products length == 1 or 0
        if (oldIndex === newIndex) return;

        const shelfSelected = props.toggleShelf;
        const product = products[newIndex];
        //left side
        const newLeftImgURL = !product
            ? noImage
            : shelfSelected
            ? product.images.shelf || noImage
            : product.images.extra_display || noImage;
        if (leftImageURL !== newLeftImgURL) props.toggleLeftLoader(false);
        //right side
        const newRightImgURL = !product
            ? noImage
            : shelfSelected
            ? product.images.shelfReference || noImage
            : product.images.extraDisplayReference || noImage;
        if (rightImageURL !== newRightImgURL) props.toggleRightLoader(false);
    };

    let onImgDragStart = (event, taskID) => {
        event.dataTransfer.setData("imgURL", event.target.getAttribute("src"));
        event.dataTransfer.setData("taskID", taskID);
    };

    let onImgDrop = (event) => {
        // if (task.state !== "12" || task.state !== "5") {
        //     return;
        // }
        const newReferenceImg = event.dataTransfer.getData("imgURL");
        const newReferenceTaskID = event.dataTransfer.getData("taskID");
        if (!newReferenceImg || newReferenceImg === referenceWorkImg || newReferenceImg === noImage) return;
        const taskIndex = props.selectedTask;
        const productIndex = props.selectedProduct;

        props.dropNewReferenceImg(taskIndex, productIndex, newReferenceImg, newReferenceTaskID);
    };

    let allowDrop = (event) => {
        event.preventDefault();
    };

    return (
        <Dialog
            fullScreen
            open={props.open}
            TransitionComponent={Transition}
            PaperProps={{ style: { background: "#06060680", color: "#FFFFFF" } }}
            style={{ zIndex: 500 }}
        >
            <AppBar className={classes.appBar}>
                <Toolbar>
                    <IconButton edge="start" color="inherit" onClick={props.handleClose} aria-label="close">
                        <CloseIcon />
                    </IconButton>
                    <Typography variant="h6" className={classes.title}>
                        Task Images
                    </Typography>
                    {/* <Button autoFocus color="inherit" onClick={handleClose}>
                            save
                        </Button> */}
                </Toolbar>
            </AppBar>

            <DialogContent dividers style={{ padding: "16px 0px", position: "relative" }}>
                {/* Task Header */}
                <GridContainer container alignItems="center" justifyContent="center">
                    <GridItem
                        className={`${classes.arrowBtn} ${classes.flexCenter}`}
                        onClick={() => changeTask(props.selectedTask - 1, props.tasks, actualWorkImg, referenceWorkImg)}
                    >
                        <ArrowBackIcon />
                    </GridItem>
                    <GridItem className={`${classes.taskTitle} ${classes.flexCenter}`}>{`Task #${task.id}`}</GridItem>
                    <GridItem
                        className={`${classes.arrowBtn} ${classes.flexCenter}`}
                        onClick={() => changeTask(props.selectedTask + 1, props.tasks, actualWorkImg, referenceWorkImg)}
                    >
                        <ArrowForwardIcon />
                    </GridItem>
                </GridContainer>

                <GridContainer alignItems="center" justifyContent="center" style={{ marginTop: "10px" }}>
                    <Typography variant="h6">{`${task.outletBranch}, ${task.city}, ${task.date}${
                        task.time ? ` - ${task.time}` : ""
                    }`}</Typography>
                </GridContainer>

                {/* Task Products images */}
                <GridContainer
                    alignItems="center"
                    justifyContent="center"
                    style={{ margin: "10px 0", justifyContent: "space-between" }}
                >
                    <GridItem
                        className={`${classes.thumbnailContainer} ${classes.leftTumbnailcontainer}`}
                        xs={12}
                        sm={12}
                        md={6}
                    >
                        <Typography variant="h6" style={{ textAlign: "center" }}>
                            Merchandiser's Work
                        </Typography>

                        {/* <CardMedia image={actualWorkImg || noImage} title="Actual Merchandiser's work" className={`${classes.thumbnailImage}`} /> */}
                        <Paper className={`${classes.thumbnailImageWrap}`} elevation={2}>
                            <img
                                key={`left-${actualWorkImg}`}
                                src={`${actualWorkImg}`}
                                alt={"Actual Merchandiser's work"}
                                title="Actual Merchandiser's work"
                                className={props.leftImageLoaded ? "" : `${classes.hidden}`}
                                onLoad={() => props.toggleLeftLoader(true)}
                                onDragStart={(event) => onImgDragStart(event, task.task_id)}
                            />
                            {props.leftImageLoaded ? null : (
                                <div className={`${classes.loader}`}>
                                    <CircularProgress />
                                </div>
                            )}
                        </Paper>
                        <GridContainer className={`${classes.btnsContainer}`} justifyContent="space-between">
                            <GridItem xs={6} sm={6} md={6} className={`${classes.leftBtns}`}>
                                {/* <Button
                                    className={props.toggleShelf ? `${classes.activebtn}` : ""}
                                    onClick={() => displayBtnClicked(true, selectedProduct, actualWorkImg, referenceWorkImg)}
                                >
                                    Shelf
                                </Button>
                                <Button
                                    className={!props.toggleShelf ? `${classes.activebtn}` : ""}
                                    onClick={() => displayBtnClicked(false, selectedProduct, actualWorkImg, referenceWorkImg)}
                                >
                                    Extra Display
                                </Button> */}
                            </GridItem>
                            <GridItem xs={6} sm={6} md={6} className={`${classes.rightBtns}`}>
                                <Button
                                    className={`${classes.cancelBtn}`}
                                    disabled={referenceWorkImg === oldReferenceWorkImg}
                                    classes={{ disabled: classes.btnDisabled }}
                                    onClick={props.rollBackReferenceImg}
                                >
                                    Cancel
                                </Button>
                                <Button
                                    className={`${classes.confirmBtn}`}
                                    disabled={noImage === actualWorkImg || referenceWorkImg === oldReferenceWorkImg}
                                    classes={{ disabled: classes.btnDisabled }}
                                    onClick={props.updateReferenceImg}
                                >
                                    Set as Reference
                                </Button>
                            </GridItem>
                        </GridContainer>
                    </GridItem>

                    <GridItem
                        className={`${classes.thumbnailContainer} ${classes.rightTumbnailcontainer}`}
                        xs={12}
                        sm={12}
                        md={6}
                    >
                        <Typography variant="h6" style={{ textAlign: "center" }}>{`Reference${
                            referenceTaskID ? ` (#${referenceTaskID})` : ""
                        }`}</Typography>
                        {/* <CardMedia image={referenceWorkImg || noImage} title="Reference Work" className={`${classes.thumbnailImageWrap}`} /> */}
                        <Paper className={`${classes.thumbnailImageWrap}`} elevation={2}>
                            <img
                                key={`right-${referenceWorkImg}`}
                                src={`${referenceWorkImg}`}
                                alt={"Reference work"}
                                title="Reference work"
                                className={props.rightImageLoaded ? "" : `${classes.hidden}`}
                                onLoad={() => props.toggleRightLoader(true)}
                                onDrop={onImgDrop}
                                onDragOver={allowDrop}
                            />
                            {props.rightImageLoaded ? null : (
                                <div className={`${classes.loader}`}>
                                    <CircularProgress />
                                </div>
                            )}
                        </Paper>
                        <GridContainer
                            alignItems="center"
                            justifyContent="center"
                            className={`${classes.taskProductSwitcher}`}
                        >
                            <GridItem
                                className={`${classes.arrowBtn} ${classes.flexCenter}`}
                                onClick={() =>
                                    changeProduct(
                                        props.selectedProduct,
                                        props.selectedProduct - 1,
                                        task.products,
                                        actualWorkImg,
                                        referenceWorkImg
                                    )
                                }
                            >
                                <ArrowBackIcon />
                            </GridItem>
                            <GridItem className={`${classes.taskTitle} ${classes.flexCenter}`}>{productName}</GridItem>
                            <GridItem
                                className={`${classes.arrowBtn} ${classes.flexCenter}`}
                                onClick={() =>
                                    changeProduct(
                                        props.selectedProduct,
                                        props.selectedProduct + 1,
                                        task.products,
                                        actualWorkImg,
                                        referenceWorkImg
                                    )
                                }
                            >
                                <ArrowForwardIcon />
                            </GridItem>
                        </GridContainer>
                    </GridItem>
                </GridContainer>

                <GridContainer alignItems="center" justifyContent="center"></GridContainer>
                <div className={`${classes.loaderContainer} ${!props.loading ? classes.hidden : ""}`}>
                    <CircularProgress />
                </div>
            </DialogContent>
        </Dialog>
    );
}

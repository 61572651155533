import React, { useEffect, useRef, useState } from "react";
import { useHistory } from "react-router";
import styles from "./styles";
import { LogoHeader } from "../../core-ui/custom";
import Title from "../../core-ui/custom/title";
import TitleDescription from "../../core-ui/custom/title-description";
import { Button } from "@material-ui/core";
import { FormHeader, PasswordField, TextField } from "../../core-ui/forms-ui";
import { Formik, Form } from "formik";
import * as Yup from "yup";
import { LoadingOverlay, TransitionAlert } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { Link } from "react-router-dom";

let INITIAL_FORM_VALUES = {
    email: "",
    password: "",
};

const FORM_SCHEMA = Yup.object().shape({
    email: Yup.string().email("Invalid email.").required("required"),
    password: Yup.string().required("Required"),
});

const SignIn = () => {
    const classes = styles();
    const history = useHistory();
    const [showAlert, setShowAlert] = useState(false);
    const [error, setError] = useState("");
    const { signin, authLoading, setAuthLoading, signinError } = useAuth();
    const formikRef = useRef(null);

    useEffect(() => {
        if (history.location.state?.message) {
            setShowAlert(true);
        }

        INITIAL_FORM_VALUES.email = history.location.state?.email || "";
    }, [history.location]);

    const handleSubmit = async (values) => {
        try {
            const { email, password } = values;
            setAuthLoading(true);
            await signin(email, password);
        } catch (error) {
            const errorCode = error.code;
            const errorMessage = error.message;
            if (errorCode === "auth/wrong-password" || errorCode === "auth/user-not-found") {
                setError("Invalid Email or password.");
            } else {
                setError("Unable to sign in, please check your internet connection or try later.");
                console.error(errorMessage);
            }
        }
        setAuthLoading(false);
    };

    return (
        <section className={classes.signinPage}>
            <TransitionAlert
                severity={history.location.state?.messageType || "info"}
                variant="filled"
                open={showAlert}
                setOpen={setShowAlert}
            >
                {history.location.state?.message}
            </TransitionAlert>
            <LogoHeader variant="dark" />

            <section className={classes.container}>
                <div className={classes.formSection}>
                    {authLoading && <LoadingOverlay test="Processing" />}
                    <Title>Murattab Supplier</Title>
                    <Formik
                        initialValues={INITIAL_FORM_VALUES}
                        validationSchema={FORM_SCHEMA}
                        onSubmit={handleSubmit}
                        innerRef={formikRef}
                    >
                        <Form>
                            <FormHeader>Sign In</FormHeader>
                            <TextField className={classes.input} name="email" label="Email" type="email" />
                            <PasswordField className={classes.input} name="password" label="Password" />
                            {error && <p className={classes.errorMsg}>{error}</p>}
                            {signinError && <p className={classes.errorMsg}>{signinError}</p>}
                            <Button type="submit" className={classes.submitBtn}>
                                Sign In
                            </Button>
                            <Link
                                to={{ pathname: "/forgot-password", state: { email: formikRef.current?.values.email } }}
                            >
                                Forgot your password?
                            </Link>
                        </Form>
                    </Formik>
                </div>

                <div className={classes.signupLinkSection}>
                    <div className={classes.signupLinkContent}>
                        <Title>You don't have Account?</Title>
                        <TitleDescription>Create your account now and join us as a supplier.</TitleDescription>
                        <Button className={classes.signupBtn} onClick={() => history.push("/sign-up")}>
                            Sign Up
                        </Button>
                    </div>
                </div>
            </section>
        </section>
    );
};

export default SignIn;

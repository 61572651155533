import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";

import { lighten, makeStyles } from "@material-ui/core/styles";
import { Button, List, ListItem, Toolbar, Tooltip, Typography } from "@material-ui/core";
import DescriptionIcon from "@material-ui/icons/Description";
import PaymentIcon from "@material-ui/icons/Payment";
import AddIcon from "@material-ui/icons/Add";
import CheckIcon from "@material-ui/icons/Check";
import EditIcon from "@material-ui/icons/Edit";
import PictureAsPdf from "@material-ui/icons/PictureAsPdf";
import ImageIcon from "@material-ui/icons/Image";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { defaultFont } from "../../core-ui/material-kit-ui/general-styles";
import { COLORS } from "../../constants/constants-lagacy";

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight: {
        //   color: "#d50000",
        //   backgroundColor: lighten("#d50000", 0.85),
        color: COLORS.primary,
        backgroundColor: lighten(COLORS.accent, 0.85),
    },
    spacer: {
        flex: "1 1 50%",
    },
    actions: {
        color: "#d50000",
    },
    title: {
        flex: "0 0 auto",
    },
}));

const EnhancedTableToolbar = (props) => {
    const classes = useToolbarStyles();
    const {
        selectedRowIds,
        // selectedRowStatus,
        numSelected,
        actions,
        // handleShowDeleteAlertMethod,
        // handleTaskDetailsMethod,
        title,
    } = props;

    let generateActionBtn = (title, action, disabled, icon = null, iconStyleCustom = {}, style = {}) => {
        let iconEl = null;
        const iconStyle = {
            height: 40,
            width: 40,
            borderRadius: "40px",
            padding: "7px",
            backgroundColor: COLORS.accent,
            color: "#FFFFFF",
            ...iconStyleCustom,
        };
        switch (icon) {
            case "payment":
                iconEl = <PaymentIcon style={iconStyle} />;
                break;

            case "add":
                iconEl = <AddIcon style={iconStyle} />;
                break;
            case "check":
                iconEl = <CheckIcon style={iconStyle} />;
                break;
            case "edit":
                iconEl = <EditIcon style={iconStyle} />;
                break;
            case "pdf":
                iconEl = <PictureAsPdf style={iconStyle} />;
                break;

            case "image":
                iconEl = <ImageIcon style={iconStyle} />;
                break;

            case "pdf":
                iconEl = <PictureAsPdfIcon style={iconStyle} />;
                break;

            default:
                iconEl = <DescriptionIcon style={iconStyle} />;
                break;
        }
        return (
            <ListItem
                key={`table-btns: ${title}`}
                style={{
                    float: "left",
                    color: "inherit",
                    position: "relative",
                    display: "block",
                    width: "auto",
                    margin: "0",
                    padding: "0",
                    ...style,
                }}
            >
                <Tooltip
                    title={title}
                    placement={"top"}
                    classes={{ tooltip: classes.tooltip }}
                    style={{ float: "right" }}
                >
                    <Button
                        onClick={() => action(selectedRowIds)}
                        // simple
                        //color="google"
                        // block
                        className={classes.textCenter}
                        style={{
                            padding: 0,
                            display: disabled ? "none" : "flex",
                            justifyContent: "center",
                            alignItems: "center",
                        }}
                        // disabled={disabled}
                    >
                        {iconEl}
                    </Button>
                </Tooltip>
            </ListItem>
        );
    };

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography
                        color="inherit"
                        variant="subtitle1"
                        style={{ color: COLORS.accent, fontWeight: "bold" }}
                    >
                        {title} ({numSelected} selected)
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle" style={{ color: COLORS.accent, fontWeight: "bold" }}>
                        {title}
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div>
                <List
                    key={`table-btns: ${title}`}
                    style={{
                        ...defaultFont,
                        fontSize: "14px",
                        margin: 0,
                        paddingLeft: "0",
                        listStyle: "none",
                        paddingTop: "0",
                        paddingBottom: "0",
                        color: "inherit",
                        display: "flex",
                    }}
                >
                    {/* {numSelected >= 0
                        ? actions.map((action) => {
                              if (action.enabled) {
                                  return generateActionBtn(action.title, action.action, action.disabled, action.icon);
                              } 
                              else if (numSelected === 1) {
                                  return generateActionBtn(action.title, action.action, action.disabled, action.icon);
                              } else if (action.isMulti) {
                                  return generateActionBtn(action.title, action.action, action.disabled, action.icon);
                              } else if (action.isNoRowRequired) {
                                  return generateActionBtn(action.title, action.action, action.disabled, action.icon);
                              }
                          })
                        : null} */}
                    {actions.map((action) => {
                        if (action.enabled(selectedRowIds)) {
                            return generateActionBtn(
                                action.title,
                                action.action,
                                action.disabled,
                                action.icon,
                                action.iconStyle,
                                action.style
                            );
                        } else {
                            return null;
                        }
                    })}
                </List>
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleShowDeleteAlertMethod: PropTypes.func,
    handleTaskDetailsMethod: PropTypes.func,
    selectedRowIds: PropTypes.array,
    selectedRowStatus: PropTypes.array,
};

export default EnhancedTableToolbar;

import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;
export default makeStyles({
    mainMenuItem: {
        position: "relative",
        listStyle: "none",
        borderBottom: borderStyle,
        "&:last-child": {
            borderBottom: "none",
        },

        "& > button": {
            padding: "15px 8px",
            color: COLORS.WHITE,
            fontWeight: 400,
            fontSize: "0.7rem",
            textTransform: "none",
            justifyContent: "flex-start",
        },
    },

    arrowIcon: {
        color: COLORS.NAV_COLOR_3,
    },

    "@media screen and (min-width: 1000px)": {
        mainMenuItem: {
            position: "relative",
            borderBottom: "none",
            padding: "0px 15px",

            "& > button": {
                padding: "8px 6px",
                justifyContent: "center",
            },

            "&:before": {
                content: "''",
                display: "block",
                position: "absolute",
                bottom: "0",
                left: "-1px",
                height: "100%",
                width: "1px",
                backgroundColor: COLORS.NAV_COLOR_2,
            },
        },
    },

    "@media screen and (min-width: 1400px)": {
        mainMenuItem: {
            "& > button": {
                fontSize: "0.9rem",
            },
        },
    },

    /*
    "@media screen and (min-width: 1200px)": {
        mainMenuItem: {
            position: "relative",
            borderBottom: "none",
            padding: "5px 15px",

            "& > button": {
                padding: "8px 6px",
                fontSize: "1rem",

            },

            "&:before": {
                content: "''",
                display: "block",
                position: "absolute",
                bottom: "0",
                left: "-1px",
                height: "100%",
                width: "1px",
                backgroundColor: COLORS.NAV_COLOR_2,
            },
        },
    },
    */
});

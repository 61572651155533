import React from "react";
import { makeStyles, TextField } from "@material-ui/core";
import { NumericFormat } from "react-number-format";
import { useField, useFormikContext } from "formik";
import COLORS from "../../../constants/colors";
import { CircularLoader } from "../../custom";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },
});

const CurrencyField = ({ name, currencySymbol = "SR ", onChange, onBlur, showValidationIcon = false, ...props }) => {
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const rootStyle = useRootStyle();

    const handleChange = (valueObject, sourceInfo) => {
        field.onChange(sourceInfo.event);
        if (onChange) onChange(valueObject, sourceInfo, helpers);
    };
    const handleBlur = (e) => {
        field.onBlur(e);
        if (onBlur) onBlur(e, helpers);
    };

    const inputConfig = {
        endAdornment: showValidationIcon && context.isValidating ? <CircularLoader /> : null,
    };

    const config = {
        ...field,
        customInput: TextField,
        onValueChange: handleChange,
        onBlur: handleBlur,
        variant: "outlined",
        thousandSeparator: ",",
        decimalScale: 2,
        InputProps: {
            startAdornment: <span style={{ marginRight: 5 }}>{currencySymbol}</span>,
            ...inputConfig,
        },
        fullWidth: true,
        classes: {
            root: rootStyle.root,
        },
        ...props,
    };
    return <NumericFormat {...config} />;
};

export default CurrencyField;

import React, { useState, useCallback, useEffect, Fragment } from "react";

//related to meterial ui package
import styles from "./styles";
import { Header, Seperator } from "../../core-ui/custom";
import { useAuth } from "../../contexts/auth-context";
import { Form, Formik } from "formik";
import { Card, Grid, Typography } from "@material-ui/core";
import { getLinkedOutlets, updateLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { RadioGroup, SubmitButton } from "../../core-ui/forms-ui";
import { FORM_SCHEMA, INITIAL_VALUES } from "./schema";
import COLORS from "../../constants/colors";
import { getOutlet } from "../../services/firestore/Outlet";
import { DISTRIBUTION_METHODS } from "../../constants/global";
import { cloneDeep } from "lodash";
import Swal from "sweetalert2";

const SelectDistributionMethod = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [linkedOutlets, setLinkedOutlets] = useState({});
    const [initialValues, setInitialValues] = useState({
        ...INITIAL_VALUES,
    });

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;
            let linkedOutlets = (await getLinkedOutlets(company_id)).map((doc) => doc.data());
            linkedOutlets = await Promise.all(
                linkedOutlets.map(async (lo) => {
                    const outlet = (await getOutlet(lo.outlet_id)).data();
                    lo.outlet = outlet;
                    if (!lo.distribution_method) lo.distribution_method = DISTRIBUTION_METHODS.DSD;
                    return lo;
                })
            );

            //array to map
            linkedOutlets = linkedOutlets.reduce((newMap, lo) => ({ ...newMap, [lo.outlet_id]: lo }), {});

            setLinkedOutlets(linkedOutlets);
            setInitialValues({
                distribution_method: {
                    ...Object.keys(linkedOutlets).reduce(
                        (newMap, key) => ({ ...newMap, [key]: linkedOutlets[key].distribution_method }),
                        {}
                    ),
                },
            });
        } catch (error) {
            throw error;
        }
    }, []);

    useEffect(() => {
        init();
    }, [init]);

    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);
            const data = { ...values };
            const updatedLinkedOutlets = cloneDeep(linkedOutlets);
            for (const outletID in data.distribution_method) {
                if (Object.hasOwnProperty.call(data.distribution_method, outletID)) {
                    const method = data.distribution_method[outletID];
                    if ([DISTRIBUTION_METHODS.DC, DISTRIBUTION_METHODS.DSD].includes(method)) {
                        updatedLinkedOutlets[outletID].distribution_method = method;
                    }
                }
            }

            const toBeUpdated = Object.values(updatedLinkedOutlets).map((lo) => {
                return {
                    outlet_id: lo.outlet_id,
                    distribution_method: lo.distribution_method,
                };
            });

            await updateLinkedOutlets(companyData.company_id, toBeUpdated);
            await Swal.fire({
                title: `Saved Successfully!`,
                text: `Outlets' distribution method has beeen updated`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
                customClass: { container: classes.swal },
            });
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    };

    return (
        <section className={classes.pageRoot}>
            <Header className={classes.pageHeader}>Select Product Distribution Method Per Outlet</Header>
            <div className={classes.pageRoot}>
                <Formik
                    initialValues={initialValues}
                    enableReinitialize
                    onSubmit={handleSubmit}
                    validationSchema={FORM_SCHEMA}
                >
                    {(formik) => {
                        return (
                            <Form>
                                <div className={classes.cardContainer}>
                                    <Typography component="h2" className={classes.cardTitle}>
                                        Outlets
                                    </Typography>
                                    <Card className={classes.formCard}>
                                        <Grid container spacing={3}>
                                            <Grid item xs={12}>
                                                <Typography className={classes.discriptionText}>
                                                    <b>DSD:</b> Direct to Store Delivery or Direct Store Delivery
                                                    {/* <b>Direct to Store Delivery or Direct Store Delivery (DSD)</b> is a
                                                    retail distribution model, where a supplier or manufacturer delivers
                                                    products directly to retail stores for selling, instead of sending
                                                    them to warehouses or distribution centers. */}
                                                </Typography>
                                                <Typography className={classes.discriptionText}>
                                                    <b>DC:</b> Distribution Center
                                                    {/* <b>Distribution Center</b> is a warehouse or other specialized
                                                    building, which is stocked with products to be redistributed to
                                                    retailers, to wholesalers, or directly to consumers. */}
                                                </Typography>
                                            </Grid>
                                            <Seperator />
                                            {Object.keys(formik.values.distribution_method).map(
                                                (outletID, index, arr) => {
                                                    const lastIndex = index === arr.length - 1;
                                                    const outletName =
                                                        linkedOutlets[outletID]?.outlet?.En_full_name || "Unknown";
                                                    return (
                                                        <Fragment key={`outlet-${outletID}`}>
                                                            <Grid item xs={12}>
                                                                <RadioGroup
                                                                    name={`distribution_method.${outletID}`}
                                                                    label={outletName}
                                                                    options={[
                                                                        {
                                                                            value: DISTRIBUTION_METHODS.DSD,
                                                                            label: "DSD",
                                                                            color: "#b1a209",
                                                                        },
                                                                        {
                                                                            value: DISTRIBUTION_METHODS.DC,
                                                                            label: "DC",
                                                                            color: "#00af00",
                                                                        },
                                                                    ]}
                                                                />
                                                            </Grid>
                                                            {!lastIndex && <Seperator />}
                                                        </Fragment>
                                                    );
                                                }
                                            )}
                                        </Grid>
                                    </Card>
                                    <SubmitButton
                                        type="submit"
                                        className={classes.submitBtn}
                                        loadingClassName={classes.circularProgress}
                                        submitting={saving}
                                    >
                                        Save
                                    </SubmitButton>
                                </div>
                            </Form>
                        );
                    }}
                </Formik>
            </div>
        </section>
    );
};

export default SelectDistributionMethod;

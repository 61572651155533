import React, { useEffect, useState } from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { lighten, makeStyles } from "@material-ui/core/styles";
import { Button, Card, Grid, TextField, Radio, darken } from "@material-ui/core";
import Autocomplete from "@material-ui/lab/Autocomplete";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import FullPageLoader from "../../../components-lagacy/Loader/FullPageLoader";
import { getSupplierBranches } from "../../../services/firestore/Supplier_Branch";
// import { getLinkedOutletBranches } from "../../../services/firestore/Branch_Outlet_Branch";
import { getOutletBranches } from "../../../services/firestore/Outlet_Branch";
import { cloneDeep } from "lodash";
import {
    initNewScheduleDocument,
    initNewProjectOutletBranchDocument,
    initNewProjectSupplierBranchDocument,
    initNewProductDocument,
} from "../../../services/firestore/Project";
import { getRegularPrices } from "../../../services/firestore/Price";
import { getSeasonalPrices } from "../../../services/firestore/Seasonal_Price";
import { useCallback } from "react";
import { getExtraDisplayContracts } from "../../../services/firestore/Contract_Extra_Display";
import { useAuth } from "../../../contexts/auth-context";
import { queryLinkedOutletBranches } from "../../../services/firestore/Linked_Outlet_Branches";
import { getProduct } from "../../../services/firestore/Product";

const useStyles = makeStyles({
    root: {
        width: "100%",
        padding: "10px 20px",
        position: "relative",
        marginBottom: "35px",
    },
    controlsCard: {
        width: "100%",
        margin: "10px 0",
        overflow: "visible",
        "& .MuiGrid-spacing-xs-1 > .MuiGrid-item": {
            "@media (min-width: 960px)": {
                paddingTop: 0,
                paddingBottom: 0,
            },
        },
    },
    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    filterInput: {
        width: "100%",
        height: "100%",
    },

    button: {
        width: "100%",
        height: "100%",
        backgroundColor: COLORS.submit,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
            color: "#fff",
        },
    },

    selectAllButton: {
        backgroundColor: COLORS.accent,
        color: "#fff",
        "&:hover": {
            backgroundColor: darken(COLORS.accent, 0.2),
            color: "#fff",
        },
    },

    disabledBtn: {
        backgroundColor: "#c9c9c9",
        color: "#00000042",
    },

    cardItemAll: {
        border: `1px solid ${COLORS.primary}`,
        backgroundColor: COLORS.background,
    },
    cardItem: {
        display: "flex",
        alignItems: "center",
        justifyContent: "flex-start",
    },

    cardItemSelected: {
        border: "1px solid green",
        backgroundColor: lighten(COLORS.submit, 0.85),
    },
    cardButton: {
        textTransform: "none",
        padding: "10px",
        width: "100%",
        height: "100%",
    },
    cardButtonLabel: {
        justifyContent: "flex-start",
        alignItems: "center",
        textAlign: "left",
    },
    cardTitle: {
        marginLeft: "5px",
        "& h3": {
            margin: "0px",
            fontSize: "1.1rem",
            fontWeight: "500",
        },
        "& p": {
            margin: "0px",
            color: COLORS.accent,
        },
    },
});

let ProjectCoverage = (props) => {
    const { projectFullData, projectType } = props;
    const { userData } = useAuth();
    const classes = useStyles(props);
    const [supplierBranches, setSupplierBranches] = useState([]);
    const [selectedSupplierBranchID, setSelectedSupplierBranchID] = useState(null);
    const [allSelected, setAllSelected] = useState(false);
    let [outletBranches, setOutletBranches] = useState([]);
    let [loading, setLoading] = useState(false);

    let init = useCallback(async () => {
        setLoading(true);
        try {
            // get supplier branch IDs from project data
            const supplierBranchesIDs = projectFullData.data.branches.filter(
                (id) => userData.owner || userData.branch_ids?.includes(id)
            );

            // get supplier branches from firestore Branch collection
            let supplierBranchesDocs = await getSupplierBranches(supplierBranchesIDs);
            const supplierBranchesList = supplierBranchesDocs.map((sp) => ({
                info: sp,
                id: sp.id,
                name: sp.data().En_name,
            }));

            // set supplier branches
            setSupplierBranches(supplierBranchesList);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    }, [projectFullData?.data?.branches]);

    let onSupplierBranchSelect = async (event, supplierBranch) => {
        if (!supplierBranch) {
            setOutletBranches([]);
            setSelectedSupplierBranchID(null);
            return;
        }
        setLoading(true);
        try {
            //get all outlet branches that are linked to selected supplier branch
            // const linkedOutletBranches = await getLinkedOutletBranches(supplierBranch.id);
            const linkedOutletBranches = await queryLinkedOutletBranches([
                {
                    key: "supplier_branch_id",
                    operator: "==",
                    value: supplierBranch.id,
                },
            ]);

            //get their information
            const outletBranchesInfo = await getOutletBranches(
                linkedOutletBranches.map((lob) => lob.data().outlet_branch_id),
                { includeCities: true }
            );
            //make the cards
            const outletBranchesList = outletBranchesInfo.map((ob) => ({
                products: linkedOutletBranches.find((lob) => lob.data().outlet_branch_id === ob.id).data().products,
                info: ob,
                id: ob.id,
                classID: ob.data().class_id,
                name: ob.data().En_short_name,
                city: ob.cityDoc.data().En_name,
                selected: false,
                hidden: false,
            }));
            //find what outlet branches that are already selected by getting outlet branches included in the project data
            const projectSupplierBranch = projectFullData.supplierBranches.find(
                (sb) => sb.doc.id === supplierBranch.id
            );
            let isAllSelected = projectSupplierBranch || outletBranchesList.length === 0 ? true : false;
            if (projectSupplierBranch) {
                const projectOutletBranchesIDs = projectSupplierBranch.outletBranches.map((ob) => ob.doc.id);
                outletBranchesList.forEach((item) => {
                    const selected = projectOutletBranchesIDs.includes(item.id);
                    if (selected) item.selected = true;
                    else isAllSelected = false;
                });
            }

            setSelectedSupplierBranchID(supplierBranch.id);
            setOutletBranches(outletBranchesList);
            setAllSelected(isAllSelected);
        } catch (error) {
            console.log(error);
            setOutletBranches([]);
        }
        setLoading(false);
    };

    let onCardSelect = (outletBranch) => {
        let temp = cloneDeep(outletBranches);
        const index = temp.findIndex((ob) => ob.id === outletBranch.id);
        if (index === -1) return false;

        temp[index].selected = !temp[index].selected;
        checkIfAllSelected(temp);
        setOutletBranches(temp);
    };

    let onCardSelectAll = () => {
        const newValue = !allSelected;
        const temp = cloneDeep(outletBranches);
        temp.forEach((ob) => (ob.selected = newValue));

        setOutletBranches(temp);
        setAllSelected(newValue);
    };

    let checkIfAllSelected = (newOutletBranches) => {
        let isAllSelected = true;
        newOutletBranches.forEach((ob) => {
            if (!ob.selected) isAllSelected = false;
        });
        setAllSelected(isAllSelected);
    };

    let onOutletBranchFilter = (e) => {
        const value = e.target.value.toUpperCase();
        let temp = cloneDeep(outletBranches);

        temp.forEach((ob) => {
            ob.hidden = ob.name.toUpperCase().indexOf(value) === -1;
        });
        setOutletBranches(temp);
    };

    let findLinkedProductsInProject = (newOutletBranch, projectFullData) => {
        let linkedProducts = null;
        for (const supplierBranch in projectFullData.supplierBranch) {
            for (const outletBranch of supplierBranch.outletBranches) {
                let candidate = outletBranch.linkedProducts;
                if (!candidate) continue;

                let outletID = newOutletBranch.info.data().outlet_id;
                if (candidate.outletID === outletID) {
                    linkedProducts = candidate;
                    break;
                }
            }
        }

        return linkedProducts;
    };

    let onSave = async () => {
        if (!selectedSupplierBranchID) return;
        const selectedSupplierBranch = supplierBranches.find((sb) => sb.id === selectedSupplierBranchID);
        setLoading(true);

        try {
            // let newProjectFullData = cloneDeep(projectFullData);

            let newProjectFullData = projectFullData;
            let projectSupplierBranch = newProjectFullData.supplierBranches.find(
                (sb) => sb.doc.id === selectedSupplierBranchID
            );
            //check if there is a supplier branch in the project
            if (!projectSupplierBranch) {
                projectSupplierBranch = initNewProjectSupplierBranchDocument(
                    newProjectFullData.data.supplier_id,
                    newProjectFullData.doc.id,
                    selectedSupplierBranchID,
                    newProjectFullData.data.mp_id || "",
                    selectedSupplierBranch.info
                );
                projectSupplierBranch.toBeAdded = true;
                newProjectFullData.supplierBranches.push(projectSupplierBranch);
            }

            let projectOutletBranches = projectSupplierBranch.outletBranches;
            //todo loop through whole outlet branches list. you face cases as follows:
            //if supplier branch does not exist in project data in the first place,
            // => include supplier branch in project data and you will encounter cases N0. 2 or 4 always

            //1. selected item and exists in project data => skip
            //2. selected item but not exists in project data => include it into project
            //3. not selected item but exists in project data => remove it from project
            //4. not selected item and does not exist in project data => skip
            for (const outletBranch of outletBranches) {
                //search the outlet branch through project data
                let projectOB = projectOutletBranches.find((pob) => pob.data.branch_id === outletBranch.id);

                if (outletBranch.selected) {
                    //case 1
                    if (projectOB) continue;
                    //case 2
                    // get prices
                    let prices = { regularPrices: [], seasonalPrices: [] };

                    if (props.projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE) {
                        let [regularPrices, seasonalPrices] = await Promise.all([
                            getRegularPrices(PROJECT_TYPES.SUPPLIER_OUTSOURCE, outletBranch.classID),
                            getSeasonalPrices(
                                PROJECT_TYPES.SUPPLIER_OUTSOURCE,
                                outletBranch.classID,
                                newProjectFullData.data.date_from,
                                newProjectFullData.data.date_to
                            ),
                        ]);

                        seasonalPrices = seasonalPrices.map((price) => price.data());
                        prices = { regularPrices, seasonalPrices };
                    }

                    // const handover = projectType === PROJECT_TYPES.SUPPLIER_OUTSOURCE;
                    const handover = false;
                    const scheduleID = "100";

                    //init schedule products
                    let scheduleProducts = await Promise.all(
                        Object.keys(outletBranch.products).map(async (pID) => {
                            const s = initNewProductDocument(pID, (await getProduct(pID)).data());
                            s.toBeAdded = true;
                            s.data.number_of_shelves = 1;
                            return s;
                        })
                    );
                    //init schedule
                    let schedule = initNewScheduleDocument(
                        newProjectFullData.data.supplier_id,
                        newProjectFullData.data.mp_id || "",
                        newProjectFullData.doc.id,
                        selectedSupplierBranchID,
                        outletBranch.id,
                        outletBranch.classID,
                        scheduleID,
                        prices,
                        handover,
                        scheduleProducts
                    );
                    schedule.toBeAdded = true;

                    projectOB = initNewProjectOutletBranchDocument(
                        newProjectFullData.data.supplier_id,
                        newProjectFullData.data.mp_id || "",
                        newProjectFullData.doc.id,
                        selectedSupplierBranchID,
                        outletBranch.id,
                        outletBranch.classID,
                        outletBranch.info.data().outlet_id,
                        `${outletBranch.info.data().city_id}`,

                        [schedule],
                        outletBranch.info
                    );

                    //get extra display contract for outlet branch if any
                    projectOB.extraDisplayContracts = await getExtraDisplayContracts(
                        newProjectFullData.data.supplier_id,
                        outletBranch.id
                    );
                    projectOutletBranches.push(projectOB);
                    projectOB.toBeAdded = true;
                    //see if the newly added outlet branch has linked products
                    projectOB.linkedProducts = findLinkedProductsInProject(projectOB, newProjectFullData);
                } else {
                    //case 4
                    if (!projectOB) continue;
                    //case 3
                    projectOB.toBeRemoved = true;
                }
            }

            //check if outlet branches array is empty or are all marked to be removed
            if (projectOutletBranches.length === 0 || projectOutletBranches.every((ob) => ob.toBeRemoved)) {
                if (projectSupplierBranch.toBeAdded === true) {
                    newProjectFullData.supplierBranches = newProjectFullData.supplierBranches.filter(
                        (br) => br.data.branch_id !== projectSupplierBranch.data.branch_id
                    );
                } else {
                    projectSupplierBranch.toBeRemoved = true;
                }
            }

            //as for updating and syncing with the database, loop through project supplier branch and its outlet branches
            //the ones that are flagged with toBeAdded are added to database as new documents
            //the once that are flagged with toBeRemoved are going to be removed from the database
            await props.sync(newProjectFullData, `Project's Coverage has been updated successfully.`);
        } catch (error) {
            console.log(error);
        }
        setLoading(false);
    };

    //componentDidMount
    useEffect(() => {
        init();
    }, [init]);

    return (
        <div className={classes.root}>
            <FullPageLoader loading={loading} />
            <Card className={classes.controlsCard}>
                <Grid container spacing={1}>
                    {/* supplier branches list */}
                    <Grid item xs={12} sm={12} md={4} className={classes.controlsGridItem} style={{ paddingTop: 0 }}>
                        <Autocomplete
                            id="supplier-branches-list"
                            className={classes.autoCompleteList}
                            options={supplierBranches}
                            getOptionLabel={(option) => option.name}
                            renderInput={(params) => (
                                <TextField {...params} label="Choose Supplier Branches" variant="outlined" />
                            )}
                            onChange={onSupplierBranchSelect}
                        />
                    </Grid>
                    {/* filters */}
                    <Grid item xs={12} sm={12} md={4} className={classes.controlsGridItem}>
                        <TextField
                            variant="outlined"
                            placeholder="Filter Outlet Branches"
                            className={classes.filterInput}
                            onChange={onOutletBranchFilter}
                        />
                    </Grid>
                    {/* select all/none button */}
                    <Grid item xs={12} sm={12} md={2} className={classes.controlsGridItem} style={{ paddingBottom: 0 }}>
                        <Button
                            className={`${classes.button} ${classes.selectAllButton}`}
                            disabled={!selectedSupplierBranchID || outletBranches.length === 0}
                            classes={{ disabled: classes.disabledBtn }}
                            onClick={() => onCardSelectAll()}
                        >
                            {allSelected ? "Select None" : "Select All"}
                        </Button>
                    </Grid>
                    {/* save button */}
                    <Grid item xs={12} sm={12} md={2} className={classes.controlsGridItem} style={{ paddingBottom: 0 }}>
                        <Button
                            className={classes.button}
                            disabled={!selectedSupplierBranchID}
                            classes={{ disabled: classes.disabledBtn }}
                            onClick={onSave}
                        >
                            Save
                        </Button>
                    </Grid>
                </Grid>
            </Card>
            <Grid container spacing={3} className={classes.outletBranchesCards}>
                {outletBranches.map((ob) =>
                    ob.hidden ? null : (
                        <Grid item xs={12} sm={6} md={4} key={`${ob.id}-${ob.name}-card-item`}>
                            <Card className={`${classes.cardItem} ${ob.selected ? classes.cardItemSelected : null}`}>
                                <Button
                                    className={classes.cardButton}
                                    classes={{ label: classes.cardButtonLabel }}
                                    onClick={() => onCardSelect(ob)}
                                >
                                    <Radio checked={ob.selected} />
                                    <div className={classes.cardTitle}>
                                        <h3>{ob.name}</h3>
                                        <p>{ob.city}</p>
                                    </div>
                                </Button>
                            </Card>
                        </Grid>
                    )
                )}
            </Grid>
        </div>
    );
};

ProjectCoverage.propTypes = {
    classes: PropTypes.object,
};

export default ProjectCoverage;

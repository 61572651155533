import { COLLECTIONS } from "./constants";
import { query } from "./helpers";

export const queryDeliveryPrice = async (q) => {
    try {
        return await query(COLLECTIONS.DELIVERY_PRICE, q);
    } catch (error) {
        throw error;
    }
};

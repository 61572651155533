import { Tooltip } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

const TaskOptionsInitials = ({ options }) => {
    if (!options) return null;

    const isGeneralMerchandisingActivated = options?.quantity?.active ?? false;
    const isOrderActivated = options?.inventory?.active && options?.inventory?.order ? true : false;
    const isDeliveryActivated = options?.delivery?.active ?? false;

    return (
        <div style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            {isGeneralMerchandisingActivated && (
                <Tooltip title="General Merchandising" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        G.M
                    </span>
                </Tooltip>
            )}
            {isOrderActivated && (
                <Tooltip title="Re-Order" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        R-O
                    </span>
                </Tooltip>
            )}
            {isDeliveryActivated && (
                <Tooltip title="Waiting Delivery" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        W.D
                    </span>
                </Tooltip>
            )}
        </div>
    );
};

export default TaskOptionsInitials;

import { COLORS } from "../../../constants/constants-lagacy";
import { StyleSheet } from "@react-pdf/renderer";

// Create styles
const styles = StyleSheet.create({
    page: {
        padding: "20px",
    },

    logoContainer: {
        display: "flex",
        width: "100%",
        height: "50px",
        flexDirection: "row",
        alignItems: "center",
        justifyContent: "space-between",
    },

    logoFooterContainer: {
        position: "absolute",
        bottom: 27,
        right: 30,
        fontSize: 8,
        color: "grey",

        height: "30px",
        justifyContent: "center",
    },

    companyLogo: {
        width: "auto",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },

    supplierHeaderText: {
        display: "flex",
        flexDirection: "column",
        alignItems: "flex-end",
        justifyContent: "center",
    },

    alignStart: {
        alignItems: "flex-start",
    },

    supplierText: {
        fontSize: 8,
        color: COLORS.accent,
    },

    supplierName: {
        fontSize: 12,
        fontWeight: "bold",
    },

    logoFooterText: {
        fontSize: 12,
        justifyContent: "center",
        textAlign: "center",
    },

    logo: {
        width: "100%",
        height: "100%",
        display: "block",
        objectFit: "contain",
    },
    eMaham: {
        flex: "1",
        textAlign: "right",
    },

    red: {
        color: COLORS.accent,
    },

    line: {
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },

    header: {
        textAlign: "center",
        fontSize: 16,
        color: COLORS.accent,
        textDecoration: "underline",
        margin: "20px 0",
    },

    headerContainer: {
        width: "100%",
        fontSize: 8,
        display: "flex",
        flexDirection: "row",
        alignItems: "center",
    },

    left: {
        flex: 1,
        textAlign: "left",
    },
    right: {
        flex: 1,
        textAlign: "right",
    },

    bold: {
        // fontFamily: "Roboto",
        fontWeight: "bold",
    },

    table: {
        width: "100%",
        margin: "5px 0 0 0",
        border: 1,
        // fontFamily: "Roboto",
    },

    row: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        fontWeight: "bold",
    },

    headerRow: {
        backgroundColor: COLORS.primary,
        fontSize: 12,
        color: "white",
    },

    bodyRow: {},

    coloredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        borderBottom: 1,
        fontWeight: "bold",
        backgroundColor: "#eef5ff",
    },

    lastRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        // borderBottom: 1,
        fontWeight: "bold",
        borderBottom: 0,
    },

    lastColoredRow: {
        display: "flex",
        flexDirection: "row",
        fontSize: 8,
        backgroundColor: "#eef5ff",
        fontWeight: "bold",
    },

    headerCell: {
        flex: 3,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    headerCellNo: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        padding: "1px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    verticalText: {
        position: "absolute",
        top: "0",
        transform: "rotate(90deg)",
        fontSize: 10,
    },

    headerCellWide: {
        flex: 3,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    lastHeaderCell: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        padding: "5px 0px",

        display: "flex",
        justifyContent: "center",
    },

    cell: {
        flex: 3,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    cellNo: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    verticalCell: {
        padding: "30px 0",
        flex: "1 1 auto",
        width: "15px",
        textAlign: "left",
    },

    outletCell: {
        flex: "1 1 auto",
        width: "15px",
    },

    outletCellColored: {
        flex: "1 1 auto",
        width: "15px",
        backgroundColor: COLORS.accent,
    },

    totalCell: {
        flex: 1,
    },

    cellWide: {
        flex: 4.056,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    lastCell: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        padding: "5px 0",

        display: "flex",
        justifyContent: "center",
    },

    leftAlignCell: {
        flex: 1,
        height: "100%",
        textAlign: "left",
        padding: "5px 0",
        borderRight: 1,

        display: "flex",
        justifyContent: "center",
    },

    rowGroup: {
        flex: 2,
        height: "100%",
        textAlign: "center",
        borderRight: 1,

        display: "flex",
        flexDirection: "column",
    },

    lastRowGroup: {
        flex: 1,
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexDirection: "column",
    },

    columnGroup: {
        flex: 1,
        height: "100%",
        textAlign: "center",

        display: "flex",
        flexDirection: "row",
    },

    rowGroupRow: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
        borderBottom: 1,
    },

    lastRowGroupRow: {
        flex: 1,
        display: "flex",
        flexDirection: "row",
    },

    cellText: {
        margin: "0 5px",
    },

    pageNumber: {
        position: "absolute",
        fontSize: 12,
        bottom: 30,
        left: 0,
        right: 0,
        textAlign: "center",
        color: "grey",
    },
    authorInfo: {
        position: "absolute",
        fontSize: 12,
        bottom: 15,
        left: 15,
        right: 0,
        textAlign: "left",
        color: "grey",
    },
    footerLine: {
        position: "absolute",
        bottom: 75,
        left: 20,
        width: "100%",
        height: "2px",
        marginTop: "5px",
        backgroundColor: "grey",
    },
    footer: {
        position: "absolute",
        bottom: 20,
        left: 20,
        fontSize: 8,
        display: "flex",
        color: "grey",
    },
});
export default styles;

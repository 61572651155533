import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import styles from "./styles";
import {
    CardMedia,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    Grid,
    Typography,
} from "@material-ui/core";
import noImage from "../../assets/images/no-image-available-1.png";
import { formatDate, formatTime } from "../../helpers/date-utils";
import { Button } from "../../core-ui/custom";
import { Link } from "react-router-dom";
import PATHS from "../../constants/paths";
import { TASK_TYPES } from "../../constants/global";

const GalleryItemDialog = ({ open, onClose, info }) => {
    const classes = styles();
    let route = {
        pathname: PATHS.INSOURCE_VISIT_DETAILS,
        state: {
            taskID: `${info.task.task_id}`,
            type: "insource",
        },
    };
    if (info.task.type === TASK_TYPES.HANDOVER || info.task.type === TASK_TYPES.REGULAR) {
        route = {
            pathname: PATHS.OUTSOURCE_VISIT_DETAILS,
            state: {
                taskID: `${info.task.task_id}`,
                type: "outsource",
            },
        };
    }
    return (
        <Dialog maxWidth="md" open={open} onClose={onClose} className={classes.galleryItemDialog}>
            <DialogTitle onClose={onClose}>
                <Typography className={classes.productName} variant="h5">
                    {info.product.En_name}
                </Typography>
                <Typography className={classes.displayTypeAndLocation} variant="h5">
                    {info.extra_display ? info.extra_display_info?.name || "Ex. Display" : "Shelf"}
                    <span className={classes.supermarket}> - {info.outlet_branch.En_short_name}</span>
                </Typography>
                <Typography className={classes.task} variant="h5">
                    Visit #<b>{info.task_id}</b> | {formatDate(info.task.date_time_from.toDate())}{" "}
                    {formatTime(info.task.date_time_from.toDate())}
                </Typography>
            </DialogTitle>

            <DialogContent dividers>
                <Grid container spacing={1}>
                    {/* Before Section */}
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.imageContainer}>
                                <a href={info.picture_before || noImage} target={"_blank"}>
                                    <CardMedia
                                        component="img"
                                        alt="Picture Before"
                                        title="Picture Before"
                                        image={info.picture_before || noImage}
                                    />
                                </a>
                            </Grid>
                            <Divider />
                            <Grid item xs={12}>
                                <Typography className={classes.imageHeader} gutterBottom variant="h5" component="h4">
                                    Before |{" "}
                                    {info.picture_before_timestamp
                                        ? formatTime(info.picture_before_timestamp.toDate())
                                        : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>

                    {/* After Section */}
                    <Grid item xs={6}>
                        <Grid container spacing={1}>
                            <Grid item xs={12} className={classes.imageContainer}>
                                <a href={info.picture_after || noImage} target={"_blank"}>
                                    <CardMedia
                                        component="img"
                                        alt="Picture After"
                                        title="Picture After"
                                        image={info.picture_after || noImage}
                                    />
                                </a>
                            </Grid>
                            <Divider />
                            <Grid item xs={12}>
                                <Typography className={classes.imageHeader} gutterBottom variant="h5" component="h4">
                                    After |{" "}
                                    {info.picture_after_timestamp
                                        ? formatTime(info.picture_after_timestamp.toDate())
                                        : ""}
                                </Typography>
                            </Grid>
                        </Grid>
                    </Grid>
                </Grid>
            </DialogContent>
            <DialogActions className={classes.dialogActions}>
                <Link to={route}>
                    <Button className={classes.visitDetailsBtn}>Visit Details</Button>
                </Link>
            </DialogActions>
        </Dialog>
    );
};

GalleryItemDialog.propTypes = {
    classes: PropTypes.object,
};

export default GalleryItemDialog;

import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";
// import { Font } from "./fonts.js";

// let rowsPerPage = 10;
// let totalPages = 0;

let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

let createPage = (info) => {
    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;
    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`PDF ${Math.random()}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Project Proposal</Text>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Project Date: <Text style={styles.bold}>{formatDate(info.creationDate)}</Text>
                        &nbsp;&nbsp;|&nbsp;&nbsp;Project No.: <Text style={styles.bold}>{info.projectId}</Text>
                    </Text>
                    <Text>&nbsp;</Text>
                    <Text>
                        To: <Text style={styles.bold}>{`${info.supplierName} | ${info.supplierCompany}`}</Text>
                    </Text>
                    <Text>&nbsp;</Text>
                    <Text>
                        From: <Text style={styles.bold}>Murattab Business Development Manager</Text>
                    </Text>
                    <Text>
                        &nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;
                        <Text style={styles.bold}>Derwaza Information Technology EST.</Text>
                    </Text>
                    <Text>&nbsp;</Text>
                    <Text>
                        Subject: <Text style={styles.bold}>Merchandising Outsourcing Project Proposal</Text>
                    </Text>
                </View>

                {/* <View style={styles.right}>
                    <Text>Phone Number: {phone}</Text>
                    <Text>Issued Date: {formatDate(new Date())}</Text>
                    <Text>Issued By: {exportedBy}</Text>
                </View> */}
            </View>
            <View style={styles.line}></View>

            <View style={styles.content}>
                <Text>&nbsp;</Text>
                <Text>Dear Sir,</Text>
                <Text style={styles.contentIntro}>
                    &nbsp;&nbsp;&nbsp;&nbsp;We would like to thank you for giving us the opportunity to serve your
                    company with your support and help from your staff to reach the goals.
                </Text>

                {/* Cities */}
                <View style={styles.list}>
                    <View style={styles.listTitle}>
                        <View style={styles.listDot}></View>
                        <View>
                            <Text>Territory of the project</Text>
                        </View>
                    </View>
                    <View style={styles.listContent}>
                        <Text style={styles.listItem}>- {info.coverageCities.join(", ")}</Text>
                    </View>
                </View>

                {/* Coverage */}
                <View style={styles.list}>
                    <View style={styles.listTitle}>
                        <View style={styles.listDot}></View>
                        <View>
                            <Text>Coverage</Text>
                        </View>
                    </View>
                    <View style={styles.listContent}>
                        <Text style={styles.listItem}>
                            - Murattab will visit the selected outlets in the city of the above areas as shown in the{" "}
                            <Text style={styles.bold}>Appendix A</Text> Attached.
                        </Text>
                    </View>
                </View>

                {/* Period */}
                <View style={styles.list}>
                    <View style={styles.listTitle}>
                        <View style={styles.listDot}></View>
                        <View>
                            <Text>Period</Text>
                        </View>
                    </View>
                    <View style={styles.listContent}>
                        <Text style={styles.listItem}>
                            - The Project will begin on <Text style={styles.bold}>{info.startDate.toDateString()}</Text>{" "}
                            until <Text style={styles.bold}>{info.endDate.toDateString()}</Text>.
                        </Text>
                    </View>
                </View>
            </View>

            {/* Products */}
            <View style={styles.list}>
                <View style={styles.listTitle}>
                    <View style={styles.listDot}></View>
                    <View>
                        <Text>Products</Text>
                    </View>
                </View>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>
                        - SKUs registered in the outlets as shown in <Text style={styles.bold}>Appendix B</Text>{" "}
                        Attached.
                    </Text>
                    <Text style={styles.listItem}>
                        - Merchandising will be done only for SKUs that are assigned and available at the outlets
                        branches.
                    </Text>
                </View>
            </View>

            {/* Reporting */}
            <View style={styles.list}>
                <View style={styles.listTitle}>
                    <View style={styles.listDot}></View>
                    <View>
                        <Text>Reporting</Text>
                    </View>
                </View>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>
                        - Murattab will provide you with a daily online report on the status of products at each visited
                        store.
                    </Text>
                </View>
            </View>

            {/* Services */}
            <View style={styles.list}>
                <View style={styles.listTitle}>
                    <View style={styles.listDot}></View>
                    <View>
                        <Text>Services</Text>
                    </View>
                </View>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>- Re-merchandise re-face all existing product displays.</Text>
                    <Text style={styles.listItem}>
                        - Remove expired and damaged out of display to the return products area when available.
                    </Text>
                    <Text style={styles.listItem}>
                        - Remove all near expiry Products as per your request in the data entry from display to the
                        return area when requested.
                    </Text>
                </View>
            </View>

            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>C.R: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />
            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createSecondPage = (info) => {
    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`PDF ${Math.random()}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>
            <Text>&nbsp;</Text>

            {/* services */}
            <View style={styles.list}>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>
                        - Apply FIFO (First In, First Out) to ensure older stock is sold before newer stock.
                    </Text>
                    <Text style={styles.listItem}>
                        - Ensure shelf or tag prices are correctly displayed on products in the selling area or other
                        available displays.
                    </Text>
                    <Text style={styles.listItem}>
                        - Follow the planogram, if available, to ensure displays are set up according to the prescribed
                        layout.
                    </Text>
                </View>
            </View>

            {/* Timing */}
            <View style={styles.list}>
                <View style={styles.listTitle}>
                    <View style={styles.listDot}></View>
                    <View>
                        <Text>Timing</Text>
                    </View>
                </View>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>
                        - Time and duration of Visits to the outlets is shown in the{" "}
                        <Text style={styles.bold}>Appendix A</Text> attached.
                    </Text>
                </View>
            </View>

            {/* Payment */}
            <View style={styles.list}>
                <View style={styles.listTitle}>
                    <View style={styles.listDot}></View>
                    <View>
                        <Text>Payment</Text>
                    </View>
                </View>
                <View style={styles.listContent}>
                    <Text style={styles.listItem}>
                        - Fees set at <Text style={styles.bold}>{info.projectPrice.toFixed(2)}</Text> + VAT{" "}
                        <Text style={styles.bold}>{info.vatPrice.toFixed(2)}</Text> = Total
                        <Text style={styles.bold}> {(info.vatPrice + info.projectPrice).toFixed(2)} SR</Text>
                    </Text>
                    <Text style={styles.listItem}>- Payment in advance.</Text>
                    <Text style={styles.listItem}>- Payment Details:</Text>
                    {/* Payment Details*/}
                    <View style={styles.listContent}>
                        <Text style={styles.listItem}>
                            - Account Name <Text style={styles.bold}>Derwaza Information Technology EST.</Text>
                        </Text>
                        <Text style={styles.listItem}>
                            - Bank Name <Text style={styles.bold}>Saudi British Bank</Text>
                        </Text>
                        <Text style={styles.listItem}>
                            - Account Number <Text style={styles.bold}>035367556001</Text>
                        </Text>
                        <Text style={styles.listItem}>
                            - IBAN Number <Text style={styles.bold}>SA7145000000035367556001</Text>
                        </Text>
                        <Text style={styles.listItem}>
                            - VAT Number <Text style={styles.bold}>300813137300003</Text>
                        </Text>
                    </View>
                </View>
            </View>

            <Text>&nbsp;</Text>
            <View style={styles.contentFooter}>
                <Text>We are looking forward to serve you.</Text>
                <Text style={styles.contentIntro}>Thank you & best regards</Text>
            </View>

            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>C.R: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    // const totalPages = 2;
    let pages = [];

    pages[0] = createPage(info);
    pages[1] = createSecondPage(info);

    return pages;
};

// Create Document Component
const ProjectContractPdf = (props) => {
    let document = createDoc(props.info);

    return <Document>{document}</Document>;
};

export default ProjectContractPdf;

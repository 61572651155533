import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import logo from "../../../assets/images/logo.png";
import styles from "./styles";
// import { Font } from "./fonts.js";

let rowsPerPage = 26;
// let totalPages = 0;

let createRow = (project, style) => {
    if (!project)
        return (
            <View style={style} key={`PDF Row ${Math.random()}-${project.id}`}>
                <Text style={styles.lastCell}>Error getting the project's data</Text>
            </View>
        );

    return (
        <View style={style} key={`PDF Row ${Math.random()}-${project.id}`}>
            <Text style={styles.cellNo}>{project.id}</Text>
            <Text style={styles.cell}>{project.project_name}</Text>
            <Text style={styles.cellNo}>{project.project_date}</Text>

            <Text style={styles.cell}>{project.project_period}</Text>
            <Text style={styles.cellNo}>{project.project_total}</Text>
            <Text style={styles.lastCell}>{project.supplier_confirmation}</Text>
        </View>
    );
};

let createPage = (projects, info, pageNum) => {
    //const rows = [...outlet.products];
    let rowsToDisplay = projects.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`Projects List ${Math.random()}-${pageNum}`}>
            <View style={styles.logoContainer}>
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <Text style={styles.header}>Projects List</Text>

            <View style={styles.content}>
                <View style={styles.table}>
                    {/* Table Header */}
                    <View style={styles.tableHeader}>
                        <View style={[styles.row, styles.headerRow]}>
                            {/* <View style={styles.rowGroupRow}>lastRowGroupRow */}
                            {/* <View style={styles.columnGroup}> */}
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>No</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Project Name</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Date</Text>
                            </View>
                            <View style={styles.headerCell}>
                                <Text style={styles.headerCellText}>Period</Text>
                            </View>
                            <View style={styles.headerCellNo}>
                                <Text style={styles.headerCellText}>Total</Text>
                            </View>
                            <View style={styles.lastHeaderCell}>
                                <Text style={styles.headerCellText}>Confirmation</Text>
                            </View>
                            {/* </View> */}
                            {/* </View> */}
                        </View>
                    </View>

                    {/* Table Body */}

                    <View style={styles.tableBody}>
                        {rowsToDisplay.map((project, index) => {
                            let style = styles.row;
                            if (index % 2 !== 0 && index === rowsToDisplay.length - 1) style = styles.lastRow;
                            else if (index % 2 === 0 && index !== rowsToDisplay.length - 1) style = styles.coloredRow;
                            else if (index % 2 === 0 && index === rowsToDisplay.length - 1)
                                style = styles.lastColoredRow;
                            console.log("asdf " + project.day);
                            return createRow(project, style);
                        })}
                    </View>
                </View>
            </View>
            {/* Footer */}
            <View style={styles.footerLine}></View>
            <View style={styles.footer}>
                <Text>Derwaza Information Technology EST.</Text>
                <Text>R.C: 4030307922</Text>
                <Text>VAT Number: 300813137300003</Text>
                <Text>Email: Sales@derwaza.tech</Text>
                <Text>Phone: +966 55 566 2183</Text>
                <Text>www.Murattab.com</Text>
                {/* <Text>Issue Date: {formatDate(new Date())}</Text> */}
                {/* <Text>Issue Time: {formatAMPM(new Date())}</Text> */}
                {/* <Text>Issued By: {exportedBy}</Text> */}
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createDoc = (info) => {
    const { projects } = info;

    let pages = [];
    let projectsPerPage = Math.ceil(projects.length / rowsPerPage);

    for (let pageNum = 0; pageNum < projectsPerPage; pageNum++) {
        pages[pageNum] = createPage(projects, info, pageNum);
    }

    return pages;
};

// Create Document Component
const ProjectsPdf = (props) => {
    let document = createDoc(props.info);
    return <Document>{document}</Document>;
};

export default ProjectsPdf;

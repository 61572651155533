import { makeStyles } from "@material-ui/styles";
import COLORS from "../../../constants/colors";

const styles = makeStyles({
    text: {
        fontSize: "0.7rem",
        color: COLORS.WHITE,
        letterSpacing: "1px",
        margin: 0,
        whiteSpace: "nowrap",
        textAlign: "center",
    },

    textDark: {
        color: COLORS.BLACK,
    },

    logo: {
        width: 105,
        height: 60,
        paddingBottom: 8,

        // margin: "20px auto",
        "& img": {
            width: "100%",
            height: "100%",
            display: "block",
            objectFit: "contain",
        },
    },
    // "@media screen and (min-width: 900px)": {
    //     logo: {
    //         margin: "20px 10px",
    //     },
    // },
});

export default styles;

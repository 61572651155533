import React from "react";
import PropTypes from "prop-types";
import clsx from "clsx";
import { lighten, makeStyles } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TablePagination from "@material-ui/core/TablePagination";
import TableRow from "@material-ui/core/TableRow";
import TableSortLabel from "@material-ui/core/TableSortLabel";
import Toolbar from "@material-ui/core/Toolbar";
import Typography from "@material-ui/core/Typography";
import Paper from "@material-ui/core/Paper";
import Checkbox from "@material-ui/core/Checkbox";
import Tooltip from "@material-ui/core/Tooltip";
import DeleteIcon from "@material-ui/icons/Delete";
import AddIcon from "@material-ui/icons/Add";
import EditIcon from "@material-ui/icons/Edit";
//import FilterListIcon from '@material-ui/icons/FilterList';
import List from "@material-ui/core/List";
import ListItem from "@material-ui/core/ListItem";
import { defaultFont } from "../core-ui/material-kit-ui/general-styles";

import componentsStyle from "../core-ui/core-styles/jss/general-component-style";
import Swal from "sweetalert2";
// nodejs library that concatenates classes
import classNames from "classnames";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";
import GridContainer from "../components-lagacy/Grid/GridContainer.jsx";
import GridItem from "../components-lagacy/Grid/GridItem.jsx";
import Box from "@material-ui/core/Box";
import Button from "../components-lagacy/CustomButtons/Button.jsx";
import { Redirect } from "react-router-dom";
import Grid from "@material-ui/core/Grid";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from "@material-ui/core";
import { COLORS } from "../constants/constants-lagacy";
import { firestore as db } from "../services/firebase";
import PATHS from "../constants/paths";

import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import AuthContext, { useAuth } from "../contexts/auth-context";
import { generatePDF } from "../helpers/pdf-utils";
import BranchesPdf from "../components-lagacy/PDF/branches";

//print Component
// class ComponentToPrint extends React.Component {
//     render() {
//         let data = this.props.branches;
//         return (
//             <div>
//                 <h3 style={{ paddingLeft: "15px", paddingTop: "15px", color: COLORS.accent, fontWeight: "700" }}>
//                     {" "}
//                     Branches List
//                 </h3>

//                 <Divider
//                     style={{
//                         marginLeft: 0,
//                         marginRight: 0,
//                         marginTop: "3px",
//                         marginBottom: "3px",
//                         padding: 0,
//                         width: "100%",
//                     }}
//                     variant="inset"
//                 />

//                 <table style={{ tableLayout: "outo", whiteSpace: "nowrap", paddingLeft: "15px", paddingRight: "15px" }}>
//                     <thead>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>English Name</th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}> </th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>Arabic Name</th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}> </th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>City</th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}> </th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>Status</th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}> </th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>Phone No.</th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}> </th>
//                         <th style={{ textAlign: "left", marginRight: "10px" }}>Contact Person</th>
//                     </thead>

//                     <tbody>
//                         {data.map((row) => (
//                             <tr style={{ marginRight: "30px" }}>
//                                 <td style={{ marginRight: "30px" }}>{row.En_name}</td>
//                                 <td style={{ marginRight: "10px" }}> </td>
//                                 <td style={{ marginRight: "10px" }}>{row.Ar_name}</td>
//                                 <td style={{ marginRight: "10px" }}> </td>
//                                 <td style={{ marginRight: "10px" }}>{row.city_name}</td>
//                                 <td style={{ marginRight: "10px" }}> </td>
//                                 <td style={{ marginRight: "10px" }}>
//                                     {row.status === "1" ? "Active" : row.status === "0" ? "Not Active" : "no"}
//                                 </td>
//                                 <td style={{ marginRight: "10px" }}> </td>
//                                 <td style={{ marginRight: "10px" }}>{row.contact_information.phone}</td>
//                                 <td style={{ marginRight: "10px" }}> </td>
//                                 <td style={{ marginRight: "10px" }}>
//                                     {row.contact_person.first_name} {row.contact_person.surname}
//                                 </td>
//                             </tr>
//                         ))}
//                     </tbody>
//                 </table>

//                 <Divider
//                     style={{
//                         marginLeft: 0,
//                         marginRight: 0,
//                         marginTop: "3px",
//                         marginBottom: "3px",
//                         padding: 0,
//                         width: "100%",
//                     }}
//                     variant="inset"
//                 />
//             </div>
//         );
//     }
// }

//BranchesList Componen

const styles = (theme) => ({
    button: {
        "&:hover": {
            backgroundColor: "transparent",
        },
    },
});

function desc(a, b, orderBy) {
    if (b[orderBy] < a[orderBy]) {
        return -1;
    }
    if (b[orderBy] > a[orderBy]) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

const headRows = [
    { id: "En_name", numeric: false, disablePadding: false, label: "Branch En. Name" },
    { id: "Ar_name", numeric: true, disablePadding: false, label: "Branch Ar. Name" },
    { id: "city_name", numeric: false, disablePadding: false, label: "City" },
    { id: "status", numeric: false, disablePadding: false, label: "Status" },
    { id: "contact_information_phone", numeric: false, disablePadding: false, label: "Tel." },
    { id: "contact_person_name", numeric: false, disablePadding: false, label: "Contact Person" },
];

function EnhancedTableHead(props) {
    const { classes, onSelectAllClick, order, orderBy, numSelected, rowCount, onRequestSort } = props;
    const createSortHandler = (property) => (event) => {
        onRequestSort(event, property);
    };

    return (
        <TableHead>
            <TableRow style={{ backgroundColor: COLORS.background, padding: "0px", margin: "0px" }}>
                <TableCell padding="checkbox">
                    <Checkbox
                        style={{
                            color: COLORS.primary,
                        }}
                        indeterminate={numSelected > 0 && numSelected < rowCount}
                        checked={numSelected === rowCount}
                        onChange={onSelectAllClick}
                        inputProps={{ "aria-label": "select all desserts" }}
                    />
                </TableCell>
                {headRows.map((row) => (
                    <TableCell
                        key={row.id}
                        align={row.numeric ? "right" : "left"}
                        padding={row.disablePadding ? "none" : "normal"}
                        sortDirection={orderBy === row.id ? order : false}
                    >
                        <TableSortLabel
                            style={{
                                color: COLORS.primary,
                                paddingBottom: "5px",
                                paddingTop: "5px",
                                fontWeight: "500",
                                fontSize: "14px",
                            }}
                            active={orderBy === row.id}
                            direction={order}
                            onClick={createSortHandler(row.id)}
                        >
                            {row.label}
                            {orderBy === row.id ? (
                                <span className={classes.visuallyHidden}>
                                    {order === "desc" ? "sorted descending" : "sorted ascending"}
                                </span>
                            ) : null}
                        </TableSortLabel>
                    </TableCell>
                ))}
            </TableRow>
        </TableHead>
    );
}

EnhancedTableHead.propTypes = {
    classes: PropTypes.object.isRequired,
    numSelected: PropTypes.number.isRequired,
    onRequestSort: PropTypes.func.isRequired,
    onSelectAllClick: PropTypes.func.isRequired,
    order: PropTypes.oneOf(["asc", "desc"]).isRequired,
    orderBy: PropTypes.string.isRequired,
    rowCount: PropTypes.number.isRequired,
};

const useToolbarStyles = makeStyles((theme) => ({
    root: {
        paddingLeft: theme.spacing(2),
        paddingRight: theme.spacing(1),
    },
    highlight:
        theme.palette.type === "light"
            ? {
                  color: "#d50000",
                  backgroundColor: lighten("#d50000", 0.85),
              }
            : {
                  color: theme.palette.text.primary,
                  backgroundColor: "#d50000",
              },
    spacer: {
        flex: "1 1 50%",
    },
    actions: {
        color: "#d50000",
    },
    title: {
        flex: "0 0 auto",
    },
}));

const EnhancedTableToolbar = (props) => {
    const { userData } = useAuth();
    const classes = useToolbarStyles();
    const {
        // branches,
        selectedRowName,
        numSelected,
        handleShowDeleteAlertMethod,
        handleAddNewSupplierBranchMethod,
        handleBranchesPdfMethod,
        handleUpdateBranchInfoMethod,
        loading,
    } = props;
    const id = selectedRowName[0];
    // const componentRef = useRef();

    return (
        <Toolbar
            className={clsx(classes.root, {
                [classes.highlight]: numSelected > 0,
            })}
        >
            <div className={classes.title}>
                {numSelected > 0 ? (
                    <Typography color="inherit" variant="subtitle1">
                        Branches List ({numSelected} selected)
                    </Typography>
                ) : (
                    <Typography variant="h6" id="tableTitle">
                        Branches List
                    </Typography>
                )}
            </div>
            <div className={classes.spacer} />
            <div>
                {numSelected > 0 ? (
                    numSelected === 1 ? (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    marginRight: "22px",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.button}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Edit"
                                    placement={"top"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        onClick={() => {
                                            handleUpdateBranchInfoMethod(id);
                                        }}
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        style={{ padding: 0 }}
                                    >
                                        <EditIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    ) : (
                        <List
                            style={{
                                ...defaultFont,
                                fontSize: "14px",
                                margin: 0,
                                paddingLeft: "0",
                                listStyle: "none",
                                paddingTop: "0",
                                paddingBottom: "0",
                                color: "inherit",
                            }}
                        >
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "inherit",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Delete"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        onClick={() => {
                                            handleShowDeleteAlertMethod(selectedRowName, numSelected);
                                        }}
                                        style={{ padding: 0 }}
                                    >
                                        <DeleteIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        </List>
                    )
                ) : (
                    <List
                        style={{
                            ...defaultFont,
                            fontSize: "14px",
                            margin: 0,
                            paddingLeft: "0",
                            listStyle: "none",
                            paddingTop: "0",
                            paddingBottom: "0",
                            color: "#91041C",
                        }}
                    >
                        {userData.owner && (
                            <ListItem
                                style={{
                                    float: "left",
                                    color: "#91041C",
                                    position: "relative",
                                    display: "block",
                                    width: "auto",
                                    margin: "0",
                                    padding: "0",
                                }}
                            >
                                <Tooltip
                                    id="instagram-twitter"
                                    title="Add New Branch"
                                    placement={window.innerWidth > 959 ? "top" : "left"}
                                    classes={{ tooltip: classes.tooltip }}
                                    style={{ float: "right" }}
                                >
                                    <Button
                                        onClick={() => {
                                            handleAddNewSupplierBranchMethod();
                                        }}
                                        simple
                                        //color="google"
                                        block
                                        className={classes.textCenter}
                                        style={{ padding: 0 }}
                                    >
                                        <AddIcon
                                            style={{
                                                height: 40,
                                                width: 40,
                                                borderRadius: "40px",
                                                padding: "7px",
                                                backgroundColor: COLORS.accent,
                                                color: COLORS.font,
                                            }}
                                        />
                                    </Button>
                                </Tooltip>
                            </ListItem>
                        )}
                        <ListItem
                            style={{
                                float: "left",
                                color: "#91041C",
                                position: "relative",
                                display: "block",
                                width: "auto",
                                margin: "0",
                                padding: "0",
                                display: loading ? "none" : "block",
                            }}
                        >
                            <Tooltip
                                id="instagram-twitter"
                                title="Add New Branch"
                                placement={window.innerWidth > 959 ? "top" : "left"}
                                classes={{ tooltip: classes.tooltip }}
                                style={{ float: "right" }}
                            >
                                <Button
                                    onClick={() => {
                                        handleBranchesPdfMethod();
                                    }}
                                    simple
                                    //color="google"
                                    block
                                    className={classes.textCenter}
                                    style={{ padding: 0 }}
                                >
                                    <PictureAsPdfIcon
                                        style={{
                                            height: 40,
                                            width: 40,
                                            borderRadius: "40px",
                                            padding: "7px",
                                            backgroundColor: COLORS.accent,
                                            color: COLORS.font,
                                        }}
                                    />
                                </Button>
                            </Tooltip>
                        </ListItem>
                    </List>
                )}
            </div>
        </Toolbar>
    );
};

EnhancedTableToolbar.propTypes = {
    numSelected: PropTypes.number.isRequired,
    handleShowDeleteAlertMethod: PropTypes.func,
    selectedRowName: PropTypes.array,
    handleUpdateBranchInfoMethod: PropTypes.func,
    handleAddNewSupplierBranchMethod: PropTypes.func,
    handleBranchesPdfMethod: PropTypes.func,
    branches: PropTypes.array,
};

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 750,
    },
    tableWrapper: {
        overflowX: "auto",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function EnhancedTable(props) {
    const {
        branches,
        handleShowDeleteAlertMethod,
        handleUpdateBranchInfoMethod,
        handleAddNewSupplierBranchMethod,
        handleBranchesPdfMethod,
        loading,
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("calories");
    const [selected, setSelected] = React.useState(branches);
    // const [selectedRow, setSelectedRow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(true);
    const dense = true;
    const [rowsPerPage, setRowsPerPage] = React.useState(5);

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = branches.map((n) => n.branch_id);
            setSelected(newSelecteds);
            return;
        }
        setSelected([]);
    }

    function handleClick(event, name) {
        const selectedIndex = selected.indexOf(name);
        let newSelected = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, name);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }

        setSelected(newSelected);
        // setSelectedRow(name);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    // function handleChangeDense(event) {
    //     setDense(event.target.checked);
    // }

    const isSelected = (name) => selected.indexOf(name) !== -1;

    const emptyRows = rowsPerPage - Math.min(rowsPerPage, branches.length - page * rowsPerPage);

    return (
        <div style={{ paddingTop: 5, marginTop: 0 }} className={classes.root}>
            <Paper className={classes.paper}>
                <EnhancedTableToolbar
                    branches={branches}
                    selectedRowName={selected}
                    numSelected={selected.length}
                    handleShowDeleteAlertMethod={handleShowDeleteAlertMethod}
                    handleUpdateBranchInfoMethod={handleUpdateBranchInfoMethod}
                    handleAddNewSupplierBranchMethod={handleAddNewSupplierBranchMethod}
                    handleBranchesPdfMethod={handleBranchesPdfMethod}
                    loading={loading}
                />
                <div className={classes.tableWrapper}>
                    <Table className={classes.table} aria-labelledby="tableTitle" size={dense ? "small" : "medium"}>
                        <EnhancedTableHead
                            classes={classes}
                            numSelected={selected.length}
                            order={order}
                            orderBy={orderBy}
                            onSelectAllClick={handleSelectAllClick}
                            onRequestSort={handleRequestSort}
                            rowCount={branches.length}
                        />
                        <TableBody>
                            {stableSort(branches, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    const isItemSelected = isSelected(row.branch_id);
                                    const labelId = `enhanced-table-checkbox-${index}`;

                                    return (
                                        <TableRow
                                            hover
                                            onClick={(event) => handleClick(event, row.branch_id)}
                                            role="checkbox"
                                            aria-checked={isItemSelected}
                                            tabIndex={-1}
                                            key={row.branch_id}
                                            selected={isItemSelected}
                                        >
                                            <TableCell padding="checkbox">
                                                <Checkbox
                                                    style={{
                                                        color: COLORS.primary,
                                                        //boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
                                                    }}
                                                    //labelStyle={{color: "#d50000"}}
                                                    //iconStyle={{fill: "#d50000"}}
                                                    checked={isItemSelected}
                                                    inputProps={{ "aria-labelledby": labelId }}
                                                />
                                            </TableCell>
                                            <TableCell>{row.En_name}</TableCell>

                                            <TableCell align="right">{row.Ar_name}</TableCell>

                                            <TableCell>{row.city_name}</TableCell>
                                            <TableCell
                                                style={
                                                    row.status === "1"
                                                        ? { backgroundColor: "#4BB543", color: "#FFFFFF" }
                                                        : row.status === "0"
                                                        ? { backgroundColor: "#575757", color: "#FFFFFF" }
                                                        : { backgroundColor: "#91041C", color: "#FFFFFF" }
                                                }
                                            >
                                                {row.status === "1"
                                                    ? "Active"
                                                    : row.status === "0"
                                                    ? "Not Active"
                                                    : "no"}
                                            </TableCell>
                                            <TableCell>{row.contact_information_phone}</TableCell>
                                            <TableCell>{row.contact_person_name}</TableCell>
                                        </TableRow>
                                    );
                                })}
                            {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )}
                        </TableBody>
                    </Table>
                </div>
                <TablePagination
                    rowsPerPageOptions={[5, 10, 25]}
                    component="div"
                    count={branches.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            </Paper>
        </div>
    );
}

//==================================================================================
//==========================SupplierBranchesList======================
//==================================================================================

class SupplierBranchesList extends React.Component {
    static contextType = AuthContext;

    constructor(props) {
        super(props);
        this.state = {
            branches: [],
            activeBranches: [],
            notActiveBranches: [],
            deletedBranches: [],
            showDeleteAlert: false,
            delete_Branches: [],
            checkedB: false,
            tableDisplay: 2,
            branchesDisplayed: [],
            cities: [],

            city: "",
            branch_id: "",
            name: "",
            supplier_name: "",

            searchCount: 0,

            pathAddNewSupplierBranch: "",
            pathUpdateBranchInfo: "",
            UpdateBranchId: "",
            pathBack: "",

            loading: false,
            dialogOpen: false,
            dialogHeader: "",
            dialogMsg: "",
            dialogCloseHandler: null,
        };
    }

    //==================================================================================
    showDialogMsg = (title, msg, closeHandler) => {
        this.setState({
            loading: false,
            dialogHeader: title,
            dialogMsg: msg,
            dialogOpen: true,
            dialogCloseHandler: closeHandler,
        });
    };

    hideDialogMsg = () => {
        this.setState({ dialogMsg: "", dialogOpen: false });
    };

    componentDidMount() {
        this.setState({ loading: true });
        // if there is any filter before go to Add or edit branch
        // if (this.props.location.state.name !== undefined || this.props.location.state.city !== undefined) {
        //     this.setState({
        //         name: this.props.location.state.name !== undefined ? this.props.location.state.name : "",
        //         city: this.props.location.state.city !== undefined ? this.props.location.state.city : "",
        //     });
        // }

        let datat = "";
        let datat2 = [];
        let datat3 = [];
        let datat4 = [];
        var citiesRef = db.collection("City").orderBy("En_name");
        citiesRef
            .get()
            .then((snapshot) => {
                snapshot.forEach((doc) => {
                    let datat = doc.data();
                    datat2.push(datat);
                });
                this.setState({ cities: datat2 });
            })
            .then(() => {
                datat2 = [];
                var citiesRef = db.collection("Branch").where("supplier_id", "==", this.props.id);
                citiesRef
                    .get()
                    .then((snapshot) => {
                        snapshot.forEach((doc) => {
                            datat = doc.data();

                            if (
                                !this.context.userData.owner &&
                                !this.context.userData.branch_ids?.includes(datat.branch_id)
                            )
                                return;

                            const cityIndex = this.state.cities.findIndex((post) => {
                                return post.city_id === datat.city_id;
                            });
                            //const cityIndex = this.state.cities.city_id.indexOf(datat.city_id);

                            datat.city_name = this.state.cities[cityIndex].En_name;
                            datat.contact_information_phone = datat.contact_information.phone;
                            datat.contact_person_name =
                                datat.contact_person.first_name + " " + datat.contact_person.surname;
                            datat2.push(datat);
                            if (datat.status === "1") {
                                datat3.push(datat);
                                //datat2.push(datat);
                            } else if (datat.status === "0") {
                                datat4.push(datat);
                            } /*else{
            datat5.push(datat);
        }*/
                        });
                        this.setState(() => ({
                            activeBranches: datat3,
                            notActiveBranches: datat4,
                            /*deletedBranches:datat5,*/ branches: datat2,
                            branchesDisplayed: datat3,
                        }));
                    })
                    .then(() => {
                        datat2 = [];
                        var citiesRef = db.collection("Supplier").where("company_id", "==", this.props.id);
                        citiesRef
                            .get()
                            .then((snapshot) => {
                                snapshot.forEach((doc) => {
                                    datat2 = doc.data();

                                    this.setState(() => ({
                                        supplier_name: datat2.company_name,
                                    }));
                                });
                            })
                            .then(() => {
                                this.setState({ loading: false });
                                // if there is any filter before go to Add or edit branch
                                // if (
                                //     this.props.location.state.name !== undefined ||
                                //     this.props.location.state.city !== undefined
                                // ) {
                                //     this.doSearch();
                                // }
                            });
                    });
            })
            .catch((err) => {
                console.log("Error getting documents", err);
                this.showDialogMsg("Alert", "Something went wrong, please try to refresh.", this.hideDialogMsg);
            });
    }

    handleShowDeleteAlert = (branches, numSelected) => {
        /*if(this.state.tableDisplay === 4){
      if(branches.length > 1){
        alert("The branches have already been deleted")
      }else{
        alert("The branch has already been deleted")
      }
      
    }else{*/
        this.deleteAlertWindow(branches);
        //}
    };

    deleteAlertWindow = (branches) => {
        if (branches.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Branches !",
                text: "Do you want to delete these branches?",
            }).then((result) => {
                if (!result.value) {
                    this.deleteAlertWindow2(branches);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Branch !",
                text: "Do you want to delete this branch?",
            }).then((result) => {
                if (!result.value) {
                    this.deleteAlertWindow2(branches);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        }
    };

    deleteAlertWindow2 = (branches) => {
        if (branches.length > 1) {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Branches !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (!result.value) {
                    this.deleteRow(branches);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        } else {
            Swal.fire({
                type: "warning",
                showCancelButton: true,
                confirmButtonColor: "",
                cancelButtonColor: "",
                confirmButtonText: "No",
                cancelButtonText: "Yes",
                title: "Delete Branch !",
                text: "Are you sure?!!",
            }).then((result) => {
                if (!result.value) {
                    this.deleteRow(branches);
                } else {
                    this.setState({ showDeleteAlert: false });
                }
            });
        }
    };

    deleteRow = (branches) => {
        //delete the Branch from firestore collection(store)
        for (let i = 0; i < branches.length; i++) {
            let status = "0";

            var storeList = db.collection("Branch").where("branch_id", "==", branches[i]);
            storeList
                .get()
                .then(function (querySnapshot) {
                    querySnapshot.forEach(function (doc) {
                        status = doc.data().status;
                        if (status === "0") {
                            this.showDialogMsg(
                                "Message",
                                "The branch (" + doc.data().En_name + ") has already been deleted",
                                this.hideDialogMsg
                            );
                        } else {
                            doc.ref.update({
                                status: "0",
                            });
                        }
                    });
                })
                .then(() => {
                    if (/*status === "0" ||*/ status === "1") {
                        //delete branch from All
                        const index = this.state.branches.findIndex((post) => {
                            return post.branch_id === branches[i];
                        });

                        //var deletedBranchItem =[];
                        var arrayAllBranches = [...this.state.branches];
                        if (index !== -1) {
                            arrayAllBranches[index].status = "0";
                            //deletedBranchItem = array.splice(index, 1);
                            //this.setState({branches: array, branchesDisplayed: array });
                        }

                        /*//change status of branch from active or not active to deleted into state of branches Array 
      const index = this.state.branches.findIndex(post => {
        return post.branch_id === branches[i]
      })
      var arrayAllBranches = [...this.state.branches];
      arrayAllBranches[index].status="2";
  
      //delete branch from All/ active/ not active array
      if(this.state.tableDisplay===1){
        
          if(status === "1"){
            
            const index = this.state.activeBranches.findIndex(post => {
              return post.branch_id === branches[i]
            })
            
            if (index !== -1) {
              var deletedbranchItem =[];
              //delete branch from active array
              var array = [...this.state.activeBranches];
              deletedbranchItem = array.splice(index, 1);
              
              //add branch into deleted array
              deletedbranchItem[0].status="2";
              var array2 = [...this.state.deletedBranches];
              array2.push(deletedbranchItem[0]);
              console.log(array2);
              this.setState({branches: arrayAllBranches, activeBranches: array, deletedBranches: array2 });
            }
            
  
          }else{
            //delete branch from not active array
            const index = this.state.notActiveBranches.findIndex(post => {
              return post.branch_id === branches[i]
            })
            
            if (index !== -1) {
              var deletedbranchItem =[];
              //delete branch from not active array
              var array = [...this.state.notActiveBranches];
              deletedbranchItem = array.splice(index, 1);
  
              //add branch into deleted array
              deletedbranchItem[0].status="2";
              var array2 = [...this.state.deletedBranches];
              array2.push(deletedbranchItem[0]);
              console.log(array2);
              this.setState({branches: arrayAllBranches, notActiveBranches: array, deletedBranches: array2 });
            }
  
  
          }
  
        }else*/ if (this.state.tableDisplay === 2) {
                            // delete branch from active array
                            const index2 = this.state.activeBranches.findIndex((post) => {
                                return post.branch_id === branches[i];
                            });

                            if (index2 !== -1) {
                                var deletedbranchItem = [];
                                //delete branch from active array
                                var array = [...this.state.activeBranches];
                                deletedbranchItem = array.splice(index2, 1);

                                //add branch into notActive array
                                deletedbranchItem[0].status = "0";
                                var array2 = [...this.state.notActiveBranches];
                                array2.push(deletedbranchItem[0]);
                                this.setState({
                                    branches: arrayAllBranches,
                                    activeBranches: array,
                                    branchesDisplayed: array,
                                    notActiveBranches: array2,
                                });
                            }
                        } /*else{
          // delete branch from not active array
            const index = this.state.notActiveBranches.findIndex(post => {
                return post.branch_id === branches[i]
              })
              
              if (index !== -1) {
                var deletedbranchItem =[];
                //delete branch from not active array
                var array = [...this.state.notActiveBranches];
                deletedbranchItem = array.splice(index, 1);
  
                //add branch into deleted array
                deletedbranchItem[0].status="2";
                var array2 = [...this.state.deletedBranches];
                array2.push(deletedbranchItem[0]);
                console.log(array2);
                this.setState({branches: arrayAllBranches, notActiveBranches: array, branchesDisplayed: array, deletedBranches: array2 });
              }
  
  
        }//else not active*/
                    } //if status is active or not active
                });
        } //for
    };

    isItemSelected = (name) => {
        // alert(name)
        this.setState({ checkedB: !this.state.checkedB });
    };

    doSearch = () => {
        let array = [];
        if (this.state.tableDisplay === 1) {
            array = [...this.state.branches];
            this.search2(array);
        } else if (this.state.tableDisplay === 2) {
            array = [...this.state.activeBranches];
            this.search2(array);
        } else if (this.state.tableDisplay === 3) {
            array = [...this.state.notActiveBranches];
            this.search2(array);
        } else {
            array = [...this.state.deletedBranches];
            this.search2(array);
        }
    };
    //==================================================================================
    search2 = (array) => {
        var queryResult = [];
        array.forEach((branch) => {
            if (
                (branch.branch_id === this.state.branch_id || this.state.branch_id.length === 0) &&
                (branch.En_name.toLowerCase().search(this.state.name.toLowerCase()) > -1 ||
                    branch.Ar_name.search(this.state.name) > -1 ||
                    this.state.name.length === 0) &&
                (branch.city_id === this.state.city || this.state.city.length === 0)
            )
                queryResult.push(branch);
        });

        this.setState({
            branchesDisplayed: queryResult,
        });
    };

    handleChange = (event, id) => {
        this.setState({ [id]: event.target.value });
    };

    handleUpdateBranchInfo = (id) => {
        this.setState({ pathUpdateBranchInfo: PATHS.UPDATE_BRANCH, UpdateBranchId: id });
    };

    handleAddNewSupplierBranch = () => {
        this.setState({ pathAddNewSupplierBranch: PATHS.ADD_BRANCH });
    };

    handleBranchesPdf = () => {
        generatePDF(
            <BranchesPdf
                info={{
                    branches: this.state.branches,
                    companyData: this.context.companyData,
                }}
            />,
            `Branches PDF`
        );
    };

    goToSupplierDashboardPage = (path) => {
        this.setState({ pathBack: path });
    };
    //==================================================================================
    render() {
        if (this.state.pathUpdateBranchInfo.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathUpdateBranchInfo,
                        state: {
                            branchID: this.state.UpdateBranchId,
                            id: this.props.id,
                            name: this.state.name,
                            city: this.state.city,
                        },
                    }}
                />
            );
        } else if (this.state.pathAddNewSupplierBranch.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathAddNewSupplierBranch,
                        state: {
                            id: this.props.id,
                            name: this.state.name,
                            city: this.state.city,
                        },
                    }}
                />
            );
        } else if (this.state.pathBack.length > 0) {
            return (
                <Redirect
                    push={true}
                    to={{
                        pathname: this.state.pathBack,
                        state: { id: this.props.id },
                    }}
                />
            );
        } else {
            // const classes = makeStyles((theme) => ({
            //     root: {
            //         width: "100%",
            //         marginTop: theme.spacing(3),
            //         overflowX: "auto",
            //     },
            //     table: {
            //         minWidth: 650,
            //     },
            // }));

            const { classes } = this.props;

            return (
                <div style={{ margin: "0px", padding: "0px", paddingBottom: "40px" }}>
                    <div
                        className={classNames(classes.main, classes.textCenter)}
                        style={{ width: "100%", display: "contents" }}
                    >
                        <div
                            style={{
                                position: "relative",
                                maxWidth: "100%",
                                display: "flex",
                                justifyContent: "center",
                            }}
                            className={classes.container}
                        >
                            <div
                                className={`${this.props.classes.backdrop} ${this.props.classes.absolutePosition} ${
                                    this.state.loading ? null : this.props.classes.loadingHidden
                                }`}
                            ></div>
                            <div
                                className={`${this.props.classes.loaderContainer} ${
                                    this.props.classes.absolutePosition
                                } ${this.state.loading ? null : this.props.classes.loadingHidden}`}
                            >
                                <CircularProgress />
                            </div>
                            <Box
                                style={{
                                    paddingTop: 15,
                                    borderRadius: "5px",
                                    marginTop: "20px",
                                    marginBottom: "30px",
                                    backgroundColor: "#FFFFFF",
                                    color: "#EEEEEE",
                                    width: "90%",
                                    boxShadow: "#96a1a9 0 4px 5px 0px",
                                }}
                                pt={4}
                            >
                                <GridContainer justifyContent="center">
                                    <div
                                        className={classes.title}
                                        style={{
                                            width: "3%",
                                            display: "flex",
                                            flexDirection: "row",
                                            alignItems: "center",
                                        }}
                                    >
                                        <div
                                            style={{
                                                fontWeight: "700",
                                                // backgroundColor: "#91041C",
                                                color: "#EEEEEE",
                                                height: "30px",
                                                width: "100%",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "2px solid #575757",
                                                // borderRightColor: "#91041C",
                                                // borderBottomColor: "#91041C",
                                                backgroundColor: COLORS.primary,
                                                borderRightColor: COLORS.primary,
                                                borderBottomColor: COLORS.primary,
                                            }}
                                        ></div>
                                    </div>
                                    <div className={classes.title} style={{ width: "90%", marginLeft: "2px" }}>
                                        <h4
                                            style={{
                                                textAlign: "left",
                                                fontWeight: "700",
                                                backgroundColor: COLORS.background,
                                                color: COLORS.primary,
                                                // color: "#91041C",
                                                height: "30px",
                                                paddingLeft: "20px",
                                                paddingTop: "3px",
                                                paddingBottom: "3px",
                                                border: "1px solid #000000",
                                            }}
                                        >
                                            Branches
                                        </h4>
                                    </div>
                                    <GridItem xs={12} sm={12} md={2}>
                                        <p
                                            style={{
                                                margin: 0,
                                                paddingTop: 0,
                                                textTransform: "none",
                                                color: "#000000",
                                                fontWeight: "900",
                                            }}
                                        >
                                            Search By
                                        </p>
                                    </GridItem>

                                    <GridItem xs={12} sm={12} md={10}>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                    </GridItem>

                                    <div style={{ width: "92%", display: "flex", flexDirection: "row" }}>
                                        <Grid item xs={12} sm={12} md={7}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={12}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        value={this.state.name}
                                                        variant="filled"
                                                        fullWidth
                                                        id="name"
                                                        label="Name"
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({ name: event.target.value })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    />
                                                </GridItem>
                                            </div>
                                        </Grid>
                                        <Grid item xs={12} sm={12} md={12}>
                                            <div style={{ width: "100%", display: "flex", flexDirection: "row" }}>
                                                <GridItem xs={12} sm={12} md={7}>
                                                    <TextField
                                                        InputProps={{
                                                            classes: {
                                                                root: classes.textFieldRoot,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        InputLabelProps={{
                                                            classes: {
                                                                root: classes.textFieldLabel,
                                                                disabled: classes.disabled,
                                                            },
                                                        }}
                                                        select
                                                        value={this.state.city}
                                                        variant="filled"
                                                        fullWidth
                                                        id="city"
                                                        label="City"
                                                        style={{ textAlign: "left" }}
                                                        placeholder=""
                                                        multiline
                                                        //value={"" + this.props.input}
                                                        onChange={(event) =>
                                                            this.setState({ city: event.target.value })
                                                        }
                                                        className={classes.textField}
                                                        margin="dense"
                                                        maxRows={1}
                                                    >
                                                        {this.state.cities.map((option) => (
                                                            <MenuItem key={option.city_id} value={option.city_id}>
                                                                {option.En_name}
                                                            </MenuItem>
                                                        ))}
                                                    </TextField>
                                                </GridItem>
                                                <GridItem
                                                    xs={12}
                                                    sm={12}
                                                    md={5}
                                                    style={{ width: "100%", display: "flex", flexDirection: "row" }}
                                                >
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <Button
                                                            simple
                                                            size="lg"
                                                            // disabled={!this.validateThirdForm()}
                                                            onClick={this.doSearch}
                                                            style={{
                                                                marginTop: 18,
                                                                marginRight: 10,
                                                                padding: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                float: "left",
                                                                textTransform: "none",
                                                                backgroundColor: COLORS.submit,
                                                                color: COLORS.font,
                                                                // borderColor: "#4BB543",
                                                                border: "2",
                                                                fontWeight: "400",
                                                            }}
                                                            type="submit"
                                                        >
                                                            {" "}
                                                            Search{" "}
                                                        </Button>
                                                    </GridItem>
                                                    <GridItem xs={12} sm={12} md={4}>
                                                        <Button
                                                            simple
                                                            size="lg"
                                                            // disabled={!this.validateThirdForm()}
                                                            onClick={() => {
                                                                this.setState({
                                                                    tableDisplay: 2,
                                                                    branchesDisplayed: this.state.activeBranches,
                                                                    branch_id: "",
                                                                    name: "",
                                                                    city: "",
                                                                });
                                                            }}
                                                            style={{
                                                                marginTop: 17,
                                                                padding: 10,
                                                                paddingLeft: 20,
                                                                paddingRight: 20,
                                                                float: "left",
                                                                textTransform: "none",
                                                                backgroundColor: COLORS.accent,
                                                                color: COLORS.font,
                                                                // borderColor: "#91041C",
                                                                border: "2",
                                                                fontWeight: "400",
                                                            }}
                                                            type="submit"
                                                        >
                                                            {" "}
                                                            Cancel{" "}
                                                        </Button>
                                                    </GridItem>
                                                </GridItem>
                                            </div>
                                        </Grid>
                                    </div>

                                    <GridItem xs={12} sm={12} md={12}>
                                        <Grid item xs={12}>
                                            <p></p>
                                            <Paper className={classes.paper}> </Paper>
                                        </Grid>
                                    </GridItem>

                                    <div style={{ width: "89%" }}>
                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 2,
                                                    branchesDisplayed: this.state.activeBranches,
                                                    branch_id: "",
                                                    name: "",
                                                    city: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 2
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Active{" "}
                                        </Button>

                                        <Button
                                            simple
                                            size="lg"
                                            // disabled={!this.validateThirdForm()}
                                            onClick={() => {
                                                this.setState({
                                                    tableDisplay: 3,
                                                    branchesDisplayed: this.state.notActiveBranches,
                                                    branch_id: "",
                                                    name: "",
                                                    city: "",
                                                });
                                            }}
                                            style={
                                                this.state.tableDisplay === 3
                                                    ? {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: COLORS.accent,
                                                          color: "#EEEEEE",
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                                    : {
                                                          marginTop: 10,
                                                          marginLeft: "10px",
                                                          padding: 10,
                                                          paddingLeft: 20,
                                                          paddingRight: 20,
                                                          textTransform: "none",
                                                          backgroundColor: "#EEEEEE",
                                                          color: COLORS.accent,
                                                          fontWeight: "400",
                                                          float: "left",
                                                          width: "11%",
                                                      }
                                            }
                                            type="submit"
                                        >
                                            {" "}
                                            Not Active{" "}
                                        </Button>
                                    </div>

                                    <GridItem xs={12} sm={12} md={11}>
                                        <EnhancedTable
                                            branches={this.state.branchesDisplayed}
                                            handleShowDeleteAlertMethod={this.handleShowDeleteAlert}
                                            handleUpdateBranchInfoMethod={this.handleUpdateBranchInfo}
                                            handleAddNewSupplierBranchMethod={this.handleAddNewSupplierBranch}
                                            handleBranchesPdfMethod={this.handleBranchesPdf}
                                            loading={this.state.loading}
                                        />
                                    </GridItem>
                                </GridContainer>
                            </Box>
                        </div>

                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                        <Grid item xs={12}>
                            <p></p>
                            <Paper className={classes.paper}> </Paper>
                        </Grid>
                    </div>

                    <Dialog open={this.state.dialogOpen} onClose={this.state.dialogCloseHandler}>
                        <DialogTitle>{this.state.dialogHeader}</DialogTitle>
                        <DialogContent>
                            <DialogContentText>{this.state.dialogMsg}</DialogContentText>
                        </DialogContent>
                        <DialogActions>
                            <Button onClick={this.state.dialogCloseHandler} style={{ backgroundColor: COLORS.accent }}>
                                Ok
                            </Button>
                        </DialogActions>
                    </Dialog>
                </div>
            );
        }
    }
}

SupplierBranchesList.propTypes = {
    classes: PropTypes.object,
    user: PropTypes.object,
};

export default withStyles(componentsStyle, styles)(SupplierBranchesList);

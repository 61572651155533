import { makeStyles } from "@material-ui/styles";

const styles = makeStyles({
    root: {
        width: "100%",
        height: "100%",
    },

    arabic: {
        direction: "rtl",
        textAlign: "right",
    },

    pageHeader: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        height: "100px",
        width: "100%",
        backgroundColor: "#fafafa",
        borderBottom: "1px solid #e0e0e0",
        padding: "20px",
        boxShadow: "0px 0px 10px #e0e0e0",
    },

    headerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "1200px",

        "& img": {
            height: "100%",
        },
        "& h1": {
            fontSize: "1.5rem",
            fontWeight: "bold",
            color: "#333",
            margin: "0",
        },
    },

    content: {
        display: "flex",
        justifyContent: "center",
        alignItems: "flex-start",
        minHeight: "calc(100vh - 180px)",
        width: "100%",
        padding: "20px",
    },

    footer: {
        display: "flex",
        justifyContent: "center",
        alignItems: "center",
        width: "100%",
        height: "80px",
        padding: "20px",
        backgroundColor: "#fafafa",
        borderTop: "1px solid #e0e0e0",
        boxShadow: "0px 0px 10px #e0e0e0",
    },
    footerWrapper: {
        display: "flex",
        justifyContent: "space-between",
        alignItems: "center",
        width: "100%",
        height: "100%",
        maxWidth: "1200px",
    },
});

export default styles;

import { flatten } from "lodash";
import React, { Fragment, useEffect, useReducer, useState } from "react";
import { Filters } from "../../components";
import { useAuth } from "../../contexts/auth-context";
import ListAltSharpIcon from "@material-ui/icons/ListAltSharp";
import { CircularLoader, Header } from "../../core-ui/custom";
import { formatDate, getToday } from "../../helpers/date-utils";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import { getLinkedOutletBranchesBySupplierID } from "../../services/firestore/Branch_Outlet_Branch";
import { getAllCities } from "../../services/firestore/City";
import { getSupplierMerchandisers } from "../../services/firestore/Merchandiser";
import { getOutletBranches, getOutletBranchesByCompany } from "../../services/firestore/Outlet_Branch";
import { getProducts, getSupplierProducts } from "../../services/firestore/Product";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { queryTasks_V2 } from "../../services/firestore/Task";
import { getLinkedOutlets } from "../../services/firestore/Supplier_Outlet_Product";
import { queryTaskDetails } from "../../services/firestore/Task_Details";
import { getMerchandiserName } from "../../helpers/merchandiser-utils";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import PictureAsPdfIcon from "@material-ui/icons/PictureAsPdf";
import { Button } from "@material-ui/core";
import { TASKS_STATUS } from "../../constants/constants-lagacy";
import DamageAndExpiryReportPdf from "../../components-lagacy/PDF/DamageAndExpiryReport";
import { generatePDFinNewTab } from "../../helpers/pdf-utils";

//related to meterial ui package
import styles from "./styles";
import { getAllOutlets } from "../../services/firestore/Outlet";
import { stringifyProductPacking } from "../../helpers/misc";

const INITIAL_FILTERS = {
    startDate: {
        label: "Start Date",
        component: "date",
        initialValue: getToday(),
    },
    endDate: {
        label: "End Date",
        component: "date",
        initialValue: getToday(),
    },
    cities: {
        label: "City",
        options: [],
        initialValue: null,
    },
    products: {
        label: "Products",
        options: [],
        initialValue: null,
    },

    outlets: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranches: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
};

const dataSourcesReducer = (state, { type, payload }) => {
    switch (type) {
        case "MULTI_SET":
            return {
                ...state,
                ...payload,
            };
        case "SET":
            return {
                ...state,
                [payload.key]: payload.value,
            };
        case "RESET":
            return {
                ...state,
                [payload.key]: payload.initialValue,
            };
        default:
            return state;
    }
};

const initialState = {
    cities: [],
    // merchandisers: [],
    outlets: [],
    supplierBranches: [],
    products: [],
    outletBranches: [],
};

const NearExpiryReport = (props) => {
    const classes = styles();
    const { companyData } = useAuth();
    // states
    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [dataSources, dispatchDataSources] = useReducer(dataSourcesReducer, initialState);
    const [tasks, setMainTasks] = useState([]);
    const [startDate, setStartDate] = useState(getToday());
    const [endDate, setEndDate] = useState(getToday());
    const [isClicked, setIsClicked] = useState(false);
    const [pdfRows, setPdfRows] = useState([]);
    let counter = 0;

    const init = async () => {
        try {
            const { company_id } = companyData;
            // load filters options
            const [products, cities, outlets] = (
                await Promise.all([getSupplierProducts(company_id), getAllCities(), getAllOutlets(company_id)])
            ).map((docs) => docs.map((doc) => doc.data()));

            dispatchDataSources({
                type: "MULTI_SET",
                payload: {
                    products: products.map((p) => ({ value: `${p.product_id}`, label: p.En_name, data: p })),
                    cities: cities.map((c) => ({ value: `${c.city_id}`, label: c.En_name })),
                    outlets: outlets.map((o) => ({ value: o.outlet_id, label: o.En_short_name })),
                },
            });
        } catch (error) {
            console.log(error);
        }
        setInitializing(false);
    };

    const initFilters = (context) => {
        INITIAL_FILTERS.products.options = dataSources.products;
        INITIAL_FILTERS.cities.options = dataSources.cities;
        INITIAL_FILTERS.outlets.options = dataSources.outlets;

        INITIAL_FILTERS.outlets.onChange = handleOutletsChange;

        context.setFilters(INITIAL_FILTERS);
    };

    const handleOutletsChange = async (option, context) => {
        try {
            if (!option) {
                dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: [] } });
                context.dispatchFilters({
                    type: "CLEAR_FILTER",
                    payload: { id: "outletBranches" },
                });
            }

            let outletBranches = (await getOutletBranchesByCompany(option.value)).map((doc) => doc.data());

            outletBranches = outletBranches.map((ob) => ({ value: ob.branch_id, label: ob.En_short_name }));

            dispatchDataSources({ type: "SET", payload: { key: "outletBranches", value: outletBranches } });
            context.dispatchFilters({
                type: "SET_FILTER_OPTIONS",
                payload: { id: "outletBranches", options: outletBranches },
            });
        } catch (error) {
            throw error;
        }
    };

    const handleDamageAndExpiryReportPdf = () => {
        generatePDFinNewTab(
            <DamageAndExpiryReportPdf
                info={{
                    companyData: companyData,
                    pdfRows: pdfRows,
                    startDate: startDate,
                    endDate: endDate,
                }}
            />,
            `Damage and Expiry Report`
        );
    };
    const handleDamageAndExpiryReportExcel = () => {};

    const search = async (values) => {
        setPdfRows([]);
        try {
            setLoading(true);
            const { startDate, endDate, cities, products, outlets, outletBranches } = values;
            const startofDate = new Date(startDate);
            const endofDate = new Date(endDate);
            startofDate.setHours(0, 0, 0, 0);
            endofDate.setHours(23, 59, 59, 999);

            const taskQuery = [
                { key: "date_time_from", operator: ">=", value: startofDate },
                { key: "date_time_from", operator: "<=", value: endofDate },
                { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                { key: "state", operator: "in", value: [TASKS_STATUS.UNDER_PROCESS, TASKS_STATUS.FINISHED] },
            ];

            if (cities?.value) taskQuery.push({ key: "city_id", operator: "==", value: cities.value });

            if (outlets?.value) taskQuery.push({ key: "outlet_id", operator: "==", value: Number(outlets.value) });
            if (outletBranches?.value)
                taskQuery.push({ key: "outlet_branch_id", operator: "==", value: outletBranches.value });

            let tasks = (await queryTasks_V2(taskQuery)).map((doc) => doc.data());
            //get task details for each task
            tasks = await Promise.all(
                tasks.map(async (task) => {
                    const query = [
                        {
                            key: "task_id",
                            operator: "==",
                            value: task.task_id,
                        },
                    ];

                    let taskDetails = (await queryTaskDetails(query)).map((doc) => doc.data());
                    task.taskDetails = taskDetails;
                    return task;
                })
            );

            //unique outlet branch ids
            let outletBranchIds = tasks.map((task) => `${task.outlet_branch_id}`);
            outletBranchIds = [...new Set(outletBranchIds)];
            const outletBranchesList = (await getOutletBranches(outletBranchIds)).map((doc) => doc.data());

            //unique product ids
            let allTaskDetails = tasks.reduce((prev, curr) => {
                prev = [...prev, ...curr.taskDetails];
                return prev;
            }, []);
            let productIds = allTaskDetails.map((taskDetail) => taskDetail.product_id);
            productIds = [...new Set(productIds)];
            //filter products
            if (products?.value) productIds = productIds.filter((id) => id === products.value);
            const tasksProducts = (await getProducts(productIds)).map((doc) => doc.data());

            //assign product info and outlet branch info into their appropriate place
            tasks.forEach((task) => {
                //outlet branch
                const outletBranch = outletBranchesList.find((ob) => ob.branch_id === `${task.outlet_branch_id}`);
                task.outletBranch = outletBranch;

                //products
                task.taskDetails.forEach((td) => {
                    const product = tasksProducts.find((prod) => prod.product_id === td.product_id);
                    td.product = product;
                });
            });
            let supplierProducts = dataSources.products.map((p) => p.data);
            // each row is a 1 product, 1 outlet branch, multiple tasks and displays
            let rows = [];
            tasks.forEach((task) => {
                let taskProducts = supplierProducts.filter((product) => {
                    let detail = task.taskDetails.find((sb) => `${sb.product_id}` === `${product.product_id}`);
                    return detail;
                });

                taskProducts.forEach((product) => {
                    // each row is a 1 product, 1 outlet branch, multiple tasks and displays
                    let rowIndex = rows.findIndex(
                        (r) =>
                            r.task.outlet_branch_id === task.outlet_branch_id &&
                            r.product.product_id === product.product_id
                    );

                    //init
                    if (rowIndex === -1) {
                        rows.push({
                            id: `${task.outlet_branch_id}-${product.product_id}`,
                            product,
                            task,
                            damageQty: 0,
                            expiredQty: 0,
                            nearExpiryQty: 0,
                            nearExpiry: [],

                            outletBranch: task.outletBranch,
                            productName: product.En_name,
                            barcode: product.barcode,
                            packing: stringifyProductPacking(product),
                            remarks: task.remarks ? "-" : task.remarks,
                        });
                        rowIndex = rows.length - 1;
                    }

                    let productDisplays = task.taskDetails.filter(
                        (sb) => `${sb.product_id}` === `${product.product_id}`
                    );
                    for (const ptd of productDisplays) {
                        rows[rowIndex].damageQty += ptd.removed_from.damaged || 0;
                        rows[rowIndex].expiredQty += ptd.removed_from.expired || 0;

                        // number_of_near_expiration = length of array
                        console.log(ptd.removed_from.number_of_near_expiration);
                        rows[rowIndex].nearExpiryQty += ptd.removed_from.number_of_near_expiration || 0;
                        if (ptd.removed_from.number_of_near_expiration > 0) {
                            console.log(ptd.removed_from.number_of_near_expiration);
                            let obj2Arr = Object.values(ptd.removed_from.near_expiration);
                            rows[rowIndex].nearExpiry = [...rows[rowIndex].nearExpiry, ...obj2Arr];
                        }
                    }
                });
            });

            rows = rows.filter((r) => r.damageQty != 0 || r.expiredQty != 0 || r.nearExpiryQty != 0);

            //if row has 0 near expiry, insert an empty item to allow rendering
            for (const row of rows) {
                if (row.nearExpiryQty <= 0) row.nearExpiry.push({ quantity: 0, date: null });
            }

            rows.sort(function (a, b) {
                if (a.outletBranch.En_short_name < b.outletBranch.En_short_name) {
                    return -1;
                }
                if (a.outletBranch.En_short_name > b.outletBranch.En_short_name) {
                    return 1;
                }
                return 0;
            });
            setPdfRows(rows);
            setMainTasks(tasks);
            setStartDate(startDate);
            setEndDate(endDate);

            setIsClicked(true);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    useEffect(() => {
        init();
    }, []);

    return (
        <section className={classes.sectionContainer}>
            <Header className={classes.reportHeader}>
                <span className={classes.headerColor}>Near Expiry Report</span>
            </Header>
            {!initializing && <Filters init={initFilters} onSubmit={search} />}
            {!isClicked && (initializing || loading) ? <CircularLoader className={classes.alignCenter} /> : null}
            {isClicked ? (
                <div className={classes.container}>
                    {/* <div className={classes.dateContainer}>
                        Date from: <span className={classes.boldFont}>{formatDate(startDate)}</span>
                        <br></br>
                        Date to: <span className={classes.boldFont}>{formatDate(endDate)}</span>
                    </div> */}
                    <div className={classes.alignRight}>
                        <Button
                            className={classes.pdfButton}
                            title="print pdf"
                            // onClick={handleDamageAndExpiryReportPdf}
                        >
                            <div className={classes.pdfIcon}>
                                <PictureAsPdfIcon />
                            </div>
                        </Button>
                        <Button
                            className={classes.pdfButton}
                            title="export Excel"
                            // onClick={handleDamageAndExpiryReportExcel}
                        >
                            <div className={classes.excelIcon}>
                                <ListAltSharpIcon />
                            </div>
                        </Button>
                    </div>

                    <Table className={classes.table}>
                        <TableHead>
                            <TableRow className={classes.headerRow}>
                                <TableCell rowSpan={2} className={classes.indexHeader}></TableCell>
                                <TableCell rowSpan={2} className={[classes.header, classes.outletHeader]}>
                                    Outlet Name
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={3} className={[classes.header, classes.productHeader]}>
                                    Product
                                </TableCell>
                                <TableCell rowSpan={1} colSpan={3} className={[classes.header, classes.stockHeader]}>
                                    Stock Position
                                </TableCell>
                                <TableCell rowSpan={2} className={[classes.header, classes.remarksHeader]}>
                                    Remarks
                                </TableCell>
                            </TableRow>
                            <TableRow className={classes.headerRow}>
                                <TableCell className={[classes.secondHeader, classes.barcodeHeader]}>Barcode</TableCell>
                                <TableCell className={[classes.secondHeader, classes.discHeader]}>
                                    Discription
                                </TableCell>
                                <TableCell className={[classes.secondHeader, classes.packingHeader]}>Packing</TableCell>
                                <TableCell className={[classes.secondHeader, classes.qtyHeader]}>Exp &nbsp;</TableCell>
                                <TableCell className={[classes.secondHeader, classes.qtyHeader]}>Near Exp</TableCell>
                                <TableCell className={[classes.secondHeader, classes.qtyHeader]}>date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {isClicked && (initializing || loading) ? (
                                <TableRow>
                                    <TableCell colSpan={9}>
                                        <CircularLoader className={classes.alignCenter} />
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {pdfRows.map((row, index) => {
                                counter++;
                                return (
                                    <Fragment key={`tdr-${row.id}`}>
                                        {row.nearExpiry.map((item, itemIndex) => {
                                            let { quantity, date } = item;
                                            if (date) date = date.toDate();

                                            if (itemIndex > 0) {
                                                return (
                                                    <TableRow
                                                        className={index % 2 === 0 ? classes.rowEven : classes.row}
                                                    >
                                                        <TableCell className={classes.qtyCell} rowSpan={1}>
                                                            {quantity}
                                                        </TableCell>
                                                        <TableCell className={classes.qtyCell} rowSpan={1}>
                                                            {formatDate(date)}
                                                        </TableCell>
                                                    </TableRow>
                                                );
                                            }
                                            return (
                                                <TableRow className={index % 2 === 0 ? classes.rowEven : classes.row}>
                                                    <TableCell
                                                        className={classes.indexCell}
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {counter}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.outletCell}
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.outletBranch.En_short_name}
                                                    </TableCell>
                                                    <TableCell
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.barcode}
                                                    </TableCell>
                                                    <TableCell
                                                        className={classes.alignLeft}
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.productName}
                                                    </TableCell>
                                                    <TableCell
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.packing}
                                                    </TableCell>
                                                    {/* <TableCell className={classes.qtyCell}>{row.damageQty}</TableCell> */}
                                                    <TableCell
                                                        className={classes.qtyCell}
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.expiredQty}
                                                    </TableCell>
                                                    <TableCell className={classes.qtyCell} rowSpan={1}>
                                                        {quantity}
                                                    </TableCell>
                                                    <TableCell className={classes.qtyCell} rowSpan={1}>
                                                        {formatDate(date)}
                                                    </TableCell>

                                                    <TableCell
                                                        className={classes.alignLeft}
                                                        rowSpan={row.nearExpiry.length > 0 ? row.nearExpiry.length : 1}
                                                    >
                                                        {row.remarks}
                                                    </TableCell>
                                                </TableRow>
                                            );
                                        })}
                                    </Fragment>
                                );
                            })}
                        </TableBody>
                    </Table>
                </div>
            ) : (
                <div></div>
            )}
        </section>
    );
};

export default NearExpiryReport;

import { STATUS } from "../constants/global";
import { authWorker } from "./firebase";
import { addSupplier } from "./firestore/Supplier";
import { addUser } from "./firestore/User";

const addNewUser = async (email, password) => {
    try {
        await authWorker.createUserWithEmailAndPassword(email, password);
        await authWorker.currentUser.sendEmailVerification();

        return authWorker.currentUser;
    } catch (error) {
        throw error;
    }
};

export const addNewAccount = async (userData, companyData) => {
    try {
        const authUser = await addNewUser(userData.email, userData.password);

        companyData.created_by = authUser.uid;
        companyData.updated_by = authUser.uid;
        const supplier = await addSupplier(companyData);

        userData.password = "";
        userData.uid = authUser.uid;
        userData.company_id = supplier.id;
        userData.created_by = authUser.uid;
        userData.updated_by = authUser.uid;

        //add user document in firestore
        userData.status = STATUS.INACTIVE;
        await addUser(userData);
        await authWorker.signOut();
        return true;
    } catch (error) {
        throw error;
    }
};

export const addUserAccount = async (userData) => {
    try {
        const authUser = await addNewUser(userData.email, userData.password);

        userData.password = "";
        userData.uid = authUser.uid;

        //add user document in firestore
        userData.status = STATUS.INACTIVE;
        await addUser(userData);
        await authWorker.signOut();
        return true;
    } catch (error) {
        throw error;
    }
};

const COLORS = {
    WHITE: "#fff",
    BLACK: "#000",
    // FRENSH_BLUE: "#91041C",
    FRENSH_BLUE: "#7E2285",
    LIGHT_EMINENCE: "#e6e3f4",
    // MALIBU: "#86C4FF",
    MALIBU: "#E34831",
    // PUMPKIN_ORANGE: "#F27121",
    PUMPKIN_ORANGE: "#FD5116",
    VALENTINE_RED: "#7892E8",

    // PRIMARY: "#91041C",
    // ACCENT: "#DB715A",
    PRIMARY: "#7E2285",
    ACCENT: "#FD5116",

    GRADIANT_1: "linear-gradient( 136deg, #f27121 0%, #e94057 50%, #602f92 100%)",

    NAV_COLOR_1: "#7E2285",
    NAV_COLOR_2: "#E34831",
    NAV_COLOR_3: "#E34831",
    NAV_COLOR_4: "#f27121",
    // NAV_COLOR_1: "#570000",
    // NAV_COLOR_2: "#7b90cb",
    // NAV_COLOR_3: "#7892E8",
    // NAV_COLOR_4: "#7a39dc",

    SUBMIT: "#4BB543",
    CANCEL: "#91041C",
};

export default COLORS;

import React, { useState } from "react";
import { IconButton, makeStyles, TextField } from "@material-ui/core";
import { useField, useFormikContext } from "formik";
import COLORS from "../../../constants/colors";
import { CircularLoader } from "../../custom";
import { Visibility, VisibilityOff } from "@material-ui/icons";

const useRootStyle = makeStyles({
    root: {
        "& label.Mui-focused": {
            color: COLORS.FRENSH_BLUE,
        },
        "& .MuiInput-underline:after": {
            borderBottomColor: COLORS.FRENSH_BLUE,
        },
        "& .MuiOutlinedInput-root": {
            "& fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&:hover fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
            "&.Mui-focused fieldset": {
                borderColor: COLORS.FRENSH_BLUE,
            },
        },
    },

    iconButton: {
        padding: 0,
        height: 18,
    },
});

const TextFieldWrapper = ({
    name,
    onChange,
    onBlur,
    type = "text",
    validateOnChange = false,
    showValidationIcon = false,
    ...otherProps
}) => {
    const [field, meta, helpers] = useField(name);
    const context = useFormikContext();

    const rootStyle = useRootStyle();
    const [showPassword, setShowPassword] = useState(false);

    const handleVisibilityClick = () => {
        setShowPassword((prevState) => !prevState);
    };

    const handleChange = (e) => {
        field.onChange(e);
        if (onChange) onChange(e, helpers);
    };

    const handleBlur = (e) => {
        field.onBlur(e);
        if (onBlur) onBlur(e, helpers);
    };

    let inputConfig = {
        endAdornment: showValidationIcon && context.isValidating ? <CircularLoader /> : null,
    };

    let inputType = type;

    if (type === "password") {
        inputConfig = {
            endAdornment: (
                <IconButton className={rootStyle.iconButton} onClick={handleVisibilityClick}>
                    {showPassword ? <Visibility /> : <VisibilityOff />}
                </IconButton>
            ),
        };
        inputType = showPassword ? "text" : "password";
    }

    const config = {
        ...field,
        ...otherProps,
        onChange: handleChange,
        onBlur: handleBlur,
        variant: "outlined",
        fullWidth: true,
        classes: {
            root: rootStyle.root,
        },
        type: inputType,
        InputProps: {
            ...inputConfig,
        },
    };
    if (meta && (meta.touched || validateOnChange) && meta.error) {
        config.error = true;
        config.helperText = meta.error;
    }

    return <TextField {...config} />;
};

export default TextFieldWrapper;

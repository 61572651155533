import React, { useEffect } from "react";
import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { darken, Grid } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

import { RadioGroup, SubmitButton } from "../../core-ui/forms-ui";
import * as Yup from "yup";
import { Form, Formik } from "formik";
import { Seperator } from "../../core-ui/custom";
import { cloneDeep } from "lodash";
import { INITIAL_SCHEDULE_OPTIONS } from "../../constants/global";

const FORM_SCHEMA = Yup.object().shape({
    standard: Yup.boolean().required("Required"),
    order: Yup.boolean().required("Required"),
    delivery: Yup.boolean().required("Required"),
});

const useStyles = makeStyles({
    table: { tableLayout: "auto", whiteSpace: "nowrap" },
    tableCell: {
        color: "#FFFFFF",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "5px",
        paddingTop: "5px",
        textAlign: "center",
        backgroundColor: COLORS.primary,
        position: "sticky",
        top: -16,
        zIndex: 100,
    },
    addButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    textCell: {
        textAlign: "center",
    },
    buttonCell: {
        textAlign: "center",
        width: "25px",
        padding: 0,
    },

    submitButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
let AddScheduleDialog = (props) => {
    const { rowData, disabled, open, handleAction, handleClose } = props;
    const classes = useStyles(props);
    const [saving, setSaving] = useState(false);
    // const [newScheduleData, setNewScheduleData] = useState(initNewScheduleDocument(rowData.scheduleData));

    useEffect(() => {
        if (!open) return;

        //setup
        console.log(rowData);
    }, [open]);

    const handleSubmit = async (values) => {
        try {
            setSaving(true);
            const { standard, order, delivery } = values;

            const newScheduleData = cloneDeep(rowData.scheduleData);
            newScheduleData.options = {
                ...INITIAL_SCHEDULE_OPTIONS,
                quantity: {
                    active: standard,
                },
                inventory: {
                    ...INITIAL_SCHEDULE_OPTIONS.inventory,
                    active: order,
                    order: order,
                },
                delivery: {
                    ...INITIAL_SCHEDULE_OPTIONS.delivery,
                    active: delivery,
                },
            };

            await handleAction(newScheduleData);
            setSaving(false);
            handleClose();
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Dialog onClose={props.handleClose} open={props.open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                {props.title}
            </DialogTitle>

            <Formik
                initialValues={{
                    standard: true,
                    order: false,
                    delivery: false,
                }}
                onSubmit={handleSubmit}
                validationSchema={FORM_SCHEMA}
            >
                {(formik) => {
                    return (
                        <Form>
                            <DialogContent dividers style={{ padding: "16px 24px" }}>
                                <Grid container spacing={1}>
                                    <Grid item xs={12}>
                                        <Typography variant="h6">{`Add New Schedule for ${rowData.outletBranchInfo.En_short_name}`}</Typography>
                                    </Grid>
                                    <Seperator />
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            name="standard"
                                            label="Activate General Merchandising"
                                            options={[
                                                { value: true, label: "Yes", color: "#00af00" },
                                                { value: false, label: "No", color: "#ff0000" },
                                            ]}
                                        />
                                    </Grid>
                                    <Seperator />
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            name="order"
                                            label="Activate Re-Order"
                                            options={[
                                                { value: true, label: "Yes", color: "#00af00" },
                                                { value: false, label: "No", color: "#ff0000" },
                                            ]}
                                        />
                                    </Grid>
                                    <Seperator />
                                    <Grid item xs={12}>
                                        <RadioGroup
                                            name="delivery"
                                            label="Activate Waiting Delivery"
                                            options={[
                                                { value: true, label: "Yes", color: "#00af00" },
                                                { value: false, label: "No", color: "#ff0000" },
                                            ]}
                                        />
                                    </Grid>
                                </Grid>
                            </DialogContent>
                            <DialogActions style={{ padding: "8px 24px" }}>
                                <SubmitButton
                                    type="submit"
                                    className={classes.submitButton}
                                    submitting={saving}
                                    disabled={saving || !formik.isValid}
                                >
                                    {props.submitText}
                                </SubmitButton>
                            </DialogActions>
                        </Form>
                    );
                }}
            </Formik>
        </Dialog>
    );
};

export default AddScheduleDialog;

import { Button, Grid } from "@material-ui/core";
import React from "react";
import CoverageAndTimingPdf from "../../../components-lagacy/PDF/coverage-and-timing";
// import CoverageAndTimingPdf from "../../../components-lagacy/PDF/coverage-and-timing";
import ProjectContractPdf from "../../../components-lagacy/PDF/project-contract";
import ProjectProductsPdf from "../../../components-lagacy/PDF/project-products";
import { COLORS } from "../../../constants/constants-lagacy";
import { generatePDF } from "../../../helpers/pdf-utils";

import { saveAs } from "file-saver";

import { auth, functions } from "../../../services/firebase";

const ProjectPDFs = (props) => {
    const {
        project,
        supplier,
        date_from,
        date_to,
        coverageCities,
        totalProjectValue,
        vat,
        scheduleRows,
        totalVisits,
        getOutletCoverage,
    } = props;

    return (
        <Grid style={{ marginRight: 22 }}>
            <Button
                onClick={() =>
                    generatePDF(
                        <ProjectContractPdf
                            info={{
                                companyData: supplier,
                                supplierName: `${supplier.contact_person.first_name} ${supplier.contact_person.surname}`,
                                supplierCompany: `${supplier.company_name}`,
                                projectId: project.project_id,
                                creationDate: project.project_date,
                                startDate: date_from,
                                endDate: date_to,
                                coverageCities: coverageCities,
                                projectPrice: totalProjectValue,
                                vatPrice: vat * totalProjectValue,
                            }}
                        />,
                        `${supplier.company_name} - Project Proposal - ${project.project_id}`
                    )
                }
                style={{
                    display: "block",
                    float: "right",
                    textTransform: "none",
                    // backgroundColor: "#91041C",
                    // color: "#91041C",
                    margin: "10px 0",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    // borderColor: "#4BB543",
                    // border: "2px solid #91041C",
                    fontWeight: "400",
                    cursor: "pointer",
                    color: "white",
                    backgroundColor: "green",
                }}
            >
                Project Proposal PDF
            </Button>

            <Button
                onClick={() => {
                    generatePDF(
                        <CoverageAndTimingPdf
                            //project, supplier, coverageCities, totalPrice, vatPrice
                            info={{
                                companyData: supplier,
                                supplierName: `${supplier.contact_person.first_name} ${supplier.contact_person.surname}`,
                                supplierCompany: `${supplier.company_name}`,
                                projectId: project.project_id,
                                startDate: date_from,
                                endDate: date_to,
                                coverageDetails: scheduleRows,
                                totalVisits: totalVisits,
                            }}
                        />,
                        `${supplier.company_name} - ${"Timing & Coverage - Appendix A"} - ${project.project_id}`
                    );
                }}
                style={{
                    float: "right",
                    textTransform: "none",
                    // backgroundColor: "#91041C",
                    // color: "#91041C",
                    margin: "10px 5px",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    // borderColor: "#4BB543",
                    // border: "2px solid #91041C",
                    fontWeight: "400",
                    cursor: "pointer",
                    color: COLORS.font,
                    backgroundColor: COLORS.secondary,
                }}
            >
                {"Timing & Coverage - Appendix A"}
            </Button>

            {/* 
                <Button
                    onClick={() => {
                        
                            const randomNumber = functions.httpsCallable("randomNumber");
                            randomNumber().then(result => {
                                console.log(result.data);
                            });
                            const sayHello = functions.httpsCallable("sayHello");
                            sayHello().then(result => {
                                console.log(result.data);
                            });
                        */}
            {/*
                        const exportToExcel = async () => {
                            const exportToExcel = functions.httpsCallable("exportToExcel");
                            //saveAs(exportToExcel, 'fileName');

                            exportToExcel().then(result => {
                                console.log(result);
                            });


                        };

                        exportToExcel();

                        
                    }}
                    style={{
                        float: "right",
                        textTransform: "none",
                        // backgroundColor: "#91041C",
                        // color: "#91041C",
                        margin: "10px 5px",
                        padding: 10,
                        paddingLeft: 20,
                        paddingRight: 20,
                        // borderColor: "#4BB543",
                        // border: "2px solid #91041C",
                        fontWeight: "400",
                        cursor: "pointer",
                        color: COLORS.font,
                        backgroundColor: COLORS.secondary,
                    }}
                >
                    {"Timing & Coverage - Appendix A - Excel"}
                </Button>
            */}

            <Button
                onClick={() =>
                    generatePDF(
                        <ProjectProductsPdf
                            //project, supplier, coverageCities, totalPrice, vatPrice
                            info={{
                                companyData: supplier,
                                supplierName: `${supplier.contact_person.first_name} ${supplier.contact_person.surname}`,
                                supplierCompany: `${supplier.company_name}`,
                                projectId: project.project_id,
                                startDate: date_from,
                                endDate: date_to,
                                outlets: getOutletCoverage(),
                            }}
                        />,
                        `${supplier.company_name} - ${"Products Coverage - Appendix B"} - ${project.project_id}`
                    )
                }
                style={{
                    float: "right",
                    textTransform: "none",
                    // backgroundColor: "#91041C",
                    // color: "#91041C",
                    margin: "10px 0px",
                    padding: 10,
                    paddingLeft: 20,
                    paddingRight: 20,
                    // borderColor: "#4BB543",
                    // border: "2px solid #91041C",
                    fontWeight: "400",
                    cursor: "pointer",
                    color: COLORS.font,
                    backgroundColor: COLORS.secondary,
                }}
            >
                Products Coverage PDF - Appendix B
            </Button>
        </Grid>
    );
};

export default ProjectPDFs;

import React, { useEffect } from "react";
import { useState } from "react";
import { makeStyles, withStyles } from "@material-ui/core/styles";
import Button from "@material-ui/core/Button";
import Dialog from "@material-ui/core/Dialog";
import MuiDialogTitle from "@material-ui/core/DialogTitle";
import MuiDialogContent from "@material-ui/core/DialogContent";
import MuiDialogActions from "@material-ui/core/DialogActions";
import IconButton from "@material-ui/core/IconButton";
import CloseIcon from "@material-ui/icons/Close";
import Typography from "@material-ui/core/Typography";

import { darken } from "@material-ui/core";
import { COLORS } from "../../constants/constants-lagacy";

import EnhancedTable from "../Table/EnhancedTable";

const useStyles = makeStyles({
    table: { tableLayout: "auto", whiteSpace: "nowrap" },
    tableCell: {
        color: "#FFFFFF",
        paddingLeft: "10px",
        paddingRight: "10px",
        paddingBottom: "5px",
        paddingTop: "5px",
        textAlign: "center",
        backgroundColor: COLORS.primary,
        position: "sticky",
        top: -16,
        zIndex: 100,
    },
    addButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
    autoCompleteList: {
        width: "100%",
        height: "100%",
    },
    textCell: {
        textAlign: "center",
    },
    buttonCell: {
        textAlign: "center",
        width: "25px",
        padding: 0,
    },

    submitButton: {
        textTransform: "none",
        color: "#FFFFFF",
        backgroundColor: COLORS.submit,
        marginBottom: "10px",
        "&:hover": {
            backgroundColor: darken(COLORS.submit, 0.2),
        },
    },
});

const styles = (theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(2),
    },
    closeButton: {
        position: "absolute",
        right: "12px",
        top: "12px",
        color: theme.palette.grey[500],
    },
});

const DialogTitle = withStyles(styles)((props) => {
    const { children, classes, onClose, ...other } = props;
    return (
        <MuiDialogTitle disableTypography className={classes.root} {...other}>
            <Typography variant="h6">{children}</Typography>
            {onClose ? (
                <IconButton aria-label="close" className={classes.closeButton} onClick={onClose}>
                    <CloseIcon />
                </IconButton>
            ) : null}
        </MuiDialogTitle>
    );
});

const DialogContent = withStyles((theme) => ({
    root: {
        padding: theme.spacing(2),
    },
}))(MuiDialogContent);

const DialogActions = withStyles((theme) => ({
    root: {
        margin: 0,
        padding: theme.spacing(1),
    },
}))(MuiDialogActions);
let tableHeads = [
    { id: "id", label: "Date" },
    { id: "day", label: "Day" },
];
let ExcludedDaysDialog = (props) => {
    const { rowData, startDate, endDate, disabled, open } = props;
    const classes = useStyles(props);
    const [dates, setDates] = useState([]);
    const [excludedDates, setExcludedDates] = useState([]);

    useEffect(() => {
        if (!open) return;

        //setup
        const schedule = rowData.scheduleData;
        const excludedDates = schedule.excluded_dates ?? [];
        const dates = [];
        let days = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];
        let daysFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
        for (let current = new Date(startDate); current <= endDate; current.setDate(current.getDate() + 1)) {
            const dayIndex = current.getDay();
            const day = days[dayIndex];
            if (schedule.merchandising_days[day].selected) {
                const selected = excludedDates.some((date) => date.getTime() === current.getTime());
                dates.push({ id: new Date(current), day: daysFull[dayIndex], selected: selected });
            }
        }

        setDates(dates);
        setExcludedDates(excludedDates);
    }, [open]);

    const onDateSelect = (rowIds) => {
        //ids = dates objects
        setExcludedDates(rowIds);
    };

    return (
        <Dialog onClose={props.handleClose} open={props.open} fullWidth={true} maxWidth="xl">
            <DialogTitle
                id="customized-dialog-title"
                onClose={props.handleClose}
                style={{ padding: "20px 56px", textAlign: "center" }}
            >
                {props.title}
            </DialogTitle>

            <DialogContent dividers style={{ padding: "16px 24px" }}>
                <EnhancedTable
                    columns={tableHeads}
                    rows={dates}
                    actions={[]}
                    updateSelected={onDateSelect}
                    toolbarDisabled
                    paginationDisabled
                    emptyMessage="No Dates"
                />
            </DialogContent>

            <DialogActions style={{ padding: "8px 24px" }}>
                <Button
                    onClick={() => props.handleAction(excludedDates)}
                    color="primary"
                    style={{ padding: "6px 0px" }}
                    className={classes.submitButton}
                >
                    {props.submitText}
                </Button>
            </DialogActions>
        </Dialog>
    );
};

export default ExcludedDaysDialog;

import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { TableRow, Tooltip } from "@material-ui/core";
import { TimeCell, ButtonCell, NumberCell, DaySelectCell, TextCell } from "../../../components-lagacy/Cell/Cells";
import { COLORS, PROJECT_TYPES } from "../../../constants/constants-lagacy";
import { formatTime } from "../../../helpers/date-utils";
import DeleteIcon from "@material-ui/icons/Delete";

const useStyles = makeStyles({
    outletBranchCell: {
        maxWidth: "250px",
        whiteSpace: "break-spaces",
    },
});

let calculateWeeklyVisits = (merchDays) => {
    let totalVisits = 0;
    for (const day in merchDays) {
        if (Object.hasOwnProperty.call(merchDays, day)) {
            totalVisits += merchDays[day].selected ? 1 : 0;
        }
    }

    return totalVisits;
};

let ScheduleRow = (props) => {
    const {
        rowData,
        onDayClick,
        onDurationChange,
        onStartTimeChange,
        projectType,
        openExtraDisplay,
        openExcludedDays,
        openProductsDialog,
        openAddSchedule,
        deleteSchedule,
    } = props;
    const classes = useStyles(props);

    //calculate the number of products in shelves
    let productsInExtraDisplay = rowData.products.reduce((acc, product) => {
        if (product.data.extra_displays.length > 0) return acc + 1;
        return acc;
    }, 0);

    const exclDates = rowData.scheduleData.excluded_dates?.length ?? 0;

    const options = rowData.scheduleData.options;
    const isGeneralMerchandisingActivated = options?.quantity?.active ?? true;
    const isOrderActivated = options?.inventory?.order ?? false;
    const isDeliveryActivated = options?.delivery?.active ?? false;

    let outletBranchNameEl = (
        <span style={{ display: "flex", alignItems: "center", justifyContent: "flex-start" }}>
            {rowData.outletBranchInfo.En_short_name}
            {isGeneralMerchandisingActivated && (
                <Tooltip title="General Merchandising" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        G.M
                    </span>
                </Tooltip>
            )}
            {isOrderActivated && (
                <Tooltip title="Re-Order" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        R-O
                    </span>
                </Tooltip>
            )}
            {isDeliveryActivated && (
                <Tooltip title="Waiting Delivery" placement="top">
                    <span
                        style={{
                            color: COLORS.submit,
                            border: "1px solid",
                            borderColor: COLORS.submit,
                            marginLeft: 5,
                            padding: 5,
                            fontSize: 11,
                            whiteSpace: "nowrap",
                        }}
                    >
                        W.D
                    </span>
                </Tooltip>
            )}
        </span>
    );

    return (
        <TableRow>
            <TextCell text={outletBranchNameEl} className={classes.outletBranchCell} />

            {/* Days Selection */}
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.sun.selected}
                handleClick={onDayClick(rowData, "sun")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.mon.selected}
                handleClick={onDayClick(rowData, "mon")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.tue.selected}
                handleClick={onDayClick(rowData, "tue")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.wed.selected}
                handleClick={onDayClick(rowData, "wed")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.thu.selected}
                handleClick={onDayClick(rowData, "thu")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.fri.selected}
                iconStyle={{ color: COLORS.submit }}
                handleClick={onDayClick(rowData, "fri")}
            />
            <DaySelectCell
                checked={rowData.scheduleData.merchandising_days.sat.selected}
                handleClick={onDayClick(rowData, "sat")}
            />

            <TextCell
                text={calculateWeeklyVisits(rowData.scheduleData.merchandising_days)}
                style={{ textAlign: "center" }}
            />

            {/* Number input */}
            <NumberCell
                number={rowData.scheduleData.duration}
                changed={onDurationChange(rowData)}
                style={{ textAlign: "center" }}
            />

            {/* Time input */}
            {projectType !== PROJECT_TYPES.SUPPLIER_OUTSOURCE ? null : (
                <TimeCell
                    id="start_time"
                    changed={onStartTimeChange(rowData)}
                    value={rowData.scheduleData.merchandising_days.sun.start_time}
                    style={{ textAlign: "center" }}
                />
            )}

            {projectType !== PROJECT_TYPES.SUPPLIER_OUTSOURCE ? null : (
                <TextCell
                    text={formatTime(rowData.scheduleData.merchandising_days.sun.end_time, false)}
                    style={{ textAlign: "center" }}
                />
            )}

            {/* <TextCell
                text={`${rowData.scheduleData.products} / ${rowData.linkedProducts.productsDocs.length}`}
                style={{ textAlign: "center" }}
            /> */}
            {/* <TextCell text={`${(outletBranch.newData.total_price.selling.total || 0).toFixed(2)} SR`} style={{ textAlign: "center" }} /> */}

            {/* Buttons */}
            <ButtonCell
                text={`Shelf (${rowData.scheduleData.products} / ${rowData.linkedProducts.productsDocs.length})`}
                colorVariant="primary"
                clicked={openProductsDialog(rowData)}
            />
            <ButtonCell
                text={`E. Display (${productsInExtraDisplay} / ${rowData.linkedProducts.productsDocs.length})`}
                colorVariant="secondary"
                clicked={openExtraDisplay(rowData)}
                disabled={rowData.extraDisplayContracts.length === 0}
            />
            <ButtonCell
                text={`Excluded Days (${exclDates})`}
                colorVariant="secondary2"
                clicked={openExcludedDays(rowData)}
            />
            <ButtonCell text={`+`} colorVariant="secondary2" clicked={openAddSchedule(rowData)} />
            {rowData.scheduleData.id !== "100" && (
                <ButtonCell
                    text={<DeleteIcon style={{ margin: 0 }} />}
                    colorVariant="secondary2"
                    clicked={() => deleteSchedule(rowData)}
                    style={{ border: "none" }}
                />
            )}
        </TableRow>
    );
};

ScheduleRow.propTypes = {
    classes: PropTypes.object,
};

export default ScheduleRow;

import { getOutletBranches, getUniqueOutletIDFromBranches } from "./Outlet_Branch";
import { getSupplierBranchInfo } from "./Supplier_Branch";
import { getProducts } from "./Product";
import { getLinkedProducts } from "./Supplier_Outlet_Product";
import { WEEKDAYS } from "../../constants/constants-lagacy";
import { cloneDeep } from "lodash";
import { getExtraDisplayContracts } from "./Contract_Extra_Display";
import { guid } from "../../helpers/firestore-utils";
import { getTomorrow } from "../../helpers/date-utils";
import { firestore as db, firebase } from "../firebase";
import "firebase/compat/storage";
import { flatten } from "lodash/fp";
import { INITIAL_SCHEDULE_OPTIONS, PLATFORM } from "../../constants/global";
import { query } from "./helpers";
import { COLLECTIONS } from "./constants";

const PROJECT_COLLECTION = "Project";

export const getSupplierProjects = async (supplierID) => {
    try {
        return (await db.collection(PROJECT_COLLECTION).where("supplier_id", "==", supplierID).get()).docs;
    } catch (error) {
        throw error;
    }
};

let getProjectOutletBranches = async (supplierBranchDoc) =>
    (await supplierBranchDoc.ref.collection("Outlet_Product").get()).docs;

let getProjectOutletBranchSchedule = async (outletBranchDoc) =>
    (await outletBranchDoc.ref.collection("Schedule").get()).docs;

let getUniqueIDsInsideProjectBranches = (projectSupplierBranches) => {
    let outletBranchesIDs = [];
    let productsIDs = [];
    for (const suppBranch of projectSupplierBranches) {
        let outletBranches = suppBranch.outletBranches;
        for (const ob of outletBranches) {
            let obDoc = ob.doc;
            let alreadyIncluded = outletBranchesIDs.includes(obDoc.id);
            if (!alreadyIncluded) outletBranchesIDs.push(obDoc.id);

            for (const schedule of ob.schedules) {
                for (const product of schedule.products) {
                    alreadyIncluded = productsIDs.includes(product.doc.id);
                    if (!alreadyIncluded) productsIDs.push(product.doc.id);
                }
            }
        }
    }

    return { outletBranchesIDs, productsIDs };
};

export let getUniqueOutletsIDsFromProject = (projectData) => {
    let outletsIDs = [];
    for (const supplierBranch of projectData.supplierBranches) {
        for (const outletBranch of supplierBranch.outletBranches) {
            let alreadyIncluded = outletsIDs.includes(outletBranch.info.data().outlet_id);
            if (!alreadyIncluded) outletsIDs.push(outletBranch.info.data().outlet_id);
        }
    }

    return outletsIDs;
};

export let getUniqueCitiesIDsFromProject = (projectData) => {
    let citiesIDs = [];
    for (const supplierBranch of projectData.supplierBranches) {
        for (const outletBranch of supplierBranch.outletBranches) {
            let alreadyIncluded = citiesIDs.includes(outletBranch.info.data().city_id);
            if (!alreadyIncluded) citiesIDs.push(outletBranch.info.data().city_id);
        }
    }

    return citiesIDs;
};

export const getProject = async (id) => {
    try {
        return await db.collection("Project").doc(id).get();
    } catch (error) {
        throw error;
    }
};

export const syncBetweenOutletBranchAndProjectOutletBranch = (outletBranch) => {
    const outletBranchData = outletBranch.info.data();
    const projectOutletBranchData = outletBranch.data;

    if (
        outletBranchData.outlet_id !== projectOutletBranchData.outlet_id ||
        `${outletBranchData.city_id}` !== `${projectOutletBranchData.city_id}` ||
        outletBranchData.class_id !== projectOutletBranchData.class_id
    ) {
        // update project outlet branch data
        projectOutletBranchData.outlet_id = outletBranchData.outlet_id;
        projectOutletBranchData.city_id = `${outletBranchData.city_id}`;
        projectOutletBranchData.class_id = outletBranchData.class_id;
        outletBranch.toBeUpdated = true;

        // update all its schedules
        for (const schedule of outletBranch.schedules) {
            schedule.data.outlet_id = projectOutletBranchData.outlet_id;
            schedule.data.city_id = projectOutletBranchData.city_id;
            schedule.data.class_id = projectOutletBranchData.class_id;
            schedule.toBeUpdated = true;
        }
    }
};

export let getWholeProject = async (projectID, relatedCollectionsIsIncluded = false) => {
    try {
        const project = await db.collection("Project").doc(projectID).get();
        const supplierBranchesDocs = (await project.ref.collection("Branch_Outlet_Product").get()).docs;

        //gather supplier branch data (firestore doc, supplier branch info and its outlet branch coverage)
        const supplierBranches = await Promise.all(
            supplierBranchesDocs.map(async (sbDoc) => {
                const sbRequests = [getProjectOutletBranches(sbDoc)];
                if (relatedCollectionsIsIncluded) sbRequests.push(getSupplierBranchInfo(sbDoc.id));

                const [outletBranchesDocs, supplierBranchInfo] = await Promise.all(sbRequests);

                // const outletBranchesDocs = (await sbDoc.ref.collection("Outlet_Product").get()).docs;
                const outletBranches = await Promise.all(
                    outletBranchesDocs.map(async (obDoc) => {
                        const schedulesDocs = await getProjectOutletBranchSchedule(obDoc);
                        const schedules = await Promise.all(
                            schedulesDocs.map(async (schDoc) => {
                                const products = (await schDoc.ref.collection("Product").get()).docs;

                                //toDate conversion
                                const data = {
                                    ...schDoc.data(),
                                    excluded_dates: schDoc.data().excluded_dates?.map((d) => d.toDate()) ?? [],
                                };

                                return {
                                    doc: schDoc,
                                    data: data,
                                    products: products.map((p) => ({ doc: p, data: p.data() })),
                                };
                            })
                        );

                        return { doc: obDoc, data: obDoc.data(), schedules: schedules };
                    })
                );

                return { doc: sbDoc, data: sbDoc.data(), outletBranches: outletBranches, info: supplierBranchInfo };
            })
        );

        if (relatedCollectionsIsIncluded) {
            //inside project's scope, get unique outlet branch IDs, product IDs to prevent duplicate requests
            const { outletBranchesIDs, productsIDs } = getUniqueIDsInsideProjectBranches(supplierBranches);
            const [outletBranchesDocs, productDocs] = await Promise.all([
                getOutletBranches(outletBranchesIDs),
                getProducts(productsIDs),
            ]);

            //get the number of linked products per outlet
            const outletIDs = getUniqueOutletIDFromBranches(outletBranchesDocs);

            const projectLinkedProducts = await Promise.all(
                outletIDs.map(async (outletID) => {
                    const linkedProducts = await getLinkedProducts(outletID, project.data().supplier_id);
                    return { outletID, productsDocs: linkedProducts };
                })
            );

            //check for extra display contracts for each outlet branch
            let extraDisplayContracts = await Promise.all(
                outletBranchesIDs.map(async (id) => getExtraDisplayContracts(project.data().supplier_id, id))
            );
            //flatten the array
            extraDisplayContracts = extraDisplayContracts.reduce((arr, contracts) => {
                contracts.forEach((c) => arr.push(c));
                return arr;
            }, []);

            //put the result in each project outlet branch
            supplierBranches.forEach((suppBranch) => {
                let outletBranches = suppBranch.outletBranches;
                for (const outletBranch of outletBranches) {
                    let obDoc = outletBranch.doc;
                    let info = outletBranchesDocs.find((doc) => doc.id === obDoc.id);
                    if (info) outletBranch.info = info;
                    else throw Error(`could not find outlet branch's info in the database (${obDoc.id})`);

                    // check if the outlet branch data is in sync with the project outlet branch and its schedules
                    // if the project is not confirmed
                    if (!project.data().isConfirmed) {
                        syncBetweenOutletBranchAndProjectOutletBranch(outletBranch);
                    }

                    let linkedProducts = projectLinkedProducts.find((lp) => lp.outletID === info.data().outlet_id);
                    outletBranch.linkedProducts = linkedProducts || [];
                    let outletBranchEDContracts = extraDisplayContracts.filter(
                        (contract) => contract.data().outlet_id === obDoc.id
                    );
                    outletBranch.extraDisplayContracts = outletBranchEDContracts;

                    for (const schedule of outletBranch.schedules) {
                        for (const product of schedule.products) {
                            info = productDocs.find((doc) => doc.id === product.doc.id);
                            if (info) product.info = info;
                            else throw Error(`could not find product's info in the database (${product.doc.id})`);
                        }
                    }
                }
            });
        }

        let data = project.data();
        data.date_from = data.date_from.toDate();
        data.date_to = data.date_to.toDate();
        data.project_date = data.project_date.toDate();

        return { doc: project, data: data, supplierBranches: supplierBranches };
    } catch (error) {
        console.log(error);
    }
};

export let initNewProjectSupplierBranchDocument = (supplierID, projectID, supplierBranchID, mpID, info = null) => {
    const data = {
        branch_id: supplierBranchID,
        project_id: projectID,
        supplier_id: supplierID,
        mp_id: mpID,
    };
    return { data, info, doc: null, outletBranches: [] };
};

export let initNewProjectOutletBranchDocument = (
    supplierID,
    mpID,
    projectID,
    supplierBranchID,
    outletBranchID,
    classID,
    outletID,
    cityID,
    schedules,
    info = null
) => {
    const data = {
        supplier_id: supplierID,
        mp_id: mpID,
        supplier_branch_id: supplierBranchID,
        project_id: projectID,
        branch_id: outletBranchID,
        class_id: classID,
        outlet_id: outletID,
        city_id: cityID,
    };
    return { data, info, doc: null, schedules: schedules, linkedProducts: null, extraDisplayContracts: [] };
};

export let initNewScheduleDocument = (
    supplierID,
    mpID,
    projectID,
    supplierBranchID,
    outletBranchID,
    classID,
    scheduleID,
    prices,
    handover = false,
    products = [],
    options = INITIAL_SCHEDULE_OPTIONS
) => {
    const startTime = new Date("2000-02-01 00:00:00");
    const endTime = new Date("2000-02-01 00:00:00");

    const data = {
        supplier_id: supplierID,
        mp_id: mpID,
        project_id: projectID,
        supplier_branch_id: supplierBranchID,
        outlet_branch_id: outletBranchID,
        class_id: classID,
        id: scheduleID,
        extra_display_contracts: [],
        has_products: products.length > 0,
        products: products.length,
        number_of_products: products.length,
        number_of_days: 0,
        total_number_of_days: 0,

        //! save everthing related to price calculation after project confirmation
        //* normal prices, extra display prices, seasons
        normal_prices: {
            regular: prices.regularPrices.length > 0 ? prices.regularPrices[0].data() : null,
            seasonal: prices.seasonalPrices,
            weekend: [],
        },
        seasons: [],
        extra_display_contracts_prices: [
            /*{
                contract: object,
                types: [{
                    number_of_products: int,
                    id: string,
                    regular: object,
                    seasonal: [objects],
                    weekend: [objects]
                }]
            }*/
        ],

        merchandising_days: {
            sun: { selected: false, start_time: startTime, end_time: endTime },
            mon: { selected: false, start_time: startTime, end_time: endTime },
            tue: { selected: false, start_time: startTime, end_time: endTime },
            wed: { selected: false, start_time: startTime, end_time: endTime },
            thu: { selected: false, start_time: startTime, end_time: endTime },
            fri: { selected: false, start_time: startTime, end_time: endTime },
            sat: { selected: false, start_time: startTime, end_time: endTime },
        },
        duration: 10,
        minimum_duration: 10,
        total_price: {
            bonus: 0,
            totalBonus: 0,
            coefficient: 0,
            handover: 0,
            cost: {
                general_merchandising: 0,
                task_per_hour: 0,
                task_per_minute: 0,
                warehouse: 0,
                extra_display: 0,
                handover: 0,
                bonus: 0,
                delivery: 0,
                re_order: 0,
                total: 0,
            },
            selling: {
                general_merchandising: 0,
                task_per_hour: 0,
                task_per_minute: 0,
                warehouse: 0,
                extra_display: 0,
                handover: 0,
                bonus: 0,
                delivery: 0,
                re_order: 0,
                total: 0,
            },
        },

        handover: handover,
        merchandiser_id: null,
        route_id: null,
        options: options,
    };

    return { doc: null, data: data, products: products };
};

export let initNewProductDocument = (productID, info = null) => {
    let data = {
        id: productID,
        number_of_shelves: 0,
        extra_displays: [], //element = {contract_id: string, extra_display_type_id: string}
        reference_id: guid(),
        reference_image: null,
    };

    return { doc: null, data: data, info: info };
};

export let getScheduleProductsByTask = async (task) => {
    try {
        const { project_id, supplier_branch_id, outlet_branch_id, schedule_id } = task;

        const products = (
            await db
                .collection("Project")
                .doc(project_id)
                .collection("Branch_Outlet_Product")
                .doc(`${supplier_branch_id}`)
                .collection("Outlet_Product")
                .doc(`${outlet_branch_id}`)
                .collection("Schedule")
                .doc(`${schedule_id}`)
                .collection("Product")
                .get()
        ).docs;
        return products;
    } catch (error) {
        throw error;
    }
};

let setProduct = async (data, scheduleRef) => {
    try {
        await scheduleRef
            .collection("Product")
            .doc(data.id)
            .set({ platform: PLATFORM, ...data });
        return await scheduleRef.collection("Product").doc(data.id).get();
    } catch (error) {
        throw error;
    }
};

let removeProducts = async (product) => {
    try {
        await product.doc.ref.delete();
    } catch (error) {
        throw error;
    }
};

let syncProduct = async (product, scheduleDoc) => {
    try {
        let updatedProductDoc = product.doc;
        if (product.toBeAdded || product.toBeUpdated) {
            updatedProductDoc = await setProduct(product.data, scheduleDoc.ref);
        } else if (product.toBeRemoved) {
            await removeProducts(product);
            return { deleted: true };
        }

        return { doc: updatedProductDoc, data: product.data, info: product.info };
    } catch (error) {
        throw error;
    }
};

export let getScheduleByTask = async (task) => {
    try {
        const { project_id, supplier_branch_id, outlet_branch_id, schedule_id } = task;

        const schedule = await db
            .collection("Project")
            .doc(project_id)
            .collection("Branch_Outlet_Product")
            .doc(`${supplier_branch_id}`)
            .collection("Outlet_Product")
            .doc(`${outlet_branch_id}`)
            .collection("Schedule")
            .doc(`${schedule_id}`)
            .get();

        return schedule;
    } catch (error) {
        throw error;
    }
};

let setSchedule = async (data, outletBranchRef) => {
    // console.log("setSchedule", data);
    try {
        await outletBranchRef
            .collection("Schedule")
            .doc(data.id)
            .set({ platform: PLATFORM, ...data });
        return await outletBranchRef.collection("Schedule").doc(data.id).get();
    } catch (error) {
        throw error;
    }
};

let removeSchedule = async (schedule) => {
    try {
        await Promise.all(schedule.products.map(removeProducts));
        await schedule.doc.ref.delete();
    } catch (error) {
        throw error;
    }
};

export const saveSchedules = async (schedules) => {
    try {
        let batch = db.batch();

        for (const sch of schedules) {
            let ref = db
                .collection(PROJECT_COLLECTION)
                .doc(sch.project_id)
                .collection(SUPPLIER_BRANCH)
                .doc(sch.supplier_branch_id)
                .collection(OUTLET_BRANCH)
                .doc(sch.outlet_branch_id)
                .collection(SCHEDULE)
                .doc(sch.id);

            batch.set(ref, { platform: PLATFORM, ...sch });
        }

        await batch.commit();
    } catch (error) {
        throw error;
    }
};

let syncSchedule = async (schedule, outletBranchDoc) => {
    try {
        let updatedScheduleDoc = schedule.doc;
        let updatedProducts = schedule.products;
        if (schedule.toBeAdded || schedule.toBeUpdated) {
            updatedScheduleDoc = await setSchedule(schedule.data, outletBranchDoc.ref);
        } else if (schedule.toBeRemoved) {
            await removeSchedule(schedule);
            return { deleted: true };
        }

        updatedProducts = await Promise.all(
            schedule.products.map(async (product) => syncProduct(product, updatedScheduleDoc))
        );
        updatedProducts = updatedProducts.filter((p) => !p.deleted);

        return { doc: updatedScheduleDoc, data: schedule.data, products: updatedProducts };
    } catch (error) {
        throw error;
    }
};

let syncAllSchedules = async (schedules, outletBranchDoc) =>
    await Promise.all(schedules.map(async (schedule) => syncSchedule(schedule, outletBranchDoc)));

let setOutletBranch = async (data, supplierBranchRef) => {
    try {
        await supplierBranchRef
            .collection("Outlet_Product")
            .doc(data.branch_id)
            .set({ platform: PLATFORM, ...data });
        return await supplierBranchRef.collection("Outlet_Product").doc(data.branch_id).get();
    } catch (error) {
        throw error;
    }
};

let removeOutletBranch = async (outletBranch) => {
    try {
        await Promise.all(outletBranch.schedules.map(removeSchedule));
        await outletBranch.doc.ref.delete();
    } catch (error) {
        throw error;
    }
};

let syncOutletBranch = async (outletBranch, supplierBranchDoc) => {
    try {
        let updatedOutletBranchDoc = outletBranch.doc;
        let updatedSchedules = outletBranch.schedules;
        if (outletBranch.toBeAdded || outletBranch.toBeUpdated) {
            updatedOutletBranchDoc = await setOutletBranch(outletBranch.data, supplierBranchDoc.ref);
        } else if (outletBranch.toBeRemoved) {
            await removeOutletBranch(outletBranch);
            return { deleted: true };
        }

        let requests = [syncAllSchedules(updatedSchedules, updatedOutletBranchDoc)];

        let linkedProducts = outletBranch.linkedProducts;
        if (!linkedProducts)
            requests.push(getLinkedProducts(outletBranch.info.data().outlet_id, outletBranch.data.supplier_id));

        let extraDisplayContracts = outletBranch.extraDisplayContracts;
        if (!extraDisplayContracts)
            requests.push(getExtraDisplayContracts(outletBranch.data.supplier_id, outletBranch.info.id));

        let results = await Promise.all(requests);
        updatedSchedules = results[0];
        linkedProducts = results[1]
            ? { outletID: outletBranch.info.data().outlet_id, productsDocs: results[1] }
            : linkedProducts;
        extraDisplayContracts = results[2] ? results[2] : extraDisplayContracts;

        updatedSchedules = updatedSchedules.filter((schedule) => !schedule.deleted);
        return {
            doc: updatedOutletBranchDoc,
            data: outletBranch.data,
            info: outletBranch.info,
            schedules: updatedSchedules,
            linkedProducts: linkedProducts,
            extraDisplayContracts: extraDisplayContracts,
        };
    } catch (error) {
        throw error;
    }
};

let setSupplierBranch = async (data, projectRef) => {
    try {
        await projectRef
            .collection("Branch_Outlet_Product")
            .doc(data.branch_id)
            .set({ platform: PLATFORM, ...data });
        return await projectRef.collection("Branch_Outlet_Product").doc(data.branch_id).get();
    } catch (error) {
        throw error;
    }
};

let removeSupplierBranch = async (supplierBranch) => {
    try {
        //remove the sub collection documents first
        await Promise.all(supplierBranch.outletBranches.map(removeOutletBranch));
        await supplierBranch.doc.ref.delete();
    } catch (error) {
        throw error;
    }
};

let syncSupplierBranch = async (supplierBranch, projectDoc) => {
    try {
        let updatedSupplierBranchDoc = supplierBranch.doc;
        let updatedOutletBranches = supplierBranch.outletBranches;
        if (supplierBranch.toBeAdded || supplierBranch.toBeUpdated) {
            updatedSupplierBranchDoc = await setSupplierBranch(supplierBranch.data, projectDoc.ref);
        } else if (supplierBranch.toBeRemoved) {
            await removeSupplierBranch(supplierBranch);
            return { deleted: true };
        }

        updatedOutletBranches = await Promise.all(
            supplierBranch.outletBranches.map(async (ob) => syncOutletBranch(ob, updatedSupplierBranchDoc))
        );
        updatedOutletBranches = updatedOutletBranches.filter((ob) => !ob.deleted);

        return {
            doc: updatedSupplierBranchDoc,
            data: supplierBranch.data,
            info: supplierBranch.info,
            outletBranches: updatedOutletBranches,
        };
    } catch (error) {
        throw error;
    }
};

let setProject = async (projectData) => {
    let updatedProjectDoc = null;
    try {
        let ref = db.collection("Project").doc(projectData.project_id);

        //set
        await ref.set({ platform: PLATFORM, ...projectData });
        //get with latest changes
        updatedProjectDoc = await ref.get();
    } catch (error) {
        // console.log(error);
        throw error;
    }
    return updatedProjectDoc;
};

let removeProject = async (project) => {
    try {
        //remove subcollections documents first
        await Promise.all(project.supplierBranches.map(removeSupplierBranch));
        await project.doc.ref.delete();
    } catch (error) {
        throw error;
    }
};
//forceRefresh is for getting the latest whole project data from database
export let syncProject = async (modifiedProject, forceRefresh = false) => {
    //if anything fails, rollback to old version
    let backup = cloneDeep(modifiedProject);
    try {
        let updatedProjectDoc = modifiedProject.doc;
        let updatedSupplierBranches = modifiedProject.supplierBranches;
        //check the flags for project document
        if (modifiedProject.toBeAdded || modifiedProject.toBeUpdated) {
            updatedProjectDoc = await setProject(modifiedProject.data);
        } else if (modifiedProject.toBeRemoved) {
            await removeProject(modifiedProject);
            return { deleted: true };
        }

        updatedSupplierBranches = await Promise.all(
            modifiedProject.supplierBranches.map(async (sb) => await syncSupplierBranch(sb, updatedProjectDoc))
        );

        updatedSupplierBranches = updatedSupplierBranches.filter((sb) => !sb.deleted);

        return { doc: updatedProjectDoc, data: modifiedProject.data, supplierBranches: updatedSupplierBranches };
    } catch (error) {
        console.log(error);
        return backup;
    }
};

export let setupScheduleRows = (projectFullData) => {
    let scheduleRows = [];
    for (const supplierBranch of projectFullData.supplierBranches) {
        for (const outletBranch of supplierBranch.outletBranches) {
            for (const schedule of outletBranch.schedules) {
                let merchandising_days = convertMerchTimeToDate(schedule.data.merchandising_days);
                scheduleRows.push({
                    supplierBranchInfo: supplierBranch.info.data(),
                    outletBranchInfo: outletBranch.info.data(),
                    scheduleData: { ...schedule.data, merchandising_days: cloneDeep(merchandising_days) },
                    products: schedule.products,
                    linkedProducts: outletBranch.linkedProducts,
                    extraDisplayContracts: outletBranch.extraDisplayContracts,
                    toBeRemoved: false,
                    toBeAdded: false,
                    toBeUpdated: false,
                });
            }
        }
    }
    return scheduleRows;
};

export let getWorkingDaysIDs = (merchandising_days) => {
    const weekday = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    let workingDaysIDs = [];
    if (merchandising_days.sun.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[0]]);
    }
    if (merchandising_days.mon.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[1]]);
    }
    if (merchandising_days.tue.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[2]]);
    }
    if (merchandising_days.wed.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[3]]);
    }
    if (merchandising_days.thu.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[4]]);
    }
    if (merchandising_days.fri.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[5]]);
    }
    if (merchandising_days.sat.selected) {
        workingDaysIDs.push(WEEKDAYS[weekday[6]]);
    }

    return workingDaysIDs;
};

let convertMerchTimeToDate = (merchandising_days) => {
    for (const dayName in merchandising_days) {
        if (Object.hasOwnProperty.call(merchandising_days, dayName)) {
            const day = merchandising_days[dayName];
            day.start_time =
                day.start_time instanceof firebase.firestore.Timestamp ? day.start_time.toDate() : day.start_time;
            day.end_time = day.end_time instanceof firebase.firestore.Timestamp ? day.end_time.toDate() : day.end_time;
        }
    }

    return merchandising_days;
};

export let calculateEndTime = (startTime, duration) => {
    let hr = startTime.getHours();
    let min = startTime.getMinutes() + hr * 60;
    let new_time_to = min + duration;
    min = new_time_to % 60;
    hr = (new_time_to - min) / 60;

    return new Date(2000, 2, 1, hr, min);
};

export let calculateDuration = (scheduleProducts) => {
    let minimumDuration = 0;
    let totalShelves = 0;
    let totalExtraDisplays = 0;
    const minutes = 1.71;
    const minutesForExtraDisplay = 10;

    // group extra displays by display_id
    let extraDisplayGroups = [];
    for (const product of scheduleProducts) {
        totalShelves += product.data.number_of_shelves;
        totalExtraDisplays += product.data.extra_displays.length;
        for (const extraDisplay of product.data.extra_displays) {
            let found = false;
            for (const group of extraDisplayGroups) {
                if (group.display_id === extraDisplay.display_id) {
                    group.extra_displays.push(extraDisplay);
                    found = true;
                    break;
                }
            }
            if (!found) {
                extraDisplayGroups.push({ display_id: extraDisplay.display_id, extra_displays: [extraDisplay] });
            }
        }
    }

    let totalProducts = totalExtraDisplays + totalShelves;

    if (totalProducts < 10) minimumDuration = Math.max(15, Math.round(totalProducts * 2.2));
    // else if (selectedProducts.length < 20) minimumDuration = Math.max(30, selectedProducts.length * 2);
    // else if (selectedProducts.length < 30) minimumDuration = Math.max(45, selectedProducts.length * 2);
    else minimumDuration = totalProducts * minutes;

    // every products in extra display multiply them by 2.2, samve as shelves
    // for (const group of extraDisplayGroups) {
    //     let totalExtraDisplays = group.extra_displays.length;
    //     minimumDuration +=
    //         totalExtraDisplays < 10 ? Math.round(Math.max(15, totalExtraDisplays * 2.2)) : totalExtraDisplays * minutes;
    // }
    minimumDuration += extraDisplayGroups.length * minutesForExtraDisplay;

    // console.log("minimumDuration", minimumDuration);
    return Math.round(minimumDuration);
};

export let checkIfADaySelected = (merchandisingDays) => {
    for (const day in merchandisingDays) {
        if (Object.hasOwnProperty.call(merchandisingDays, day)) {
            const mDay = merchandisingDays[day];
            if (mDay.selected) {
                return true;
            }
        }
    }

    return false;
};

export let validateProjectPeriod = (startDate, endDate, isConfirmed) => {
    const tmw = getTomorrow();
    // const tmw = getToday();
    if (!isConfirmed) {
        if (startDate < tmw || startDate > endDate) return false;
    } else {
        if (endDate < tmw || endDate < startDate) return false;
    }

    return true;
};

export const queryProjects = async (options) => {
    try {
        return await query(COLLECTIONS.PROJECT, options);
    } catch (error) {
        throw error;
    }
};

const SUPPLIER_BRANCH = "Branch_Outlet_Product";
const OUTLET_BRANCH = "Outlet_Product";
const SCHEDULE = "Schedule";
// const PRODUCT = "Product";

export const getSchedulesByPath = async (path) => {
    try {
        const [projectID, supplierBranchID, outletBranchID, scheduleID] = path.split(":");
        return await db
            .collection(PROJECT_COLLECTION)
            .doc(projectID)
            .collection(SUPPLIER_BRANCH)
            .doc(supplierBranchID)
            .collection(OUTLET_BRANCH)
            .doc(outletBranchID)
            .collection(SCHEDULE)
            .doc(scheduleID)
            .get();
    } catch (error) {
        throw error;
    }
};

export const getFreeSchedules = async (projectIDs, supplierBranchIDs = []) => {
    try {
        let projects = await Promise.all(projectIDs.map((id) => getProject(id)));

        let supplierBranches = await Promise.all(
            projects.map(async (pr) => {
                if (supplierBranchIDs.length > 0)
                    return (await pr.ref.collection(SUPPLIER_BRANCH).where("branch_id", "in", supplierBranchIDs).get())
                        .docs;

                return (await pr.ref.collection(SUPPLIER_BRANCH).get()).docs;
            })
        );

        supplierBranches = flatten(supplierBranches);

        let outletBranches = await Promise.all(
            supplierBranches.map(async (sb) => (await sb.ref.collection(OUTLET_BRANCH).get()).docs)
        );

        outletBranches = flatten(outletBranches);

        let schedules = await Promise.all(
            outletBranches.map(
                async (ob) => (await ob.ref.collection(SCHEDULE).where("route_id", "==", null).get()).docs
            )
        );

        return flatten(schedules);
    } catch (error) {
        throw error;
    }
};

//============== utils ================
const firestoreKeyMap = (key) => {
    switch (key) {
        case "supplier_id":
            return "supplier_id";
        case "mp_id":
            return "mp_id";
        case "date_from":
            return "date_from";
        case "date_to":
            return "date_to";

        default:
            return null;
    }
};

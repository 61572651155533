import { firestore as db } from "../firebase";
import { query } from "./helpers";

const BRANCH_COLLECTION = "Branch";

export const querySupplierBranches = async (q) => {
    try {
        return await query(BRANCH_COLLECTION, q);
    } catch (error) {
        throw error;
    }
};

export let getSupplierBranchInfo = async (id) => {
    let branch = null;

    try {
        branch = await db.collection(BRANCH_COLLECTION).doc(id).get();
    } catch (error) {
        console.log(error);
    }

    return branch;
};

export let getSupplierBranches = async (IDs) => {
    let branches = [];

    try {
        branches = await Promise.all(IDs.map(getSupplierBranchInfo));
    } catch (error) {
        console.log(error);
    }

    return branches;
};

export let getBranchesBySupplierID = async (supplierID, onlyActive = true) => {
    try {
        let branches = [];
        let ref = db.collection(BRANCH_COLLECTION).where("supplier_id", "==", supplierID);
        if (onlyActive) ref = ref.where("status", "==", "1");

        branches = (await ref.get()).docs;
        return branches;
    } catch (error) {
        throw error;
    }
};

import { Tooltip } from "@material-ui/core";
import { useCallback } from "react";
import { useState } from "react";
import { TASKS_STATUS } from "../constants/constants-lagacy";
import { TASK_TYPES } from "../constants/global";
import { useAuth } from "../contexts/auth-context";
import { formatDate } from "../helpers/date-utils";
import { getCities } from "../services/firestore/City";
import { getEDRegularPrices } from "../services/firestore/Extra_Display_Price";
// import { getMerchandisersFromTasks } from "../services/firestore/Merchandiser";
import { getOutletsFromTasks } from "../services/firestore/Outlet";
import { getOutletBranchesFromTasks } from "../services/firestore/Outlet_Branch";
import { getProducts } from "../services/firestore/Product";
// import { getSupplierBranches } from "../services/firestore/Supplier_Branch";
import { queryTasks_V2 } from "../services/firestore/Task";
import { getDetailsFromTasks } from "../services/firestore/Task_Details";
import useDashboardFilters from "./use-dashboard-filters";

const useDashboard = () => {
    const { companyData } = useAuth();

    // listeners for tasks documents
    // let unsub = useRef([]);
    // listeners for task_details documents
    // let unsubDetails = useRef([]);
    // const [taskDetailsSnapshotResults, setTaskDetailsSnapshotResults] = useState([]);
    // const [taskDetailsSnapshotsCounter, setTaskDetailsSnapshotsCounter] = useState(0);
    const { filterValues, updateFilterValues, initFilters, setQueryParams } = useDashboardFilters();

    const [tasks, setTasks] = useState([]);
    const [taskDetails, setTaskDetails] = useState([]);
    const [outlets, setOutlets] = useState([]);
    const [outletBranches, setOutletBranches] = useState([]);
    const [merchandisers, setMerchandisers] = useState([]);
    // const [dateRange, setDateRange] = useState({
    //     startDate: getToday(),
    //     endDate: getTomorrow(),
    //     key: "selection",
    // });

    // const [supplierBranchesList, setSupplierBranchesList] = useState([]);
    // const [selectedSupplierBranch, setSelectedSupplierBranch] = useState(null);

    //loading flags
    const [tasksLoading, setTasksLoading] = useState(true);
    const [taskDetailsLoading, setTaskDetailsLoading] = useState(true);
    const [loading, setLoading] = useState(true);

    //calculaions results
    const [totalQty, setTotalQty] = useState(0);
    const [outOfStockDisplays, setOutOfStockDisplays] = useState([]);

    const [damagedDisplays, setDamagedDisplays] = useState([]);
    const [damagedQty, setDamagedQty] = useState(0);

    const [expiredDisplays, setExpiredDisplays] = useState([]);
    const [expiredQty, setExpiredQty] = useState(0);

    const [nearExpiryDisplays, setNearExpiryDisplays] = useState([]);
    const [nearExpiryQty, setNearExpiryQty] = useState(0);

    //chart data
    const [skuExpiredLine, setSkuExpiredLine] = useState({});
    const [skuNearExpiryLine, setSkuNearExpiryLine] = useState({});
    const [unitsExpiredLine, setUnitsExpiredLine] = useState({});
    const [unitsNearExpiryLine, setUnitsNearExpiryLine] = useState({});

    const [outOfStockShelfLine, setOutOfStockShelfLine] = useState({});
    const [outOfStockExtraDisplayLine, setoutOfStockExtraDisplayLine] = useState({});
    const [outOfStockWarehouseLine, setOutOfStockWarehouseLine] = useState({});
    const [outOfStockTableData, setOutOfStockTableData] = useState([]);

    const getTasks = useCallback(
        async (filters) => {
            setQueryParams(filters);
            updateFilterValues(filters);
            try {
                //reset
                setTasks([]);
                setTaskDetails([]);
                setOutOfStockDisplays([]);
                setDamagedDisplays([]);
                setDamagedQty(0);
                setExpiredDisplays([]);
                setExpiredQty(0);
                setNearExpiryDisplays([]);
                setNearExpiryQty(0);
                setTotalQty(0);

                // setTasksLoading(true);
                setLoading(true);
                const { date, supplierBranch, project, outlet, outletBranch, city, product } = filters;

                let query = [
                    { key: "supplier_id", operator: "==", value: Number(companyData.company_id) },
                    { key: "date_time_from", operator: ">=", value: date.startDate },
                    { key: "date_time_from", operator: "<", value: date.endDate },
                    { key: "type", operator: "in", value: [TASK_TYPES.HANDOVER, TASK_TYPES.REGULAR] },
                ];

                if (supplierBranch)
                    query.push({ key: "supplier_branch_id", operator: "==", value: Number(supplierBranch.value) });
                if (outlet) query.push({ key: "outlet_id", operator: "==", value: Number(outlet.value) });
                if (outletBranch)
                    query.push({ key: "outlet_branch_id", operator: "==", value: Number(outletBranch.value) });
                if (city) query.push({ key: "city_id", operator: "==", value: Number(city.value) });
                if (project) query.push({ key: "project_id", operator: "==", value: project.value });

                const tasks = await queryTasks_V2(query);
                onTasksUpdate(tasks, product);
                // setTasksLoading(false);
            } catch (error) {
                // setTasksLoading(false);
                setLoading(false);
                console.log(error);
            }
        },
        [companyData.company_id]
    );

    const onTasksUpdate = async (snapshot, productFilter) => {
        try {
            // setTaskDetailsLoading(true);
            // const newTasks = snapshot.docs.map((doc) => doc.data());
            const newTasks = snapshot.map((doc) => doc.data());
            // console.log(newTasks);

            // get outlet info from tasks
            const outlets = (await getOutletsFromTasks(newTasks)).map((outlet) => outlet.data());

            // get outlet branch info from tasks
            const outletBranches = (await getOutletBranchesFromTasks(newTasks)).map((branch) => branch.data());

            // get merchandisers info from tasks
            // const newMerchandisers = await getMerchandisersFromTasks(newTasks, merchandisers);

            // get supplier branch ids
            // const branchIDs = [...new Set(newMerchandisers.map((merch) => merch.account_information.branch_id))];
            // const supplierBranches = (await getSupplierBranches(branchIDs)).map((doc) => doc.data());

            // get city ids
            const cityIDs = [...new Set(outletBranches.map((ob) => `${ob.city_id}`))];
            const cities = (await getCities(cityIDs)).map((doc) => doc.data());

            // set supplier branch in merchandiser
            // newMerchandisers.forEach(
            //     (merch) =>
            //         (merch.branch = supplierBranches.find((s) => s.branch_id === merch.account_information.branch_id))
            // );

            outletBranches.forEach((ob) => {
                ob.outlet = outlets.find((o) => `${o.outlet_id}` === `${ob.outlet_id}`);
                ob.city = cities.find((c) => `${c.city_id}` === `${ob.city_id}`);
            });

            //set outlet branch and merchandiser in task
            newTasks.forEach((task) => {
                task.outlet_branch = outletBranches.find((ob) => `${ob.branch_id}` === `${task.outlet_branch_id}`);
                task.outlet = task.outlet_branch.outlet;
                // task.merchandiser = newMerchandisers.find((m) => m.uid === task.uid) || null;
            });

            let query = [];
            if (productFilter) query.push({ key: "product_id", operator: "==", value: productFilter.value });

            // listen to task details from tasks
            //this can be muiltiple snapshot listeners for every 10 tasks
            const options = {
                // isEnded: false,
                enableOnSnapshot: false,
                // onSnapshot: (snapshot, index, length) => onTaskDetailsUpdate(snapshot, index, length),
                // onError: (error) => console.log(error),
                query,
            };
            // unsubDetails.current = await getDetailsFromTasks(newTasks, options);
            const taskDetails = await getDetailsFromTasks(newTasks, options);

            // unique product id
            const productIds = taskDetails.map((taskDetail) => taskDetail.product_id);
            const uniqueProductIds = [...new Set(productIds)];
            //get product info
            const products = (await getProducts(uniqueProductIds)).map((product) => product.data());

            // unique extra display id
            let extraDisplayTypeIds = taskDetails.filter((td) => td.extra_display).map((td) => td.extra_display_type);
            extraDisplayTypeIds = [...new Set(extraDisplayTypeIds)];
            //get extra display types info
            const extraDisplayTypes = (await getEDRegularPrices(extraDisplayTypeIds)).map((doc) => doc.data());

            const outOfStockDisplays = [];

            const damagedDisplays = [];
            let dmgQty = 0;

            const expiredDisplays = [];
            let expQty = 0;

            const nearExpiryDisplays = [];
            let nearExpQty = 0;

            let totalQty = 0;

            let skuExpiredLine = { id: "Expired SKUs", data: [] };
            let skuNearExpiredLine = { id: "Near Expiry SKUs", data: [] };
            let unitsExpiredLine = { id: "units-expired", data: [] };
            let unitsNearExpiredLine = { id: "units-near-expired", data: [] };
            // const outletStats = {};
            const outletStats = outlets.reduce((prev, curr) => {
                if (!prev[curr.outlet_id]) {
                    prev[curr.outlet_id] = {
                        outlet: curr,
                        expired: {},
                        nearExpiry: {},
                        damaged: {},
                        outOfStock: {},
                    };
                }
                return prev;
            }, {});

            let outOfStockShelfLine = { id: "Shelf", data: [], min: 0, max: 0 };
            let outOfStockExtraDisplayLine = { id: "Extra Display", data: [], min: 0, max: 0 };
            let outOfStockWarehouseLine = { id: "Warehouse", data: [], min: 0, max: 0 };
            let outOfStockTableData = [];
            const productStats = products.reduce((prev, product) => {
                if (!prev[product.product_id]) {
                    prev[product.product_id] = {
                        product,
                        outletBranches: {},

                        outOfStockShelf: {
                            outletIDs: [],
                            outletBranchIDs: [],
                            numberOfDisplays: 0,
                        },
                        outOfStockExtraDisplay: {
                            outletIDs: [],
                            outletBranchIDs: [],
                            numberOfDisplays: 0,
                        },
                        outOfStockWarehouse: {
                            outletIDs: [],
                            outletBranchIDs: [],
                        },
                    };
                }

                return prev;
            }, {});

            //! HEAVY CALCULATIONS
            //* assign product info to task details and perform calculations
            taskDetails.forEach((taskDetail) => {
                const product = products.find((product) => product.product_id === taskDetail.product_id);
                taskDetail.product_name = {
                    en: product.En_name,
                    ar: product.Ar_name,
                };

                if (taskDetail.extra_display) {
                    taskDetail.extraDisplay = extraDisplayTypes.find(
                        (ex) => ex.extra_display_price_id === taskDetail.extra_display_type
                    );
                }

                //set task
                taskDetail.task = newTasks.find((task) => `${task.task_id}` === `${taskDetail.task_id}`);
                const dateStr = formatDate(taskDetail.task.date_time_from.toDate(), "-");

                //set product stats
                const stats = productStats[taskDetail.product_id];
                let outletBranchStats = stats.outletBranches[`${taskDetail.task.outlet_branch_id}`];

                if (!outletBranchStats) {
                    outletBranchStats = {
                        outletBranch: taskDetail.task.outlet_branch,
                        displays: [taskDetail],
                        outOfStockShelf: {},
                        outOfStockExtraDisplay: {},
                        // by default is true unless ANY display has quantity added, it becomes false
                        outOfStockWarehouse: { [dateStr]: true },
                        dates: [dateStr],
                    };
                } else {
                    outletBranchStats.displays.push(taskDetail);

                    //if it is a new date, add it as new entry as true by default
                    if (!outletBranchStats.dates.find((d) => d === dateStr)) {
                        outletBranchStats.outOfStockWarehouse[dateStr] = true;
                        outletBranchStats.dates.push(dateStr);
                    }
                }

                //if quantity added !== 0, that means there is a stock in the warehouse
                //! BUT it does not indicate this could be the last quantity pulled from the warehouse
                if (taskDetail.warehouse_draw === taskDetail.warehouse_actual_draw) {
                    outletBranchStats.outOfStockWarehouse[dateStr] = false;
                }
                //is out of stock
                if (isOutOfStock(taskDetail) && taskDetail.isEnded) {
                    outOfStockDisplays.push(taskDetail);
                    //calculate stats
                    const displayType = taskDetail.extra_display ? "outOfStockExtraDisplay" : "outOfStockShelf";
                    if (taskDetail.extra_display) {
                        if (!outletBranchStats.outOfStockExtraDisplay[dateStr]) {
                            outletBranchStats.outOfStockExtraDisplay[dateStr] = [];
                        }
                        outletBranchStats.outOfStockExtraDisplay[dateStr].push(taskDetail);
                    } else {
                        if (!outletBranchStats.outOfStockShelf[dateStr]) {
                            outletBranchStats.outOfStockShelf[dateStr] = [];
                        }
                        outletBranchStats.outOfStockShelf[dateStr].push(taskDetail);
                    }

                    let outletIDs = productStats[taskDetail.product_id][displayType].outletIDs;
                    let outletBranchIDs = productStats[taskDetail.product_id][displayType].outletBranchIDs;
                    outletIDs.push(`${taskDetail.task.outlet_id}`);
                    outletBranchIDs.push(`${taskDetail.task.outlet_branch_id}`);

                    productStats[taskDetail.product_id][displayType].outletIDs = [...new Set(outletIDs)];
                    productStats[taskDetail.product_id][displayType].outletBranchIDs = [...new Set(outletBranchIDs)];
                    productStats[taskDetail.product_id][displayType].numberOfDisplays++;
                }

                productStats[taskDetail.product_id].outletBranches[`${taskDetail.task.outlet_branch_id}`] =
                    outletBranchStats;

                //calculate damaged
                if (taskDetail.removed_from?.damaged) {
                    damagedDisplays.push(taskDetail);
                    dmgQty += taskDetail.removed_from?.damaged;
                }

                //calculate expired
                if (taskDetail.removed_from?.expired) {
                    expiredDisplays.push(taskDetail);
                    expQty += taskDetail.removed_from?.expired;

                    //if outlet does not exist, init
                    if (!outletStats[`${taskDetail.task.outlet_id}`]) {
                        let newStats = {
                            outlet: taskDetail.task.outlet,
                            expired: {},
                            nearExpiry: {},
                            damaged: {},
                            outOfStock: {},
                        };

                        //init product entry
                        newStats["expired"][`${taskDetail.product_id}`] = taskDetail.removed_from?.expired;

                        //set
                        outletStats[`${taskDetail.task.outlet_id}`] = newStats;
                    } else {
                        //if sku does not exist, init
                        if (!outletStats[`${taskDetail.task.outlet_id}`]["expired"][`${taskDetail.product_id}`]) {
                            outletStats[`${taskDetail.task.outlet_id}`]["expired"][`${taskDetail.product_id}`] =
                                taskDetail.removed_from?.expired;
                        } else {
                            outletStats[`${taskDetail.task.outlet_id}`]["expired"][`${taskDetail.product_id}`] +=
                                taskDetail.removed_from?.expired;
                        }
                    }
                }

                //calculate near expired
                let nearExpiryArr = [];
                if (taskDetail.removed_from?.number_of_near_expiration > 0) {
                    let productNearExpQty = 0;
                    //change near expiry objects into array of objects and calculate the sum
                    for (const key in taskDetail.removed_from.near_expiration) {
                        if (Object.hasOwnProperty.call(taskDetail.removed_from.near_expiration, key)) {
                            const element = taskDetail.removed_from.near_expiration[key];
                            nearExpQty += element.quantity;
                            productNearExpQty += element.quantity;
                            nearExpiryArr.push({
                                date: element.date?.toDate() || "Not Set",
                                quantity: element.quantity,
                            });
                        }
                    }
                    nearExpiryDisplays.push(taskDetail);
                    //if outlet does not exist, init
                    if (!outletStats[`${taskDetail.task.outlet_id}`]) {
                        let newStats = {
                            outlet: taskDetail.task.outlet,
                            expired: {},
                            nearExpiry: {},
                            damaged: {},
                            outOfStock: {},
                        };

                        //init product entry
                        newStats["nearExpiry"][`${taskDetail.product_id}`] = productNearExpQty;

                        //set
                        outletStats[`${taskDetail.task.outlet_id}`] = newStats;
                    } else {
                        //if sku does not exist, init
                        if (!outletStats[`${taskDetail.task.outlet_id}`]["nearExpiry"][`${taskDetail.product_id}`]) {
                            outletStats[`${taskDetail.task.outlet_id}`]["nearExpiry"][`${taskDetail.product_id}`] =
                                productNearExpQty;
                        } else {
                            outletStats[`${taskDetail.task.outlet_id}`]["nearExpiry"][`${taskDetail.product_id}`] +=
                                productNearExpQty;
                        }
                    }
                }
                taskDetail.nearExpiry = nearExpiryArr;

                //calculate total Qty
                totalQty += taskDetail.quantity_on + taskDetail.quantity_added;
            });

            //if task details listeners are empty, there is no task details to listen to
            // if (unsubDetails.current.length === 0) {
            // setTaskDetailsLoading(false);
            // }

            skuExpiredLine.data = Object.keys(outletStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === outletStats[curr].outlet.En_short_name)) {
                    prev.push({
                        x: outletStats[curr].outlet.En_short_name,
                        y: Object.keys(outletStats[curr].expired).length,
                    });
                }

                return prev;
            }, []);

            skuNearExpiredLine.data = Object.keys(outletStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === outletStats[curr].outlet.En_short_name)) {
                    prev.push({
                        x: outletStats[curr].outlet.En_short_name,
                        y: Object.keys(outletStats[curr].nearExpiry).length,
                    });
                }
                return prev;
            }, []);

            unitsExpiredLine.data = Object.keys(outletStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === outletStats[curr].outlet.En_short_name)) {
                    prev.push({
                        x: outletStats[curr].outlet.En_short_name,
                        y: Object.values(outletStats[curr].expired).reduce((sum, curr) => sum + curr, 0),
                    });
                }

                return prev;
            }, []);

            unitsNearExpiredLine.data = Object.keys(outletStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === outletStats[curr].outlet.En_short_name)) {
                    prev.push({
                        x: outletStats[curr].outlet.En_short_name,
                        y: Object.values(outletStats[curr].nearExpiry).reduce((sum, curr) => sum + curr, 0),
                    });
                }
                return prev;
            }, []);

            outOfStockShelfLine.data = Object.keys(productStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === productStats[curr].product.En_name)) {
                    const y = productStats[curr].outOfStockShelf.numberOfDisplays;
                    outOfStockShelfLine.max = outOfStockShelfLine.max >= y ? outOfStockShelfLine.max : y;
                    prev.push({
                        x: productStats[curr].product.En_name,
                        y,
                    });
                }
                return prev;
            }, []);

            outOfStockExtraDisplayLine.data = Object.keys(productStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === productStats[curr].product.En_name)) {
                    const y = productStats[curr].outOfStockExtraDisplay.numberOfDisplays;
                    outOfStockExtraDisplayLine.max =
                        outOfStockExtraDisplayLine.max >= y ? outOfStockExtraDisplayLine.max : y;
                    prev.push({
                        x: productStats[curr].product.En_name,
                        y,
                    });
                }
                return prev;
            }, []);

            outOfStockWarehouseLine.data = Object.keys(productStats).reduce((prev, curr) => {
                if (!prev.find((item) => item.x === productStats[curr].product.En_name)) {
                    const arr = Object.values(productStats[curr].outletBranches);
                    const frequancy = arr.reduce((prev, curr) => {
                        const outOfStockWarehouse = Object.values(curr.outOfStockWarehouse);
                        const times = outOfStockWarehouse.filter((bool) => bool).length;
                        return prev + times;
                    }, 0);
                    outOfStockWarehouseLine.max =
                        outOfStockWarehouseLine.max >= frequancy ? outOfStockWarehouseLine.max : frequancy;
                    prev.push({
                        x: productStats[curr].product.En_name,
                        y: frequancy,
                    });
                }
                return prev;
            }, []);

            outOfStockTableData = Object.values(productStats).reduce((prev, curr) => {
                let productRows = [];
                let outletBranches = Object.values(curr.outletBranches);
                for (const ob of outletBranches) {
                    for (const dateStr of ob.dates) {
                        const shelves = ob.outOfStockShelf[dateStr] ?? [];
                        const extraDisplays = ob.outOfStockExtraDisplay[dateStr] ?? [];
                        const extraDisplaysStr = extraDisplays.reduce((str, ex) => {
                            return `${str} - ${ex.extraDisplay.extra_display_type}`;
                        }, "");
                        const warehouse = ob.outOfStockWarehouse[dateStr];

                        // const totalShelves = ob.displays.filter(dis => formatDate(dis.task.date_time_from.toDate(), "-") === dateStr && !dis.extra_display);
                        const totalExtraDisplays = ob.displays.filter(
                            (dis) => formatDate(dis.task.date_time_from.toDate(), "-") === dateStr && dis.extra_display
                        ).length;

                        if (shelves.length <= 0 && extraDisplays.length <= 0 && !warehouse) {
                            continue;
                        }
                        //find displays on the same date
                        productRows.push({
                            id: `${curr.product.product_id}-${ob.outletBranch.branch_id}-${dateStr}`,
                            product: curr.product.En_name,
                            outlet: ob.outletBranch.outlet.En_short_name,
                            outletBranch: ob.outletBranch.En_short_name,
                            city: ob.outletBranch.city.En_name,
                            date: dateStr,
                            shelf: (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: shelves.length > 0 ? "red" : "transparent",
                                    }}
                                ></div>
                            ),
                            extraDisplays: (
                                <Tooltip title={`${extraDisplaysStr}`}>
                                    <div
                                        style={{
                                            width: "100%",
                                            height: "100%",
                                            color: "white",
                                            display: "flex",
                                            justifyContent: "center",
                                            alignItems: "center",
                                            backgroundColor:
                                                extraDisplays.length === 0 || totalExtraDisplays === 0
                                                    ? "transparent"
                                                    : extraDisplays.length < totalExtraDisplays
                                                    ? "darkorange"
                                                    : "red",
                                        }}
                                    >
                                        {extraDisplays.length} / {totalExtraDisplays}
                                    </div>
                                </Tooltip>
                            ),
                            warehouse: (
                                <div
                                    style={{
                                        width: "100%",
                                        height: "100%",
                                        backgroundColor: warehouse ? "red" : "transparent",
                                    }}
                                ></div>
                            ),
                        });
                    }
                }

                return [...prev, ...productRows];
            }, []);

            setTasks(newTasks);
            setOutlets(outlets);
            setOutletBranches(outletBranches);
            // setMerchandisers(newMerchandisers);
            // setTasksLoading(false);

            // setTaskDetailsLoading(false);

            setTaskDetails(taskDetails);

            setOutOfStockDisplays(outOfStockDisplays);
            setDamagedDisplays(damagedDisplays);
            setDamagedQty(dmgQty);
            setExpiredDisplays(expiredDisplays);
            setExpiredQty(expQty);
            setNearExpiryDisplays(nearExpiryDisplays);
            setNearExpiryQty(nearExpQty);

            setTotalQty(totalQty + dmgQty + expQty);

            setSkuExpiredLine(skuExpiredLine);
            setSkuNearExpiryLine(skuNearExpiredLine);
            setUnitsExpiredLine(unitsExpiredLine);
            setUnitsNearExpiryLine(unitsNearExpiredLine);

            setOutOfStockShelfLine(outOfStockShelfLine);
            setoutOfStockExtraDisplayLine(outOfStockExtraDisplayLine);
            setOutOfStockWarehouseLine(outOfStockWarehouseLine);

            setOutOfStockTableData(outOfStockTableData);

            setLoading(false);
        } catch (error) {
            // setTasksLoading(false);
            setLoading(false);
            console.log(error);
        }
    };

    // const onTasksError = (error) => {
    //     setTasksLoading(false);
    //     console.log(error);
    // };

    // const onTaskDetailsUpdate = async (snapshot, index, length) => {
    //     const taskDetails = snapshot.docs.map((doc) => doc.data());
    //     // set task details in listeners results array so we can fletten it later
    //     setTaskDetailsSnapshotResults((prev) => {
    //         let newResults = [...prev];
    //         newResults[index] = taskDetails;
    //         return newResults;
    //     });
    //     //increment counter
    //     setTaskDetailsSnapshotsCounter((prev) => prev + 1);
    // };

    // const init = useCallback(async () => {
    //     try {
    //         const supplierBranches = (await getBranchesBySupplierID(companyData.company_id)).map((doc) => ({
    //             data: doc.data(),
    //             label: doc.data().En_name,
    //             value: doc.id,
    //         }));

    //         setSupplierBranchesList(supplierBranches);
    //     } catch (error) {
    //         console.log(error);
    //     }
    // }, []);

    // useEffect(() => {
    //     getTasks(dateRange.startDate, dateRange.endDate, selectedSupplierBranch);
    // }, [getTasks, dateRange.startDate, dateRange.endDate, selectedSupplierBranch]);

    // useEffect(() => {
    //     init();
    // }, []);

    // flatten task details results (because we have multiple snapshot listeners for every 10 tasks)
    // useEffect(() => {
    //     if (taskDetailsSnapshotResults.length > 0) {
    //         // flatten snapshotResults
    //         const flat = taskDetailsSnapshotResults.reduce((acc, curr) => acc.concat(curr ?? []), []);
    //         setTaskDetails(flat);
    //     }
    // }, [taskDetailsSnapshotResults]);

    // wait for all task details to be loaded
    // useEffect(() => {
    //     if (taskDetailsSnapshotsCounter >= unsubDetails.current?.length) {
    //         setTaskDetailsLoading(false);
    //     }
    // }, [taskDetails]);

    //on unmount effect
    // useEffect(() => {
    //     return () => {
    //         //unsubscribe
    //         unsub.current?.();
    //         unsubDetails.current.forEach((unsub) => unsub?.());
    //     };
    // }, []);

    //calculations
    const isOutOfStock = (display) => {
        return display.quantity_on + display.quantity_added === 0;
    };

    const getAvailabilityRate = () => {
        if (taskDetails.length <= 0) return 0;
        return (outOfStockDisplays.length / taskDetails.length) * 100;
    };

    const getExpiredOrDamagedRate = () => {
        if (totalQty <= 0) return 0;
        const sum = damagedQty + expiredQty;
        const total = totalQty;

        return (sum / total) * 100;
    };

    const getNearExpiryRate = () => {
        if (totalQty <= 0) return 0;
        return (nearExpiryQty / totalQty) * 100;
    };

    const getCompletedTasksRate = () => {
        if (tasks.length <= 0) return 0;
        return (
            (tasks.filter((task) =>
                [TASKS_STATUS.AUDITING, TASKS_STATUS.FINISHED, TASKS_STATUS.FINISHED].includes(task.state)
            ).length /
                tasks.length) *
            100
        );
    };

    return {
        loading,
        tasks,
        getTasks,
        taskDetails,
        outlets,
        outletBranches,
        // merchandisers,
        filterValues,
        updateFilterValues,
        initFilters,
        setQueryParams,

        totalQty,
        outOfStockDisplays,
        damagedDisplays,
        damagedQty,
        expiredDisplays,
        expiredQty,
        nearExpiryDisplays,
        nearExpiryQty,

        skuExpiredLine,
        skuNearExpiryLine,
        unitsExpiredLine,
        unitsNearExpiryLine,

        outOfStockShelfLine,
        outOfStockExtraDisplayLine,
        outOfStockWarehouseLine,

        outOfStockTableData,

        getAvailabilityRate,
        getExpiredOrDamagedRate,
        getNearExpiryRate,
        getCompletedTasksRate,
    };
};

export default useDashboard;

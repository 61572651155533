import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { DatePicker, MuiPickersUtilsProvider } from "@material-ui/pickers";
import DateFnsUtils from "@date-io/date-fns";

const useStyles = makeStyles({
    label: {
        color: "#000000",
        "&$disabled": {
            color: "#000000",
        },
    },
    input: {
        fontWeight: "600",
        backgroundColor: "transparent",
        height: "100%",
        "&$disabled": {
            fontWeight: "600",
            color: "#000000",
            backgroundColor: "white",
            "&::before": {
                border: 0,
            },
        },
    },
    //override with an empty style so the styles above take precedence
    disabled: {},
});

let CustomDatePicker = (props) => {
    const classes = useStyles(props);
    return (
        <MuiPickersUtilsProvider style={{ selectColor: "#91041C" }} utils={DateFnsUtils}>
            <DatePicker
                fullWidth
                multiline
                inputVariant="filled"
                format="dd/MM/yyyy"
                // margin="dense"
                style={{ color: "#000000", fontWeight: "600" }}
                InputProps={{
                    classes: {
                        root: classes.input,
                        disabled: classes.disabled,
                    },
                }}
                InputLabelProps={{
                    classes: {
                        root: classes.label,
                        disabled: classes.disabled,
                    },
                }}
                {...props.rootProps}
            />
        </MuiPickersUtilsProvider>
    );
};

CustomDatePicker.propTypes = {
    classes: PropTypes.object,
};

export default CustomDatePicker;

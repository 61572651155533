import { Button } from "@material-ui/core";
import React, { forwardRef } from "react";
import styles from "./styles";

const ButtonWrapper = (props, ref) => {
    const { children, className, buttonVariant = "default", ...otherProps } = props;
    const classes = styles();

    const variants = {
        default: classes.buttonRoot,
        cancel: `${classes.buttonRoot} ${classes.cancel}`,
        transparent: `${classes.buttonRoot} ${classes.transparent}`,
    };

    const config = {
        ...otherProps,
        className: `${variants[buttonVariant]} ${className}`,
        classes: {
            disabled: classes.disabled,
        },
    };
    return (
        <Button innerRef={ref} {...config}>
            {children}
        </Button>
    );
};

export default forwardRef(ButtonWrapper);

import React from "react";
import { Page, Text, View, Document, Image } from "@react-pdf/renderer";
import styles from "../../core-ui/core-styles/pdf/general";
import logo from "../../assets/images/logo.png";
import { formatTime, formatDate } from "../../helpers/date-utils";

let rowsPerPage = 17;
let totalPages = 0;
const dayNames = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
const tableHeaders = [
    {
        group: true,
        cells: [
            [{ text: "Product", style: {} }],
            [
                { text: "Code", style: { flex: 1 } },
                { text: "Description", style: { flex: 2 } },
            ],
        ],
        style: { flex: 2 },
    },
    {
        group: true,
        cells: [
            [{ text: "Stock Position (Pc's)", style: {} }],
            [
                { text: "Damage", style: {} },
                { text: "Expiry", style: {} },
                { text: "Near Expiry", style: {} },
            ],
        ],
        style: {},
    },
    { group: false, cells: "Near Expiry Date", style: { flex: 1 } },
    { group: false, cells: "Remarks", style: { flex: 2 } },
];

let createCellGroup = (rows, style) => (
    <View key={`table-${Math.random()} group-${Math.random()}`} style={[...style, styles.cellGroup]}>
        {rows.map((cells, rowIndex) => (
            <View
                key={`table-row${rowIndex} group-${Math.random()}`}
                style={rowIndex + 1 === rows.length ? [styles.row, styles.lastRow] : styles.row}
            >
                {cells.map((cellObj, cellIndex) => (
                    <View
                        key={`table-cell${cellIndex} group-${Math.random()}`}
                        style={
                            cellIndex + 1 === cells.length
                                ? [styles.cell, styles.lastCell, cellObj.style]
                                : [styles.cell, cellObj.style]
                        }
                    >
                        <View style={{ height: "20px", display: "flex", justifyContent: "center" }}>
                            <Text>{cellObj.text}</Text>
                        </View>
                    </View>
                ))}
            </View>
        ))}
    </View>
);

let createRow = (row, style = styles.row) => {
    return (
        <View style={style} key={`table Row ${Math.random()}`}>
            {row.cells.map((cellObj, index) => {
                const cellStyle =
                    index + 1 === tableHeaders.length
                        ? [styles.cell, styles.lastCell, cellObj.style]
                        : [styles.cell, cellObj.style];
                if (cellObj.group) return createCellGroup(cellObj.cells, cellStyle);
                else
                    return (
                        <View key={`table-row-${index} single`} style={cellStyle}>
                            <Text>{cellObj.cells}</Text>
                        </View>
                    );
            })}
        </View>
    );
};

let createTableHeader = () => {
    return (
        <View style={styles.tableHeader}>
            <View style={[styles.row, styles.headerRow]}>
                {tableHeaders.map((headerObj, index) => {
                    const style =
                        index + 1 === tableHeaders.length
                            ? [styles.cell, styles.lastCell, headerObj.style]
                            : [styles.cell, headerObj.style];
                    if (headerObj.group) return createCellGroup(headerObj.cells, style);
                    else
                        return (
                            <View key={`table-header-${index} single`} style={style}>
                                <Text>{headerObj.cells}</Text>
                            </View>
                        );
                })}
            </View>
        </View>
    );
};

let createPage = (info, rows, pageNum) => {
    const { name, mpName, projectID, taskID, date, city, outletBranch, exportedBy } = info;
    const day = dayNames[date.getDay()];

    let rowsToDisplay = rows.slice(pageNum * rowsPerPage, pageNum * rowsPerPage + rowsPerPage);

    const companyLogo = info.companyData.logo;
    const companyName = info.companyData.company_name;

    return (
        <Page size="A4" orientation="portrait" style={styles.page} key={`PDF ${Math.random()}`}>
            {/* Page Header */}
            <View style={styles.logoContainer}>
                {/* <Image style={styles.logo} src={logo} /> */}
                {companyLogo && <Image style={styles.companyLogo} src={companyLogo} />}
                <Text style={styles.title}>Products Expiry & Damage Report</Text>
                <View style={[styles.supplierHeaderText, !companyLogo ? styles.alignStart : ""]}>
                    <Text style={styles.supplierText}>Supplier</Text>
                    <Text style={styles.supplierName}>{companyName}</Text>
                </View>
            </View>

            <View style={styles.line}></View>

            <View style={styles.headerContainer}>
                <View style={styles.left}>
                    <Text>
                        Supplier: <Text style={styles.bold}>{name}</Text>
                    </Text>
                    {mpName && (
                        <Text>
                            Merchandising Provider: <Text style={styles.bold}>{mpName}</Text>
                        </Text>
                    )}
                    <Text>
                        Project No.: <Text style={styles.bold}>{projectID}</Text>
                    </Text>
                    <Text>
                        Visit No.: <Text style={styles.bold}>{taskID}</Text> <Text style={styles.red}>|</Text> Visit
                        Date: <Text style={styles.bold}>{formatDate(date)}</Text> <Text style={styles.red}>|</Text>{" "}
                        Visit Day: <Text style={styles.bold}>{day}</Text>
                    </Text>
                    <Text>
                        City: <Text style={styles.bold}>{city}</Text> <Text style={styles.red}>|</Text> Outlet Branch:{" "}
                        <Text style={styles.bold}>{outletBranch}</Text>
                    </Text>
                </View>
            </View>

            {/* Page Body */}
            <View style={styles.table}>
                {/* Table header */}
                {createTableHeader()}

                {/* Table Body */}
                <View style={styles.tableBody}>
                    {rowsToDisplay.map((row, index) => {
                        let style = [styles.row];
                        if (index === rowsToDisplay.length - 1) style.push(styles.lastRow);
                        if (index % 2 === 0) style.push(styles.coloredRow);

                        return createRow(row, style);
                    })}
                </View>
            </View>

            {/* Page Footer */}
            <View style={styles.footer}>
                <Text>Issue Date: {formatDate(new Date())}</Text>
                <Text>Issue Time: {formatTime(new Date())}</Text>
                <Text>Issued By: {exportedBy}</Text>
            </View>
            <Text
                style={styles.pageNumber}
                render={({ pageNumber, totalPages }) => `${pageNumber} / ${totalPages}`}
                fixed
            />

            <View style={styles.logoFooterContainer}>
                <Image style={styles.logo} src={logo} />
                <Text style={styles.logoFooterText}>Murattab</Text>
            </View>
        </Page>
    );
};

let createProductRows = (product) => {
    let filteredRows = [];
    const damaged = product.removedFromShelf.damaged + product.removedFromExtraDisplay.damaged;
    const expiry = product.removedFromShelf.expired + product.removedFromExtraDisplay.expired;
    const remarks = product.remarks || "";

    for (const nearExp of product.removedFromShelf.near_expiration) {
        let quantity = nearExp.quantity;
        let date = nearExp.date ? formatDate(nearExp.date.toDate()) : "-";

        if (!quantity) continue;

        let data = {
            damaged,
            expiry,
            date,
            nearExpiry: quantity,
        };

        if (filteredRows.length > 0) {
            data.damaged = "-";
            data.expiry = "-";
        }

        let index = filteredRows.findIndex((row) => row.date === data.date);
        if (index !== -1) {
            filteredRows[index].nearExpiry += quantity;
        } else {
            filteredRows.push(data);
        }
    }

    for (const nearExp of product.removedFromExtraDisplay.near_expiration) {
        let quantity = nearExp.quantity;
        let date = nearExp.date ? formatDate(nearExp.date.toDate()) : "-";

        if (!quantity) continue;

        let data = {
            damaged,
            expiry,
            date,
            nearExpiry: quantity,
        };

        if (filteredRows.length > 0) {
            data.damaged = "-";
            data.expiry = "-";
        }

        let index = filteredRows.findIndex((row) => row.date === data.date);
        if (index !== -1) {
            filteredRows[index].nearExpiry += quantity;
        } else {
            filteredRows.push(data);
        }
    }

    if (filteredRows.length <= 0 && (damaged || expiry)) {
        filteredRows.push({ damaged, expiry, date: "-", nearExpiry: "-" });
    }

    for (const rowData of filteredRows) {
        rowData.cells = [
            {
                group: true,
                cells: [
                    [
                        { text: product.code, style: { flex: 1 } },
                        { text: product.name, style: { flex: 2 } },
                    ],
                ],
                style: { flex: 2 },
            },
            {
                group: true,
                cells: [
                    [
                        { text: rowData.damaged || "-", style: {} },
                        { text: rowData.expiry || "-", style: {} },
                        { text: rowData.nearExpiry || "-", style: {} },
                    ],
                ],
                style: {},
            },
            { group: false, cells: rowData.date, style: { flex: 1 } },
            { group: false, cells: remarks, style: { flex: 2 } },
        ];
    }

    return filteredRows;
};

let createDoc = (info) => {
    let rows = info.products.map(createProductRows).filter((productRows) => productRows.length > 0);
    //flatten the array into 1 dimension
    rows = rows.reduce((arr, productRows) => [...arr, ...productRows], []);

    totalPages = Math.ceil(rows.length / rowsPerPage);
    let pages = [];
    for (let pageNum = 0; pageNum < totalPages; pageNum++) {
        pages[pageNum] = createPage(info, rows, pageNum);
    }

    return pages;
};

// Create Document Component
const ProductExpiryAndDamagePdf = (props) => {
    let document = createDoc(props.info);

    return <Document>{document}</Document>;
};

export default ProductExpiryAndDamagePdf;

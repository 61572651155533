import React from "react";

//related to meterial ui package
import PropTypes from "prop-types";
import { makeStyles } from "@material-ui/core/styles";
import { generatePDF } from "../../helpers/pdf-utils";
import { Button, darken } from "@material-ui/core";
import GetAppIcon from "@material-ui/icons/GetApp";
import { COLORS } from "../../constants/constants-lagacy";

const useStyles = makeStyles({
    button: {
        textTransform: "none",
        padding: 10,
        paddingLeft: 20,
        paddingRight: 20,
        fontWeight: "400",
        cursor: "pointer",
        color: "white",
        backgroundColor: COLORS.primary,
        width: "100%",
        "&:hover": {
            backgroundColor: darken(COLORS.primary, 0.2),
            color: "#ffffff",
        },
    },

    disabled: {
        backgroundColor: "gray",
        color: "black",
    },
});

let PDFButton = (props) => {
    const defaultClasses = useStyles(props);
    const { pdfComponent, filename, label, classes, ...other } = props;
    return (
        <Button
            onClick={() => generatePDF(props.pdfComponent, props.filename)}
            // className={`${classes.button} ${props.className ? props.className : ""}`}
            classes={{ root: defaultClasses.button, disabled: defaultClasses.disabled, ...classes }}
            {...other}
        >
            {props.label} <GetAppIcon />
        </Button>
    );
};

PDFButton.propTypes = {
    classes: PropTypes.object,
};

export default PDFButton;

export let formatDate = (date, separator = ".", reversed = false) => {
    if (!date) return "";
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();

    if (reversed) return `${year}${separator}${month}${separator}${day}`;
    return `${day}${separator}${month}${separator}${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};

export let dateRangeOverlaps = (a_start, a_end, b_start, b_end) => {
    if (a_start <= b_start && b_start <= a_end) return true; // b starts in a
    if (a_start <= b_end && b_end <= a_end) return true; // b ends in a
    if (b_start <= a_start && a_end <= b_end) return true; // a in b
    return false;
};

export let formatTime = (time, ampm = true) => {
    let hour = time.getHours();
    let minutes = time.getMinutes();
    let ampmText = "";

    if (ampm) {
        ampmText = hour < 12 ? "AM" : "PM";
        hour = hour % 12;
        hour = hour || 12; //if hour == 0, make it 12
    }

    //prefix 0 if needed
    hour = hour < 10 ? `0${hour}` : hour;
    minutes = minutes < 10 ? `0${minutes}` : minutes;

    return ampm ? `${hour}:${minutes} ${ampmText}` : `${hour}:${minutes}`;
};

export let getToday = () => {
    let today = new Date();
    today.setHours(0, 0, 0, 0);
    return today;
};

export let getTomorrow = () => {
    let tomorrow = new Date();
    tomorrow.setDate(tomorrow.getDate() + 1);
    tomorrow.setHours(0, 0, 0, 0);
    return tomorrow;
};

export let getDayName = (weekdayIndex, fullName = true) => {
    const weekdayFull = ["Sunday", "Monday", "Tuesday", "Wednesday", "Thursday", "Friday", "Saturday"];
    const weekdayShort = ["sun", "mon", "tue", "wed", "thu", "fri", "sat"];

    if (fullName) return weekdayFull[weekdayIndex];

    return weekdayShort[weekdayIndex];
};

export const dayNameToIndex = (dayName) => {
    switch (dayName.toLowerCase()) {
        case "sun":
        case "sunday":
            return 0;
        case "mon":
        case "monday":
            return 1;
        case "tue":
        case "tuesday":
            return 2;
        case "wed":
        case "wednesday":
            return 3;
        case "thu":
        case "thursday":
            return 4;
        case "fri":
        case "friday":
            return 5;
        case "sat":
        case "saturday":
            return 6;
        default:
            return -1;
    }
};

export let convertMinutesToHoursAndMinutes = (n) => {
    let num = n;
    let hours = num / 60;
    let rhours = Math.floor(hours);
    let minutes = (hours - rhours) * 60;
    let rminutes = Math.round(minutes);

    let string = ``;
    if (rhours > 0) string += `${rhours} hours`;
    if (rminutes > 0) string += ` ${rminutes} minutes`;

    return string;
};

export let getRelativeDateFromToday = (after = 1) => {
    const date = getToday();
    date.setDate(date.getDate() + after);
    return date;
};

export const getDaysSinceSpecificDate = (date) => {
    const now = new Date();
    const diff = now.getTime() - date.getTime();
    const diffDays = Math.ceil(diff / (1000 * 3600 * 24));

    return diffDays;
};

export const calculateTimeDuration = (start, end) => {
    let duration = Math.round(Math.abs(end - start) / 1000 / 60);
    return duration;
};

export let timestamp = (time) => {
    let date = time.toDate();
    return date.getHours() + ":" + date.getMinutes() + ":" + date.getSeconds();
};

export const isValidDate = (date) => {
    if (!date) return false;
    //in case date is string
    date = new Date(date);
    if (Object.prototype.toString.call(date) === "[object Date]") {
        // it is a date
        if (isNaN(date)) {
            // d.getTime() or d.valueOf() will also work
            // date object is not valid
            return false;
        } else {
            // date object is valid
            return true;
        }
    } else {
        // not a date object
        return false;
    }
};

export const getDatesfromRange = (start, end, frequancy = "weekly", selectedWeekDays = []) => {
    let dates = [];

    if (start > end) return dates;

    if (frequancy === "weekly") {
        //tranform dates into weeks starting from sunday and ending with saturday
        let weeks = [];
        for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
            let currentWeekIndex = weeks.length > 0 ? weeks.length - 1 : 0;
            // if 0 (= sunday local time) OR beginning of the loop, create new week
            if (current.getDay() === 0 || current.getTime() === start.getTime()) {
                currentWeekIndex = weeks.length;
                weeks.push([]);
            }

            //check if the current date is one of the selected week days
            if (selectedWeekDays.find((sel) => sel === current.getDay())) {
                //insert current date into the current week
                weeks[currentWeekIndex].push(new Date(current));
            }
        }

        //select one latest date for each week
        for (const week of weeks) {
            if (week.length > 0) dates.push(week[week.length - 1]);
        }
    } else if (frequancy === "monthly") {
        //tranform dates into months
        let months = [];
        for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
            let currentMonthIndex = months.length > 0 ? months.length - 1 : 0;
            // if 1 (= beginning of the month) OR beginning of the loop, create new month
            if (current.getDate() === 1 || current.getTime() === start.getTime()) {
                currentMonthIndex = months.length;
                months.push([]);
            }

            //check if the current date is one of the selected week days
            if (selectedWeekDays.find((sel) => sel === current.getDay())) {
                //insert current date into the current week
                months[currentMonthIndex].push(new Date(current));
            }
        }

        //select one middle date for each month
        for (const month of months) {
            if (month.length > 0) dates.push(month[Math.ceil(month.length / 2)]);
        }
    }
    // else {
    //     for (let current = new Date(start); current <= end; current.setDate(current.getDate() + 1)) {
    //         //check if the current date is one of the selected week days
    //         if (selectedWeekDays.find((sel) => sel === current.getDay())) {
    //             //insert current date into dates
    //             dates.push(new Date(current));
    //         }
    //     }
    // }

    return dates;
};

import { makeStyles } from "@material-ui/core";
import COLORS from "../../../constants/colors";

const borderStyle = `1px ${COLORS.NAV_COLOR_2} solid`;

export default makeStyles({
    secondMenu: {
        position: "absolute",
        top: "51px",
        left: "-38px",
        width: "85%",
        borderRight: borderStyle,
        borderBottom: borderStyle,
        backgroundColor: COLORS.NAV_COLOR_1,
        zIndex: 100,
        // margin: 0,
        // padding: " 10px 38px",
        paddingLeft: "38px",
    },

    "@media screen and (min-width: 1000px)": {
        secondMenu: {
            top: "37px",
            left: "-1px",
            width: "38vw",
            maxWidth: "400px",
            height: "auto",
            maxHeight: "calc(100vh - 101px)",
            borderLeft: borderStyle,
            padding: 0,
            overflowX: "hidden",
            // maxWidth: "230px",
            // padding: " 10px 38px 10px 0px",
        },
    },

    /*
    "@media screen and (min-width: 1200px)": {
        secondMenu: {
            top: "55px",
            left: "-1px",
            width: "38vw",
            maxWidth: "400px",
            height: "auto",
            maxHeight: "calc(100vh - 101px)",
            borderLeft: borderStyle,
            padding: 0,
            overflowX: "hidden",
            // maxWidth: "230px",
            // padding: " 10px 38px 10px 0px",
        },
    },
    */

    "@media screen and (min-width: 1400px)": {
        secondMenu: {
            top: "42px",
        },
    },
});

import * as Yup from "yup";
import { DISTRIBUTION_METHODS } from "../../constants/global";

const objectRules = (object, rule) => Object.keys(object).reduce((newMap, key) => ({ ...newMap, [key]: rule }), {});

export const FORM_SCHEMA = Yup.object().shape({
    distribution_method: Yup.lazy((obj) =>
        Yup.object().shape({
            ...objectRules(obj, Yup.mixed().oneOf(Object.values(DISTRIBUTION_METHODS), "Invalid method")),
        })
    ),
});

export const INITIAL_VALUES = {
    distribution_method: {
        //outlet_id: "dsd" || "dc"
    },
};

import { useCallback, useState } from "react";
import { useAuth } from "../../contexts/auth-context";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { queryLinkedOutlets } from "../../services/firestore/Linked_Outlets";
import { getSupplierProducts } from "../../services/firestore/Product";
import { queryLinkedOutletBranches, updateLinkedOutletBranch } from "../../services/firestore/Linked_Outlet_Branches";
import { getOutletBranch } from "../../services/firestore/Outlet_Branch";
import { useSearchParams } from "react-router-dom-v5-compat";
import { STATUS } from "../../constants/global";
import { cloneDeep } from "lodash";
import { getOutlet } from "../../services/firestore/Outlet";
import { INITIAL_VALUES } from "./schema";
import COLORS from "../../constants/colors";
import styles from "./styles";
import Swal from "sweetalert2";

const FILTERS = {
    supplierBranch: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outlet: {
        label: "Outlet",
        options: [],
        initialValue: null,
        onChange: (value, context) => {},
    },
    outletBranch: {
        label: "Outlet Branch",
        options: [],
        initialValue: null,
    },
};

const useProductStockSettings = () => {
    const classes = styles();
    const { companyData } = useAuth();
    const [searchParams] = useSearchParams();

    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [linkedOutletBranchesList, setLinkedOutletBranchesList] = useState([]);
    const [products, setProducts] = useState([]);
    const [selectedLinkedOutletBranch, setSelectedLinkedOutletBranch] = useState(null);

    const [initialValues, setInitialValues] = useState({
        ...INITIAL_VALUES,
    });

    const [alertState, setAlertState] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const setAlertOpen = (open) => {
        setAlertState({ ...alertState, open });
    };

    const applyFilters = useCallback(
        async (values, context) => {
            try {
                setLoading(true);
                const { outletBranch, outlet } = values;

                //reset
                setSelectedLinkedOutletBranch(null);

                if (!outletBranch?.value || linkedOutletBranchesList.length <= 0) {
                    setLoading(false);
                    return;
                }

                const latestOutletBranch = (await queryLinkedOutletBranches({ docID: outletBranch.data.id })).data();
                // let latestOutlet = outlet?.data ?? null;
                // if (!latestOutlet) {
                //     latestOutlet = await queryLinkedOutlets([
                //         { key: "supplier_id", operator: "==", value: companyData.company_id },
                //         { key: "outlet_id", operator: "==", value: outletBranch.data.outlet_id },
                //         { key: "status", operator: "==", value: STATUS.ACTIVE },
                //     ]);
                //     if (latestOutlet.length > 0) latestOutlet = latestOutlet[0]?.data();
                //     else latestOutlet = null;
                // }

                // formik initial values
                setInitialValues({
                    products: {
                        ...Object.keys(latestOutletBranch.products).reduce(
                            (formProducts, productID) => ({
                                ...formProducts,
                                [productID]: { ...latestOutletBranch.products[productID] },
                            }),
                            {}
                        ),
                    },
                });

                setSelectedLinkedOutletBranch(latestOutletBranch);
                setLoading(false);
            } catch (error) {
                setLoading(false);
                throw error;
            }
        },
        [linkedOutletBranchesList.length]
    );

    const loadLinkedOutletBranches = useCallback(
        async (supplierBranchID = null, outletID = null) => {
            try {
                const { company_id } = companyData;
                let query = [
                    { key: "supplier_id", operator: "==", value: company_id },
                    { key: "status", operator: "==", value: STATUS.ACTIVE },
                ];

                if (!supplierBranchID && !outletID) return [];

                if (supplierBranchID) {
                    query.push({ key: "supplier_branch_id", operator: "==", value: supplierBranchID });
                }
                if (outletID) {
                    query.push({ key: "outlet_id", operator: "==", value: outletID });
                }

                let linkedOutletBranchesList = await queryLinkedOutletBranches(query);
                linkedOutletBranchesList = await Promise.all(
                    linkedOutletBranchesList.map(async (lob) => {
                        const outletBranch = (await getOutletBranch(lob.data().outlet_branch_id)).data();
                        const data = { ...lob.data(), outletBranch };
                        return {
                            label: outletBranch.En_short_name,
                            value: outletBranch.branch_id,
                            data: data,
                        };
                    })
                );

                //this is where you store options and products
                setLinkedOutletBranchesList(linkedOutletBranchesList);
                return linkedOutletBranchesList;
            } catch (error) {
                console.log(error);
            }
        },
        [companyData]
    );

    const handleSupplierBranchChange = useCallback(
        async (option, context) => {
            try {
                const supplierBranchID = option?.value;
                const outletID = context.filters.outlet.value?.value;
                if (!supplierBranchID && !outletID) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "outletBranch" } });
                    return;
                }
                const linkedOutletBranchesList = await loadLinkedOutletBranches(supplierBranchID, outletID);

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "outletBranch", options: linkedOutletBranchesList },
                });
            } catch (error) {
                console.log(error);
            }
        },
        [loadLinkedOutletBranches]
    );

    const handleOutletChange = useCallback(
        async (option, context) => {
            try {
                const outletID = option?.value;
                const supplierBranchID = context.filters.supplierBranch.value?.value;
                if (!supplierBranchID && !outletID) {
                    context.dispatchFilters({ type: "CLEAR_FILTER", payload: { id: "outletBranch" } });
                    return;
                }
                const linkedOutletBranchesList = await loadLinkedOutletBranches(supplierBranchID, outletID);

                context.dispatchFilters({
                    type: "SET_FILTER_OPTIONS",
                    payload: { id: "outletBranch", options: linkedOutletBranchesList },
                });
            } catch (error) {
                console.log(error);
            }
        },
        [loadLinkedOutletBranches]
    );

    const init = useCallback(async () => {
        try {
            const { company_id } = companyData;

            //filters
            let [branchesList, linkedOutlets, products] = await Promise.all([
                getBranchesBySupplierID(company_id),
                queryLinkedOutlets([
                    { key: "supplier_id", operator: "==", value: company_id },
                    { key: "status", operator: "==", value: STATUS.ACTIVE },
                ]),
                getSupplierProducts(company_id),
            ]);

            branchesList = branchesList.map((branch) => ({
                value: branch.id,
                label: branch.data().En_name,
                data: branch.data(),
            }));

            //doc to list item and get outlets data
            const linkedOutletsList = await Promise.all(
                linkedOutlets.map(async (o) => {
                    const outlet = (await getOutlet(o.data().outlet_id)).data();
                    const data = { ...o.data(), outlet };
                    return {
                        value: data.outlet_id,
                        label: outlet.En_short_name,
                        data,
                    };
                })
            );

            setProducts(products.map((doc) => doc.data()));
            return { branchesList, linkedOutletsList };
        } catch (error) {
            throw error;
        }
    }, [companyData]);

    //entry point after load
    const initFilters = useCallback(
        async (context) => {
            try {
                const { branchesList, linkedOutletsList } = await init();
                const filters = cloneDeep(FILTERS);

                filters.supplierBranch.options = branchesList;
                filters.outlet.options = linkedOutletsList;
                filters.supplierBranch.onChange = handleSupplierBranchChange;
                filters.outlet.onChange = handleOutletChange;

                //check if there is any search params in URL
                if (searchParams.get("supplier_branch")) {
                    const id = searchParams.get("supplier_branch");
                    filters.supplierBranch.initialValue = branchesList.find((b) => b.value === id);
                }
                if (searchParams.get("outlet")) {
                    const id = searchParams.get("outlet");
                    filters.outlet.initialValue = linkedOutletsList.find((b) => b.value === id);
                }

                //load outlet branch options
                if (filters.supplierBranch.initialValue !== null || filters.outlet.initialValue !== null) {
                    const options = await loadLinkedOutletBranches(
                        filters.supplierBranch.initialValue,
                        filters.outlet.initialValue
                    );
                    filters.outletBranch.options = options;
                    if (searchParams.get("outlet_branch")) {
                        const id = searchParams.get("outlet_branch");
                        filters.outletBranch.initialValue = options.find((sc) => sc.value === id) || null;
                    }
                }

                context.setFilters(filters);
            } catch (error) {
                console.error(error);
            }
            setInitializing(false);
        },
        [searchParams, init, handleOutletChange, handleSupplierBranchChange, loadLinkedOutletBranches]
    );

    // formik
    const handleSubmit = async (values, helpers) => {
        try {
            setSaving(true);
            const data = { ...values };
            const updatedProducts = cloneDeep(data.products);
            updateLinkedOutletBranch(selectedLinkedOutletBranch.id, {
                products: updatedProducts,
            });

            await Swal.fire({
                title: `Saved Successfully!`,
                text: `Products' stock settings has beeen updated`,
                icon: "success",
                confirmButtonColor: COLORS.SUBMIT,
                customClass: { container: classes.swal },
            });
        } catch (error) {
            console.log(error);
        }
        setSaving(false);
    };

    return {
        initializing,
        loading,
        saving,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,

        products,
        initialValues,
        handleSubmit,
    };
};

export default useProductStockSettings;

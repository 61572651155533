import React from "react";
import {
    Checkbox,
    CircularProgress,
    makeStyles,
    Paper,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TableRow,
    TextField,
    Tooltip,
} from "@material-ui/core";
import EnhancedTableToolbar from "./EnhancedTableToolbar";
import EnhancedTableHead from "./EnhancedTableHead";
import { COLORS } from "../../constants/constants-lagacy";
import moment from "moment";
import { EnhancedToolip } from "../../components";

// =================== Utilities =================== //
let formatDate = (date) => {
    const day = addLeadingZero(date.getDate());
    const month = addLeadingZero(date.getMonth() + 1);
    const year = date.getFullYear();
    return `${day}.${month}.${year}`;
};

let addLeadingZero = (number) => {
    return number < 10 ? "0" + number : number;
};
function desc(a, b, orderBy) {
    let valA = a[orderBy];
    let valB = b[orderBy];

    if (orderBy === "date") {
        valA = moment(a, "dd/mm/yyyy").toDate();
        valB = moment(b, "dd/mm/yyyy").toDate();
    }
    if (valB < valA) {
        return -1;
    }
    if (valB > valA) {
        return 1;
    }
    return 0;
}

function stableSort(array, cmp) {
    const stabilizedThis = array.map((el, index) => [el, index]);
    stabilizedThis.sort((a, b) => {
        const order = cmp(a[0], b[0]);
        if (order !== 0) return order;
        return a[1] - b[1];
    });
    return stabilizedThis.map((el) => el[0]);
}

function getSorting(order, orderBy) {
    return order === "desc" ? (a, b) => desc(a, b, orderBy) : (a, b) => -desc(a, b, orderBy);
}

// =================== Utilities =================== //

const useStyles = makeStyles((theme) => ({
    root: {
        width: "100%",
        marginTop: theme.spacing(3),
        boxShadow: "0 0 3px lightgrey",
    },
    paper: {
        width: "100%",
        marginBottom: theme.spacing(2),
    },
    table: {
        minWidth: 280,
        tableLayout: "auto",
        // whiteSpace: "nowrap",
    },
    tableWrapper: {
        overflowX: "auto",
    },

    row: {
        backgroundColor: "white",
    },
    visuallyHidden: {
        border: 0,
        clip: "rect(0 0 0 0)",
        height: 1,
        margin: -1,
        overflow: "hidden",
        padding: 0,
        position: "absolute",
        top: 20,
        width: 1,
    },
}));

function EnhancedTable(props) {
    const {
        title,
        columns,
        rows,
        upperPinnedRow,
        pinnedRow,
        actions,
        updateSelected,
        handleShowDeleteAlertMethod,
        handleTaskDetailsMethod,
        selectionDisabled,
        toolbarDisabled = false,
        paginationDisabled = false,
        stickyHeader = false,
        emptyMessage,
        loading,
    } = props;
    const classes = useStyles();
    const [order, setOrder] = React.useState("asc");
    const [orderBy, setOrderBy] = React.useState("date");
    const [selected, setSelected] = React.useState(rows.filter((row) => row.selected).map((row) => row.id));
    const [selectedStatus, setSelectedStatus] = React.useState(rows);
    // const [selectedRow, setSelectedRow] = React.useState([]);
    const [page, setPage] = React.useState(0);
    // const [dense, setDense] = React.useState(true);
    const dense = true;
    let [rowsPerPage, setRowsPerPage] = React.useState(10);

    rowsPerPage = paginationDisabled ? rows.length : rowsPerPage;

    function handleRequestSort(event, property) {
        const isDesc = orderBy === property && order === "desc";
        setOrder(isDesc ? "asc" : "desc");
        setOrderBy(property);
    }

    function handleSelectAllClick(event) {
        if (event.target.checked) {
            const newSelecteds = rows.map((row) => row.id);
            setSelected(newSelecteds);
            updateSelected(newSelecteds);

            return;
        }
        setSelected([]);
        updateSelected([]);
    }

    function handleClick(event, id, state) {
        const selectedIndex = selected.indexOf(id);
        const selectedStatusIndex = selectedStatus.indexOf(state);
        let newSelected = [];
        let newSelectedStatus = [];

        if (selectedIndex === -1) {
            newSelected = newSelected.concat(selected, id);
        } else if (selectedIndex === 0) {
            newSelected = newSelected.concat(selected.slice(1));
        } else if (selectedIndex === selected.length - 1) {
            newSelected = newSelected.concat(selected.slice(0, -1));
        } else if (selectedIndex > 0) {
            newSelected = newSelected.concat(selected.slice(0, selectedIndex), selected.slice(selectedIndex + 1));
        }
        setSelected(newSelected);
        updateSelected(newSelected);
        // setSelectedRow(id);

        if (selectedStatusIndex === -1) {
            newSelectedStatus = newSelectedStatus.concat(selectedStatus, state);
        } else if (selectedStatusIndex === 0) {
            newSelectedStatus = newSelectedStatus.concat(selectedStatus.slice(1));
        } else if (selectedStatusIndex === selectedStatus.length - 1) {
            newSelectedStatus = newSelectedStatus.concat(selectedStatus.slice(0, -1));
        } else if (selectedStatusIndex > 0) {
            newSelectedStatus = newSelectedStatus.concat(
                selectedStatus.slice(0, selectedStatusIndex),
                selectedStatus.slice(selectedStatusIndex + 1)
            );
        }
        setSelectedStatus(newSelectedStatus);
    }

    function handleChangePage(event, newPage) {
        setPage(newPage);
    }

    function handleChangeRowsPerPage(event) {
        setRowsPerPage(+event.target.value);
        setPage(0);
    }

    // function handleChangeDense(event) {
    //     setDense(event.target.checked);
    // }

    function generateRow(row, index, isPinned = false) {
        const isItemSelected = isSelected(row.id);
        const labelId = `enhanced-table-checkbox-${index}`;
        const cellStyles = row.cellStyles ? row.cellStyles : {};
        const cellProps = row.cellProps ? row.cellProps : {};

        return (
            <TableRow
                className={classes.row}
                hover
                role="checkbox"
                aria-checked={isItemSelected}
                tabIndex={-1}
                key={`${row.id} - ${row.type}`}
                selected={isItemSelected}
                style={
                    isPinned
                        ? {
                              borderTop: "2px solid black",
                              borderBottom: "2px solid black",
                              backgroundColor: "#91041c30",
                          }
                        : {}
                }
            >
                {selectionDisabled ? null : (
                    <TableCell padding="checkbox" onClick={(event) => handleClick(event, row.id, row.state)}>
                        <Checkbox
                            style={{
                                // color: "rgba(145, 4, 28)",
                                color: COLORS.primary,
                                //boxShadow: "0 4px 20px 0px rgba(0, 0, 0, 0.14), 0 7px 12px -5px rgba(244, 67, 54, 0.46)"
                            }}
                            //labelStyle={{color: "#d50000"}}
                            //iconStyle={{fill: "#d50000"}}
                            checked={isItemSelected}
                            inputProps={{ "aria-labelledby": labelId }}
                        />
                    </TableCell>
                )}
                {columns.map((column) => {
                    const textOverflowStyle = column.textEllepsis
                        ? {
                              textOverflow: "ellipsis",
                              whiteSpace: "nowrap",
                              overflow: "hidden",
                              maxWidth: "0",
                          }
                        : {};
                    let data = row[column.id] instanceof Date ? formatDate(row[column.id]) : row[column.id];
                    let cellValue = data;

                    if (column.input) {
                        cellValue = (
                            <TextField
                                value={row[column.id]}
                                onChange={(event) => column.inputChanged(event, row.id)}
                            />
                        );
                    }

                    if (column.textEllepsis) {
                        cellValue = (
                            <EnhancedToolip title={data} placement="top" arrow>
                                <span>{data}</span>
                            </EnhancedToolip>
                        );
                    }
                    return column.hidden || row[column.id] === null ? null : (
                        <TableCell
                            key={column.id}
                            onClick={(event) => {
                                if (column.click) {
                                    column.click(event, row.id);
                                }
                            }}
                            padding={column.disablePadding ? "none" : "normal"}
                            style={{
                                cursor: column.click ? "pointer" : "auto",
                                ...cellStyles[column.id],
                                ...textOverflowStyle,
                            }}
                            {...cellProps[column.id]}
                        >
                            {cellValue}
                        </TableCell>
                    );
                })}
            </TableRow>
        );
    }

    const isSelected = (id) => selected.indexOf(id) !== -1;

    // const emptyRows = rowsPerPage - Math.min(rowsPerPage, rows.length - page * rowsPerPage);

    return (
        <div style={{ padding: "0px", margin: "0px", width: "100%" }} className={classes.root}>
            {/* <Paper style={{ padding: "0px", margin: "0px", width: "100%" }} className={classes.paper}> */}
            {toolbarDisabled ? null : (
                <EnhancedTableToolbar
                    title={title}
                    actions={actions}
                    selectedRowIds={selected}
                    selectedRowStatus={selectedStatus}
                    numSelected={selected.length}
                    handleShowDeleteAlertMethod={handleShowDeleteAlertMethod}
                    handleTaskDetailsMethod={handleTaskDetailsMethod}
                />
            )}
            <div className={classes.tableWrapper}>
                <Table
                    className={`${classes.table} ${props.classes?.table}`}
                    aria-labelledby="tableTitle"
                    size={dense ? "small" : "medium"}
                >
                    <EnhancedTableHead
                        columns={columns}
                        classes={classes}
                        numSelected={selected.length}
                        order={order}
                        orderBy={orderBy}
                        onSelectAllClick={handleSelectAllClick}
                        onRequestSort={handleRequestSort}
                        rowCount={rows.length}
                        selectionDisabled={selectionDisabled}
                        stickyHeader={stickyHeader}
                    />
                    {loading ? (
                        <TableBody>
                            <TableRow className={classes.row}>
                                <TableCell
                                    style={{ textAlign: "center", padding: "5px" }}
                                    colSpan={columns.length + (selectionDisabled ? 0 : 1)}
                                >
                                    <CircularProgress />
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    ) : (
                        <TableBody>
                            {/* Pinned Row that appears on every page */}
                            {upperPinnedRow &&
                            Object.keys(upperPinnedRow).length > 0 &&
                            upperPinnedRow.constructor === Object &&
                            page === 0
                                ? generateRow(upperPinnedRow, -2, true)
                                : null}

                            {/* Normal Rows */}
                            {stableSort(rows, getSorting(order, orderBy))
                                .slice(page * rowsPerPage, page * rowsPerPage + rowsPerPage)
                                .map((row, index) => {
                                    return generateRow(row, index);
                                })}

                            {/* if the table is empty after loading */}
                            {rows.length === 0 ? (
                                <TableRow className={classes.row}>
                                    <TableCell
                                        style={{ textAlign: "center", padding: "15px" }}
                                        colSpan={columns.length + (selectionDisabled ? 0 : 1)}
                                    >
                                        {emptyMessage ?? "No Data"}
                                    </TableCell>
                                </TableRow>
                            ) : null}
                            {/* {emptyRows > 0 && (
                                <TableRow style={{ height: 49 * emptyRows }}>
                                    <TableCell colSpan={6} />
                                </TableRow>
                            )} */}

                            {/* Pinned Row that appears on every page */}
                            {pinnedRow &&
                            Object.keys(pinnedRow).length > 0 &&
                            pinnedRow.constructor === Object &&
                            page * rowsPerPage + rowsPerPage >= rows.length
                                ? generateRow(pinnedRow, -1, true)
                                : null}
                        </TableBody>
                    )}
                </Table>
            </div>
            {paginationDisabled ? null : (
                <TablePagination
                    rowsPerPageOptions={[2, 5, 10, 25]}
                    component="div"
                    count={rows.length}
                    rowsPerPage={rowsPerPage}
                    page={page}
                    backIconButtonProps={{
                        "aria-label": "previous page",
                    }}
                    nextIconButtonProps={{
                        "aria-label": "next page",
                    }}
                    onPageChange={handleChangePage}
                    onRowsPerPageChange={handleChangeRowsPerPage}
                />
            )}
            {/* </Paper> */}
        </div>
    );
}

export default EnhancedTable;

import { cloneDeep } from "lodash";
import { useCallback, useState } from "react";
import { STATUS } from "../../constants/global";
import { useAuth } from "../../contexts/auth-context";
import useTransferList from "../../hooks/use-transfer-list";
import { queryLinkedOutlets } from "../../services/firestore/Linked_Outlets";
import { getOutlet } from "../../services/firestore/Outlet";
import { getBranchesBySupplierID } from "../../services/firestore/Supplier_Branch";
import { getAllCities } from "../../services/firestore/City";
import { useSearchParams } from "react-router-dom-v5-compat";
// import { migrateBranchOutletBranch, migrateSupplierOutletProduct } from "../../services/migration";
import {
    addLinkedOutletBranch,
    queryLinkedOutletBranches,
    updateLinkedOutletBranch,
} from "../../services/firestore/Linked_Outlet_Branches";
import { getOutletBranch, queryOutletBranches } from "../../services/firestore/Outlet_Branch";
import { linkOutletBranches, unlinkOutletBranches } from "../../services/firestore/Branch_Outlet_Branch";

const FILTERS = {
    supplierBranch: {
        label: "Supplier Branch",
        options: [],
        initialValue: null,
    },
    outlet: {
        label: "Outlet",
        options: [],
        initialValue: null,
    },
    city: {
        label: "City",
        options: [],
        initialValue: null,
    },
};

const useLinkOutletBranches = () => {
    const { companyData } = useAuth();

    const [searchParams] = useSearchParams();

    const [linkedOutletBranches, setLinkedOutletBranches] = useState([]);
    const [inactiveLinkedOutletBranches, setInactiveLinkedOutletBranches] = useState([]);

    // const { INITIAL_LIST_ITEM, transferListState, dispatchTransferListState, transferHandler, filterHandler } =
    //     useTransferList();
    const {
        INITIAL_LIST_ITEM,
        leftList,
        setLeftList,
        rightList,
        setRightList,
        checkedList,
        setCheckedList,
        transferHandler,
        filterHandler,
    } = useTransferList();

    const [alertState, setAlertState] = useState({
        open: false,
        message: "",
        severity: "info",
    });

    const [initializing, setInitializing] = useState(true);
    const [loading, setLoading] = useState(false);
    const [saving, setSaving] = useState(false);

    const [selectedSupplierBranch, setSelectedSupplierBranch] = useState(null);

    const setAlertOpen = (open) => {
        setAlertState({ ...alertState, open });
    };

    const lagacySave = async () => {
        try {
            const { company_id } = companyData;

            // save the items that exist in the new list but not exist in the old one
            const toBeSaved = rightList.filter(
                (item) => !linkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id)
            );
            // delete the items that exist in the old list but not exist in the new one
            const toBeDeleted = leftList.filter((item) =>
                linkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id)
            );

            const outletBranchIDs = toBeSaved.map((item) => item.id);
            await linkOutletBranches(company_id, selectedSupplierBranch.branch_id, outletBranchIDs);

            //delete
            await unlinkOutletBranches(
                selectedSupplierBranch.branch_id,
                toBeDeleted.map((d) => d.id)
            );
        } catch (error) {
            throw error;
        }
    };

    const saveHandler = async () => {
        setSaving(true);
        try {
            if (!selectedSupplierBranch) {
                return;
            }

            //!lagacy
            await lagacySave();
            // const { leftList, rightList } = transferListState;

            // save the items that exist in the new list but not exist in the old one
            // and does not have a document in firestore
            let toBeAdded = rightList.filter(
                (item) =>
                    !linkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id) &&
                    !inactiveLinkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id)
            );
            //add new docs
            toBeAdded = await Promise.all(
                toBeAdded.map(async (listItem) => {
                    let data = {
                        ...listItem.data,
                        supplier_branch_id: selectedSupplierBranch.branch_id,
                        status: STATUS.ACTIVE,
                    };
                    data = await addLinkedOutletBranch(data);
                    listItem.data = data;
                    return listItem;
                })
            );

            // save the items that exist in the new list but not exist in active list
            // and have a document in firestore
            let toBeActive = rightList.filter(
                (item) =>
                    !linkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id) &&
                    inactiveLinkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id)
            );
            //update existing docs
            toBeActive = await Promise.all(
                toBeActive.map(async (listItem) => {
                    let data = {
                        supplier_branch_id: selectedSupplierBranch.branch_id,
                        status: STATUS.ACTIVE,
                    };
                    await updateLinkedOutletBranch(listItem.data.id, data);
                    listItem.data.status = STATUS.ACTIVE;
                    listItem.data.supplier_branch_id = selectedSupplierBranch.branch_id;
                    return listItem;
                })
            );

            // deactivate the items that exist in the left list and exist in the active linked outlets
            let toBeInactive = leftList.filter((item) =>
                linkedOutletBranches.map((i) => i.outlet_branch_id).includes(item.id)
            );
            //update existing docs
            toBeInactive = await Promise.all(
                toBeInactive.map(async (listItem) => {
                    let data = {
                        supplier_branch_id: null,
                        status: STATUS.INACTIVE,
                    };
                    await updateLinkedOutletBranch(listItem.data.id, data);
                    listItem.data.status = STATUS.INACTIVE;
                    listItem.data.supplier_branch_id = null;
                    return listItem;
                })
            );

            //update the lists
            const newLinkedOutletBranches = cloneDeep(linkedOutletBranches);
            const newInactiveLinkedOutletBranches = cloneDeep(inactiveLinkedOutletBranches);

            //in active linked outlet, delete toBeInactive
            toBeAdded.forEach((item) => {
                newLinkedOutletBranches.push(item.data);
            });
            toBeActive.forEach((item) => {
                const index = newInactiveLinkedOutletBranches.findIndex((o) => o.id === item.data.id);
                //from inactive to active
                newLinkedOutletBranches.push(newInactiveLinkedOutletBranches[index]);
                //remove inactive
                newInactiveLinkedOutletBranches.splice(index, 1);
            });
            toBeInactive.forEach((item) => {
                const index = newLinkedOutletBranches.findIndex((o) => o.id === item.data.id);
                //from active to inactive
                newInactiveLinkedOutletBranches.push(newLinkedOutletBranches[index]);
                //remove active
                newLinkedOutletBranches.splice(index, 1);
            });

            setLinkedOutletBranches(newLinkedOutletBranches);
            setInactiveLinkedOutletBranches(newInactiveLinkedOutletBranches);

            setAlertState({
                open: true,
                message: "Successfully saved",
                severity: "success",
            });
        } catch (error) {
            console.error(error);
        }
        setSaving(false);
    };

    const applyFilters = async (values, context) => {
        try {
            setLoading(true);
            const { company_id } = companyData;
            const { supplierBranch, outlet, city } = values;
            const outletsList = context.filters.outlet.options;
            const citiesList = context.filters.city.options;

            //reset
            setLeftList([]);
            setRightList([]);
            setCheckedList([]);
            setSelectedSupplierBranch(null);

            if (!supplierBranch?.value || outletsList.length <= 0) {
                setLoading(false);
                return;
            }

            let outletBranchQuery = [];

            let linkedOutletBranchQuery = [
                { key: "supplier_id", operator: "==", value: company_id },
                // { key: "supplier_branch_id", operator: "==", value: supplierBranch.value },
            ];

            if (outlet) {
                linkedOutletBranchQuery.push({ key: "outlet_id", operator: "==", value: outlet.value });
                outletBranchQuery.push({ key: "outlet_id", operator: "==", value: outlet.value });
            } else {
                outletBranchQuery.push({ key: "outlet_id", operator: "in", value: outletsList.map((o) => o.value) });
            }

            if (city) {
                linkedOutletBranchQuery.push({ key: "city_id", operator: "==", value: city.value });
                outletBranchQuery.push({ key: "city_id", operator: "==", value: Number(city.value) });
            }

            //master array
            let outletBranches = (await queryOutletBranches(outletBranchQuery)).map((doc) => {
                return {
                    ...doc.data(),
                    outlet: outletsList.find((c) => `${c.value}` === `${doc.data().outlet_id}`)?.data ?? null,
                    city: citiesList.find((c) => `${c.value}` === `${doc.data().city_id}`)?.data ?? null,
                };
            });

            //helper array to know which outlet branch is active
            let linkedOutletBranches = await queryLinkedOutletBranches(linkedOutletBranchQuery);

            //remove outlet branches that are linked to other supplier branches from both arrays
            const otherLinkedOutletBranches = linkedOutletBranches.filter(
                (lob) => lob.data().supplier_branch_id && lob.data().supplier_branch_id !== supplierBranch.value
            );
            outletBranches = outletBranches.filter(
                (ob) => !otherLinkedOutletBranches.find((lob) => lob.data().outlet_branch_id === ob.branch_id)
            );
            linkedOutletBranches = linkedOutletBranches.filter(
                (lob) => !lob.data().supplier_branch_id || lob.data().supplier_branch_id === supplierBranch.value
            );

            //doc to data and get outlet branches' data
            linkedOutletBranches = await Promise.all(
                linkedOutletBranches.map(async (lob) => {
                    let outletBranch = outletBranches.find((ob) => ob.branch_id === lob.data().outlet_branch_id);
                    //outlet branch is not found for the following reasons:
                    //1) linked outlet branch exists but not its outlet is not linked with supplier
                    //2) linked outlet branch exists and its outlet is linked with supplier but no active
                    //hence get it from firestore in push it into outletBranches array
                    if (!outletBranch) {
                        outletBranch = (await getOutletBranch(lob.data().outlet_branch_id)).data();
                        outletBranch.outlet = (await getOutlet(outletBranch.outlet_id)).data();
                        outletBranch.city =
                            citiesList.find((c) => `${c.value}` === `${outletBranch.city_id}`)?.data ?? null;
                    }
                    return {
                        ...lob.data(),
                        outlet_branch: outletBranch,
                    };
                })
            );

            let inactiveLinkedOutletBranches = linkedOutletBranches.filter((lob) => lob.status === STATUS.INACTIVE);
            linkedOutletBranches = linkedOutletBranches.filter((lob) => lob.status === STATUS.ACTIVE);

            //setup transfer list
            const rightList = linkedOutletBranches.map((lob) => {
                let item = cloneDeep(INITIAL_LIST_ITEM);
                item.id = lob.outlet_branch_id;
                item.data = lob;
                item.primaryLabel = lob.outlet_branch.En_short_name;
                item.secondaryLabel = `${lob.outlet_branch.outlet.En_short_name} - ${lob.outlet_branch.city.En_name}`;
                return item;
            });

            const leftList = outletBranches.reduce((prev, current) => {
                const exist = rightList.find((r) => r.id === current.branch_id);
                if (exist) return prev;
                let item = cloneDeep(INITIAL_LIST_ITEM);
                const lob = inactiveLinkedOutletBranches.find((o) => o.outlet_branch_id === current.branch_id) ?? {
                    id: null,
                    supplier_id: company_id,
                    // null because it is not linked yet, same as other inactive linked outlet branches
                    supplier_branch_id: null,
                    outlet_id: current.outlet_id,
                    outlet_branch_id: current.branch_id,
                    class_id: current.class_id,
                    city_id: current.city_id,
                    products: {},
                    status: STATUS.INACTIVE,
                };
                prev.push({
                    ...item,
                    id: current.branch_id,
                    data: lob,
                    primaryLabel: current.En_short_name,
                    secondaryLabel: `${current.outlet.En_short_name} - ${current.city.En_name}`,
                });
                return prev;
            }, []);

            setLinkedOutletBranches(linkedOutletBranches);
            setInactiveLinkedOutletBranches(inactiveLinkedOutletBranches);
            //set state
            // dispatchTransferListState({
            //     type: "INIT",
            //     payload: {
            //         leftList,
            //         rightList,
            //         checkedList: [],
            //     },
            // });
            setLeftList(leftList);
            setRightList(rightList);
            setCheckedList([]);
            setSelectedSupplierBranch(supplierBranch.data);
            setLoading(false);
        } catch (error) {
            setLoading(false);
            throw error;
        }
    };

    const loadFiltersOptions = useCallback(async () => {
        try {
            // await migrateSupplierOutletProduct();
            // await migrateBranchOutletBranch();
            const { company_id } = companyData;

            //filters
            let [branchesList, linkedOutlets, citiesList] = await Promise.all([
                getBranchesBySupplierID(company_id),
                queryLinkedOutlets([
                    { key: "supplier_id", operator: "==", value: company_id },
                    { key: "status", operator: "==", value: STATUS.ACTIVE },
                ]),
                getAllCities(),
            ]);

            //doc to data get outlets data
            linkedOutlets = await Promise.all(
                linkedOutlets.map(async (o) => {
                    const outlet = (await getOutlet(o.data().outlet_id)).data();
                    return {
                        ...o.data(),
                        outlet,
                    };
                })
            );

            //docs to lists
            branchesList = branchesList.map((branch) => ({
                value: branch.id,
                label: branch.data().En_name,
                data: branch.data(),
            }));
            let outletsList = linkedOutlets.map((o) => ({
                value: o.outlet_id,
                label: o.outlet.En_short_name,
                data: o.outlet,
            }));
            citiesList = citiesList.map((c) => ({
                value: c.id,
                label: c.data().En_name,
                data: c.data(),
            }));

            return { branchesList, outletsList, citiesList };
        } catch (error) {
            throw error;
        }
    }, [companyData]);

    const initFilters = async (context) => {
        try {
            const filters = cloneDeep(FILTERS);
            const { branchesList, outletsList, citiesList } = await loadFiltersOptions();

            filters.supplierBranch.options = branchesList;
            filters.outlet.options = outletsList;
            filters.city.options = citiesList;

            //check if there is any search params in URL
            if (searchParams.get("supplier_branch")) {
                const id = searchParams.get("supplier_branch");
                filters.supplierBranch.initialValue = branchesList.find((b) => b.value === id);
            }
            if (searchParams.get("outlet")) {
                const id = searchParams.get("outlet");
                filters.outlet.initialValue = outletsList.find((b) => b.value === id);
            }
            if (searchParams.get("city")) {
                const id = searchParams.get("city");
                filters.city.initialValue = citiesList.find((b) => b.value === id);
            }

            context.setFilters(filters);
        } catch (error) {
            console.error(error);
        }
        setInitializing(false);
    };

    return {
        initializing,
        loading,
        saving,
        // transferListState,
        leftList,
        rightList,
        checkedList,

        transferHandler,
        filterHandler,
        saveHandler,
        alertState,
        setAlertOpen,
        initFilters,
        applyFilters,
    };
};

export default useLinkOutletBranches;

import { getCities } from "./City";
import { firestore as db } from "../firebase";
import { arrayChunks } from "../../helpers/array-helpers";
import { flattenDeep } from "lodash";
import { queryLinkedOutletBranches } from "./Linked_Outlet_Branches";
import { query } from "./helpers";
import { COLLECTIONS } from "./constants";
import { STATUS } from "../../constants/global";
import { getLinkedOutletBranchesBySupplierID } from "./Branch_Outlet_Branch";

export const queryOutletBranches = async (q) => {
    try {
        return query(COLLECTIONS.OUTLET_BRANCH, q);
    } catch (error) {
        throw error;
    }
};

export let getOutletBranch = async (id) => {
    let outletBranch = null;
    try {
        outletBranch = await db.collection("Outlet_Branch").doc(id).get();
    } catch (error) {
        throw error;
    }

    return outletBranch;
};

export let getOutletBranches = async (IDs, options = { includeCities: false, includeOutletClasses: false }) => {
    let outletBranches = [];

    try {
        outletBranches = await Promise.all(IDs.map(getOutletBranch));
        if (options.includeCities) {
            //gather city IDs for requests
            const citiesIDs = getUniqueCitiesIDs(outletBranches);
            //get cities info
            const citiesDocs = await getCities(citiesIDs);
            //include the cities into their appropriate outlet branches
            outletBranches.forEach((ob) => (ob.cityDoc = citiesDocs.find((c) => c.id === `${ob.data().city_id}`)));
        }
    } catch (error) {
        throw error;
    }
    return outletBranches;
};

export let getOutletBranchesByCompany = async (outletID) => {
    let outletBranches = [];

    try {
        outletBranches = (await db.collection("Outlet_Branch").where("outlet_id", "==", outletID).get()).docs;
        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export let getOutletBranchesByCompanies = async (outletIDs, onlyActive = true) => {
    let outletBranches = [];
    const chunks = arrayChunks(outletIDs, 10);
    try {
        const result = await Promise.all(
            chunks.map(async (chunk) => {
                let ref = db.collection("Outlet_Branch").where("outlet_id", "in", chunk);
                if (onlyActive) ref = ref.where("status", "==", "1");
                return (await ref.get()).docs;
            })
        );
        // outletBranches = (await db.collection("Outlet_Branch").where("outlet_id", "in", outletIDs).get()).docs;
        outletBranches = flattenDeep(result);
        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const getOutletBranchesBySchedules = async (schedules) => {
    try {
        const IDs = [...new Set(schedules.map((s) => s.outlet_branch_id))];

        return await Promise.all(IDs.map((id) => getOutletBranch(id)));
    } catch (error) {
        throw error;
    }
};

export let getUniqueOutletIDFromBranches = (outletBranches) => {
    let outletIDs = [];

    for (const outletBranch of outletBranches) {
        let alreadyIncluded = outletIDs.includes(outletBranch.data().outlet_id);
        if (!alreadyIncluded) outletIDs.push(outletBranch.data().outlet_id);
    }

    return outletIDs;
};

export const getOutletBranchesFromTasks = async (tasks) => {
    try {
        const IDs = [...new Set(tasks.map((t) => `${t.outlet_branch_id}`))];
        const outletBranches = await Promise.all(IDs.map((id) => getOutletBranch(id)));

        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const getOutletBranchesBySupplierBranchCoverage = async (supplierBranchID) => {
    try {
        // const linkedOutletBranches = (await getAllLinkedOutletBranches([supplierBranchID]))[supplierBranchID] ?? [];
        const linkedOutletBranchIDs = (
            await queryLinkedOutletBranches([
                { key: "supplier_branch_id", operator: "==", value: supplierBranchID },
                { key: "status", operator: "==", value: STATUS.ACTIVE },
            ])
        ).map((doc) => doc.data().outlet_branch_id);
        const outletBranches = await getOutletBranches(linkedOutletBranchIDs);

        return outletBranches;
    } catch (error) {
        throw error;
    }
};

export const getSupplierOutletBranches = async (supplieriD) => {
    try {
        const linkedOutletBranches = await getLinkedOutletBranchesBySupplierID(supplieriD);
    } catch (error) {
        throw error;
    }
};

//=============******* utils *******=============//
let getUniqueCitiesIDs = (outletBranchesDocs) => {
    let citiesIDs = outletBranchesDocs.map((doc) => `${doc.data().city_id}`);
    return [...new Set(citiesIDs)];
};

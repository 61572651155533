import React, { createContext, useContext, useEffect, useReducer, useState } from "react";
import { useCallback } from "react";
import { useLocation } from "react-router-dom";
import { PLATFORM_FULL, WEB_USER_ACCOUNT_STATUS } from "../constants/global";
import { auth, functions } from "../services/firebase";
import { logSignIn, logSignOut } from "../services/firestore/Log";
import { getSupplier } from "../services/firestore/Supplier";
import { getUser, queryUser, verifyUser } from "../services/firestore/User";
import { useGeolocation } from "./geolocation-context";
import _ from "lodash";

Storage.prototype.setObject = function (key, value) {
    this.setItem(key, JSON.stringify(value));
};

Storage.prototype.getObject = function (key) {
    var value = this.getItem(key);
    return value && JSON.parse(value);
};

const AuthContext = createContext({
    currentUser: null,
    companyData: null,
    userData: null,
    isSignedIn: false,
    signinError: "",
    signin: async (email, password) => {},
    signout: async () => {},
    sendResetPassword: async (email) => {},
    updateEmail: (email) => {},
    updatePassword: (password) => {},
    sendVerificationEmail: async () => {},
    verifyActionCode: async (code) => {},
    confirmResetPassword: (code, password) => {},
});

const authReducer = (state, action) => {
    if (action.type === "LOCAL_STORAGE_SYNC") {
        return {
            currentUser: action?.value?.currentUser || null,
            companyData: action?.value?.companyData || null,
            userData: action?.value?.userData || null,
            // isSignedIn: action?.value?.isSignedIn || false,
        };
    } else if (action.type === "SIGN_IN") {
        return {
            currentUser: action.value.currentUser,
            companyData: action.value.companyData,
            userData: action.value.userData,
            signinError: "",
        };
    } else if (action.type === "SIGN_OUT") {
        return {
            currentUser: null,
            companyData: null,
            userData: null,
            signinError: state.signinError,
        };
    } else if (action.type === "SIGN_IN_ERROR") {
        return {
            currentUser: null,
            companyData: null,
            userData: null,
            signinError: action.value.signinError,
        };
    } else if (action.type === "UPDATE_COMPANY") {
        return {
            ...state,
            companyData: action.value,
        };
    }
};

const authInit = () => {
    const auth = localStorage.getObject("auth");
    if (!auth?.currentUser || !auth?.userData || !auth?.companyData)
        return {
            currentUser: null,
            companyData: null,
            userData: null,
        };

    const { userData, companyData, currentUser } = auth;
    // in local storage, the date is saved as string. convert it to date
    userData.created_at = new Date(userData.created_at);
    userData.updated_at = new Date(userData.updated_at);
    companyData.created_at = new Date(companyData.created_at);
    companyData.updated_at = new Date(companyData.updated_at);

    return { currentUser, companyData, userData };
};

export const AuthContextProvider = (props) => {
    const { position } = useGeolocation();
    const { pathname } = useLocation();

    const [authState, dispatchAuthState] = useReducer(
        authReducer,
        {
            currentUser: null,
            companyData: null,
            userData: null,
            signinError: "",
        },
        authInit
    );

    const authStateChangeHandler = useCallback(
        async (user) => {
            if (user) {
                try {
                    //if already signed in
                    if (authState.currentUser && _.isEqual(user, authState.currentUser)) return;
                    else if (authState.currentUser && !_.isEqual(user, authState.currentUser)) {
                        dispatchAuthState({
                            type: "LOCAL_STORAGE_SYNC",
                            value: {
                                currentUser: user,
                                companyData: authState.companyData,
                                userData: authState.userData,
                            },
                        });
                        localStorage.setObject("auth", {
                            currentUser: user,
                            companyData: authState.companyData,
                            userData: authState.userData,
                        });
                        return;
                    }

                    setLoading(true);
                    //see if the data is saved in local storage
                    const authObject = localStorage.getObject("auth");
                    //if the user already signed in
                    if (authObject?.currentUser && authObject?.userData && authObject?.companyData) {
                        const { userData: user, companyData: company, currentUser: currUser } = authObject;
                        // in local storage, the date is saved as string. convert it to date
                        user.created_at = new Date(user.created_at);
                        user.updated_at = new Date(user.updated_at);
                        company.created_at = new Date(company.created_at);
                        company.updated_at = new Date(company.updated_at);

                        setLoading(false);
                        dispatchAuthState({
                            type: "SIGN_IN",
                            value: {
                                currentUser: currUser,
                                companyData: company,
                                userData: user,
                            },
                        });
                        return;
                    }
                    //if user was signing in and passed the auth
                    const userData = (await getUser(user.uid))?.data();
                    // user entered correct auth info but has no user document OR incorrect company type OR incorrect platform
                    // if (!userData || userData.company_type !== "supplier" || userData.platform !== PLATFORM) {
                    if (!userData || userData.company_type !== "supplier") {
                        dispatchAuthState({
                            type: "SIGN_IN_ERROR",
                            value: {
                                signinError: "This account is not a supplier account.",
                            },
                        });
                        await signout();
                        setLoading(false);
                        return;
                    }

                    if (userData.status === WEB_USER_ACCOUNT_STATUS.INACTIVE) {
                        dispatchAuthState({
                            type: "SIGN_IN_ERROR",
                            value: {
                                signinError:
                                    "Your account is in verification process. Please wait for the approval by admin.",
                            },
                        });
                        await signout();
                        setLoading(false);
                        return;
                    }

                    // sync user verification status with firestore
                    if (!userData.is_verified && user.emailVerified) {
                        await verifyUser(user.uid);
                        userData.is_verified = true;
                    }
                    // convert firestore timestamp to date object
                    userData.created_at = userData.created_at.toDate();
                    userData.updated_at = userData.updated_at.toDate();

                    const companyData = (await getSupplier(userData?.company_id))?.data();
                    if (companyData) {
                        // convert firestore timestamp to date object
                        companyData.created_at = companyData.created_at.toDate();
                        companyData.updated_at = companyData.updated_at.toDate();
                    }

                    setLoading(false);
                    dispatchAuthState({
                        type: "SIGN_IN",
                        value: { currentUser: user, companyData, userData },
                    });
                    localStorage.setObject("auth", {
                        currentUser: user,
                        companyData,
                        userData,
                    });
                    await logSignIn(user.uid, position);
                } catch (error) {
                    console.log(error);
                    await signout();
                    setLoading(false);
                    return;
                }
            } else {
                //if alraedy signed out
                if (!authState.currentUser) return;
                //if the user is signed in then sign out
                if (authState.currentUser) {
                    logSignOut(authState.currentUser.uid, position, pathname);
                }

                dispatchAuthState({
                    type: "SIGN_OUT",
                    value: { currentUser: null, companyData: null, userData: null },
                });
                localStorage.setObject("auth", {});
            }
        },
        [authState, pathname, position]
    );

    const [loading, setLoading] = useState(false);

    useEffect(() => {
        const authUnsubscribe = auth.onAuthStateChanged(authStateChangeHandler);
        return authUnsubscribe;
    }, [authStateChangeHandler]);

    // const signupHandler = (email, password) => {
    //     return auth.createUserWithEmailAndPassword(email, password);
    // };

    const signin = (email, password) => {
        return auth.signInWithEmailAndPassword(email, password);
        // return superSignin(email);
    };

    const signout = async () => {
        return auth.signOut();
        // superSignout();
    };

    const sendResetPassword = async (email) => {
        const sendResetPassword = functions.httpsCallable("sendSupplierResetPassword");
        return sendResetPassword({ email: email, platform: PLATFORM_FULL });
    };

    const verifyActionCode = async (code, action = "reset-password") => {
        // return auth.verifyPasswordResetCode(code);
        if (action === "reset-password") return auth.verifyPasswordResetCode(code);
        else if (action === "verify-email") return auth.applyActionCode(code);
    };

    const confirmResetPassword = (code, password) => {
        return auth.confirmPasswordReset(code, password);
    };

    const updateEmail = (email) => {
        return authState.currentUser?.updateEmail(email);
    };

    const updatePassword = (password) => {
        return authState.currentUser?.updatePassword(password);
    };

    const sendVerificationEmail = async (email) => {
        // return authState.currentUser?.sendEmailVerification();
        const sendEmailVerification = functions.httpsCallable("verifySupplierEmail");
        return sendEmailVerification({ email: email, platform: PLATFORM_FULL });
    };

    const contextValue = {
        ...authState,
        signin,
        signout,
        sendResetPassword,
        updateEmail,
        updatePassword,
        sendVerificationEmail,
        verifyActionCode,
        confirmResetPassword,
        authLoading: loading,
        setAuthLoading: setLoading,
        // onSignup: signupHandler,
    };

    return <AuthContext.Provider value={contextValue}>{props.children}</AuthContext.Provider>;
};

export const useAuth = () => {
    return useContext(AuthContext);
};

export default AuthContext;

import { USER_TYPES } from "./global";
import PATHS from "./paths";

const DEFAULT_ACCESS = {
    [USER_TYPES.ADMIN]: false,
    [USER_TYPES.BRANCH_MANAGER]: false,
};

const HOME = {
    label: "Dashboard",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    link: PATHS.DASHBOARD,
};
const SERVICES_MENU = {
    label: "Services",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    active: false,
    menu: [
        {
            label: "Merchandising",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            active: false,
            menu: [
                {
                    label: "Product Merchandising",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.OUTSOURCE_PROJECTS,
                },
                {
                    label: "Outlet Merchandising",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Promoting",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
            ],
        },
        {
            label: "One Time Services",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            active: false,
            menu: [
                {
                    label: "Auditing",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Inventory",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Competitor Activity",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Stand Installation and Setup",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Outlet Setup",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Wrapping Services",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Promoter",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
            ],
        },
    ],
};

const OPERATIONS_MENU = {
    label: "Operations",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Visits Management",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.OUTSOURCE_VISITS_MANAGEMENT,
        },
        {
            label: "Reports",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Daily Merchandising Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.DAILY_MERCHANDISING_REPORT,
                },
                {
                    label: "Monthly Merchandising Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Expiry and Damage Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.EXPIRY_AND_DAMAGE_REPORT,
                },
                {
                    label: "Near Expiry Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.NEAR_EXPIRY_REPORT,
                },
                {
                    label: "Out Of Stock Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.OUT_OF_STOCK_REPORT,
                },
                {
                    label: "Drawn and Back Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Drawn From Markets Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Compititor Prices For a Product",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Product Distribution By Outlet",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Outlet Stock Inventory Report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Coverage and Distribution Reports",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
                {
                    label: "Auditing report",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                },
            ],
        },
        {
            label: "Gallery",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.GALLERY,
        },
    ],
};

const FINANCE_MENU = {
    label: "Finance",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Payment Vouchers",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
        },
        {
            label: "Receive Vouchers",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
        },
        {
            label: "Account Statement",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
        },
    ],
};

const MANAGEMENT_MENU = {
    label: "Management",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Users",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: false,
            },
            link: PATHS.USERS,
        },
        {
            label: "Products",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Brands Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.BRANDS,
                },
                {
                    label: "Products Management",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.PRODUCTS,
                },
                {
                    label: "Link Products with Outlets",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_PRODUCTS_WITH_OUTLETS,
                },
                {
                    label: "Link Products with Outlet Branches",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_PRODUCTS_WITH_OUTLET_BRANCHES,
                },
                {
                    label: "Product Stock Settings",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.PRODUCT_STOCK_SETTINGS,
                },
                // {
                //     label: "Link Products to Outlets",
                //     access: {
                //         ...DEFAULT_ACCESS,
                //         [USER_TYPES.ADMIN]: true,
                //         [USER_TYPES.BRANCH_MANAGER]: true,
                //     },
                //     link: PATHS.LINKED_OUTLETS,
                // },
            ],
        },
        {
            label: "Extra Display Contracts",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.EXTRA_DISPLAY_CONTRACTS,
        },
    ],
};

const DATA_MENU = {
    label: "Co. Data",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    menu: [
        {
            label: "Branches",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            link: PATHS.BRANCHES,
        },
        {
            label: "Outlets",
            access: {
                ...DEFAULT_ACCESS,
                [USER_TYPES.ADMIN]: true,
                [USER_TYPES.BRANCH_MANAGER]: true,
            },
            menu: [
                {
                    label: "Outlets Selection",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_OUTLETS,
                },
                {
                    label: "Distribution Method Selection",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.SELECT_DISTRIBUTION_METHOD,
                },
                {
                    label: "Outlet Branches Selection",
                    access: {
                        ...DEFAULT_ACCESS,
                        [USER_TYPES.ADMIN]: true,
                        [USER_TYPES.BRANCH_MANAGER]: true,
                    },
                    link: PATHS.LINK_OUTLET_BRANCHES,
                },
            ],
        },
    ],
};

const HELP_MENU = {
    label: "Help",
    access: {
        ...DEFAULT_ACCESS,
        [USER_TYPES.ADMIN]: true,
        [USER_TYPES.BRANCH_MANAGER]: true,
    },
    link: PATHS.HELP,
};

const NAV_MENU = [
    { ...HOME },
    { ...SERVICES_MENU },
    { ...OPERATIONS_MENU },
    { ...FINANCE_MENU },
    { ...MANAGEMENT_MENU },
    { ...DATA_MENU },
    { ...HELP_MENU },
];

export default NAV_MENU;
